import { Button } from '@progress/kendo-react-buttons';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Loader } from '@progress/kendo-react-indicators';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { useState } from 'react';
import CenterDivPanel from '../../components/CenterDivPanel';
import { fetchApi } from '../../services/api';

enum DocType {
  BOL,
  CustomsInvoice,
  CarrierAgreementForm,
  CarrierInvoice,
  Email
}

interface ClassifiedPage {
  PageNumber: number;
  DocType: DocType;
  Confidence: number;
}

interface ViewModel {
  TrainedVersion: string;
  Files: string[];
  ClassifiedPages: ClassifiedPage[];
}

const DisplayClassifiedLabel = (docType: DocType, Confidence: number) => {
  switch (docType) {
    case DocType.BOL:
    case DocType.CustomsInvoice:
      if (Confidence >= 0.70)
        return (<span className="badge badge-success">ACCEPTED</span>);
      return (<span className="badge badge-warning">REJECTED (LOW CONFIDENCE)</span>);
    default:
      return (<span className="badge badge-warning">REJECTED</span>);
  }

}

const DownloadClassifiedEpayDocs = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [epayTransactionId, setEpayTransactionId] = useState<number>(0);
  const [tripNumber, setTripNumber] = useState<number>(0);
  const [transactionStatusNote, setTransactionStatusNote] = useState('');

  const [viewModel, setViewModel] = useState<ViewModel>();
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  const _classifyAndDownloadDocs = () => {
    setLoading(true);

    const data = {
      EpayTransactionId: epayTransactionId ?? 0,
      TripNumber: tripNumber ?? 0
    };

    fetchApi(`/api/Settlements/DownloadClassifiedEpayDocs`, data, 'POST')
      .then((resp) => {
        setLoading(false);
        setViewModel(resp);

        //if (resp.blob.size > 0)
        //  downloadBlobAsFile(resp.blob, resp.filename);
        //else {
        //  alert('No file returned.');
        //}
      })
      .catch(e => {
        setLoading(false);
        if (!e?.status) alert('An error occurred.');
      });
  };


  //useEffect(() => {
  //  // Convert each byte array to a Blob and create an object URL for it
  //  const urls = viewModel?.Files.map((byteArray) => {
  //    const blob = new Blob([byteArray], { type: 'image/jpeg' });
  //    return URL.createObjectURL(blob);
  //  });

  //  setImageUrls(urls);

  //  // Clean up the object URLs when the component unmounts
  //  return () => {
  //    urls?.forEach(URL.revokeObjectURL);
  //  };
  //}, [viewModel?.Files]);

  //const _classifyAndDownloadDocs = () => {
  //  setLoading(true);

  //  const data = {
  //    EpayTransactionId: epayTransactionId,
  //  };

  //  fetchByteData(`/api/Settlements/DownloadClassifiedEpayDocs`, data, 'POST')
  //    .then((resp) => {
  //      setLoading(false);
  //      if (resp.blob.size > 0)
  //        downloadBlobAsFile(resp.blob, resp.filename);
  //      else {
  //        alert('No file returned.');
  //      }
  //    })
  //    .catch(async e => {
  //      setLoading(false);
  //      if (!e?.status) alert('An error occurred.');
  //    });
  //};

  const _approve = () => {
    setLoading(true);

    const data = {
      EpayTransactionId: epayTransactionId,
      EpayTransactionStatus: 124,
      TransactionStatusNote: '',
      TripNumber: tripNumber
    };

    fetchApi(`/api/Settlements/DownloadClassifiedEpayDocs`, data, 'POST')
      .then((resp) => {
        setLoading(false);
      }
      ).catch((e) => {
        setLoading(false);
        if (!e?.status) alert('An error occurred.');
      });
  };

  const _reject = () => {
    setLoading(true);

    const data = {
      EpayTransactionId: epayTransactionId,
      EpayTransactionStatus: 122,
      TransactionStatusNote: transactionStatusNote,
      TripNumber: tripNumber
    };

    fetchApi(`/api/Settlements/DownloadClassifiedEpayDocs`, data, 'POST')
      .then((resp) => {
        setLoading(false);
      }
      ).catch((e) => {
        setLoading(false);
        if (!e?.status) alert('An error occurred.');
      });
  };

  return <div className="container-fluid">
    {loading && <div className="container"><CenterDivPanel>
      <Loader type="converging-spinner" />
    </CenterDivPanel></div>}
    <h2 className="mt-4">Epay AI Document Classifier {viewModel?.TrainedVersion}</h2>
    <div className="row">
      <div className="col-4">
        <FieldWrapper className="mt-2">
          <NumericTextBox
            placeholder="Epay Transaction ID"
            min={0}
            format="0"
            disabled={loading}
            value={epayTransactionId}
            label="Epay Transaction ID"
            onChange={(e) => setEpayTransactionId(e.value)}
          />
        </FieldWrapper>
        <FieldWrapper className="mt-2">
          <NumericTextBox
            placeholder="Trip Number"
            min={0}
            format="0"
            disabled={loading}
            value={tripNumber}
            label="Trip Number"
            onChange={(e) => setTripNumber(e.value)}
          />
        </FieldWrapper>
        {/*<FieldWrapper>*/}
        {/*  <Label>Note</Label>*/}
        {/*  <TextArea*/}
        {/*    maxLength={300}*/}
        {/*    rows={4}*/}
        {/*    placeholder="If applicable, enter a rejection note..."*/}
        {/*    value={transactionStatusNote}*/}
        {/*    onChange={(e: any) => setTransactionStatusNote(e.value)}*/}
        {/*  />*/}
        {/*</FieldWrapper>*/}
        <div className="k-form-buttons mt-4" style={{ display: 'block' }}>
          <Button themeColor="primary" disabled={loading || (Number(epayTransactionId) <= 0 && Number(tripNumber) <= 0)} onClick={() => _classifyAndDownloadDocs()}>
            Analyze Docs
          </Button>
          {/*<Button themeColor="success" disabled={loading || epayTransactionId === 0} className="ml-4" onClick={() => _approve()}>*/}
          {/*  Mark as Approved*/}
          {/*</Button>*/}
          {/*<Button themeColor="error" disabled={loading || epayTransactionId === 0 || isNullOrWhiteSpace(transactionStatusNote)} className="ml-2" onClick={() => _reject()}>*/}
          {/*  Mark as Rejected*/}
          {/*</Button>*/}
        </div>
      </div>
      <div className="col-8">
        <div style={{ height: '80vh', overflowY: 'scroll', overflowX: 'hidden' }}>
          {viewModel?.Files?.map((base64String, index) => (
            <div key={index} className="row">
              <div className="col-auto">
                <img src={`data:image/jpeg;base64,${base64String}`} alt={`Document Page ${index + 1}`} width={600} style={{ marginTop: 6 }} />
              </div>
              <div className="col-auto p-0">
                <div>
                  <label className="mr-1">Page #</label>
                  <span>{viewModel?.ClassifiedPages[index].PageNumber}</span>
                </div>
                <div>
                  <label className="mr-1">Type:</label>
                  <span>{DocType[viewModel?.ClassifiedPages[index].DocType]}</span>
                </div>
                <div>
                  <label className="mr-1">Confidence:</label>
                  <span>{Math.round(viewModel?.ClassifiedPages[index].Confidence * 100)}%</span>
                </div>
                <div>
                  {DisplayClassifiedLabel(viewModel?.ClassifiedPages[index].DocType, viewModel?.ClassifiedPages[index].Confidence)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
}

export default DownloadClassifiedEpayDocs;
