import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { Grid, GridCellProps, GridColumn as Column, GridDetailRowProps, GridEvent, GridExpandChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { arrowRotateCwIcon, filterClearIcon } from '@progress/kendo-svg-icons';
import Moment from 'moment-timezone';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import CenterDivPanel from '../../../components/CenterDivPanel';
import SimpleDateRangePicker from '../../../components/SimpleDateRangePicker';
import { fetchApi } from '../../../services/api';
import { Title } from '../../../utils/title';
import { TotalChargesCell, TotalChargesHeaderCell } from '../TotalChargesCell';
import InvoiceGatewayCommunicationPartnerComboBox, { InvoiceGatewayCommunicationPartner } from './InvoiceGatewayCommunicationPartnerComboBox';
import InvoiceGatewayCustomerMultiColumnComboBox, { InvoiceGatewayPartner } from './InvoiceGatewayCustomerMultiColumnComboBox';
import InvoiceGatewayCustomerSummaryDetailComponent from './InvoiceGatewayCustomerSummaryDetailComponent';

interface InvoiceGatewayContextType {
  invoiceGatewayPartners: InvoiceGatewayPartner[];
  invoiceGatewayCommunicationPartners: InvoiceGatewayCommunicationPartner[];
}

export const InvoiceGatewayContext = React.createContext<InvoiceGatewayContextType | null>(null);

export type PostDataState = {
  skip: number,
  take: number,
  sort: string,
  filters: PostDataFilter,
};

export enum FilterGroupByOption {
  PostBatch,
  PostBatchAndCustomer
}

export enum FilterInvoiceStatusRecordTypeOption {
  BalanceDue = 1
}

export type PostDataFilter = {
  FromDateTime: Date,
  ToDateTime: Date,
  AllowRefreshingOfComponent: boolean,
  OrderNumber?: number | null,
  PostBatchID?: number | null,
  CustomerID?: number | null,
  SentStatus?: number | null,
  CommunicationID?: string | null,
  GroupBy?: FilterGroupByOption,
  InvoiceStatusRecordType?: FilterInvoiceStatusRecordTypeOption,
  UsesInvoiceEmailModule?: boolean;
};

export interface InvoiceBatchSummary {
  TotalCharges: number;
  InvoicePostBatchID: number;
  PostedDateTime: Date;
  CustomerCount: number;
  InvoiceCount: number;
  InvoiceProcessedCount: number;
  InvoiceFailureCount: number;
  InvoiceProcessingCount: number;
  InvoiceNotSentCount: number;
  HasErrors: boolean;
  Expanded?: boolean;

  Details?: InvoiceCustomerSummary[];
}

export interface InvoiceCustomerSummary {
  TotalCharges: number;
  InvoicePostBatchID: number;
  PostedDateTime: Date;
  InvoiceStatus: number;
  LocationID: number;
  CustomerID: number;
  CustomerName: string;
  InvoiceCount: number;
  InvoiceProcessedCount: number;
  InvoiceFailureCount: number;
  InvoiceProcessingCount: number;
  InvoiceNotSentCount: number;
  HasErrors: boolean;
  APICredID: number;
  InvoiceEmailRecord: boolean;
  TransferMethodValue: string;
  Expanded?: boolean;

  Details?: InvoiceDetail[];
}

export interface InvoiceDetail {
  TotalCharges: number;
  InvoicePostBatchID: number;
  PostedDateTime: Date;
  InvoiceStatus: number;
  InvoiceStatusName: string;
  LocationID: number;
  CustomerID: number;
  CustomerName: string;
  TotalRecords: number;
  ReferenceNumber1: string;
  ReferenceNumber2: string;
  ReferenceNumber3: string;
  ReferenceNumber4: string;
  InvoiceNumber: string;
  OrderNumber: number;
  OrderID: number;
  InvoiceID: number;
  InvoiceSentStatusComment: string;
  InvoiceSequence: number;
  InvoiceLetter: string;
  APICredID: number;
  InvoiceEmailRecord: boolean;
  ShowAllReferenceNumbers: boolean;

  InvoiceGatewayOrderPoints?: InvoiceGatewayOrderPoint;
}

export interface InvoiceGatewayOrderPoint {
  OrderID: number;
  ShipperCustomerID: number;
  ShipperNumber: string;
  ShipperName: string;
  ShipperZip: string;
  ConsigneeCustomerID: number;
  ConsigneeNumber: string;
  ConsigneeName: string;
  ConsigneeZip: string;
  BillToCustomerID: number;
  BillToNumber: string;
  BillToName: string;
  BillToZip: string;
}

export interface UpdateStatusResponse {
  InvoiceID: number;
  Status: number;
  StatusName: string;
}

interface InvoiceBatchSummaryCommandCellProps extends GridCellProps {
  sendPostBatch: (item: InvoiceBatchSummary) => void;
}

const InvoiceBatchSummaryCommandCell = (props: InvoiceBatchSummaryCommandCellProps) => {
  let dataItem: InvoiceBatchSummary = props.dataItem;

  return (
    <td className="k-command-cell">
      <Button themeColor={"primary"} onClick={() => props.sendPostBatch(dataItem)}>
        Send Post Batch
      </Button>
    </td>
  );
};

const InvoiceGateway = () => {
  const [loading, setLoading] = useState(false);
  const [statusButtonGroupOption, setStatusButtonGroupOption] = useState(0);
  const render = useRef(true);
  const defaultPostDataState = {
    skip: 0,
    take: 50,
    //sort: "OrderNumber",
    filters: {
      FromDateTime: Moment().subtract(7, "days").startOf('day').utc().toDate(),
      ToDateTime: Moment().endOf('day').utc().toDate(),
      AllowRefreshingOfComponent: true,
      PostBatchID: null,
      OrderNumber: null,
      CustomerID: null,
      SentStatus: null,
      CommunicationID: null,
      GroupBy: FilterGroupByOption.PostBatch,
      InvoiceStatusRecordType: null,
      UsesInvoiceEmailModule: null
    } as PostDataFilter
  } as PostDataState
  const SESSION_STORAGE_NAME = "InvoiceGatewayFilterV1";
  //const sessionStorageAndFilter = sessionStorage.getItem(SESSION_STORAGE_NAME);
  let pds = { ...defaultPostDataState };
  //if (sessionStorageAndFilter) {
  //  pds = JSON.parse(sessionStorageAndFilter);
  //  pds.filters.FromDateTime = Moment(pds.filters.FromDateTime).utc().toDate();
  //  pds.filters.ToDateTime = Moment(pds.filters.ToDateTime).utc().toDate();
  //}
  const [postDataState, setPostDataState] = useState<PostDataState>(pds);

  const totalRecords = useRef(0);
  const DataState_Take = 50;

  const [invoiceGatewayPartners, setInvoiceGatewayPartners] = useState<InvoiceGatewayPartner[]>();
  const [invoiceGatewayCommunicationPartners, setInvoiceGatewayCommunicationPartners] = useState<InvoiceGatewayCommunicationPartner[]>();
  const [invoiceBatchSummary, setInvoiceBatchSummary] = useState<InvoiceBatchSummary[]>([]);

  const fetchMain = useCallback((append: boolean, _postDataState: PostDataState = null) => {
    setLoading(true);

    const dataGridState = _postDataState == null ? { ...postDataState } : { ..._postDataState };
    if (!append) {
      dataGridState.take = DataState_Take;
      dataGridState.skip = 0;

      document.querySelector('.InvoiceGatway-andFilter .k-grid-content').scrollTop = 0;
      setPostDataState(dataGridState);
    }

    sessionStorage.setItem(SESSION_STORAGE_NAME, JSON.stringify(dataGridState));

    fetchApi(`/api/Billing/GetInvoiceGatewayInvoiceBatches`, dataGridState, 'POST')
      .then(({ Data, Total }) => {
        totalRecords.current = Total;

        if (!append) {

          if (Data.length === 1 && (dataGridState.filters.OrderNumber > 0 || dataGridState.filters.PostBatchID > 0))
            Data[0].Expanded = true;

          setInvoiceBatchSummary(Data);
        } else {
          setInvoiceBatchSummary(prevData => prevData.concat(Data));
        }

        setLoading(false);
      }).catch(async e => {
        setLoading(false);
        if (!e?.status)
          alert('Error: Please see admin');
        else if (e.status !== 404) {
          alert(e?.detail);
        }
      });
  }, [postDataState]);

  useEffect(() => {
    if (render.current === true) {
      render.current = false;
    } else {
      return;
    }
    fetchMain(false);
  }, [fetchMain]);

  //useMessage('refresh', (send, payload) => {
  //  fetchMain(false);
  //});

  const search = useDebouncedCallback(useCallback((o: PostDataState) => {
    fetchMain(false, o);
  }, [fetchMain]), 750);

  const SaveAndFetchPostDataState = (o: PostDataState) => {
    //TODO: Create a new hook to store PostDataState, but jsut for child components.  The 2nd setPostDataState, just for child components will be populated after the search or part of the useDebouncedCallback.search
    invoiceBatchSummary.filter(x => x.Expanded === true).forEach((item, index) => { item.Expanded = false });
    setInvoiceBatchSummary([...invoiceBatchSummary]);

    setPostDataState(o);
    search(o);
  }

  const scrollHandler = (event: GridEvent) => {
    const e = event.nativeEvent;
    if (e.target.scrollTop >= (e.target.scrollHeight - (e.target.clientHeight)) - 1) {
      if (invoiceBatchSummary.length === totalRecords.current)  //When auto-scrolling, ignore when we've hit max records
        return;

      postDataState.skip = postDataState.skip + postDataState.take;

      fetchMain(true);
    }
  }

  //const filterData = (filter: FilterDescriptor) => {
  //  const data = invoiceGatewayPartners.slice();
  //  return filterBy(data, filter);
  //};

  //const filterChange = (event: ComboBoxFilterChangeEvent) => {
  //  event.filter.field = "DataFilterByContents";

  //  const newData =
  //    event.filter.value.length > 1
  //      ? filterData(event.filter)
  //      : invoiceGatewayPartners.slice();

  //  setInvoiceGatewayPartnersSubset(newData);
  //};

  const multiColumnComboBoxColumns = [
    { field: "CustomerNumber", header: "Code", width: "125px" },
    { field: "Name", header: "Customer", width: "300px" },
    { field: "TransferMethodValue", header: "Method", width: "75px" },
    { field: "CommunicationPartner", header: "Partner", width: "300px" }
  ];

  const setBatchAsInProgress = (dataItem: InvoiceBatchSummary) => {
    setLoading(true);

    const isExpanded = dataItem.Expanded;
    if (isExpanded)
      expandGrid(dataItem, false);

    const data = {
      PostBatchNumber: dataItem.InvoicePostBatchID,
      Status: 19, //processing (generic), server will perform the switch  //todo add enum
      InvoiceStatusRecordType: postDataState.filters?.InvoiceStatusRecordType
    };

    fetchApi(`/api/billing/UpdateInvoiceGatewayBatchStatus`, data, 'POST')
      .then((resp: UpdateStatusResponse[]) => {

        if (isExpanded)
          expandGrid(dataItem, true); //could just loop through and retain the expanded value of the object
        //expandGrid(dataItem, false);
        //expandGrid(dataItem, true);
        //resp.forEach((item, index) => {
        //  let invoiceDetail = dataItem.Details.find(x => x.InvoiceID === item.InvoiceID);
        //  invoiceDetail.InvoiceStatusName = item.StatusName;
        //  invoiceDetail.InvoiceStatus = item.Status;
        //});
        //setInvoiceBatchSummary([...invoiceBatchSummary]);
      }).catch(async e => {
        if (!e?.status)
          alert('Error: Please see admin');
        else if (e.status !== 404) {
          alert(e?.detail);
        }
      }).finally(() => setLoading(false));
  }

  const expandChange = (event: GridExpandChangeEvent) => {

    let _eventDataInvoiceBatchSummary = event.dataItem as InvoiceBatchSummary;
    expandGrid(_eventDataInvoiceBatchSummary, event.value);
    //_eventDataInvoiceBatchSummary.Expanded = event.value;
    //setInvoiceBatchSummary([...invoiceBatchSummary]);

    //if (!event.value || event.dataItem.Details) {
    //  return;
    //}
    //fetchCompanyInvoiceDetails(_eventDataInvoiceBatchSummary.CustomerID, _eventDataInvoiceBatchSummary.InvoicePostBatchID);
  };

  const expandGrid = (dataItem: InvoiceBatchSummary, expand: boolean) => {
    dataItem.Expanded = expand;
    setInvoiceBatchSummary([...invoiceBatchSummary]);
  }

  const detail = useCallback((props: GridDetailRowProps) => {
    const dataItem: InvoiceBatchSummary = props.dataItem;
    return (
      <InvoiceGatewayCustomerSummaryDetailComponent
        Filter={{ ...postDataState.filters }}
        InvoiceBatchSummary={dataItem}
      />
    );
  }, [postDataState.filters]);

  return <>
    {loading && <CenterDivPanel>
      <Loader type="converging-spinner" />
    </CenterDivPanel>}
    <Title string="Invoice Gateway" />
    <h4 className="text-left mt-3">Invoice Gateway</h4>
    <InvoiceGatewayContext.Provider value={{
      invoiceGatewayPartners: invoiceGatewayPartners,
      invoiceGatewayCommunicationPartners: invoiceGatewayCommunicationPartners
    }}>
      <Grid
        className='InvoiceGatway-andFilter'
        style={{
          maxHeight: `${window.innerHeight * .80}px`,
        }}
        data={invoiceBatchSummary}
        total={totalRecords.current}
        onScroll={scrollHandler}
        resizable={true}
        detail={detail}
        expandField="Expanded"
        onExpandChange={expandChange}
      >
        <GridToolbar size="small">
          <Button
            icon="refresh"
            svgIcon={arrowRotateCwIcon}
            title="Refresh"
            size="small"
            onClick={() => fetchMain(false)}
          />

          <InvoiceGatewayCustomerMultiColumnComboBox
            preSelectedInvoiceGatewayPartner={{ CustomerId: postDataState.filters.CustomerID, UsesInvoiceEmailModule: postDataState.filters.UsesInvoiceEmailModule }}
            selectedInvoiceGatewayPartner={(e, f) => SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, CustomerID: e, UsesInvoiceEmailModule: f } })}
            returnedInvoiceGatewayPartners={(e) => setInvoiceGatewayPartners(e)}
          />
          <InvoiceGatewayCommunicationPartnerComboBox
            preSelectedId={postDataState.filters.CommunicationID}
            selectedId={(e) => SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, CommunicationID: e } })}
            returnedInvoiceGatewayCommunicationPartners={(e) => setInvoiceGatewayCommunicationPartners(e)}
          />
          <ButtonGroup>
            <Button onClick={() => {
              if (statusButtonGroupOption !== 1) {
                setStatusButtonGroupOption(1);
                SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, SentStatus: 1 } });
              } else {
                setStatusButtonGroupOption(0);
                SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, SentStatus: 0 } });
              }
            }} selected={statusButtonGroupOption === 1} togglable={true}>INV. NOT SENT</Button>
            <Button onClick={() => {
              if (statusButtonGroupOption !== 2) {
                setStatusButtonGroupOption(2);
                SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, SentStatus: 2 } });
              } else {
                setStatusButtonGroupOption(0);
                SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, SentStatus: 0 } });
              }
            }} selected={statusButtonGroupOption === 2} togglable={true}>INV. W/ERRORS</Button>
            <Button onClick={() => {
              if (statusButtonGroupOption !== 3) {
                setStatusButtonGroupOption(3);
                SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, SentStatus: 3 } });
              } else {
                setStatusButtonGroupOption(0);
                SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, SentStatus: 0 } });
              }
            }} selected={statusButtonGroupOption === 3} togglable={true}>INV. PROCESSING</Button>
          </ButtonGroup>
          <span>
            <label className="mt-1">Post Batch Date Range:</label>
            <SimpleDateRangePicker
              value={{ start: postDataState.filters.FromDateTime, end: postDataState.filters.ToDateTime }}
              onChange={(e) => {
                setPostDataState({ ...postDataState, filters: { ...postDataState.filters, FromDateTime: e.start, ToDateTime: e.end } });
                if (e && e.start && e.end) {
                  SaveAndFetchPostDataState({
                    ...postDataState, filters: {
                      ...postDataState.filters,
                      FromDateTime: Moment(e.start).startOf('day').utc().toDate(),
                      ToDateTime: Moment(e.end).endOf('day').utc().toDate()
                    }
                  });
                }
              }}
            />
          </span>

          <NumericTextBox
            value={postDataState.filters?.OrderNumber}
            placeholder='Order Number'
            min={0}
            spinners={false}
            width={130}
            format="0"
            onChange={(e) => {
              SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, OrderNumber: e?.value } });
            }}
          />

          <NumericTextBox
            value={postDataState.filters?.PostBatchID}
            placeholder='Post Batch Number'
            min={0}
            spinners={false}
            width={130}
            format="0"
            onChange={(e) => {
              SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, PostBatchID: e?.value } });
            }}
          />


          <Button
            onClick={() => {
              if (postDataState.filters?.InvoiceStatusRecordType === FilterInvoiceStatusRecordTypeOption.BalanceDue)
                SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, InvoiceStatusRecordType: null } });
              else
                SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, InvoiceStatusRecordType: FilterInvoiceStatusRecordTypeOption.BalanceDue } });
            }}
            selected={postDataState.filters?.InvoiceStatusRecordType === FilterInvoiceStatusRecordTypeOption.BalanceDue}
            togglable={true} themeColor={postDataState.filters?.InvoiceStatusRecordType === FilterInvoiceStatusRecordTypeOption.BalanceDue ? 'error' : 'base'}>
            Show Balance Due Invoices Only
          </Button>


          <Button
            title="Clear All Filters and Sort"
            icon="filter-clear"
            svgIcon={filterClearIcon}
            onClick={() => {
              setStatusButtonGroupOption(0);
              SaveAndFetchPostDataState({ ...defaultPostDataState });
            }}
          />
        </GridToolbar>
        <Column field="InvoicePostBatchID" title="Post Batch Number" headerCell={TotalChargesHeaderCell} className="text-right" />
        <Column field="CustomerCount" title="Total Customers" headerCell={TotalChargesHeaderCell} className="text-right" />
        <Column field="TotalCharges" title="Total Charges" headerCell={TotalChargesHeaderCell} cell={TotalChargesCell} />
        <Column field="InvoiceCount" title="Total Invoices" headerCell={TotalChargesHeaderCell} className="text-right" />
        <Column field="InvoiceProcessedCount" title="Invoices Sent" headerCell={TotalChargesHeaderCell} className="text-right" />
        <Column field="InvoiceFailureCount" title="Invoices w/Errors" headerCell={TotalChargesHeaderCell} className="text-right" />
        <Column width={130} cell={(props: GridCellProps) => <InvoiceBatchSummaryCommandCell {...props} sendPostBatch={(e) => { setBatchAsInProgress(e); }} />} />
      </Grid>
    </InvoiceGatewayContext.Provider>
    {
      totalRecords.current > 0 &&
      <div className="k-pager k-pager-md k-grid-pager">
        <div style={{ marginLeft: "auto", marginRight: 0 }}>
          {postDataState.skip + postDataState.take > totalRecords.current ? totalRecords.current : postDataState.skip + postDataState.take} of {totalRecords.current} items
        </div>
      </div>
    }
  </>;
}

export default InvoiceGateway;
