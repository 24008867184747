import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, DropDownButton, DropDownButtonItem } from "@progress/kendo-react-buttons";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { AutoComplete, AutoCompleteChangeEvent, ListItemProps } from "@progress/kendo-react-dropdowns";
import { useHistory, useParams } from "react-router";
import Moment from 'moment-timezone';
import { useDebouncedCallback } from "use-debounce";
import LoadingPanel from "../../../components/LoadingPanel";
import { fetchApi } from "../../../services/api";
import { ISingleSearchLocation, singleSearch } from "../../../services/pcmiler";
import { ILink } from "../../../types/link";
import PayEditable from "./PayEditable";
import { CalculatePayRateResult } from "TypeGen/Pay/calculate-pay-rate-result";
import { PayLineItem } from "TypeGen/Order/Planning/Vehicle/pay-line-item";
import { DispatchStatusType } from "TypeGen/Order/dispatch-status-type";
import { RadioButton } from "@progress/kendo-react-inputs";
import { JsonResponse } from "TypeGen/json-response";

type OrderPlanningVehicleDetails = {
  VehicleNumber: string;
  VehicleMPH: number;
  AvailableNote: string;
  ShipDateTime: Date;
  DispatchStatus: DispatchStatusType;
  DeadHeadStartDateTime: Date;
  LocationOptions: LocationOption[];
  Pay: CalculatePayRateResult;
  ExistingPay: CalculatePayRateResult | null;

  Errors: string[];
  Warnings: string[];

  Links: ILink[];
}

type LocationOption = {
  // For Display Only
  Label: string;
  Name: string;
  DeadHeadMiles: number;

  // Selected
  CityID?: number;

  // Manually Entered
  City: string;
  State: string;
  Zip: string;
}

type RouteComponentParams = {
  orderId: string;
  vehicleId: string;
};

const OrderPlanningVehicle = () => {
  const history = useHistory();
  const { orderId: orderIdParam, vehicleId: vehicleIdParam } = useParams<RouteComponentParams>();
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState<OrderPlanningVehicleDetails>();

  // Location AutoComplete
  const locationInputRef = useRef<AutoComplete>(null);
  const [locationOptions, setLocationOptions] = useState<ISingleSearchLocation[]>([]);
  const [locationLoading, setLocationLoading] = useState(false);
  const [locationValue, setLocationValue] = useState('');

  // Options
  const [locationOption, setLocationOption] = useState<LocationOption>();
  const [deadHeadStartDateTime, setDeadHeadStartDateTime] = useState<Date>();
  const [useExistingPay, setUseExistingPay] = useState<"true" | "false">("false");

  const refreshOrderDetails = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Order/PlanningDetailsVehicle/${orderIdParam}/${vehicleIdParam}`)
      .then((response: OrderPlanningVehicleDetails) => {
        setLocationOption(response.LocationOptions[0]);
        setDeadHeadStartDateTime(Moment.utc(response.DeadHeadStartDateTime).toDate());
        setDetails(response);
        if (response.ExistingPay) setUseExistingPay("true");
        setLoading(false);
      })
      .catch(err => {
        alert(err);
        setLoading(false);
      });
  }, [orderIdParam, vehicleIdParam]);

  const calculateEtaWarning = useCallback(() => {

    if (!details) return '';

    const eta = Moment.utc(deadHeadStartDateTime).add(locationOption.DeadHeadMiles / details.VehicleMPH, 'hours');
    const schedule = Moment.utc(details.ShipDateTime);

    return eta.isAfter(schedule) ? `Calculated ETA to first pick-up (${eta.tz('America/New_York').format("MM/DD/YYYY HH:mm")}) is later than scheduled pick-up date/time (${schedule.tz('America/New_York').format("MM/DD/YYYY HH:mm")})` : '';
  }, [details, locationOption, deadHeadStartDateTime]);

  const dispatchOrder = (dispatchStatus: DispatchStatusType) => {
    setLoading(true);

    const payload = {
      DispatchStatus: dispatchStatus,
      Location: locationOption,
      DeadHeadStartDateTime: deadHeadStartDateTime,
      PayLineItems: (useExistingPay == 'false' || details.ExistingPay == null) ? details.Pay.LineItems.map(x => {
        return {
          RateDescriptionID: x.RateDescriptionID,
          Description: x.Description,
          Rate: x.Rate,
          RateUoM: x.RateUoM,
          Quantity: x.Quantity,
          QuantityUoM: x.QuantityUoM
        } as PayLineItem}
      ) : undefined
    };

    const command = details.Links.find(x => x.Name === 'Dispatch' || x.Name === 'Plan');
    fetchApi(command.Link, payload, command.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          history.push(response.Links.find(x => x.Name === "Track").Link);
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(e => {
        // If not problem details
        if (!e?.status) alert('Unable to dispatch');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const unPlanOrder = () => {
    setLoading(true);

    const command = details.Links.find(x => x.Name === 'UnPlan');
    fetchApi(command.Link, {}, command.Method)
    .then((response: JsonResponse) => {
      if (response.Success) {
        history.push(response.Links.find(x => x.Name === "Track").Link);
      } else {
        alert(response.ErrorMessage);
      }
    })
    .catch(e => {
      // If not problem details
      if (!e?.status) alert('Unable to un-plan');
    })
    .finally(() => {
      setLoading(false);
    });
  }

  const renderLocation = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = <span>{itemProps.dataItem.ShortString}</span>;
    return React.cloneElement(li, li.props, itemChildren);
  }

  const updateLocationsDebounced =  useDebouncedCallback((searchTerm: string) => {
    setLocationLoading(true);
    singleSearch(searchTerm)
      .then((response) => {
        setLocationLoading(false);
        setLocationOptions(response.Locations);
      })
      .catch(() => {
        setLocationLoading(false);
        setLocationOptions([]);
      })
  }, 350);

  const etaWarning = calculateEtaWarning();

  useEffect(() => {
    refreshOrderDetails();
  }, [orderIdParam, vehicleIdParam, refreshOrderDetails]);

  if (loading && !details) {
    return <LoadingPanel />;
  }

  return <div className="card my-2">
    <div className="card-body">
      <h5 className="card-title text-center">Plan Vehicle # {details.VehicleNumber}</h5>
      {details.Errors.map((error, index) => <div className="alert alert-danger" key={index}>
        {error}
      </div>)}
      {details.Warnings.map((warning, index) => <div className="alert alert-warning" key={index}>
        {warning}
      </div>)}
      {etaWarning && <div className="alert alert-warning">
        {etaWarning}
      </div>}
      {details.AvailableNote && <div className="alert alert-dark" role="alert">
        {details.AvailableNote}
      </div>}
      {details.LocationOptions.map((x, index) => <div className="form-group row" key={index}>
        <label className="col-form-label form-check-label col-md-3" htmlFor={`location${index}`}>{x.Label}</label>
        <div className="col-md-9 mt-1 pl-4">
          <input
            className="form-check-input"
            type="radio"
            name="locations"
            id={`location${index}`}
            defaultChecked={index === 0}
            onChange={() => {
              setLocationOption(x);
              setLocationValue('');
            }}
          />
          <span className="align-middle">
            {x.Name}
          </span>
        </div>
      </div>)}
      <div className="form-group row">
        <label className="col-form-label form-check-label col-md-3" htmlFor="location">Another City, State</label>
        <div className="col-md-9 mt-1 pl-4">
          <input
            className="form-check-input"
            type="radio"
            name="locations"
            id="location"
            onChange={(e) => {
              e.preventDefault();
              locationInputRef.current?.focus();
            }}
            checked={locationOption?.Label === "Another City, State"}
          />
          <AutoComplete
            ref={locationInputRef}
            data={locationOptions}
            value={locationValue}
            loading={locationLoading}
            placeholder={`Enter Location`}
            itemRender={renderLocation}
            onChange={(event: AutoCompleteChangeEvent) => {

              // Update Location
              let location = event.target.value;
              if (typeof event.target.value === 'object') {
                const place = event.target.value as ISingleSearchLocation;
                location = place.Address.Zip + ' ' + place.Address.City + ', ' + place.Address.State;
                setLocationOption({
                  Name: '',
                  Label: 'Another City, State',
                  DeadHeadMiles: 0,
                  City: place.Address.City,
                  State: place.Address.State,
                  Zip: place.Address.Zip,
                });
              }

              setLocationValue(location);

              // Update Autocomplete Suggestions
              if (typeof event.target.value !== 'object' && event.target.value.length > 2) {
                updateLocationsDebounced(event.target.value);
              }
            }}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="deadHeadStartTime" className="col-form-label col-md-3">Dead Head Start Time</label>
        <div className="col-md-9">
          <DateTimePicker
            required
            width={210}
            format="MM/dd/yyyy HH:mm"
            id="deadHeadStartTime"
            value={deadHeadStartDateTime}
            onChange={(e) => setDeadHeadStartDateTime(e.value)}
          />
        </div>
      </div>
      {details.ExistingPay && <>
        <RadioButton
          className="my-2"
          name="existingPay"
          value="true"
          checked={useExistingPay === "true"}
          label="Use Existing Pay"
          onChange={(e) => setUseExistingPay(e.value)}
        />
        <PayEditable
          editable={false}
          data={details.ExistingPay}
        />
        <RadioButton
          className="my-2"
          name="existingPay"
          value="false"
          checked={useExistingPay === "false"}
          label="Set New Pay"
          onChange={(e) => setUseExistingPay(e.value)}
        />
      </>}
      <PayEditable
        data={details.Pay}
        onChange={(pay) => {
          setDetails({ ...details, Pay: pay });
          setUseExistingPay("false");
        }}
      />
      <div className="text-center mt-3">
        <Button themeColor="secondary" onClick={() => history.push(`/Order/Planning/${orderIdParam}`)}>
          Back
        </Button>
        {details.Links.find(x => x.Name === 'UnPlan') && <Button
          themeColor="error"
          className="ml-2"
          onClick={unPlanOrder}
          disabled={loading}
        >
          Un-Plan Order
        </Button>}
        {details.Links.find(x => x.Name === 'Plan') && <DropDownButton
          themeColor="primary"
          className="ml-2"
          disabled={details.Errors.length > 0 || (useExistingPay === "false" && details.Pay.Total === 0) || loading}
          onItemClick={(e) => dispatchOrder(e.itemIndex === 0 ? DispatchStatusType.PlannedReady : DispatchStatusType.PlannedOnHold)}
          text="Plan Order"
        >
          <DropDownButtonItem text="Auto Dispatch" disabled={details.DispatchStatus === DispatchStatusType.PlannedReady} />
          <DropDownButtonItem text="On-Hold Dispatch" disabled={details.DispatchStatus === DispatchStatusType.PlannedOnHold} />
        </DropDownButton>}
        {details.Links.find(x => x.Name === 'Dispatch') && <Button
          themeColor="success"
          className="ml-2"
          disabled={details.Errors.length > 0 || (useExistingPay === "false" && details.Pay.Total === 0) || loading}
          onClick={() => dispatchOrder(DispatchStatusType.Started)}
        >
          Dispatch Order
        </Button>}
      </div>
    </div>
  </div>
}

export default OrderPlanningVehicle;