import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";

export default class YesNoCell extends React.Component<GridCellProps> {
  render() {
    if (!this.props.field || this.props.rowType !== 'data') return null;

    return (
      <td>
        {this.props.dataItem[this.props.field] === null ? 'Unknown' : this.props.dataItem[this.props.field] ? 'Yes' : 'No'}
      </td>
    );
  }
}
