import { Dialog } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import AuditInfo, { AuditInfoViewModel } from "../../components/AuditInfo";
import CenterDivPanel from "../../components/CenterDivPanel";
import { EditableAddress } from "../../components/EditableAddress";
import { EditableField } from "../../components/EditableField";
import { fetchApi } from "../../services/api";
import { AddressViewModel } from '../../types/address';
import { IDName } from '../../types/idname';
import { Title } from '../../utils/title';
import { OwnerNavBar } from "./NavBar";
import { JsonResponse } from "TypeGen/json-response";
import { Link } from "react-router-dom";

type Props = {
  isDialog?: boolean
  ownerId?: number;

  onClose?: () => void;
}

type RouteComponentParams = {
  ownerId: string;
};

type OwnerProfileType = {
  Owner: OwnerType,
  Users: IDName[];
}

export type OwnerType = {
  ModNum: number;
  OwnerNumber: string;
  FirstName: string;
  LastName: string;
  CompanyName: string;
  Active: boolean;
  EMailAddress: string;
  CellPhone1: string;
  HomePhone1: string;
  OwnerType: number;
  FleetCardPolicy: number;
  SecurityNumber: string;
  ACHBankName: string;
  ACHBankAccountNumber: string;
  ACHTransitRoutingNumber: string;
  VendorID: string;
  FederalTaxpayerID: string;
  Address: AddressViewModel;
  AuditInfo: AuditInfoViewModel;
}

export const OwnerTypes = [{ ID: 1, Name: 'OWNER OPERATOR' }, { ID: 2, Name: 'FLEET OWNER' }, { ID: 3, Name: 'COMPANY VEHICLE' }, { ID: 4, Name: 'PARTNER VEHICLE' }] as IDName[];
const FleetCardPolicies = [{ ID: 1, Name: 'DEFAULT' }, { ID: 2, Name: 'COMPANY DRIVER' }, { ID: 3, Name: 'O/O WITH ADVANCE' }, { ID: 4, Name: 'O/O WITHOUT ADVANCE' }, { ID: 5, Name: 'SHOP' }] as IDName[];

const OwnerProfile = (props: Props) => {

  const { ownerId: ownerIdParam } = useParams<RouteComponentParams>();
  const [ownerId, setOwnerId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<OwnerProfileType>();

  const fetchProfile = useCallback(() => {
    async function fetchData() {
      setLoading(true);
      await fetchApi(`/api/Asset/Owner/${ownerId}`)
        .then(data => {
          setLoading(false);
          setProfile(data);
        })
        .catch(err => {
          alert(err);
          setLoading(false);
        });
    }
    fetchData();
  }, [ownerId]);

  useEffect(() => {
    if (props.ownerId > 0)
      setOwnerId(props.ownerId);
    else
      setOwnerId(Number(ownerIdParam));
  }, [ownerIdParam, props.ownerId]);

  useEffect(() => {
    if (ownerId > 0)
      fetchProfile();
  }, [ownerId, fetchProfile]);

  const saveAsyncProfile = async (value: Partial<OwnerType>): Promise<void> => {
    let errorMsg = '';
    const newProfile: OwnerType = Object.assign({}, profile.Owner, value);
    await fetchApi(`/api/Asset/Owner/${ownerId}`, newProfile, 'PUT')
      .then((response: JsonResponse) => {
        if (response.Success === false) {
          errorMsg = response.ErrorMessage;
        } else {
          const newProfile: OwnerType = Object.assign({}, profile.Owner, value);
          setProfile({ ...profile, Owner: { ...newProfile, ModNum: newProfile.ModNum + 1 } });
        }
      }).catch((e) => {
        if (e?.status) {
          return Promise.reject(e);
        } else {
          errorMsg = "Unknown Error Occurred!";
        }
      });
    if (errorMsg.length) {
      alert(errorMsg);
      return Promise.reject(errorMsg);
    }
    return Promise.resolve();
  }

  const dataView = () => {
    if (loading || !profile) {
      return <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>;
    }

    return (<>
      {props.isDialog !== true && ownerId > 0 && <OwnerNavBar id={ownerId} currentTab="Profile" />}
      <Title string={profile.Owner.OwnerNumber} />
      <div className="row m-2">
        <div className="col-md-6">
          <div className="form-group row">
            <label htmlFor="OwnerNumber" className="col-sm-2 col-form-label">Owner ID</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 10 }}
                data={profile.Owner.OwnerNumber}
                save={async (data) => await saveAsyncProfile({ OwnerNumber: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="CompanyName" className="col-sm-2 col-form-label">Company Name</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 40 }}
                data={profile.Owner.CompanyName}
                save={async (data) => await saveAsyncProfile({ CompanyName: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="OwnerName" className="col-sm-2 col-form-label">Name</label>
            <div className="col-sm-10">
              <EditableField
                data={{ FirstName: profile.Owner.FirstName, LastName: profile.Owner.LastName }}
                renderDisplay={(data) => `${data.FirstName} ${data.LastName}`}
                renderEditable={(data, saving, onChange) => <>
                  <input autoFocus required disabled={saving} className="form-control" type="text" value={data.FirstName} maxLength={20} onChange={(e) => onChange({ FirstName: e.target.value, LastName: data.LastName })} />
                  <input required disabled={saving} className="form-control" type="text" value={data.LastName} maxLength={20} onChange={(e) => onChange({ FirstName: data.FirstName, LastName: e.target.value })} />
                </>}
                save={async (data) => await saveAsyncProfile({ FirstName: data.FirstName, LastName: data.LastName })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="Address" className="col-sm-2 col-form-label">Address</label>
            <div className="col-sm-10">
              <EditableAddress
                title="Address"
                data={profile.Owner.Address}
                save={async (data) => await saveAsyncProfile({ Address: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="OwnerType" className="col-sm-2 col-form-label">Owner Type</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Owner.OwnerType}
                renderDisplay={(data) => OwnerTypes.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {OwnerTypes.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ OwnerType: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="FleetCardPolicy" className="col-sm-2 col-form-label">Fleet Card Policy</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Owner.FleetCardPolicy}
                renderDisplay={(data) => FleetCardPolicies.find(x => x.ID == (data === 0 ? 1 : data)).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {FleetCardPolicies.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ FleetCardPolicy: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="EMailAddress" className="col-sm-2 col-form-label">Email Address</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ type: 'email', maxLength: 50 }}
                data={profile.Owner.EMailAddress}
                save={async (data) => await saveAsyncProfile({ EMailAddress: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="CellPhone1" className="col-sm-2 col-form-label">Cell Phone</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="phone"
                data={profile.Owner.CellPhone1}
                save={async (data) => await saveAsyncProfile({ CellPhone1: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="HomePhone1" className="col-sm-2 col-form-label">Home Phone</label>
            <div className="col-sm-10">
              <EditableField
                dataType="phone"
                data={profile.Owner.HomePhone1}
                save={async (data) => await saveAsyncProfile({ HomePhone1: data })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="FederalTaxpayerID" className="col-sm-2 col-form-label">Fed. Taxpayer ID</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 15 }}
                data={profile.Owner.FederalTaxpayerID}
                save={async (data) => await saveAsyncProfile({ FederalTaxpayerID: data })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="SecurityNumber" className="col-sm-2 col-form-label">Security Number</label>
            <div className="col-sm-10">
              <EditableField
                inputProps={{ maxLength: 9 }}
                dataType="string"
                data={profile.Owner.SecurityNumber}
                renderDisplay={(data) => (data != null ? 'Hidden' : "None")}
                save={async (data) => await saveAsyncProfile({ SecurityNumber: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="ACHBankName" className="col-sm-2 col-form-label">ACH Bank Name</label>
            <div className="col-sm-10">
              <EditableField
                inputProps={{ maxLength: 25 }}
                dataType="string"
                data={profile.Owner.ACHBankName}
                renderDisplay={(data) => (data != null ? 'Hidden' : "None")}
                save={async (data) => await saveAsyncProfile({ ACHBankName: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="ACHBankAccountNumber" className="col-sm-2 col-form-label">ACH Bank Account Number</label>
            <div className="col-sm-10">
              <EditableField
                inputProps={{ maxLength: 15 }}
                dataType="string"
                data={profile.Owner.ACHBankAccountNumber}
                renderDisplay={(data) => (data != null ? 'Hidden' : "None")}
                save={async (data) => await saveAsyncProfile({ ACHBankAccountNumber: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="ACHTransitRoutingNumber" className="col-sm-2 col-form-label">ACH Transit Routing Number</label>
            <div className="col-sm-10">
              <EditableField
                inputProps={{ maxLength: 9 }}
                dataType="string"
                data={profile.Owner.ACHTransitRoutingNumber}
                renderDisplay={(data) => (data != null ? 'Hidden' : "None")}
                save={async (data) => await saveAsyncProfile({ ACHTransitRoutingNumber: data })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="VendorID" className="col-sm-2 col-form-label">Vendor ID</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 100 }}
                data={profile.Owner.VendorID}
                save={async (data) => await saveAsyncProfile({ VendorID: data })}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <AuditInfo audit={profile.Owner.AuditInfo} />
        </div>
      </div>
    </>);
  }

  if (props.isDialog) {
    return <Dialog
      className="dialog-w11/12"
      title={<Link to={`/Assets/Owner/${props.ownerId}`}>Owner Profile {profile?.Owner.OwnerNumber ?? ''}</Link>}
      onClose={() => {
        props.onClose();
      }}
    >
      {dataView()}
    </Dialog>
  }

  return dataView();
}

export default OwnerProfile;