import AssetPanel from '../Assets/AssetPanel';

const AssetCarriers = () => {
  return <div className="form-row">
    <div className="col-lg-12 p-1">
      <AssetPanel
        searchTerm=""
        tabIndex={5}
      />
    </div>
  </div>
}

export default AssetCarriers;
