import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { GridCellProps } from '@progress/kendo-react-grid';
import { SvgIcon } from "@progress/kendo-react-common";
import { pencilIcon } from '@progress/kendo-svg-icons';
import { useState } from 'react';

const CarrierTierCell = (props: GridCellProps) => {
    const [inEdit, setInEdit] = useState(false);
    const localizedData = [
      { text: 'Platinum', value: 10 },
      { text: 'Gold', value: 20 },
      { text: 'Silver', value: 30 },
      { text: 'N/A', value: 0 },
    ];

    const handleChange = (e: DropDownListChangeEvent) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.value
            });
            setInEdit(false);
        }
    }

    const { dataItem } = props;
    const field = props.field || '';
    const dataValue = dataItem[field] === null ? '' : dataItem[field];

    return (
      <td onClick={(e) => inEdit === false && setInEdit(true)}>
        {inEdit ?
          <DropDownList
            style={{ width: "100px" }}
            onChange={handleChange}
            value={localizedData.find(c => c.value === dataValue)}
            data={localizedData}
            textField="text"
          />
        : <SvgIcon icon={pencilIcon} style={{ cursor: "pointer" }} />
        }
      </td>
    );
}

export default CarrierTierCell;
