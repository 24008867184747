import * as React from 'react';
import { Grid, GridCellProps, GridColumn as Column, GridHeaderCellProps, GridPageChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Page } from '@progress/kendo-react-dropdowns';
import { formatNumber } from '@progress/kendo-intl';
import Moment from 'moment-timezone';
import { useState } from 'react';
import { fetchApi } from '../../services/api';
import CenterDivPanel from '../../components/CenterDivPanel';
import { Loader } from '@progress/kendo-react-indicators';
import { DateRangePicker, DateRangePickerChangeEvent, SelectionRange } from '@progress/kendo-react-dateinputs';
import { Input } from '@progress/kendo-react-inputs';
import DriverAutoComplete from '../AssetDrivers/DriverAutoComplete';
import { Button } from '@progress/kendo-react-buttons';
import { filterClearIcon } from '@progress/kendo-svg-icons';

type CardChangeLogViewItem = {
    ActivityDateTime: Date,
    UserName: string,
    DriverNumber: string,
    TransactionType: number,
    TransactionTypeValue: string,
    TransactionCode: string,
    TransactionAmount: number
}

const AmountCell = (props: GridCellProps) => {
    if (!props.field)
        return null;
    return (
        <td colSpan={props.colSpan} style={props.style} className={props.className}>
            {formatNumber(props.dataItem[props.field], "c")}
        </td>
    );
};

const DateCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    return (
        <td colSpan={props.colSpan} style={props.style} className={props.className}>
            {Moment.utc(props.dataItem[props.field]).tz("America/New_York").format("MM/DD/YYYY HH:mm")}
        </td>
    );
};

const RightAlignHeaderCell = (props: GridHeaderCellProps) => {
    return (<a className="k-link" onClick={props.onClick} style={{ textAlign: "right" }}>
        <span>{props.title}</span>
        {props.children}
    </a>);
};

const DriverCardLogs = () => {
    const initialDataState: Page = { skip: 0, take: 25 };
    const [page, setPage] = React.useState(initialDataState);
    const minDate = Moment().subtract(18, "months").toDate();
    const maxDate = Moment().toDate();
    const defaultDateRange = { start: Moment().subtract(2, "months").toDate(), end: Moment().toDate() } as SelectionRange;
    const [selectedDateRange, setSelectedDateRange] = useState<SelectionRange>(defaultDateRange);

    const [driver, setDriver] = useState<number>(null);

    const [cardNumber, setCardNumber] = useState('');

    const [loading, setLoading] = useState(false);
    const [cardChangeLog, setCardChangeLog] = useState<CardChangeLogViewItem[]>([]);

    const pageChange = (event: GridPageChangeEvent) => {
        setPage(event.page);
    };

    const getCardChangeLog = () => {
        setLoading(true);
        let data = {}
        data = { ...data, Active: true, StartDate: selectedDateRange.start, EndDate: selectedDateRange.end };
        if (cardNumber)
            data = { ...data, CardNumber: cardNumber };
        if (driver)
            data = { ...data, DriverID: driver };

        fetchApi(`/api/Asset/GetDriverCardTransactionLog`, data, 'POST')
            .then((response: CardChangeLogViewItem[]) => {
                setCardChangeLog(response);
                setLoading(false);
            });
    }

    const onDateRangeChange = (e: DateRangePickerChangeEvent) => {
        var currentMoment = Moment();
        if (e.value.start == null)
            e.value.start = currentMoment.toDate();
        if (e.value.end == null)
            e.value.end = currentMoment.toDate();

        setSelectedDateRange(e.value);
    }

    return <div className="container-fluid mt-3">
        {loading && <CenterDivPanel>
            <Loader type="converging-spinner" />
        </CenterDivPanel>}
        <Grid style={{ maxHeight: '800px' }} resizable={true} reorderable={true}
            data={cardChangeLog.slice(page.skip, page.take + page.skip)}
            skip={page.skip}
            take={page.take}
            total={cardChangeLog.length}
            pageable={true}
            onPageChange={pageChange}
        >
            <Column field="ActivityDateTime" title="Date" cell={DateCell} />
            <Column field="UserName" title="UserID" />
            <Column field="DriverNumber" title="Driver ID" />
            <Column field="CardNumber" title="Card Number" />
            <Column field="TransactionTypeValue" title="Transaction Type" />
            <Column field="TransactionCode" title="Change/Code" />
            <Column field="TransactionAmount" title="Amount" headerCell={RightAlignHeaderCell} cell={AmountCell} className="text-right" />
            <GridToolbar>
                <DateRangePicker
                    startDateInputSettings={{ label: null }}
                    endDateInputSettings={{ label: null }}
                    min={minDate}
                    max={maxDate}
                    value={selectedDateRange}
                    onChange={onDateRangeChange}
                />
                &nbsp;
                <DriverAutoComplete
                    style={{ width: "275px" }}
                    selectedDriverID={driver}
                    onSelectedDriver={(e) => setDriver(e?.DriverID)}
                />
                &nbsp;
                <Input
                    style={{ width: "200px" }}
                    placeholder='card number'
                    value={cardNumber}
                    onChange={(e) => setCardNumber(e.value)}
                />
                &nbsp;
                &nbsp;
                <Button
                    themeColor="primary"
                    title="Search"
                    onClick={() => { setPage(initialDataState); getCardChangeLog(); }}
                >Search</Button>
                &nbsp;
                <Button
                    title="Clear All Filters and Sort"
                    icon="filter-clear"
                    svgIcon={filterClearIcon}
                    onClick={() => {
                        setDriver(null);
                        setCardNumber('');
                        setSelectedDateRange(defaultDateRange);
                        setPage(initialDataState);
                        //  setResetValues(true);
                    }}
                />
            </GridToolbar>
        </Grid>
    </div >
}

export default DriverCardLogs;