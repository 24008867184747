import * as React from 'react';
import { GridCellProps, GridHeaderCell } from '@progress/kendo-react-grid';
import { formatNumber } from '@progress/kendo-intl';

export class TotalChargesCell extends React.Component<GridCellProps> {
    render() {        
        return <td style={{ textAlign: "right" }}>{formatNumber(this.props.dataItem.TotalCharges, "c")}</td>;
    }

    public shouldComponentUpdate(nextProps: GridCellProps) {
        return this.props.dataItem[this.props.field] !== nextProps.dataItem[this.props.field];
    }
}

export class TotalChargesHeaderCell extends GridHeaderCell {
    render() {
        return (
            <a className="k-link" onClick={this.props.onClick} style={{ textAlign: "right" }}>
                <span>{this.props.title}</span>
                {this.props.children}
            </a>
        );
    }
}