import { version } from "../../version";
import { store } from '../../store/index';
import { ProblemDetails } from "../../store/ProblemDetails";

export const fetchApi = (endPoint: string, payload = {}, method = 'get') => {

  const mediaHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };

  const options = {
    body: JSON.stringify(payload),
    headers: mediaHeaders,
    method: method.toLowerCase(),
    credentials: 'same-origin',
    redirect: 'manual'
  } as RequestInit;

  if (method.toLowerCase() != 'post' && Object.keys(payload).length === 0) {
    delete options.body;
  }

  return fetch(endPoint, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {

        // Version check
        const apiVersion = response.headers.get("Api-Version");
        if (apiVersion && apiVersion != version) {
          store.dispatch({ type: 'NEW_VERSION' });
        }

        return response.json();
      }

      // Redirect for login
      if (response.type === 'opaqueredirect') {
        window.location.href = '/home/LoginRedirect?redirectUrl=' + encodeURIComponent(window.location.href);
      } else if (response.headers.get("content-type").indexOf("application/problem+json") > -1) {
        return response.json().then((problem) => {
          store.dispatch({ type: 'PROBLEM_DETAILS', problem: problem });
          return Promise.reject(problem);
        });
      }

      return Promise.reject(response.status);
    });
};

export const fetchApiFormData = (endPoint: string, formData: FormData, method = 'post') => {

  const mediaHeaders = {
    'Accept': 'application/json',
    'enctype': 'multipart/form-data',
  };

  const options = {
    body: formData,
    headers: mediaHeaders,
    method: method.toLowerCase(),
    credentials: 'same-origin',
    redirect: 'manual'
  } as RequestInit;

  return fetch(endPoint, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {

        // Version check
        const apiVersion = response.headers.get("Api-Version");
        if (apiVersion && apiVersion != version) {
          store.dispatch({ type: 'NEW_VERSION' });
        }

        return response.json();
      }

      // Redirect for login
      if (response.type === 'opaqueredirect') {
        window.location.href = '/home/LoginRedirect?redirectUrl=' + encodeURIComponent(window.location.href);
      } else if (response.headers.get("content-type").indexOf("application/problem+json") > -1) {
        return response.json().then((problem) => {
          store.dispatch({ type: 'PROBLEM_DETAILS', problem: problem });
          return Promise.reject(problem);
        });
      }

      return Promise.reject(response.status);
    });
};

export const fetchByteData = (endPoint: string, payload = {}, method = 'get') => {

  const mediaHeaders = {
    'Accept': 'application/octet-stream',
    'Content-Type': 'application/json',
  };

  const options = {
    body: JSON.stringify(payload),
    headers: mediaHeaders,
    method: method.toLowerCase(),
    credentials: 'same-origin',
    redirect: 'manual'
  } as RequestInit;

  if (method.toLowerCase() != 'post' && Object.keys(payload).length === 0) {
    delete options.body;
  }

  return fetch(endPoint, options)
    .then(async (response) => {
      if (response.status >= 200 && response.status < 300) {

        // Version check
        const apiVersion = response.headers.get("Api-Version");
        if (apiVersion && apiVersion != version) {
          store.dispatch({ type: 'NEW_VERSION' });
        }

        const contentDisposition = response.headers.get("Content-Disposition");
        let fileName: string | null = null;
        if (contentDisposition) {
          if (contentDisposition.includes('filename*='))
            fileName = decodeURIComponent(contentDisposition.split('filename*=')[1].split(';')[0].split("''")[1]);
        }
        return { blob: await response.blob(), filename: fileName };
      }

      // Redirect for login
      if (response.type === 'opaqueredirect') {
        window.location.href = '/home/LoginRedirect?redirectUrl=' + encodeURIComponent(window.location.href);
      } else if (response.headers.get("content-type").indexOf("application/problem+json") > -1) {
        return response.json().then((problem) => {
          store.dispatch({ type: 'PROBLEM_DETAILS', problem: problem });
          return Promise.reject(problem);
        });
      }

      return Promise.reject(response.status);
    });
};

export const downloadBlobAsFile = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}

export const dispatchProblemDetails = (problem: ProblemDetails) => {
  store.dispatch({ type: 'PROBLEM_DETAILS', problem: problem });
};
