import * as React from 'react';
import { DropDownList, DropDownListChangeEvent, DropDownListProps } from '@progress/kendo-react-dropdowns';
import { GridFilterCellProps } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { IDNameViewModel } from 'TypeGen/id-name-view-model';
import { filterClearIcon } from '@progress/kendo-svg-icons';

type DropdownFilterCellProps = {
  value: number;
  data: IDNameViewModel[];
} & Omit<GridFilterCellProps, 'value'>;

export const DropdownFilterCellIDName = (props: DropdownFilterCellProps) => {
    const checkValue = (value: number) => value > 0;
    const onChange = (event: DropDownListChangeEvent) => {
        const hasValue = checkValue(event.target.value.ID);
        props.onChange({
            value: hasValue ? event.target.value.ID : '',
            operator: hasValue ? 'eq' : '',
            syntheticEvent: event.syntheticEvent
        });
    }

    const onClearButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        props.onChange({
            value: '',
            operator: '',
            syntheticEvent: event
        });
    }
    return (
      <div className="k-filtercell">
        <DropDownList
          data={props.data}
          onChange={onChange}
          value={props.data.find(x => x.ID === props.value)}
          textField="Name"
          dataItemKey="ID"
          defaultItem={{ID: 0, Name: 'Select'}}
        />
        <Button
          title="Clear"
          disabled={!checkValue(props.value)}
          onClick={onClearButtonClick}
          icon="filter-clear"
          svgIcon={filterClearIcon}
        />
      </div>
    );
}