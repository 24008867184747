import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Checkbox, NumericTextBox } from '@progress/kendo-react-inputs';
import { DropDownList, ListItemProps, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Link } from 'react-router-dom';
import { fetchApi } from '../../services/api';
import { Title } from '../../utils/title';
import LoadBoardSettings from './LoadBoardSettings';
import QuoteSettings from './QuoteSettings';
import { CompanySettingsViewModel as Settings } from 'TypeGen/CompanySettings/company-settings-view-model';
import { IndividualRequestQuoteSettingsViewModel } from 'TypeGen/CompanySettings/individual-request-quote-settings-view-model';

type Props = {}

type GetCompanySettings = {
  ResetRules: ResetRule[];
} & Settings;

type ResetRule = {
  MPG: number;
  HaulingCategory: string;
  Rules: string[];
}

type State = {
  loading: boolean;
  settings: null|GetCompanySettings;
  vehicles: Array<{ ID: number; Name: string }>;
  selectedQuoteSettingId: number;
}

export default class CompanySettings extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      settings: null,
      vehicles: [],
      selectedQuoteSettingId: 739,
    };

    this.saveSettings = this.saveSettings.bind(this);
  }

  public componentDidMount() {
    fetchApi('/api/Company/Settings')
    .then((response: GetCompanySettings) => {
      this.setState({
          loading: false,
          settings: response,
      });
    });

    fetchApi("/api/Track/AssetNumbers")
    .then((data: { Drivers: Array<{ ID: number, Name: string }>, Vehicles: Array<{ ID: number, Name: string }> }) => {
      this.setState({ vehicles: data.Vehicles });
    });
  }

  public render() {
    if (this.state.loading || this.state.settings === null) {
      return (
        <span
          className="k-i-loading k-icon"
          style={{ fontSize: 64, position: 'absolute', left: '50%' }}
        />
      );
    }

    const quoteSetting = this.state.settings.QuoteSettings.find(x => x.ID === this.state.selectedQuoteSettingId);

    return (
      <div className="container card-block k-form k-form-md">
        <Title string="Company Settings" />
        <fieldset style={{ padding: 'revert', border: 'revert' }}>
        <legend>Carrier Load Board Settings</legend>
          <div className="k-form-field">
            <label>Default % of Revenue Offer Rate (Rivian = 83%):</label>
            <NumericTextBox
              required
              format="p"
              value={this.state.settings.LoadBoardMargin}
              onChange={(e) => this.setSettings({ LoadBoardMargin: e.value })}
              min={0}
              max={1}
              step={0.01}
            />
          </div>
        </fieldset>

        <br />

        <fieldset style={{ padding: 'revert', border: 'revert' }}>
        <legend>Third-Party Load Board Settings</legend>

        <div className="k-form-field">
          <label>Asset within miles:</label>
          <NumericTextBox
            min={50}
            max={1000}
            spinners={false}
            format="n0"
            value={this.state.settings.AssetWithinDistance}
            onChange={(e) => {
              this.setSettings({
                AssetWithinDistance: e.value
              });
            }}
            required
          />
        </div>

        <div className="k-form-field">
          <label>Non Asset within miles:</label>
          <NumericTextBox
            min={25}
            max={1000}
            spinners={false}
            format="n0"
            value={this.state.settings.NonAssetWithinDistance}
            onChange={(e) => {
              this.setSettings({
                NonAssetWithinDistance: e.value
              });
            }}
            required
          />
        </div>

        <div className="k-form-field">
          <label>Do not send tractors to these receivers:</label>
          <MultiSelect
            data={[]}
            style={{ width: '100%' }}
            onChange={(e) => {
              this.setSettings({ NoTractorLocations: e.target.value });
            }}
            value={this.state.settings.NoTractorLocations}
            allowCustom={true}
          />
        </div>

        <div className="k-form-field">
          <label>Dock High only to these locations:</label>
          <MultiSelect
            data={[]}
            style={{ width: '100%' }}
            onChange={(e) => {
              this.setSettings({ DockHighLocations: e.target.value });
            }}
            value={this.state.settings.DockHighLocations}
            allowCustom={true}
          />
        </div>

        <div className="k-form-field">
          <label>No Lift Gate vehicles to these locations:</label>
          <MultiSelect
            data={[]}
            style={{ width: '100%' }}
            onChange={(e) => {
              this.setSettings({ NoLiftGateLocations: e.target.value });
            }}
            value={this.state.settings.NoLiftGateLocations}
            allowCustom={true}
          />
        </div>

        <div className="form-group row">
          <label className="col-form-label col-md-3">Airfreight Rates:</label>
          <div className="col-md-9">
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <label className="col-form-label">Minimum Markup Rate</label>
                  <NumericTextBox
                    min={0}
                    format={{ style: 'currency' }}
                    value={this.state.settings.AirfreightMinimumRate}
                    onChange={(e) => {
                      this.setSettings({ AirfreightMinimumRate: e.value });
                    }}
                  />
                </div>
                <div className="col-md-3">
                  <label className="col-form-label">Maximum Markup Rate</label>
                  <NumericTextBox
                    min={0}
                    format={{ style: 'currency' }}
                    value={this.state.settings.AirfreightMaximumRate}
                    onChange={(e) => {
                      this.setSettings({ AirfreightMaximumRate: e.value });
                    }}
                  />
                </div>
                <div className="col-md-3">
                  <label className="col-form-label">Markup %</label>
                  <NumericTextBox
                    min={0}
                    max={1}
                    step={0.01}
                    format={{ style: 'percent' }}
                    value={this.state.settings.AirfreightMarkup}
                    onChange={(e) => {
                      this.setSettings({ AirfreightMarkup: e.value });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-form-label col-md-3">LTL Rates:</label>
          <div className="col-md-9">
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <label className="col-form-label">Minimum Markup Rate</label>
                  <NumericTextBox
                    min={0}
                    format={{ style: 'currency' }}
                    value={this.state.settings.EShipMinimumRate}
                    onChange={(e) => {
                      this.setSettings({ EShipMinimumRate: e.value });
                    }}
                  />
                </div>
                <div className="col-md-3">
                  <label className="col-form-label">Maximum Markup Rate</label>
                  <NumericTextBox
                    min={0}
                    format={{ style: 'currency' }}
                    value={this.state.settings.EShipMaximumRate}
                    onChange={(e) => {
                      this.setSettings({ EShipMaximumRate: e.value });
                    }}
                  />
                </div>
                <div className="col-md-3">
                  <label className="col-form-label">Markup %</label>
                  <NumericTextBox
                    min={0}
                    max={1}
                    step={0.01}
                    format={{ style: 'percent' }}
                    value={this.state.settings.EShipMarkup}
                    onChange={(e) => {
                      this.setSettings({ EShipMarkup: e.value });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <LoadBoardSettings
              id="AA"
              title="Active Aero Settings"
              settings={this.state.settings.ActiveAeroSettings}
              onChange={(settings) => this.setSettings({ ActiveAeroSettings: settings })}
            />
          </div>
          <div className="col-md-6">
            <LoadBoardSettings
              id="XPONLM"
              title="XPO/NLM Settings"
              settings={this.state.settings.XPOSettings}
              onChange={(settings) => this.setSettings({ XPOSettings: settings })}
            />
          </div>
          <div className="col-md-6">
            <LoadBoardSettings
              id="SYL"
              title="Sylectus Load Board Settings"
              settings={this.state.settings.SylectusSettings}
              onChange={(settings) => this.setSettings({ SylectusSettings: settings })}
            />
          </div>
          <div className="col-md-6">
            <LoadBoardSettings
              id="ECCO"
              title="Expeditors ECCO Settings"
              settings={this.state.settings.ExpeditorsSettings}
              onChange={(settings) => this.setSettings({ ExpeditorsSettings: settings })}
            />
          </div>
          <div className="col-md-6">
            <LoadBoardSettings
              id="RYDR"
              title="Ryder LMS Settings"
              settings={this.state.settings.RyderLMSSettings}
              onChange={(settings) => this.setSettings({ RyderLMSSettings: settings })}
            />
          </div>
          <div className="col-md-6">
            <LoadBoardSettings
              id="CYT"
              title="Coyote Settings"
              settings={this.state.settings.CoyoteSettings}
              onChange={(settings) => this.setSettings({ CoyoteSettings: settings })}
            />
          </div>
          <div className="col-md-6">
            <LoadBoardSettings
              id="CAT"
              title="Caterpillar Settings"
              settings={this.state.settings.CaterpillarSettings}
              onChange={(settings) => this.setSettings({ CaterpillarSettings: settings })}
            />
          </div>
          <div className="col-md-6">
            <LoadBoardSettings
              id="SHAW"
              title="Shaw Settings"
              settings={this.state.settings.ShawMASettings}
              onChange={(settings) => this.setSettings({ ShawMASettings: settings })}
            />
          </div>
          <div className="col-md-6">
            <LoadBoardSettings
              id="PEPSI"
              title="Pepsi Settings"
              settings={this.state.settings.PepsiSettings}
              onChange={(settings) => this.setSettings({ PepsiSettings: settings })}
            />
          </div>
        </div>
        </fieldset>
        <fieldset style={{ padding: 'revert', border: 'revert' }}>
          <legend>Company Quote Settings</legend>
          <DropDownList
            data={this.state.settings.QuoteSettings}
            dataItemKey="ID"
            value={quoteSetting}
            valueRender={(span: React.ReactElement<HTMLSpanElement>, value: IndividualRequestQuoteSettingsViewModel) => {
              const itemChildren = <>{value.Label} Settings - {(value.CreateQuotes ? value.AutoOfferDriversWorkHours || value.AutoOfferDriversAfterHours ? value.AutoEmailRate ? "Auto Bid" : "Auto Offer" : "Create" : "Disabled")}</>;
              return React.cloneElement(span, span.props, value.ID === 739 ? <>{value.Label}</> : itemChildren);
            }}
            itemRender={(li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
              const itemChildren = <>{itemProps.dataItem.Label} Settings - {(itemProps.dataItem.CreateQuotes ? itemProps.dataItem.AutoOfferDriversWorkHours || itemProps.dataItem.AutoOfferDriversAfterHours ? itemProps.dataItem.AutoEmailRate ? "Auto Bid" : "Auto Offer" : "Create" : "Disabled")}</>;
              return React.cloneElement(li, li.props, itemProps.dataItem.ID === 739 ? <>{itemProps.dataItem.Label}</> : itemChildren);
            }}
            onChange={(e) => this.setState({ selectedQuoteSettingId: e.target.value.ID })}
          />
          <br /><br />
          <QuoteSettings
            settings={quoteSetting}
            defaults={this.state.settings.QuoteSettings[0]}
            onChange={(settings) => this.setSettings({ QuoteSettings: this.state.settings.QuoteSettings.map(x => x.ID === quoteSetting.ID ? settings : x) })}
          />
          <br />
          <Checkbox
            label="Do Not Auto Rate Using Tariff Rates"
            checked={this.state.settings.DisableQuoteTariffRate}
            onChange={(e) =>  this.setSettings({ DisableQuoteTariffRate: e.value })}
          />
          <br /><br />
          <legend>Price Optimization</legend>
          <Checkbox
            label="Enabled"
            checked={this.state.settings.SmartbidEnabled}
            onChange={(e) =>  this.setSettings({ SmartbidEnabled: e.value })}
          />
          {this.state.settings.SmartbidEnabled && <>
            <div className="k-form-field">
              <label>Linehaul Rate Per Mile Increase By Max</label>
              <NumericTextBox
                min={0}
                step={0.01}
                format={{ style: 'currency' }}
                value={this.state.settings.SmartbidIncreaseRatePerMile}
                onChange={(e) => {
                  this.setSettings({ SmartbidIncreaseRatePerMile: e.value });
                }}
              />
            </div>
            <div className="k-form-field">
              <label>Linehaul Rate Per Mile Decrease By Max</label>
              <NumericTextBox
                max={0}
                step={-0.01}
                format={{ style: 'currency' }}
                value={this.state.settings.SmartbidDecreaseRatePerMile}
                onChange={(e) => {
                  this.setSettings({ SmartbidDecreaseRatePerMile: e.value });
                }}
              />
            </div>
          </>}
          <br /><br />
          <Link to="/Quote/Flow">Click here to view a visualization of the quote process</Link>
        </fieldset>

        <br />

        <div className="k-form-field">
          <label>Vehicles excluded from "Turndown, Reset and Hours Given Back Policy" rules:</label>
          <MultiSelect
            data={this.state.vehicles}
            style={{ width: '100%' }}
            onChange={(e) => {
              this.setSettings({ TurndownExcludedVehicleIDs: e.target.value.map(x => x.ID) });
            }}
            value={this.state.vehicles.filter(x => this.state.settings.TurndownExcludedVehicleIDs.includes(x.ID))}
            textField="Name"
          />
        </div>

        <br />

        <Button onClick={this.saveSettings} themeColor="primary">Save Settings</Button>

        <br /><br />

        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Additional Load Board Auto-Decline Rules</h5>
            <ul>
              <li>If pickup and delivery are both in Canada</li>
              <li>If the pickup or delivery is CAN and outside of Ontario</li>
              <li>We skip asset checks and don't auto decline if the pickup is more than 24 hours in the future</li>
            </ul>
          </div>
        </div>

        <br />

        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Turndown, Reset and Hours Given Back Policy</h5>

            {this.state.settings.ResetRules.map((rule, index) => <React.Fragment key={index}>
              <p className="font-weight-bold">{rule.HaulingCategory}</p>
              <ul>
                {rule.Rules.map((r, i) => <li key={i}>{r}</li>)}
                {rule.MPG === 3 && <li>Auto Offer Maximum Miles: {this.state.settings.AutoOfferMaxRegionalDistance}</li>}
                {rule.MPG === 4 && <li>Auto Offer Maximum Miles: {this.state.settings.AutoOfferMaxLocalOnlyDistance}</li>}
              </ul>
            </React.Fragment>)}
          </div>
        </div>

        <br />

        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Dispatch Vehicle Suggestion Ranking</h5>
            <ol>
              <li>+30 pts - Driver Accepted Quote</li>
              <li>+10 pts - Freight Will Fit Vehicle</li>
              <li>+5 pts - Vehicle is on reserve by you</li>
              <li>-5 pts - Vehicle is on reserve by someone else</li>
              <li>+5 pts - Vehicle Payload &gt; Order Total Weight</li>
              <li>-10 pts - Vehicle Payload &gt; 2x Order Total Weight</li>
              <li>+5 pts - Vehicle Type matches Requested Vehicle Type</li>
              <li>-10 pts - Vehicle Type is Tractor and Tractor was not requested</li>
              <li>-10 pts - Vehicle Type is not Tractor and Tractor was requested</li>
              <li>Scale up to +10 pts if Vehicle LA is 0 to 168 hours</li>
              <li>+5 pts - Vehicle is In-Service</li>
              <li>-10 pts - Vehicle is Out of Service</li>
              <li>-5 pts - Vehicle is On A Load</li>
              <li>Scale up to +20 pts if Vehicle Distance to Pickup is 200 to 0 miles out</li>
            </ol>
          </div>
        </div>

        <br />

        <div className="card">
          <div className="card-body">
            <h5 className="card-title">PC*Miler Version</h5>
            <p>Transcend TMS: {this.state.settings.PCMilerVersion}</p>
          </div>
        </div>
      </div>
    );
  }

  private setSettings(value: Partial<Settings>) {
    const settings = Object.assign({}, this.state.settings, value);
    this.setState({ settings });
  }

  private saveSettings() {
    this.setState({ loading: true });
    fetchApi('/api/Company/Settings', this.state.settings as Settings, 'post')
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert("Unable to update settings");
      })
      .finally(() => {
        this.setState({ loading: false });
      })
  }
}
