import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";

export default function dimensionsCell(
  viewFit: (vehicleId: number) => void,
) {
  return class extends React.Component<GridCellProps> {
    render() {
      if (!this.props.field) return null;
      return (
        <td>
          <a
            href="#"
            style={{ color: '#007bff' }}
            onClick={(e) => {
              viewFit(this.props.dataItem.VehicleID)
              e.preventDefault();
            }}
          >{this.props.dataItem.BoxLength} x {this.props.dataItem.BoxWidth} x {this.props.dataItem.BoxHeight}</a>
        </td>
      );
    }

    public shouldComponentUpdate(nextProps: GridCellProps) {
      return nextProps.dataItem.Hash !== this.props.dataItem.Hash;
    }
  }
}
