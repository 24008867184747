import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Input, NumericTextBox, RadioGroup, TextArea } from '@progress/kendo-react-inputs';
import { Error, Label } from '@progress/kendo-react-labels';
import { useEffect, useState } from 'react';
import { fetchApi } from '../../../services/api';
import CenterDivPanel from '../../../components/CenterDivPanel';
import { Loader } from "@progress/kendo-react-indicators";
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import Moment from 'moment-timezone';
import { Fade } from "@progress/kendo-react-animation";
import { isNullOrWhiteSpaceValidator } from '../../../utils/utils';
import { Button } from '@progress/kendo-react-buttons';
import { JsonResponse } from 'TypeGen/json-response';
import DriverAutoComplete from 'views/AssetDrivers/DriverAutoComplete';
import TrailerAutoComplete from 'views/AssetTrailers/TrailerAutoComplete';
import VehicleAutoComplete from 'views/AssetVehicles/VehicleAutoComplete';
import OwnerAutoComplete from 'views/AssetOwners/OwnerAutoComplete';
import { useHistory } from 'react-router-dom';

type Props = {
    DriverID: number,
    CloseDialog: () => void
}

const DriverAccidentReportDialog = (props: Props) => {
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [vehicleId, setVehicleId] = useState<number | null>(null);
    const [trailerId, setTrailerId] = useState<number | null>(null);
    const [ownerId, setOwnerId] = useState<number | null>(null);
    const [accidentType, setAccidentType] = useState({ value: '', error: '' });
    const [policeReportOrBadgeNumber, setPoliceReportOrBadgeNumber] = useState({ value: '', error: '' });

    const [otherVehicleMake, setOtherVehicleMake] = useState({ value: '', error: '' });
    const [otherVehicleModel, setOtherVehicleModel] = useState({ value: '', error: '' });
    const [otherVehiclePlate, setOtherVehiclePlate] = useState({ value: '', error: '' });
    const [otherVehicleOwnerName, setOtherVehicleOwnerName] = useState({ value: '', error: '' });

    //Textareas
    const [accidentLocation, setAccidentLocation] = useState({ value: '', error: '' });
    const [accidentDescription, setAccidentDescription] = useState({ value: '', error: '' });
    const [accidentWeatherConditions, setAccidentWeatherConditions] = useState({ value: '', error: '' });
    const [accidentRoadConditions, setAccidentRoadConditions] = useState({ value: '', error: '' });
    const [vehicleTowedLocation, setVehicleTowedLocation] = useState({ value: '', error: '' });
    const [otherVehicleInsuranceInformation, setOtherVehicleInsuranceInformation] = useState({ value: '', error: '' });
    const [injuryInformation, setInjuryInformation] = useState({ value: '', error: '' });
    const [witnessInformation, setWitnessInformation] = useState({ value: '', error: '' });

    const [driverId, setDriverId] = useState(props.DriverID);

    const [dateTimeOfAccident, setDateTimeOfAccident] = useState({ value: Moment().toDate(), error: '' });
    const [tripNumber, setTripNumber] = useState({ value: 0, error: '' });

    const [injuriesReported, setInjuriesReported] = React.useState<{ value: boolean | null, error: string }>({ value: null, error: '' });
    const [occupantsInOurVehicle, setOccupantsInOurVehicle] = React.useState<{ value: boolean | null, error: string }>({ value: null, error: '' });
    const [damageToOtherVehicle, setDamageToOtherVehicle] = React.useState<{ value: boolean | null, error: string }>({ value: null, error: '' });
    const [anyVehiclesTowed, setAnyVehiclesTowed] = React.useState<{ value: boolean | null, error: string }>({ value: null, error: '' });
    const [anyFuelSpill, setAnyFuelSpill] = React.useState<{ value: boolean | null, error: string }>({ value: null, error: '' });
    const [policeReportTaken, setPoliceReportTaken] = React.useState<{ value: boolean | null, error: string }>({ value: null, error: '' });
    const [citationGiven, setCitationGiven] = React.useState<{ value: boolean | null, error: string }>({ value: null, error: '' });
    const [dotRecordable, setDotRecordable] = React.useState<{ value: boolean | null, error: string }>({ value: null, error: '' });
    const [anyDamageToOurVehicle, setAnyDamageToOurVehicle] = React.useState<{ value: boolean | null, error: string }>({ value: null, error: '' });
    const [isVehicleDrivable, setIsVehicleDrivable] = React.useState<{ value: boolean | null, error: string }>({ value: null, error: '' });
    const [doesLoadNeedToBeRecovered, setDoesLoadNeedToBeRecovered] = React.useState<{ value: boolean | null, error: string }>({ value: null, error: '' });
    const yesNoRadioButtonValues = [
        { label: "Yes", value: true },
        { label: "No", value: false }
    ];
    const trueFalseNullRadioButtonValues = [
        { label: "Yes", value: true },
        { label: "No", value: false },
        { label: "Unknown", value: null }
    ];

    useEffect(() => {
        if (driverId) {
            setLoading(true);

            fetchApi(`/api/Asset/GetAccidentReportDriverRelatedData/${driverId}`)
                .then((response: {
                    DriverID: number,
                    VehicleID: number | null,
                    TrailerID: number | null,
                    OwnerID: number | null,
                    TripNumber: number
                }) => {
                    setDriverId(response.DriverID);
                    setVehicleId(response.VehicleID);
                    setTrailerId(response.TrailerID);
                    setOwnerId(response.OwnerID);
                    setTripNumber({ value: response.TripNumber, error: '' });

                    setLoading(false);
                });
        }
    }, [driverId]);

    const _save = () => {
        //Text Inputs
        const accidentTypeError = isNullOrWhiteSpaceValidator(accidentType.value, 'Value cannot be empty.');
        const accidentLocationError = isNullOrWhiteSpaceValidator(accidentLocation.value, 'Value cannot be empty.');
        const accidentDescriptionError = isNullOrWhiteSpaceValidator(accidentDescription.value, 'Value cannot be empty.');
        const accidentRoadConditionsError = isNullOrWhiteSpaceValidator(accidentRoadConditions.value, 'Value cannot be empty.');
        const accidentWeatherConditionsError = isNullOrWhiteSpaceValidator(accidentWeatherConditions.value, 'Value cannot be empty.');
        const vehicleTowedLocationError = isNullOrWhiteSpaceValidator(vehicleTowedLocation.value, 'Value cannot be empty.');
        const policeReportOrBadgeNumberError = isNullOrWhiteSpaceValidator(policeReportOrBadgeNumber.value, 'Value cannot be empty.');
        const injuryInformationError = isNullOrWhiteSpaceValidator(injuryInformation.value, 'Value cannot be empty.');
        const witnessInformationError = isNullOrWhiteSpaceValidator(witnessInformation.value, 'Value cannot be empty.');

        //Additional Inputs
        const otherVehicleMakeError = isNullOrWhiteSpaceValidator(otherVehicleMake.value, 'Value cannot be empty.');
        const otherVehicleModelError = isNullOrWhiteSpaceValidator(otherVehicleModel.value, 'Value cannot be empty.');
        const otherVehiclePlateError = isNullOrWhiteSpaceValidator(otherVehiclePlate.value, 'Value cannot be empty.');
        const otherVehicleOwnerNameError = isNullOrWhiteSpaceValidator(otherVehicleOwnerName.value, 'Value cannot be empty.');
        const otherVehicleInsuranceInformationError = isNullOrWhiteSpaceValidator(otherVehicleInsuranceInformation.value, 'Value cannot be empty.');

        //DateTime
        const dateOfAccidentDateDiff = Moment(dateTimeOfAccident.value).diff(Moment(), 'minutes');
        const dateOfAccidentError = dateOfAccidentDateDiff > 0 ? 'Date cannot be in the future.' : '';

        //Radio Input
        const injuriesReportedError = injuriesReported.value === null ? 'Option must be selected' : '';
        const anyVehiclesTowedError = anyVehiclesTowed.value === null ? 'Option must be selected' : '';
        const anyFuelSpillError = anyFuelSpill.value === null ? 'Option must be selected' : '';
        const policeReportTakenError = policeReportTaken.value === null ? 'Option must be selected' : '';
        const anyDamageToOurVehicleError = anyDamageToOurVehicle.value === null ? 'Option must be selected' : '';
        const isVehicleDrivableError = isVehicleDrivable.value === null ? 'Option must be selected' : '';
        const doesLoadNeedToBeRecoveredError = doesLoadNeedToBeRecovered.value === null ? 'Option must be selected' : '';
        const occupantsInOurVehicleError = occupantsInOurVehicle.value === null ? 'Option must be selected' : '';
        const damageToOtherVehicleError = damageToOtherVehicle.value === null ? 'Option must be selected' : '';

        const inputErrorValues: string[] = [
            accidentTypeError,
            accidentLocationError,
            accidentDescriptionError,
            accidentRoadConditionsError,
            accidentWeatherConditionsError,
            policeReportOrBadgeNumberError,
            dateOfAccidentError,
            injuriesReportedError,
            anyVehiclesTowedError,
            anyFuelSpillError,
            policeReportTakenError,
            anyDamageToOurVehicleError,
            isVehicleDrivableError,
            doesLoadNeedToBeRecoveredError,
            occupantsInOurVehicleError,
            damageToOtherVehicleError,
            injuryInformationError,
            witnessInformationError
        ]

        let additionalInputErrorValues: string[] = [];
        if (damageToOtherVehicle.value === true) {
            additionalInputErrorValues.push(otherVehicleMakeError);
            additionalInputErrorValues.push(otherVehicleModelError);
            additionalInputErrorValues.push(otherVehiclePlateError);
            additionalInputErrorValues.push(otherVehicleOwnerNameError);
            additionalInputErrorValues.push(otherVehicleInsuranceInformationError);
        }
        if (anyVehiclesTowed.value === true) {
            additionalInputErrorValues.push(vehicleTowedLocationError);
        }

        if (inputErrorValues.some(x => x) || additionalInputErrorValues.some(x => x)) {
            setAccidentType({ ...accidentType, error: accidentTypeError });
            setAccidentLocation({ ...accidentLocation, error: accidentLocationError });
            setAccidentDescription({ ...accidentDescription, error: accidentDescriptionError });
            setAccidentRoadConditions({ ...accidentRoadConditions, error: accidentRoadConditionsError });
            setAccidentWeatherConditions({ ...accidentWeatherConditions, error: accidentWeatherConditionsError });
            setVehicleTowedLocation({ ...vehicleTowedLocation, error: vehicleTowedLocationError });
            setPoliceReportOrBadgeNumber({ ...policeReportOrBadgeNumber, error: policeReportOrBadgeNumberError });
            setInjuryInformation({ ...injuryInformation, error: injuryInformationError });
            setWitnessInformation({ ...witnessInformation, error: witnessInformationError });

            setDateTimeOfAccident({ ...dateTimeOfAccident, error: dateOfAccidentError });

            setOtherVehicleMake({ ...otherVehicleMake, error: otherVehicleMakeError });
            setOtherVehicleModel({ ...otherVehicleModel, error: otherVehicleModelError });
            setOtherVehiclePlate({ ...otherVehiclePlate, error: otherVehiclePlateError });
            setOtherVehicleOwnerName({ ...otherVehicleOwnerName, error: otherVehicleOwnerNameError });
            setOtherVehicleInsuranceInformation({ ...otherVehicleInsuranceInformation, error: otherVehicleInsuranceInformationError });

            setInjuriesReported({ ...injuriesReported, error: injuriesReportedError });
            setAnyVehiclesTowed({ ...anyVehiclesTowed, error: anyVehiclesTowedError });
            setAnyFuelSpill({ ...anyFuelSpill, error: anyFuelSpillError });
            setPoliceReportTaken({ ...policeReportTaken, error: policeReportTakenError });
            setCitationGiven({ ...citationGiven, error: '' });
            setDotRecordable({ ...dotRecordable, error: '' });
            setAnyDamageToOurVehicle({ ...anyDamageToOurVehicle, error: anyDamageToOurVehicleError });
            setIsVehicleDrivable({ ...isVehicleDrivable, error: isVehicleDrivableError });
            setDoesLoadNeedToBeRecovered({ ...doesLoadNeedToBeRecovered, error: doesLoadNeedToBeRecoveredError });
            setOccupantsInOurVehicle({ ...occupantsInOurVehicle, error: occupantsInOurVehicleError });
            setDamageToOtherVehicle({ ...damageToOtherVehicle, error: damageToOtherVehicleError });

            // Scroll to first error after next cycle
            setTimeout(() => {
                const elements = ([...document.querySelectorAll(".k-window-content .k-form-error")] as HTMLDivElement[]).filter((x) => x.offsetHeight !== 0);
                elements[0]?.scrollIntoView({behavior: 'smooth', block: 'center'});
            });
            return;
        }

        setLoading(true);

        const data = {
            DriverID: driverId,
            VehicleID: vehicleId,
            TrailerID: trailerId,
            OwnerID: ownerId,
            accidentType: accidentType.value,
            accidentLocation: accidentLocation.value,
            accidentDescription: accidentDescription.value,
            accidentRoadConditions: accidentRoadConditions.value,
            accidentWeatherConditions: accidentWeatherConditions.value,
            vehicleTowedLocation: vehicleTowedLocation.value,
            policeReportOrBadgeNumber: policeReportOrBadgeNumber.value,
            dateTimeOfAccident: dateTimeOfAccident.value,
            otherVehicleMake: otherVehicleMake.value,
            otherVehicleModel: otherVehicleModel.value,
            otherVehiclePlate: otherVehiclePlate.value,
            otherVehicleOwnerName: otherVehicleOwnerName.value,
            otherVehicleInsuranceInformation: otherVehicleInsuranceInformation.value,
            injuriesReported: injuriesReported.value,
            anyVehiclesTowed: anyVehiclesTowed.value,
            anyFuelSpill: anyFuelSpill.value,
            policeReportTaken: policeReportTaken.value,
            citationGiven: citationGiven.value,
            dotRecordable: dotRecordable.value,
            anyDamageToOurVehicle: anyDamageToOurVehicle.value,
            isVehicleDrivable: isVehicleDrivable.value,
            doesLoadNeedToBeRecovered: doesLoadNeedToBeRecovered.value,
            occupantsInOurVehicle: occupantsInOurVehicle.value,
            damageToOtherVehicle: damageToOtherVehicle.value,
            tripNumber: tripNumber.value,
            injuryInformation: injuryInformation.value,
            witnessInformation: witnessInformation.value
        }
        fetchApi(`/api/Asset/SendInternalAccidentReport`, data, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    var link = response.Links.find(l => l.Name === 'DriverAccident');
                    if (link) history.push(link.Link);
                    props.CloseDialog();
                } else {
                    alert(response.ErrorMessage);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                alert("Unable to save data. Please try again.");
            });
    }

    return <div className="container-fluid">
        <Dialog title="Accident / Incident Report" onClose={props.CloseDialog} width={600} height={window.innerHeight / 1.10}>
            {loading && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}
            <form className="k-form k-form-md p-0">
                <FieldWrapper>
                    <Label>Driver:</Label>
                    <DriverAutoComplete
                        required
                        selectedDriverID={driverId}
                        onSelectedDriver={(e) => setDriverId(e?.DriverID)}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Fleet Owner:</Label>
                    <OwnerAutoComplete
                        selectedOwnerID={ownerId}
                        onSelectedOwner={(e) => setOwnerId(e?.OwnerID)}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Time of Accident / Incident:</Label>
                    <DateTimePicker
                        required
                        format="MM/dd/yyyy HH:mm"
                        formatPlaceholder="formatPattern"
                        value={dateTimeOfAccident.value}
                        onChange={(e) => setDateTimeOfAccident({ value: e.value, error: '' })}
                        width="100%"
                    />
                    <Error>{dateTimeOfAccident.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Vehicle:</Label>
                    <VehicleAutoComplete
                        selectedVehicleID={vehicleId}
                        onSelectedVehicle={(e) => setVehicleId(e?.VehicleID)}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Trailer:</Label>
                    <TrailerAutoComplete
                        selectedTrailerID={trailerId}
                        onSelectedTrailer={(e) => setTrailerId(e?.TrailerID)}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Trip Number:</Label>
                    <NumericTextBox
                        width="100%"
                        min={0}
                        spinners={false}
                        format="0"
                        value={tripNumber.value}
                        onChange={(e) => setTripNumber({ value: e.value || 0, error: '' })}
                    />
                    <Error>{tripNumber.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Accident / Incident Type:</Label>
                    <Input
                        maxLength={50}
                        disabled={loading}
                        value={accidentType.value}
                        onChange={(e) => setAccidentType({ value: e.value || '', error: '' })}
                    />
                    <Error>{accidentType.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Any injuries reported?</Label>
                    <RadioGroup
                        data={yesNoRadioButtonValues}
                        layout={'horizontal'}
                        defaultValue={injuriesReported.value}
                        onChange={(e) => setInjuriesReported({ value: e.value as boolean, error: '' })}
                    />
                    <Error>{injuriesReported.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Any vehicles towed?</Label>
                    <RadioGroup
                        data={yesNoRadioButtonValues}
                        layout={'horizontal'}
                        defaultValue={anyVehiclesTowed.value}
                        onChange={(e) => setAnyVehiclesTowed({ value: e.value as boolean, error: '' })}
                    />
                    <Error>{anyVehiclesTowed.error}</Error>
                </FieldWrapper>
                <Fade style={{ zIndex: "1", width: "100%" }} transitionEnterDuration={800}>
                    {anyVehiclesTowed.value === true &&
                        <FieldWrapper>
                            <Label>If towed, where was vehicle towed?</Label>
                            <TextArea
                                rows={2}
                                value={vehicleTowedLocation.value}
                                onChange={(e) => setVehicleTowedLocation({ value: e.value as string || '', error: '' })}
                            />
                            <Error>{vehicleTowedLocation.error}</Error>
                        </FieldWrapper>}
                </Fade>
                <FieldWrapper>
                    <Label>Any fuel spill or clean up?</Label>
                    <RadioGroup
                        data={yesNoRadioButtonValues}
                        layout={'horizontal'}
                        defaultValue={anyFuelSpill.value}
                        onChange={(e) => setAnyFuelSpill({ value: e.value as boolean, error: '' })}
                    />
                    <Error>{anyFuelSpill.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Location (Please give simple description of location)</Label>
                    <TextArea
                        rows={2}
                        maxLength={200}
                        value={accidentLocation.value}
                        onChange={(e) => setAccidentLocation({ value: e.value as string || '', error: '' })}
                    />
                    <Error>{accidentLocation.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Was a police report taken?</Label>
                    <RadioGroup
                        data={yesNoRadioButtonValues}
                        layout={'horizontal'}
                        defaultValue={policeReportTaken.value}
                        onChange={(e) => setPoliceReportTaken({ value: e.value as boolean, error: '' })}
                    />
                    <Error>{policeReportTaken.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Police Report or Badge Number:</Label>
                    <Input
                        maxLength={50}
                        disabled={loading}
                        value={policeReportOrBadgeNumber.value}
                        onChange={(e) => setPoliceReportOrBadgeNumber({ value: e.value || '', error: '' })}
                    />
                    <Error>{policeReportOrBadgeNumber.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Was there a citation issued to the Load One Driver/Vehicle?</Label>
                    <RadioGroup
                        data={trueFalseNullRadioButtonValues}
                        layout={'horizontal'}
                        defaultValue={citationGiven.value}
                        onChange={(e) => setCitationGiven({ value: e.value as boolean | null, error: '' })}
                    />
                    <Error>{citationGiven.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Was the accident / incident DOT recordable?</Label>
                    <RadioGroup
                        data={trueFalseNullRadioButtonValues}
                        layout={'horizontal'}
                        defaultValue={dotRecordable.value}
                        onChange={(e) => setDotRecordable({ value: e.value as boolean | null, error: '' })}
                    />
                    <Error>{dotRecordable.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Please describe the accident / incident:</Label>
                    <TextArea
                        rows={2}
                        value={accidentDescription.value}
                        onChange={(e) => setAccidentDescription({ value: e.value as string || '', error: '' })}
                    />
                    <Error>{accidentDescription.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Please describe the road conditions at time of the accident / incident:</Label>
                    <TextArea
                        rows={2}
                        maxLength={200}
                        value={accidentRoadConditions.value}
                        onChange={(e) => setAccidentRoadConditions({ value: e.value as string || '', error: '' })}
                    />
                    <Error>{accidentRoadConditions.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Please describe the weather conditions at time of accident / incident:</Label>
                    <TextArea
                        rows={2}
                        maxLength={200}
                        value={accidentWeatherConditions.value}
                        onChange={(e) => setAccidentWeatherConditions({ value: e.value as string || '', error: '' })}
                    />
                    <Error>{accidentWeatherConditions.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Is there damage to our vehicle?</Label>
                    <RadioGroup
                        data={yesNoRadioButtonValues}
                        layout={'horizontal'}
                        defaultValue={anyDamageToOurVehicle.value}
                        onChange={(e) => setAnyDamageToOurVehicle({ value: e.value as boolean, error: '' })}
                    />
                    <Error>{anyDamageToOurVehicle.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Is the vehicle drivable?</Label>
                    <RadioGroup
                        data={yesNoRadioButtonValues}
                        layout={'horizontal'}
                        defaultValue={isVehicleDrivable.value}
                        onChange={(e) => setIsVehicleDrivable({ value: e.value as boolean, error: '' })}
                    />
                    <Error>{isVehicleDrivable.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Does load need to be recovered?</Label>
                    <RadioGroup
                        data={yesNoRadioButtonValues}
                        layout={'horizontal'}
                        defaultValue={doesLoadNeedToBeRecovered.value}
                        onChange={(e) => setDoesLoadNeedToBeRecovered({ value: e.value as boolean, error: '' })}
                    />
                    <Error>{doesLoadNeedToBeRecovered.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Injury Information:</Label>
                    <TextArea
                        rows={2}
                        maxLength={200}
                        value={injuryInformation.value}
                        onChange={(e) => setInjuryInformation({ value: e.value as string || '', error: '' })}
                    />
                    <Error>{injuryInformation.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Witness Information:</Label>
                    <TextArea
                        rows={2}
                        maxLength={200}
                        value={witnessInformation.value}
                        onChange={(e) => setWitnessInformation({ value: e.value as string || '', error: '' })}
                    />
                    <Error>{witnessInformation.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Any occupants in our vehicle besides the driver:</Label>
                    <RadioGroup
                        data={yesNoRadioButtonValues}
                        layout={'horizontal'}
                        defaultValue={occupantsInOurVehicle.value}
                        onChange={(e) => setOccupantsInOurVehicle({ value: e.value as boolean, error: '' })}
                    />
                    <Error>{occupantsInOurVehicle.error}</Error>
                </FieldWrapper>

                {/*Separate section below*/}
                <FieldWrapper>
                    <Label>Was there other vehicle damage?</Label>
                    <RadioGroup
                        data={yesNoRadioButtonValues}
                        layout={'horizontal'}
                        defaultValue={damageToOtherVehicle.value}
                        onChange={(e) => setDamageToOtherVehicle({ value: e.value as boolean, error: '' })}
                    />
                    <Error>{damageToOtherVehicle.error}</Error>
                </FieldWrapper>
                <Fade style={{ zIndex: "1", width: "100%" }} transitionEnterDuration={800}>
                    {damageToOtherVehicle.value === true &&
                        <>
                            <span className="font-italic" style={{ fontSize: "small" }}>* If answered yes to the previous question, please provide the following information for the other damaged vehicle.</span>
                            <FieldWrapper>
                                <Label>Vehicle Make:</Label>
                                <Input
                                    maxLength={50}
                                    disabled={loading}
                                    value={otherVehicleMake.value}
                                    autoFocus={true}
                                    onChange={(e) => setOtherVehicleMake({ value: e.value || '', error: '' })}
                                />
                                <Error>{otherVehicleMake.error}</Error>
                            </FieldWrapper>
                            <FieldWrapper>
                                <Label>Vehicle Model:</Label>
                                <Input
                                    maxLength={50}
                                    disabled={loading}
                                    value={otherVehicleModel.value}
                                    onChange={(e) => setOtherVehicleModel({ value: e.value || '', error: '' })}
                                />
                                <Error>{otherVehicleModel.error}</Error>
                            </FieldWrapper>
                            <FieldWrapper>
                                <Label>Vehicle Plate:</Label>
                                <Input
                                    maxLength={8}
                                    disabled={loading}
                                    value={otherVehiclePlate.value}
                                    onChange={(e) => setOtherVehiclePlate({ value: e.value || '', error: '' })}
                                />
                                <Error>{otherVehiclePlate.error}</Error>
                            </FieldWrapper>
                            <FieldWrapper>
                                <Label>Vehicle Owner's Name:</Label>
                                <Input
                                    maxLength={50}
                                    disabled={loading}
                                    value={otherVehicleOwnerName.value}
                                    onChange={(e) => setOtherVehicleOwnerName({ value: e.value || '', error: '' })}
                                />
                                <Error>{otherVehicleOwnerName.error}</Error>
                            </FieldWrapper>
                            <FieldWrapper>
                                <Label>Insurance Information:</Label>
                                <TextArea
                                    rows={2}
                                    maxLength={200}
                                    value={otherVehicleInsuranceInformation.value}
                                    onChange={(e) => setOtherVehicleInsuranceInformation({ value: e.value as string || '', error: '' })}
                                />
                                <Error>{otherVehicleInsuranceInformation.error}</Error>
                            </FieldWrapper>
                        </>}
                </Fade>

            </form>

            <DialogActionsBar>
                <Button onClick={props.CloseDialog}>Cancel</Button>
                <Button themeColor="primary" onClick={_save}>Send Report</Button>
            </DialogActionsBar>
        </Dialog>
    </div>
}

export default DriverAccidentReportDialog;

