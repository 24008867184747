import { Upload as KendoUpload, UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';
import * as React from 'react';
import LoadingPanel from '../../components/LoadingPanel';
import { fetchApi } from '../../services/api';
import { kendoUploadOnStatusChangeEventHandler } from '../../utils/kendo';
import { Title } from '../../utils/title';
import { JsonResponse } from 'TypeGen/json-response';

type Props = {}

type State = {
    isLoading: boolean;
    isAdmin: boolean;
}

export default class UploadEmailOwnerPaySheets extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            isAdmin: false,
        }

        this.onStatusChange = this.onStatusChange.bind(this);
    }  

    public render() {
        return (<>
            <Title string="Upload/Email Owner Pay Sheets" />
            {this.state.isLoading && <LoadingPanel />}
            <div className="container" style={{
                marginTop: "30px", padding: "10px 10px 10px 10px", border: "1px solid #BFBFBF", borderRadius: "5px"
            }}>
                <div className="row">
                    <div className="col">
                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                            <span className="k-window-title k-dialog-title">Upload/Email Owner Pay Sheets</span>
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col">
                        <div className="k-window-content k-dialog-content">
                            <div className="row align-items-center">
                                <div className="col">
                                    <KendoUpload
                                        disabled={!this.state.isAdmin}
                                        batch={false}
                                        multiple={false}
                                        autoUpload={false}
                                        defaultFiles={[]}
                                        withCredentials={false}
                                        restrictions={{
                                            allowedExtensions: ['.csv']
                                        }}
                                        saveUrl={`/api/Settlements/Upload/EmailOwnerPaySheets`}
                                        saveMethod="POST"
                                        onBeforeUpload={() => this.setState({ isLoading: true })}
                                        onStatusChange={this.onStatusChange}
                                    />
                                    <p className="p-1">
                                        File type supported: .csv
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>);
    }
    
    private onStatusChange(e: UploadOnStatusChangeEvent) {
        this.setState({ isLoading: false });
        kendoUploadOnStatusChangeEventHandler(e);
    }

    public componentDidMount() {
        ((document.getElementsByClassName("k-upload-button")[0] as HTMLElement).getElementsByTagName("span")[0] as HTMLElement).innerText = "Select file...";
        ((document.getElementsByClassName("k-dropzone")[0] as HTMLElement).getElementsByClassName("k-dropzone-hint")[0] as HTMLElement).innerText = "Drop file here to upload";

        this.fetch();
    }

    private fetch() {
        this.setState({
            isLoading: true
        });

        fetchApi('/api/Settlements/IsAdmin')
            .then((response: JsonResponse) => {
                this.setState({ isLoading: false, isAdmin: response.Success });
            })
    }

}