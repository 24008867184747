import * as React from 'react';
import { fetchApi } from '../../services/api';
import { formatNumber } from '@progress/kendo-intl';

type State = {
  loading: boolean;
  dailyAssetsChart: number[];
  dailyAssetsRevenue: any[];
}

type Props = {
  filter: { Name: string, Filter: string };
}

export default class DailyRevenueAssets extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      dailyAssetsChart: [],
      dailyAssetsRevenue: [],
    }
  }

  public componentDidMount() {
    this.refreshData();
  }

  public refreshData() {
    fetchApi(`/api/Report/RevenueTodayAssets/${this.props.filter.Filter}`)
      .then((response: Array<{ ServiceTeamID: number, TotalCharges: number }>) => {
        this.setState({
          loading: false,
          dailyAssetsChart: response.map(x => x.TotalCharges),
          dailyAssetsRevenue: response
        });
      });
  }

  public render() {
    if (this.state.loading) {
      return <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 report-card"></div>;
    }

    return <React.Fragment>
        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 report-card">
          <p>
            <span className="report-card-title">{this.props.filter.Name} Daily Revenue - Assets</span>
            <br />
            {formatNumber(this.state.dailyAssetsChart.reduce((a, b) => { return a + b }, 0), "c0")} Total Revenue
          </p>
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Service Team</th>
                <th style={{ textAlign: 'right' }}>Revenue</th>
              </tr>
            </thead>
            <tbody>
              {this.state.dailyAssetsRevenue.map((revenue, index) => {
                return <tr key={index}>
                  <td>{this.serviceTeamName(revenue.ServiceTeamID)}</td>
                  <td style={{ textAlign: 'right' }}>{formatNumber(revenue.TotalCharges, "c0")}</td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </React.Fragment>
  }

  private serviceTeamName(serviceTeamID: number): string {
    switch(serviceTeamID) {
      case 1:
        return "TruckLoad";
      case 2:
        return "Brokerage";
      case 3:
        return "Dedicated";
      case 4:
        return "Expedite";
      case 5:
        return "TL Brokerage";
      case 6:
        return "Specialized";
      case 7:
        return "Not Committed";
      default:
        return "N/A";
    }
  }
}
