import { useCallback, useEffect, useState } from "react";
import { Title } from '../../utils/title';
import { useParams } from "react-router";
import { CarrierNavBar } from "./CarrierNavBar";
import { fetchApi } from "../../services/api";
import { Loader } from "@progress/kendo-react-indicators";
import { Grid, GridColumn as Column, GridNoRecords, GridSortChangeEvent } from "@progress/kendo-react-grid";
import DateCell from "../../components/cells/DateCell";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";

type RouteComponentParams = {
  carrierId: string;
};

type CarrierDeviceType = {
  CarrierAppDeviceID: string;
  CreatedDateTime: Date;
  Email: string;
  Activated: boolean;
  ActivationCode: number | null;
  DeviceName: string;
  HasPushNotifications: boolean;
  LastRefreshDateTime: Date | null;
}

const CarrierDevices = () => {

  const { carrierId: carrierIdParam } = useParams<RouteComponentParams>();
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState<CarrierDeviceType[]>();
  const [sort, setSort] = useState<SortDescriptor[]>([{ field: 'LastRefreshDateTime', dir: 'desc' }]);

  const refresh = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Asset/CarrierDevices/${carrierIdParam}`)
      .then((data: { Devices: CarrierDeviceType[] }) => {
        setLoading(false);
        setDevices(data.Devices);
      })
      .catch(err => {
        alert(err);
        setLoading(false);
      });
  }, [carrierIdParam]);

  const onGridSortChange = (event: GridSortChangeEvent) => {
    setSort(event.sort);
  };

  useEffect(() => {
    refresh();
  }, [carrierIdParam, refresh]);

  const loadingView = <div className="k-pos-absolute k-top-center mt-5">
    <Loader type="converging-spinner" />
  </div>;

  const dataView = () => <Grid
      sortable
      sort={sort}
      data={orderBy(devices, sort)}
      onSortChange={onGridSortChange}
      dataItemKey="CarrierAppDeviceID"
    >
      <GridNoRecords>
          {loading == false && <span>No Logins Found</span>}
      </GridNoRecords>
      <Column field="CreatedDateTime" title="Created" cell={DateCell} />
      <Column field="Email" title="Email" />
      <Column field="Activated" title="Activated" cell={(e) => <td>{e.dataItem.Activated ? 'Yes' : e.dataItem.ActivationCode}</td>} />
      <Column field="DeviceName" title="Device Name" />
      <Column field="LastRefreshDateTime" title="Last Opened" cell={DateCell} />
    </Grid>

  return (<>
    <CarrierNavBar id={carrierIdParam} currentTab="Devices" />
    <Title string="Logins" />
    <br />
    {loading || !devices ? loadingView : dataView()}
  </>);
}

export default CarrierDevices;