import { formatNumber } from '@progress/kendo-intl';
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import moment from 'moment-timezone';
import { useCallback, useContext, useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useHistory, useParams } from "react-router";
import { fetchApi } from "services/api";
import { ApiLink } from "TypeGen/api-link";
import { IDNameViewModel } from "TypeGen/id-name-view-model";
import { JsonResponse } from "TypeGen/json-response";
import { StopEditViewModel } from "TypeGen/Order/stop-edit-view-model";
import { Command as EditOrderStop } from "TypeGen/Order/StopEdit/command";
import { OrderFreightDetail } from "TypeGen/Order/StopEdit/order-freight-detail";
import { OrderStopNote } from "TypeGen/Order/StopEdit/order-stop-note";
import { ViewModel } from "TypeGen/Order/StopEdit/view-model";
import { StopVerb } from "TypeGen/stop-verb";
import { OrderContext } from "../Details";
import NoteEditUI from "../Notes/Edit";
import StopEditUI from "./Edit";
import { SvgIcon } from "@progress/kendo-react-common";
import { infoCircleIcon } from '@progress/kendo-svg-icons';

type RouteComponentParams = {
  orderId: string;
  orderStopId: string;
};

type Props = {
  orderId?: number;
  orderStopId?: number;
  onClose?: () => void;
  children?: React.ReactNode
};

const OrderStop: React.FC<Props> = props => {
  const { orderId: orderIdParam, orderStopId: orderStopIdParam } = useParams<RouteComponentParams>();
  const { setActiveStopIds, setDeleteStopIds, refresh: refreshOrder } = useContext(OrderContext);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [orderStop, setOrderStop] = useState<StopEditViewModel>();
  const [savedOrderStop, setSavedOrderStop] = useState<StopEditViewModel>();
  const [freightDetails, setFreightDetails] = useState<OrderFreightDetail[]>([]);
  const [reasonsLate, setReasonsLate] = useState<IDNameViewModel[]>([]);
  const [links, setLinks] = useState<ApiLink[]>([]);
  const [note, setNote] = useState<OrderStopNote>(undefined);
  const [notes, setNotes] = useState<OrderStopNote[]>([]);
  const requireReasonLate = orderStop?.ScheduledDateTime !== savedOrderStop?.ScheduledDateTime && reasonsLate.length > 0;
  const [orderId, setOrderId] = useState(0);
  const [orderStopId, setOrderStopId] = useState(0);

  const refreshOrderStop = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Order/StopEdit/${orderId}/${orderStopId}`)
      .then((response: ViewModel) => {
        // Parse dates
        response.StopEdit.ScheduledDateTime = moment.utc(response.StopEdit.ScheduledDateTime).tz('America/New_York').format("MM/DD/YYYY HH:mm");
        response.StopEdit.EarliestStopDateTime = response.StopEdit.StopVerb === StopVerb.WINDOW ? moment.utc(response.StopEdit.EarliestStopDateTime).tz('America/New_York').format("MM/DD/YYYY HH:mm") : undefined;
        response.StopEdit.LatestStopDateTime = response.StopEdit.StopVerb === StopVerb.WINDOW ? moment.utc(response.StopEdit.LatestStopDateTime).tz('America/New_York').format("MM/DD/YYYY HH:mm") : undefined;
        setOrderStop(response.StopEdit);
        setSavedOrderStop(response.StopEdit);
        setFreightDetails(response.FreightDetails);
        setReasonsLate(response.ReasonsLate);
        setNotes(response.Notes);
        setLinks(response.Links);
        setLoading(false);
      })
      .catch(err => {
        alert(err);
        setLoading(false);
      });
  }, [orderId, orderStopId]);

  useEffect(() => {
    if (props.orderId > 0) {
      setOrderId(props.orderId);
      setOrderStopId(props.orderStopId);
    }
    else {
      setOrderId(Number(orderIdParam));
      setOrderStopId(Number(orderStopIdParam));
    }
  }, [orderIdParam, orderStopIdParam, props.orderId, props.orderStopId]);

  useEffect(() => {
    if (orderId > 0 && orderStopId > 0) {
      refreshOrderStop();
      setActiveStopIds([orderStopId]);
      setDeleteStopIds([]);
    }
  }, [orderId, orderStopId, refreshOrderStop, setActiveStopIds, setDeleteStopIds]);

  const save = () => {
    setLoading(true);
    const command = links.find(l => l.Name === 'Save');
    const data = {
      CustomerID: orderStop.CustomerID,
      City: orderStop.City,
      State: orderStop.State,
      ZipCode: orderStop.ZipCode,
      CustomerContactID: orderStop.CustomerContactID,
      ScheduledDateTime: orderStop.ScheduledDateTime,
      EarliestStopDateTime: orderStop.EarliestStopDateTime,
      LatestStopDateTime: orderStop.LatestStopDateTime,
      StopVerb: orderStop.StopVerb,
      ReasonLate: requireReasonLate ? orderStop.ReasonLate : undefined,
      AdjustSubsequentStops: orderStop.AdjustSubsequentStops
    } as EditOrderStop;
    fetchApi(command.Link, data, command.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          if (props.onClose)
            props.onClose();
          else {
            refreshOrder();
            history.push(`/Order/${orderId}`);
          }
        } else {
          alert(response.ErrorMessage);
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to update stop');
      });
  }

  const newNote = () => {
    setNote({
      OrderNoteID: 0,
      ModifiedDateTime: '',
      ModifiedByFullName: '',
      CustomerVisible: false,
      CarrierVisible: false,
      DriverVisible: false,
      IsCritical: false,
      Note: ''
    });
  }

  const addNote = (note: OrderStopNote) => {
    const link = links.find(l => l.Name === 'AddNote');
    setLoading(true);
    fetchApi(link.Link, note, link.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          setNote(undefined);
          refreshOrderStop();
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert(e);
      });
  }

  useHotkeys('ctrl+s, n', (e, handler) => {
    switch (handler.keys.join('')) {
      case 's':
        if (!note) {
          save();
          e.preventDefault();
        }
        break;
      case 'n':
        setTimeout(newNote);
        break;
    }
  }, [save, newNote]);

  if (loading && !orderStop) {
    return <div className="d-flex justify-content-center mt-5">
      <Loader type="converging-spinner" />
    </div>;
  }

  return <div className='py-2'>
    <div className="row">
      <div className="col-md-7">
        <StopEditUI
          orderStop={orderStop}
          setOrderStop={setOrderStop}
          reasonsLate={requireReasonLate ? reasonsLate : undefined}
          resolveStopNote={notes.find(x => x.Note.startsWith("CUST:"))?.Note}
        />
      </div>
      <div className="col-md-5">
        {freightDetails.map((freighDetail) => <div
          className="card mb-2"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/Order/${orderId}/Freight/${freighDetail.OrderFreightDetailID}`);
          }}
          onMouseEnter={() => {
            setActiveStopIds([freighDetail.PickupStopID, freighDetail.DeliveryStopID]);
          }}
          onMouseLeave={() => {
            setActiveStopIds([orderStop.OrderStopID]);
          }}
        >
          <div className="card-body">
            <h5 className="card-title">
              {freighDetail.IsPickup ? 'Delivering to ' : 'Picking Up at '}{freighDetail.LocationName}
            </h5>
            {formatNumber(freighDetail.Pieces, 'n0')} Pieces
            <br />
            {formatNumber(freighDetail.Weight, 'n0')} {freighDetail.WeightUnitOfMeasure === 1 ? 'lbs' : 'kg'}
            <br />
            {formatNumber(freighDetail.Length, 'n0')} x {formatNumber(freighDetail.Width, 'n0')} x {formatNumber(freighDetail.Height, 'n0')} {freighDetail.DimsUnitOfMeasure === 2 ? 'in' : 'ft'} (LxWxH)
            <br />
            {freighDetail.Stackable ? 'Stackable' : 'Non-Stackable'}
            <br />
            {freighDetail.FreightValue > 0 && formatNumber(freighDetail.FreightValue, 'c2')}
          </div>
        </div>)}
      </div>
    </div>
    {note && <NoteEditUI
      loading={loading}
      orderNote={note}
      cancel={() => setNote(undefined)}
      noteType="Stop"
      save={addNote}
    />}
    {notes.length === 0
      ? <h4 className="text-center">No Stop Notes.</h4>
      : <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Stop Note</th>
            <th>Sharing</th>
            <th>Modified By</th>
            <th>Modified Date</th>
          </tr>
        </thead>
        <tbody>
          {notes.map(note => {
            const sharingArray = Array.from([(note.CustomerVisible ? 'Customer' : ''), (note.CarrierVisible ? 'Carrier' : ''), (note.DriverVisible ? 'Driver' : '')]).filter(Boolean);
            if (sharingArray.length === 0) sharingArray.push('None');
            return <tr key={note.OrderNoteID} onClick={() => setNote(note)}>
              <td>
                {note.IsCritical && <SvgIcon icon={infoCircleIcon} className='text-danger mr-1' />}
                {note.Note}
              </td>
              <td>{sharingArray.join(', ')}</td>
              <td>{note.ModifiedByFullName}</td>
              <td>{moment.utc(note.ModifiedDateTime).local().format('MM/DD/YYYY HH:mm')}</td>
            </tr>
          })}
        </tbody>
      </table>}
    <div className="text-center mt-2">
      <Button onClick={newNote} disabled={loading}>Add Note</Button>
    </div>
    <div className="text-center mt-3">
      {props.onClose && <Button themeColor="secondary" onClick={props.onClose} className='mr-2'>Cancel</Button>}
      <Button themeColor="primary" onClick={save} disabled={loading}>Update Stop</Button>
    </div>
  </div>
}

export default OrderStop;