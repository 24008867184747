import * as React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { EmailAlias } from '.';
import { Button } from '@progress/kendo-react-buttons';

export default function MyCommandCell(
  enterEdit: (dataItem: EmailAlias) => void,
  remove: (dataItem: EmailAlias) => void,
  save: (dataItem: EmailAlias) => void,
  cancel: (dataItem: EmailAlias) => void,
  editField: string
) {
    return class extends React.Component<GridCellProps> {
        render() {
            return !this.props.dataItem[editField]
                ? (
                    <td className="k-command-cell">
                        <Button
                            themeColor="primary"
                            className="k-grid-edit-command"
                            onClick={(e) => enterEdit(this.props.dataItem)}>
                            Edit
                        </Button>
                        <Button
                            className="k-grid-remove-command"
                            onClick={(e) => window.confirm('Confirm deleting: ' + this.props.dataItem.CustomerNumber) && remove(this.props.dataItem)}>
                            Remove
                        </Button>
                    </td>
                )
                : (
                    <td className="k-command-cell">
                        <Button
                            className="k-grid-save-command"
                            onClick={(e) => save(this.props.dataItem)}>
                            {this.props.dataItem.ID
                                ? 'Update'
                                : 'Add'}
                        </Button>
                        <Button
                            className="k-grid-cancel-command"
                            onClick={(e) => cancel(this.props.dataItem)}>{this.props.dataItem.ID
                                ? 'Cancel'
                                : 'Discard'}
                        </Button>
                    </td>
                );
        }
    }
};
