import { useCallback, useEffect, useState } from "react";
import { Title } from '../../utils/title';
import { useParams } from "react-router";
import { VehicleNavBar } from "./NavBar";
import { fetchApi } from "../../services/api";
import { Loader } from "@progress/kendo-react-indicators";
import { CompositeFilterDescriptor, DataSourceRequestState, toDataSourceRequestString } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import DateCell from "../../components/cells/DateCell";
import { BooleanFilter, DateFilter, Filter, NumericFilter, Operators, TextFilter } from "@progress/kendo-react-data-tools";
import { Window } from "@progress/kendo-react-dialogs";
import RouteMap from "../../components/RouteMap";
import { Button } from "@progress/kendo-react-buttons";
import { JsonResponse } from "TypeGen/json-response";
import ManualPositionPopup, { NewPositionType } from "./ManualPositionPopup";
import { Link } from "react-router-dom";
import { openWindow } from "services/openWindow";
import { DataSourceFilter } from "views/Track/Positions";

type RouteComponentParams = {
    vehicleId: string;
};

export type PositionsType = {
    Data: PositionType[],
    Total: number,
}

export type PositionType = {
    PositionID: number;
    VehicleID: number;
    TripID: number | null;
    TrailerID: number | null;
    Event: number;
    DateTime: string;
    City: string;
    State: string;
    Latitude: number;
    Longitude: number;
    Direction: number;
    Speed: number;
    Ignition: boolean | null;
    RepeatCount: number;
    DataSource: number;
    DataSourceName: string;
    UserNumber: string;
    TripNumber: number | null;
    VehicleNumber: string;
    TrailerNumber: string;
}

export const resetDataState = {
    skip: 0,
    take: 50,
    sort: [{
        field: "DateTime",
        dir: "desc"
    }],
} as DataSourceRequestState;

export const positionEventName = (event: number) => {
    switch (event) {
        case 2:
            return "Dispatched Order";
        case 3:
            return "Arrive Shipper";
        case 4:
            return "Freight Loaded";
        case 7:
            return "Arrive Consignee";
        case 8:
            return "Empty";
        case 17:
            return "Check Call";
        case 18:
            return "Dispatch DeadHead";
        case 19:
            return "Empty DeadHead";
        case 61:
            return "Tracking On";
        case 62:
            return "Tracking Off";
        default:
            return event;
    }
}

const VehiclePositions = () => {

    const { vehicleId: vehicleIdParam } = useParams<RouteComponentParams>();
    const [loading, setLoading] = useState(true);
    const [showMap, setShowMap] = useState(false);
    const [showNewPosition, setShowNewPosition] = useState(false);
    const [positions, setPositions] = useState<PositionsType>({
        Data: [],
        Total: 0,
    });
    const [dataState, setDataState] = useState<DataSourceRequestState>(resetDataState);
    const [filter, setFilter] = useState<CompositeFilterDescriptor>({
        logic: 'and', filters: []
    });

    const refresh = useCallback(() => {
        setLoading(true);
        const queryStr = `${toDataSourceRequestString(dataState)}`;
        fetchApi(`/api/Asset/VehiclePositions/${vehicleIdParam}?${queryStr}`, {}, 'POST')
            .then(data => {
                setLoading(false);
                setPositions(data);
            })
            .catch(err => {
                alert(err);
                setLoading(false);
            });
    }, [vehicleIdParam, dataState]);

    useEffect(() => {
        refresh();
    }, [vehicleIdParam, refresh]);

    const savePosition = (newPosition: NewPositionType) => {
        return fetchApi(`/api/Asset/AddVehiclePosition/${vehicleIdParam}`, newPosition, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    setShowNewPosition(false);
                    refresh();
                }
                else
                {
                    alert(response.ErrorMessage);
                }
            })
            .catch((e) => {
                // If not problem details
                if (!e?.status) alert("Unable to save position");
            });
    }

    return (<>
        <VehicleNavBar id={vehicleIdParam} currentTab="Positions" />
        <Title string="Positions" />
        {loading && <div className="k-pos-absolute k-top-center mt-5">
            <Loader type="converging-spinner" />
        </div>}
        {showMap && <Window stage="FULLSCREEN" style={{ width: '100%' }} onClose={() => setShowMap(false)}>
            <RouteMap lnglats={positions.Data.reverse().map(x => [x.Longitude, x.Latitude])} />
        </Window>}
        {showNewPosition && <ManualPositionPopup
            close={() => setShowNewPosition(false)}
            save={savePosition}
        />}
        <div>
            <Filter
                className="kendoFilterControl"
                value={filter}
                onChange={(e) => {
                    setFilter(e.filter);
                }}
                fields={[
                    {
                        name: "TripNumber",
                        label: "Trip #",
                        filter: NumericFilter,
                        operators: Operators.numeric,
                    },
                    {
                        name: "TrailerNumber",
                        label: "Trailer #",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                    {
                        name: "DateTime",
                        label: "Date",
                        filter: DateFilter,
                        operators: Operators.date,
                    },
                    {
                        name: "City",
                        label: "City",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                    {
                        name: "State",
                        label: "State",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                    {
                        name: "Speed",
                        label: "Speed",
                        filter: NumericFilter,
                        operators: Operators.numeric,
                    },
                    {
                        name: "Ignition",
                        label: "Ignition",
                        filter: BooleanFilter,
                        operators: Operators.boolean,
                    },
                    {
                        name: "RepeatCount",
                        label: "Repeat Count",
                        filter: NumericFilter,
                        operators: Operators.numeric
                    },
                    {
                        name: "DataSource",
                        label: "Source",
                        filter: DataSourceFilter,
                        operators: [{ text: 'filter.eqOperator', operator: 'eq' }]
                    },
                    {
                        name: "UserNumber",
                        label: "User",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                ]}
            />
            <label className="ml-2" style={{ verticalAlign: "bottom", marginBottom: "10px" }}>
                <Button
                    themeColor="primary"
                    onClick={() => setDataState({ ...dataState, filter: filter })}
                >Search
                </Button>
                &nbsp;&nbsp;
                {positions.Data.length > 0 && <>
                    <Button
                        onClick={() => setShowMap(true)}
                    >Map
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            openWindow(`https://www.google.com/maps/dir/${positions.Data[0].Latitude},${positions.Data[0].Longitude}`);
                        }}
                    >
                        Google
                    </Button>
                    &nbsp;&nbsp;
                </>}
                <Button
                    onClick={() => setShowNewPosition(true)}
                >New Position
                </Button>
            </label>
        </div>
        {positions && <Grid
            {...dataState}
            data={positions.Data}
            total={positions.Total}
            sortable
            pageable
            onDataStateChange={(e) => setDataState(e.dataState)}
        >
            <Column field="TripNumber" title="Trip #" cell={(e) => <td>
                <Link style={{ color: "#007bff" }} to={`/Trip/${e.dataItem.TripID}`}>{e.dataItem.TripNumber}</Link>
            </td>} />
            <Column field="TrailerNumber" title="Trailer #" cell={(e) => <td>
                <Link style={{ color: "#007bff" }} to={`/Assets/Trailer/${e.dataItem.TrailerID}`}>{e.dataItem.TrailerNumber}</Link>
            </td>} />
            <Column field="DateTime" title="Date" filterable={false} filter="date" cell={DateCell} />
            <Column field="City" />
            <Column field="State" />
            <Column field="Speed" filter="numeric" format="{0:n0} mph" />
            <Column field="Ignition" filter="boolean" cell={e => <td>{e.dataItem.Ignition ? "ON" : e.dataItem.Ignition === false ? "OFF" : ""}</td>} />
            <Column field="RepeatCount" filter="numeric" />
            <Column field="DataSourceName" title="Source" />
            <Column field="UserNumber" title="User" />
            <Column field="Event" filterable={false} cell={(e) => <td>{positionEventName(e.dataItem.Event)}</td>} />
        </Grid>}
    </>);
}

export default VehiclePositions;