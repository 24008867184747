import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { openWindow } from '../../../services/openWindow';
import { ILink } from '../../../types/link';

export default class LastAvailableCell extends React.Component<GridCellProps> {
  render() {
    if (!this.props.field) return null;

    return (
      <td>
        <a
          href="#"
          style={{ color: '#007bff' }}
          onClick={(e) => {
            openWindow(this.props.dataItem.Links.filter((x: ILink) => x.Name === 'WorkloadHistory')[0].Link);
            e.preventDefault();
          }}
        >{this.props.dataItem.LastAvailable}
        </a>
      </td>
    );
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return nextProps.dataItem.Hash !== this.props.dataItem.Hash;
  }
}
