import { useCallback, useEffect, useState } from "react";
import { Title } from '../../utils/title';
import { fetchApi } from "../../services/api";
import { Loader } from "@progress/kendo-react-indicators";
import { CompositeFilterDescriptor, DataSourceRequestState, toDataSourceRequestString } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { DateFilter, Filter, NumericFilter, Operators, TextFilter } from "@progress/kendo-react-data-tools";
import { Button } from "@progress/kendo-react-buttons";
import LocalDateOnlyCell from "components/cells/LocalDateOnlyCell";
import { Link } from "react-router-dom";
import Moment from 'moment-timezone';

type PositionsType = {
    Data: OrderScheduleType[],
    Total: number,
}

type OrderScheduleType = {
    OrderScheduleID: number;
    OrderID: number;
    OrderNumber: number;
    ShipperCustomerID: number;
    ShipperCustomerName: string;
    ShipperCustomerCityState: string;
    ShipDateTime: Date;
    ConsigneeCustomerID: number;
    ConsigneeCustomerName: string;
    ConsigneeCustomerCityState: string;
    DeliveryDateTime: Date;
    Occurs: number;
    EffectiveFromDate: Date;
    EffectiveToDate: Date;
    LastCreatedDate: Date | null;
    LeadDays: number;
    LaneName: string;
    Note: string;
}

const resetDataState = {
    skip: 0,
    take: 50,
    sort: [{
        field: "OrderNumber",
        dir: "asc"
    }],
    filter: {
        logic: 'and', filters: [
            { field: "EffectiveToDate", operator: "gte", value: new Date() },
            { field: "EffectiveFromDate", operator: "lte", value: new Date() },
        ]
    }
} as DataSourceRequestState;

const ScheduledOrders = () => {

    const [loading, setLoading] = useState(true);
    const [positions, setPositions] = useState<PositionsType>();
    const [dataState, setDataState] = useState<DataSourceRequestState>(resetDataState);
    const [filter, setFilter] = useState<CompositeFilterDescriptor>(resetDataState.filter);

    const refresh = useCallback(() => {
        setLoading(true);
        const queryStr = `${toDataSourceRequestString(dataState)}`;
        fetchApi(`/api/Order/ScheduledOrders?${queryStr}`, {}, 'POST')
            .then(data => {
                setLoading(false);
                setPositions(data);
            })
            .catch(err => {
                alert(err);
                setLoading(false);
            });
    }, [dataState]);

    useEffect(() => {
        refresh();
    }, [ refresh]);

    const occursName = (occurs: number) => {
        switch (occurs) {
            case 0:
                return "Daily";
            case 1:
                return "Weekly";
            case 2:
                return "Monthly";
            case 3:
                return "Bi-Weekly";
            default:
                return occurs;
        }
    }

    return (<>
        <Title string="Scheduled Orders" />
        {loading && <div className="k-pos-absolute k-top-center mt-5">
            <Loader type="converging-spinner" />
        </div>}
        <div>
            <Filter
                className="kendoFilterControl"
                value={filter}
                onChange={(e) => {
                    setFilter(e.filter);
                }}
                fields={[
                    {
                        name: "OrderNumber",
                        label: "Order #",
                        filter: NumericFilter,
                        operators: Operators.numeric,
                    },
                    {
                        name: "ShipperCustomerName",
                        label: "Shipper Name",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                    {
                        name: "ConsigneeCustomerName",
                        label: "Consignee Name",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                    {
                        name: "EffectiveFromDate",
                        label: "From Date",
                        filter: DateFilter,
                        operators: Operators.date,
                    },
                    {
                      name: "EffectiveToDate",
                      label: "To Date",
                      filter: DateFilter,
                      operators: Operators.date,
                    },
                    {
                        name: "LeadDays",
                        label: "Lead Days",
                        filter: NumericFilter,
                        operators: Operators.numeric,
                    },
                    {
                        name: "LaneName",
                        label: "Lane",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                ]}
            />
            <label className="ml-2" style={{ verticalAlign: "bottom", marginBottom: "10px" }}>
                <Button
                    themeColor="primary"
                    onClick={() => setDataState({ ...dataState, filter: filter })}
                >Search
                </Button>
            </label>
        </div>
        {positions && <Grid
            {...dataState}
            data={positions.Data}
            total={positions.Total}
            sortable
            pageable
            onDataStateChange={(e) => setDataState(e.dataState)}
        >
            <Column field="OrderNumber" title="Order #" cell={(props) => <td>
                <Link style={{ color: "#007bff" }} to={`/Order/${props.dataItem.OrderID}/Schedule`}>{props.dataItem.OrderNumber}</Link>
            </td>} />
            <Column field="ShipperCustomerName" title="Shipper" cell={(props) => <td>
                <Link style={{ color: "#007bff" }} to={`/Customers/Customer/${props.dataItem.ShipperCustomerID}`}>{props.dataItem.ShipperCustomerName}</Link><br />
                <small>{props.dataItem.ShipperCustomerCityState}</small>
            </td>} />
            <Column field="ConsigneeCustomerName" title="Consignee" cell={(props) => <td>
                <Link style={{ color: "#007bff" }} to={`/Customers/Customer/${props.dataItem.ConsigneeCustomerID}`}>{props.dataItem.ConsigneeCustomerName}</Link><br />
                <small>{props.dataItem.ConsigneeCustomerCityState}</small>
            </td>} />
            <Column field="Occurs" filterable={false} cell={(e) => <td>{occursName(e.dataItem.Occurs)}</td>} />
            <Column field="EffectiveFromDate" title="Effective From/To" filterable={false} filter="date" cell={(props) => <td>
                {Moment(props.dataItem.EffectiveFromDate).format("MM/DD/YYYY")}<br />
                {Moment(props.dataItem.EffectiveToDate).format("MM/DD/YYYY")}
            </td>} />
            <Column field="LastCreatedDate" title="Last Generated" filterable={false} filter="date" cell={LocalDateOnlyCell} />
            <Column field="LeadDays" title="Lead Days" filter="numeric" />
            <Column field="Note" />
            <Column field="LaneName" title="Lane" />
        </Grid>}
    </>);
}

export default ScheduledOrders;