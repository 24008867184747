import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import Moment from 'moment-timezone';
import { VehicleNavBar } from "./NavBar";
import { Title } from "utils/title";
import { fetchApi } from "services/api";
import { ApiLink } from "TypeGen/api-link";
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import DateCell from "components/cells/DateCell";
import { VehicleHardwareViewModel } from "TypeGen/Assets/Hardware/vehicle-hardware-view-model";
import { GridLoadingPanel } from "components/GridLoadingPanel";
import { Button } from "@progress/kendo-react-buttons";
import AddHardwareDialog from "./AddHardwareDialog";
import { AssetHardwareType } from "TypeGen/Assets/asset-hardware-type";
import DateOnlyCell from "components/cells/DateOnlyCell";
import { arrowRotateCwIcon, pencilIcon, plusIcon } from "@progress/kendo-svg-icons";

type RouteComponentParams = {
  vehicleId: string;
};

type Response = {
  Hardware: VehicleHardwareViewModel[];
  Links: ApiLink[];
}

export const hardwareTypes = [
  { ID: AssetHardwareType.Netradyne, Name: 'Netradyne' },
  { ID: AssetHardwareType.Omnitracs, Name: 'Omnitracs' },
  { ID: AssetHardwareType.IowaLicensePlate, Name: 'Iowa License Plate' },
  { ID: AssetHardwareType.BestPass, Name: 'Best Pass' },
  { ID: AssetHardwareType.FuelCard, Name: 'Fuel Card' },
  { ID: AssetHardwareType.BridgeTag, Name: 'Bridge Tag' },
  { ID: AssetHardwareType.LeadCard, Name: 'Lead Card' },
  { ID: AssetHardwareType.Transponder, Name: 'Transponder' },
  { ID: AssetHardwareType.Decals, Name: 'Decals' },
  { ID: AssetHardwareType.NameBadge, Name: 'Name Badge' },
];

const VehicleHardware = () => {

  const { vehicleId: vehicleIdParam } = useParams<RouteComponentParams>();
  const [loading, setLoading] = useState(true);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [data, setData] = useState<VehicleHardwareViewModel[]>([]);
  const [editData, setEditData] = useState<VehicleHardwareViewModel | null>(null);
  const [links, setLinks] = useState<ApiLink[]>([]);

  const refresh = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Asset/VehicleHardware/${vehicleIdParam}`)
      .then((data: Response) => {
        setData(data.Hardware);
        setLinks(data.Links);
      })
      .finally(() => setLoading(false));
  }, [vehicleIdParam]);

  useEffect(() => {
    refresh();
  }, [vehicleIdParam, refresh]);

  return <>
    <VehicleNavBar id={vehicleIdParam} currentTab="Hardware" />
    <Title string="Hardware" />
    {showAddDialog && <AddHardwareDialog
      VehicleID={parseInt(vehicleIdParam)}
      EditData={editData}
      CloseDialog={(shouldRefresh) => {
        shouldRefresh && refresh();
        setEditData(null);
        setShowAddDialog(false);
      }}
    />}
    {loading && <GridLoadingPanel />}
    <Grid data={data} dataItemKey="AssetHardwareID">
      <GridToolbar>
        <Button
          title="Refresh"
          icon="refresh"
          svgIcon={arrowRotateCwIcon}
          onClick={refresh}
          disabled={loading}
        />
        {links.find(x => x.Name === 'Add') && <Button
            icon="plus"
            svgIcon={plusIcon}
            themeColor="primary"
            disabled={loading}
            onClick={() => setShowAddDialog(true)}
        > Hardware
        </Button>}
      </GridToolbar>
      <Column field="HardwareNumber" title="Hardware #" />
      <Column field="HardwareType" title="Type" cell={(e) => <td>{hardwareTypes.find(x => x.ID == e.dataItem.HardwareType)?.Name}</td>} />
      <Column field="DisableIntegration" title="Status" cell={(e) => <td className={e.dataItem.DisableIntegration ? 'text-danger' : ''}>
        {e.dataItem.DisableIntegration ? 'Disabled' : ''}
      </td>} />
      <Column field="Note" />
      <Column field="InstalledDateTime" title="Provided On" cell={DateOnlyCell} />
      <Column field="RemovedDateTime" title="Returned On" cell={(e) => <td>
        {e.dataItem.RemovedDateTime ? Moment.utc(e.dataItem.RemovedDateTime).tz("America/New_York").format("MM/DD/YYYY") : 'In Use'}
      </td>} />
      <Column field="ModifiedByFullName" title="Modified By" />
      <Column field="ModifiedDateTime" title="Modified Date" cell={DateCell} />
      {links.find(x => x.Name === 'Add') && <Column title="Action" cell={(e) => {
        return <td>
          <Button
            icon="edit"
            svgIcon={pencilIcon}
            size="small"
            themeColor="primary"
            onClick={() => {
              setEditData(e.dataItem);
              setShowAddDialog(true);
            }
          }>
            Edit
          </Button>
        </td>;
      }} />}
    </Grid>
  </>;
}

export default VehicleHardware;