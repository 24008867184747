import * as Sentry from '@sentry/browser';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import * as React from 'react';
import { useResizeDetector } from 'react-resize-detector';

type Props = {
    drawRoute: boolean;
    clearRoute: boolean;
    locations: any[];
    bordersOpen: boolean;
    routeOptimization: number;
    routingType?: number;
    classOverride?: number;
    onLoading?: () => void;
    onReport: (report: MapReport) => void;
    onError?: (error: any) => void;
};

export type MapBaseReport = {
    routeId: string,
    report: MapReport[],
}

export type MapReport = {
    __type: string,
    ReportLines: MapReportLines[]
}

export type MapReportLines = {
    Stop: MapReportLinesStop,
    LMiles: number,
    TMiles: number,
    LCostMile: number,
    LTolls: number,
    TTolls: number,
    TCostMile: number
}

export type MapReportLinesStop = {
    DistanceFromRoad: number,
    TimeZone: string,
    Address: MapReportLinesStopAddress,
    Coords: MapReportLinesStopCoords
}

export type MapReportLinesStopAddress = {
    City: string,
    State: string,
    Zip: string,
    CountryAbbreviation: string
}

export type MapReportLinesStopCoords = {
    Lat: number,
    Lon: number
}

const MapPanel = (props: Props) => {

    const map = React.useRef<TrimbleMaps.Map>(null);
    const routingLayer = React.useRef<TrimbleMaps.Route>(null);
    const [mapLoaded, setMapLoaded] = React.useState(false);
    const [mapStyleLoaded, setMapStyleLoaded] = React.useState(false);

    const onResize = React.useCallback(() => {
        if (map.current) {
          map.current.resize();
        }
    }, []);

    const { ref } = useResizeDetector({ onResize });

    const renderMap = React.useCallback((locations: any[], bordersOpen: boolean, routeOptimization: any, routingType: any, classOverride: any) => {
        if (!routingLayer.current) {
            routingLayer.current = new TrimbleMaps.Route({
                routeId: "QuoteRoute",
                highwayOnly: false,
                tollDiscourage: false,
                reportType: [
                    TrimbleMaps.Common.ReportType.MILEAGE,
                    TrimbleMaps.Common.ReportType.DETAIL
                ],
                dataVersion: TrimbleMaps.Common.DataVersion.PCM30, // For Report Data
                dataset: "PCM30", // For Map Path
            });

            routingLayer.current.on('routeloading', () => {
                props.onLoading && props.onLoading();
            });

            routingLayer.current.on('report', (reports: any[]) => {
                props.onReport(reports[0]);
            });

            // This works, but it's not in the documentation
            routingLayer.current.on('error', (error: any) => {
                props.onError && props.onError(error);
                Sentry.captureException(error);
            });
        }

        // Map style isn't loaded yet
        if (!map.current.isStyleLoaded()) {
            // Retry after a second
            setTimeout(() => {
                renderMap(locations, bordersOpen, routeOptimization, routingType, classOverride);
            }, 100);
            return;
        }

        if (!map.current.getLayer("route-layer-QuoteRoute")) {
            routingLayer.current.addTo(map.current);
        }

        // Add/Update Route
        var coords = locations.map(x => new TrimbleMaps.LngLat(x.Coordinates.Longitude, x.Coordinates.Latitude));
        routingLayer.current.update({
            stops: coords,
            bordersOpen: bordersOpen,
            routeOptimization: routeOptimization,
            routeType: routingType,
            overrideClass: classOverride,
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, routingLayer]);

    React.useEffect(() => {
        if (map.current && mapLoaded && mapStyleLoaded) {
            if (props.clearRoute && routingLayer.current) {
                routingLayer.current.remove();
            } else if (props.drawRoute && props.locations.length > 1) {
                renderMap(props.locations, props.bordersOpen, props.routeOptimization, props.routingType, props.classOverride);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        map,
        mapLoaded,
        mapStyleLoaded,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        JSON.stringify(props.locations),
        props.bordersOpen,
        props.routeOptimization,
        props.routingType,
        props.classOverride,
        props.clearRoute,
        props.drawRoute,
        renderMap
    ]);

    React.useEffect(() => {
        const style = localStorage["darkmode"] === 'false' ? TrimbleMaps.Common.Style.TRANSPORTATION : TrimbleMaps.Common.Style.TRANSPORTATION_DARK;
        map.current = new TrimbleMaps.Map({
            container: "quote-map",
            style: style,
            center: [-83.3083,42.2079],
            zoom: 12,
            scrollZoom: false,
            trackResize: false,
            attributionControl: false,
        });

        var navControl = new TrimbleMaps.NavigationControl();
        map.current.addControl(navControl, 'top-left');
        map.current.setDarkMode(style === TrimbleMaps.Common.Style.TRANSPORTATION_DARK);

        map.current.on('load', () => {
            setMapLoaded(true);
            map.current.setWeatherRadarVisibility(true);
        });

        map.current.on('style.load', () => {
            setMapStyleLoaded(true);
        })

        return () => {
            map.current.remove();
        }
    }, [renderMap]);

    return <div ref={ref} id="quote-map" style={{ width: '100%', height: '100%', minHeight: '500px' }} />;
}

export default MapPanel;
