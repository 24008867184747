import { SortDescriptor } from '@progress/kendo-data-query';
import { formatNumber } from '@progress/kendo-intl';
import { Button } from '@progress/kendo-react-buttons';
import { DateInput, SelectionRange } from '@progress/kendo-react-dateinputs';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Grid, GridCellProps, GridColumn as Column, GridEvent, GridHeaderCell, GridSortChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import Moment from 'moment-timezone';
import * as React from 'react';
import { ServiceTeam } from "TypeGen/service-team";
import CustomerInput from '../../../components/CustomerInput';
import LoadingPanel from '../../../components/LoadingPanel';
import SimpleDateRangePicker from '../../../components/SimpleDateRangePicker';
import { fetchApi } from '../../../services/api';
import { openWindowPerc } from '../../../services/openWindow';
import { ILink } from '../../../types/link';
import { Title } from '../../../utils/title';
import { enumToArrayOfIDName } from '../../../utils/utils';
import CarrierAutoComplete from '../../AssetCarriers/CarrierAutoComplete';
import VehicleAutoComplete from '../../AssetVehicles/VehicleAutoComplete';
import CustomerAutoComplete from '../../Customers/CustomerAutoComplete';
import { filterClearIcon } from '@progress/kendo-svg-icons';

type Props = {}

type State = {
  isLoading: boolean;
  data: InvoiceViewModel[];
  moreRecordsExist: boolean;
  // dataState: GridState;
  allowUnsort: boolean;
  multiple: boolean;
  advanceSearch: AdvanceSearchParameters;
  dateRange: SelectionRange;
  sortDesc: SortDescriptor[];
}

type AdvanceSearchParameters = {
  page: number;
  pageSize: number;
  orderNumberFilter: number;
  referenceNumberFilter: string;
  customerFilter: number;
  customerNumberFilter: string;
  carrierFilter: number;
  vehicleFilter: number;
  assetTypeFilter: string;
  shipFromDate?: Date;
  shipToDate?: Date;
  invoiceStatus?: number;
  serviceTeam?: number;
  isDryRun: boolean;
  isArcMinorityOrder: boolean;
  hasBalanceDue: boolean;
  isCrossDock: boolean;

  sortDescriptors: SortDescriptor[]
}

type InvoiceViewModel = {
  InvoiceID: number;
  InvoiceSequence: number;
  InvoiceLetter: string;
  OrderID: number;
  OrderNumber: number;
  InvoiceNumber: string;
  TotalCharges: number;
  ReferenceNumber1: string;
  InvoiceStatus: number;
  InvoiceStatusText: string;
  TotalMiles: number;
  PaperworkIn: boolean;
  IsArcMinorityOrder: boolean;
  OrderStatus: number;
  LoadType: LoadTypeModel;
  BillTo: CustomerViewModel;
  Shipper: StopCustomerViewModel;
  Consignee: StopCustomerViewModel;
  InvoiceQuickProBillLink: ILink;
}

type CustomerViewModel = {
  CustomerID: number;
  CustomerName: string;
  CustomerNumber: string;
  City: string;
  State: string;
  Zip: string;
  CustomerProfileLink: ILink;
}

type StopViewModel = {
  StopDateTime: Date;
}

type StopCustomerViewModel = CustomerViewModel & StopViewModel;

enum LoadTypeModel {
  NA,
  Asset,
  NonEPayCarrier,
  EPayCarrier
}

class InvoiceNumberHeaderCell extends GridHeaderCell {

  render() {
    return (
      <a className="k-link" onClick={this.props.onClick}>
        Invoice Number
        <br />Status
        <br />Ref. 1
        {this.props.children}
      </a>
    );
  }
}

class InvoiceNumberCell extends React.Component<GridCellProps> {
  render() {
    const dataItem = this.props.dataItem as InvoiceViewModel;
    return <td>
      <a
        href="#"
        style={{ color: '#007bff' }}
        onClick={(e) => {
          openWindowPerc(dataItem.InvoiceQuickProBillLink.Link, .9, .85);
          e.preventDefault();
        }}>{dataItem.InvoiceNumber}
      </a>
      <br />{dataItem.InvoiceStatusText}
      <br />{dataItem.ReferenceNumber1}
    </td>;
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return this.props.dataItem[this.props.field] !== nextProps.dataItem[this.props.field];
  }
}

class StopCell extends React.Component<GridCellProps> {
  render() {
    const dataItem = this.props.dataItem[this.props.field] as StopCustomerViewModel;
    return (<td>
      <a
        href="#"
        style={{ color: '#007bff' }}
        onClick={(e) => {
          openWindowPerc(dataItem.CustomerProfileLink.Link);
          e.preventDefault();
        }}>{dataItem.CustomerNumber}
      </a>-{dataItem.CustomerName}
      <br />{dataItem.City}, {dataItem.State}
      <br />{Moment.utc(dataItem.StopDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}
    </td>);
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return this.props.dataItem[this.props.field] !== nextProps.dataItem[this.props.field];
  }
}

class TotalChargesHeaderCell extends GridHeaderCell {
  render() {
    return (
      <a className="k-link" onClick={this.props.onClick} style={{ textAlign: "right" }}>
        <span>
          Total Charges
          <br />Miles
        </span>
        {this.props.children}
      </a>
    );
  }
}

class TotalChargesCell extends React.Component<GridCellProps> {
  render() {
    const dataItem = this.props.dataItem as InvoiceViewModel;
    return (<td style={{ textAlign: "right" }}>
      {formatNumber(this.props.dataItem.TotalCharges, "c")}
      <br />{dataItem.TotalMiles} mi.
    </td>);
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return this.props.dataItem[this.props.field] !== nextProps.dataItem[this.props.field];
  }
}

class PaperworkInHeaderCell extends GridHeaderCell {
  render() {
    return (
      <a className="k-link" title="For paperwork, it checks for 'POD' and/or 'Combined' where 'Print w/Invoice' is set to yes" onClick={this.props.onClick} style={{ textAlign: "left" }}>
        <span>
          Paperwork In
          <br />Load Type
        </span>
        {this.props.children}
      </a>
    );
  }
}

class PaperworkInCell extends React.Component<GridCellProps> {
  render() {
    const dataItem = this.props.dataItem as InvoiceViewModel;

    //let assetTypeSpan = <span style={{ fontWeight: "bold" }}>Asset</span>;

    let assetTypeSpan: JSX.Element;
    if (dataItem.LoadType === LoadTypeModel.EPayCarrier)
      assetTypeSpan = <span>EPay Carrier</span>;
    else if (dataItem.LoadType === LoadTypeModel.Asset)
      assetTypeSpan = <span>Asset</span>;
    else if (dataItem.LoadType === LoadTypeModel.NonEPayCarrier)
      assetTypeSpan = <span>Carrier</span>;
    else
      assetTypeSpan = <span>N/A</span>;

    return (<td>
      Paperwork In: {dataItem.PaperworkIn ? "Y" : <span style={{ fontWeight: "bold" }}>N</span>}
      <br />{assetTypeSpan}
    </td>);
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return this.props.dataItem[this.props.field] !== nextProps.dataItem[this.props.field];
  }
}

class CustomerCell extends React.Component<GridCellProps> {
  render() {
    const dataItem = this.props.dataItem[this.props.field] as CustomerViewModel;
    return (<td>
      <a
        href="#"
        style={{ color: '#007bff' }}
        onClick={(e) => {
          openWindowPerc(dataItem.CustomerProfileLink.Link);
          e.preventDefault();
        }}>{dataItem.CustomerNumber}
      </a>-{dataItem.CustomerName}
      <br />{dataItem.City}, {dataItem.State}
      {this.props.dataItem.IsArcMinorityOrder ? <><br /><span className='badge badge-warning'>ARC</span></> : ''}
    </td>);
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return this.props.dataItem[this.props.field] !== nextProps.dataItem[this.props.field];
  }
}


class CustomStartDateInput extends React.Component {
  render() {
    return (
      <DateInput {...this.props} label={undefined} width={120} formatPlaceholder={{ year: 'yyyy', month: 'MM', day: 'dd' }} />
    );
  }
}

class CustomEndDateInput extends React.Component {
  render() {
    return (
      <DateInput {...this.props} label={undefined} width={120} formatPlaceholder={{ year: 'yyyy', month: 'MM', day: 'dd' }} />
    );
  }
}

export default class InvoiceListing extends React.Component<Props, State> {
  private CONST_TAKE = 50;
  private minDate = Moment().subtract(3, "years").toDate();
  private maxDate = Moment().add(7, "days").toDate();

  private invoiceStatuses = [
    { value: 0, text: 'Any Status' },
    //{ value: 90, text: 'Rated/Discrepancy' },
    { value: 95, text: 'Unaudited' },
    { value: 100, text: 'Rated' },
    { value: 101, text: 'Rated In Billing, POD In' },
    { value: 102, text: 'Rated In Billing, BOL In' },
    { value: 103, text: 'Rated In Billing, POD/BOL In' },
    { value: 105, text: 'Discrepancy' },
    { value: 110, text: 'Ready to Post' },
    /*{ value: 115, text: 'Printed' }, Not used in TMS */
    { value: 120, text: 'Posted' },
  ]

  private serviceTeams = [{ ID: 0, Name: 'Any Service Team' }].concat(enumToArrayOfIDName(ServiceTeam));

  private defaultSort = [{ field: "InvoiceNumber", dir: "asc" }] as SortDescriptor[];
  private SESSION_STORAGE_NAME: string = "InvoiceListingFilterV1";
  constructor(props: Props) {
    super(props);

    const sessionStorageAndFilter = sessionStorage.getItem(this.SESSION_STORAGE_NAME);

    let filterSortState: any;
    if (sessionStorageAndFilter) {
      filterSortState = JSON.parse(sessionStorageAndFilter);
      filterSortState.dateRange.start = Moment(filterSortState.dateRange.start).utc().toDate();
      filterSortState.dateRange.end = Moment(filterSortState.dateRange.end).utc().toDate();
      filterSortState.advanceSearch.shipFromDate = Moment(filterSortState.advanceSearch.shipFromDate).utc().toDate();
      filterSortState.advanceSearch.shipToDate = Moment(filterSortState.advanceSearch.shipToDate).utc().toDate();
    } else {
      filterSortState = { ...this.resetFilterSortState() };
    }

    this.state = {
      isLoading: false,
      data: [],
      moreRecordsExist: false,
      allowUnsort: true,
      multiple: true,
      ...filterSortState
    };

    this.filterClear = this.filterClear.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.scrollHandler = this.scrollHandler.bind(this);
    this.sortChange = this.sortChange.bind(this);
  }

  private resetFilterSortState() {
    return {
      advanceSearch: {
        orderNumberFilter: 0, referenceNumberFilter: "", customerFilter: 0, customerNumberFilter: '', invoiceStatus: 100, serviceTeam: 0, carrierFilter: 0, vehicleFilter: 0,
        shipFromDate: Moment().subtract(1, "month").startOf('day').utc().toDate(),
        shipToDate: Moment().endOf('day').utc().toDate(),
        assetTypeFilter: 'All Loads',
        isDryRun: false, isArcMinorityOrder: false, isCrossDock: false, hasBalanceDue: false,
        page: 1, pageSize: 50,
        sortDescriptors: []
      } as AdvanceSearchParameters,
      dateRange: {
        start: Moment().subtract(1, "month").startOf('day').utc().toDate(),
        end: Moment().endOf('day').utc().toDate()
      } as SelectionRange,
      sortDesc: [...this.defaultSort]
    };
  }

  sortChange = (event: GridSortChangeEvent) => { //only support single sort currently      
    if (event.sort.length === 0)
      event.sort = [...this.defaultSort];

    this.setState({ advanceSearch: { ...this.state.advanceSearch, sortDescriptors: event.sort } }, () => { this.refreshData() });

  }

  private filterClear() {
    this.setState({ ...this.resetFilterSortState() });
  }

  private refreshData() {
    document.getElementsByClassName("k-grid-content")[0].scrollTop = 0;
    this.fetch(false);
  }

  onChange = (e: SelectionRange) => {
    if (Moment(e.start).year() >= Moment(this.minDate).year() && Moment(e.end).diff(Moment(e.start), 'years') >= 1) {
      e.end = Moment(e.start).add(1, "years").toDate();
    }

    this.setState({
      dateRange: e, advanceSearch: {
        ...this.state.advanceSearch,
        shipFromDate: Moment(e.start).startOf('day').utc().toDate(),
        shipToDate: Moment(e.end).endOf('day').utc().toDate()
      }
    });
  }

  private scrollHandler(event: GridEvent) {
    const e = event.nativeEvent;
    if (e.target.scrollTop >= (e.target.scrollHeight - e.target.clientHeight) - 1) {
      if (!this.state.moreRecordsExist)  //When auto-scrolling, ignore when we've hit max records
        return;

      this.fetch(true);
    }
  }

  public render() {
    return (<>
      <Title string="Invoice Listing" />
      <h4 className="text-left mt-3">Invoice Listing</h4>
      {this.state.isLoading && <LoadingPanel />}
      <Grid
        style={{ maxHeight: window.innerHeight * .75 }}
        className="minGridColumnWidth"
        data={this.state.data}
        resizable={true}
        //onScroll={this.scrollHandler}
        sort={this.state.advanceSearch.sortDescriptors}
        onSortChange={this.sortChange}
        sortable={true}
      >
        <GridToolbar>
          <Input placeholder="Order No." type="number"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (this.state.advanceSearch.orderNumberFilter > 0) {
                  e.preventDefault();
                  this.refreshData();
                }
              }
            }}
            value={this.state.advanceSearch.orderNumberFilter > 0 ? this.state.advanceSearch.orderNumberFilter : ""}
            onChange={(value) => {
              this.setState({ advanceSearch: { ...this.state.advanceSearch, orderNumberFilter: Number(value.value) } });
            }} />

          <Input placeholder="Ref No."
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (this.state.advanceSearch.referenceNumberFilter.length >= 3) {
                  e.preventDefault();
                  this.refreshData();
                }
              }
            }}
            value={this.state.advanceSearch.referenceNumberFilter} onChange={(value) => {
              this.setState({ advanceSearch: { ...this.state.advanceSearch, referenceNumberFilter: value.value } });
            }} />

          <DropDownList
            //style={{ width: "300px" }}
            data={['All Loads', 'Asset Loads', 'Carrier Loads']}
            value={['All Loads', 'Asset Loads', 'Carrier Loads'].find(x => x === this.state.advanceSearch.assetTypeFilter)}
            onChange={(e) => this.setState({ advanceSearch: { ...this.state.advanceSearch, assetTypeFilter: e.value } })}
          // defaultValue="t"
          />
          <DropDownList
            style={{ width: 150 }}
            defaultValue={this.serviceTeams[0]}
            value={this.serviceTeams.find(x => x.ID === this.state.advanceSearch.serviceTeam)}
            data={this.serviceTeams}
            textField="Name"
            dataItemKey="ID"
            onChange={(e) => this.setState({ advanceSearch: { ...this.state.advanceSearch, serviceTeam: e.value.ID } })}
          />
          <DropDownList
            style={{ width: 250 }}
            defaultValue={0}
            value={this.invoiceStatuses.find(x => x.value === this.state.advanceSearch.invoiceStatus)}
            data={this.invoiceStatuses}
            textField="text"
            onChange={(e) => this.setInvoiceStatus(e)}
          />
          <Checkbox
            className='align-self-center'
            value={this.state.advanceSearch.isDryRun}
            onChange={(e) => this.setState({
              advanceSearch: {
                ...this.state.advanceSearch,
                isDryRun: e.value,
                isCrossDock: false
              }
            })}
            label={"Is Dry Run"}
          />
          <Checkbox
            className='align-self-center'
            value={this.state.advanceSearch.isCrossDock}
            onChange={(e) => this.setState({
              advanceSearch: {
                ...this.state.advanceSearch,
                isCrossDock: e.value,
                isDryRun: false
              }
            })}
            label={"Is Cross Dock"}
          />
          <Checkbox
            className='align-self-center'
            value={this.state.advanceSearch.hasBalanceDue}
            onChange={(e) => this.setState({ advanceSearch: { ...this.state.advanceSearch, hasBalanceDue: e.value } })}
            label={"Has Balance Due"}
          />
          <Checkbox
            className='align-self-center'
            value={this.state.advanceSearch.isArcMinorityOrder}
            onChange={(e) => this.setState({ advanceSearch: { ...this.state.advanceSearch, isArcMinorityOrder: e.value } })}
            label={"Is Arc Minority"}
          />
          <Button
            title="Reset Filters"
            icon="filter-clear"
            svgIcon={filterClearIcon}
            onClick={this.filterClear}
          />
          <Button
            themeColor="primary"
            //disabled={
            //   (this.state.advanceSearch.orderNumberFilter.length > 0 && this.state.advanceSearch.orderNumberFilter.length < 3) &&
            //   (this.state.advanceSearch.customerFilter.length > 0 && this.state.advanceSearch.customerFilter.length < 3)
            // }
            className="ml-2"
            title="Search"
            onClick={this.refreshData}
          >Search</Button>
        </GridToolbar>
        <GridToolbar className="kendo-grid-toolbar-overflow-visible">
          <div>
            <CustomerInput
              prependGroup
              placeholder='Company'
              CustomerID={this.state.advanceSearch?.customerFilter}
              CustomerNumber={this.state.advanceSearch?.customerNumberFilter}
              onChange={(e) => {
                this.setState({ advanceSearch: { ...this.state.advanceSearch, customerFilter: e?.CustomerID, customerNumberFilter: e?.CustomerNumber } });
              }}
            />
          </div>

          <CarrierAutoComplete
            placeholder="Carrier"
            selectedCarrierID={this.state.advanceSearch?.carrierFilter}
            style={{ width: '175px' }}
            onSelectedCarrier={(e) => {
              if (e?.CarrierID > 0) {

              } else {

              }
              this.setState({ advanceSearch: { ...this.state.advanceSearch, carrierFilter: e?.CarrierID } });
            }}
          />

          <VehicleAutoComplete
            placeholder='Vehicle'
            style={{ width: '175px' }}
            //style={{ flex: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}

            selectedVehicleID={this.state.advanceSearch?.vehicleFilter}
            //setFocus={true}
            onSelectedVehicle={(e) => this.setState({ advanceSearch: { ...this.state.advanceSearch, vehicleFilter: e?.VehicleID } })}
          />

          <span>
            <label className="mt-1">Pick-Up Date Range:</label>
            <SimpleDateRangePicker
              min={this.minDate}
              max={this.maxDate}
              value={this.state.dateRange}
              onChange={(e) => {
                this.onChange(e);
              }}
            />
          </span>
        </GridToolbar>
        <Column field="InvoiceNumber" headerCell={InvoiceNumberHeaderCell} cell={InvoiceNumberCell} />
        <Column field="Shipper" title="Shipper" cell={StopCell} />
        <Column field="Consignee" title="Consignee" cell={StopCell} />
        <Column field="BillTo" title="Customer" cell={CustomerCell} />
        <Column field="TotalCharges" title="Total Charges" headerCell={TotalChargesHeaderCell} cell={TotalChargesCell} sortable={false} />
        {/*<Column field="InvoiceNumber" title="Info" headerCell={PaperworkInHeaderCell} cell={PaperworkInCell} sortable={false} />*/}
      </Grid>
      {
        <div className="k-pager k-pager-md k-grid-pagert">
          <div style={{ marginLeft: "auto", marginRight: 0 }}>
            <i>Showing {this.state.data.length} Record(s)</i>
          </div>
        </div>
      }
    </>);
  }

  setInvoiceStatus(e: DropDownListChangeEvent): void {
    this.setState({ advanceSearch: { ...this.state.advanceSearch, invoiceStatus: e.value.value } });
  }

  setFilterSessionStorage(): void {
    sessionStorage.setItem(this.SESSION_STORAGE_NAME, JSON.stringify({ advanceSearch: { ...this.state.advanceSearch }, dateRange: { ...this.state.dateRange }, sortDesc: [...this.state.sortDesc] }));
  }

  public componentDidMount() {
    this.fetch(false);

    window.addEventListener('message', this.handleMessage);
  }

  public componentWillUnmount() {
    window.removeEventListener('message', this.handleMessage);
  }

  handleMessage = (e: MessageEvent) => {
    if (e.type === "message" && e.data?.type === "refresh") {
      this.refreshData();
    }
  }

  private fetch(append: boolean) {
    if (Moment(this.state.advanceSearch.shipFromDate).isValid() === false || Moment(this.state.advanceSearch.shipToDate).isValid() === false)
      return;

    this.setState({ isLoading: true })

    const _page = (append) ? this.state.advanceSearch.page + 1 : 1;
    const data = { ...this.state.advanceSearch, page: _page } as AdvanceSearchParameters;

    //fetchApi(`/api/billing/invoicelisting?${queryStr}`, data, 'POST')
    fetchApi(`/api/billing/invoicelisting`, data, 'POST')
      .then(({ Data, MoreRecordsExist }) => {
        if (!append)
          this.setState({
            isLoading: false, data: Data, moreRecordsExist: MoreRecordsExist,
            advanceSearch: { ...this.state.advanceSearch, page: _page }
          }, () => this.setFilterSessionStorage());
        else {
          this.setState({
            isLoading: false,
            data: this.state.data.concat(Data),
            moreRecordsExist: MoreRecordsExist,
            advanceSearch: { ...this.state.advanceSearch, page: _page }
          });
        }
      })
  }
}
