import * as React from 'react';
import { Title } from '../../utils/title';
import { Grid, GridColumn as Column, GridToolbar, GridSortChangeEvent, GridItemChangeEvent } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { fetchApi } from '../../services/api';
import MyCommandCell from './MyCommandCell';
import { Button } from '@progress/kendo-react-buttons';
import { JsonResponse } from 'TypeGen/json-response';
import CenterDivPanel from '../../components/CenterDivPanel';
import { Loader } from '@progress/kendo-react-indicators';
import { arrowRotateCwIcon, filterClearIcon, plusIcon } from '@progress/kendo-svg-icons';

type Props = {};

type State = {
    data: EmailAlias[],
    originalData: EmailAlias[],
    dataSort: SortDescriptor[];
    editID: null | number,
    loading: boolean;
}

export type EmailAlias = {
    CustomerID: number;
    CustomerNumber: string;
    EmailAlias: string;
    AutoOffer: boolean;
    ShowRate: boolean;

    inEdit: boolean;
}

export default class EmailAliases extends React.Component<Props, State> {

    private export: React.RefObject<ExcelExport>;
    private CommandCell: typeof React.Component;

    constructor(props: Props) {
        super(props);

        this.state = {
            data: [],
            originalData: [],
            dataSort: [{ field: "CustomerNumber", dir: "asc" }],
            editID: null,
            loading: false,
        };

        this.export = React.createRef();

        this.reset = this.reset.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.onSortChange = this.onSortChange.bind(this);

        this.enterInsert = this.enterInsert.bind(this);
        this.itemChange = this.itemChange.bind(this);

        const enterEdit = this.enterEdit.bind(this);
        const save = this.save.bind(this);
        const cancel = this.cancel.bind(this);
        const remove = this.remove.bind(this);
        this.CommandCell = MyCommandCell(enterEdit, remove, save, cancel, "inEdit");
    }

    public componentDidMount() {
        this.fetchData();
    }

    public render() {
        return (
            <div style={{ position: "relative" }}>
                <Title string="Email Aliases" />
                {this.state.loading && <CenterDivPanel>
                    <Loader type="converging-spinner" />
                </CenterDivPanel>}
                <ExcelExport ref={this.export}>
                    <Grid sortable={{ allowUnsort: true }}
                        scrollable={'none'}
                        data={this.state.data.map((item) =>
                            Object.assign({
                                inEdit: item.CustomerID === this.state.editID
                            }, item)
                        )}
                        sort={this.state.dataSort}
                        onSortChange={this.onSortChange}
                        editField="inEdit"
                        onItemChange={this.itemChange}
                    >
                        <GridToolbar>
                            <Button
                                title="Reset Sort"
                                icon="filter-clear"
                                svgIcon={filterClearIcon}
                                onClick={this.reset}
                            />
                            <Button
                                title="Refresh"
                                icon="refresh"
                                svgIcon={arrowRotateCwIcon}
                                onClick={this.fetchData}
                            />
                            <Button
                                onClick={() => this.export.current.save(this.state.data)}
                            >Excel
                            </Button>
                            <Button
                                themeColor="primary"
                                icon="plus"
                                svgIcon={plusIcon}
                                onClick={this.enterInsert}
                            >
                                Add Email Alias
                            </Button>
                        </GridToolbar>
                        <Column field="CustomerNumber" title="Auth Co" editor="text" />
                        <Column field="EmailAlias" title="Email Alias" editor="text" />
                        <Column field="AutoOffer" title="Auto Offer" editor="boolean" />
                        <Column field="ShowRate" title="Show Rate" editor="boolean" />
                        <Column cell={this.CommandCell} width="180px" />
                    </Grid>
                </ExcelExport>
            </div>
        );
    }

    private enterInsert() {
        // Only allow adding one at a time
        const index = this.state.data.findIndex(p => p.CustomerID === 0);
        if (index === -1) {
            const dataItem = { CustomerID: 0, CustomerNumber: '', EmailAlias: '', AutoOffer: false, ShowRate: false, inEdit: true };
            const newnotes = this.state.data.slice();
            newnotes.unshift(dataItem);
            this.update(newnotes, dataItem);
            this.setState({
                data: newnotes
            });
        }
    }

    private enterEdit(dataItem: EmailAlias) {
        this.update(this.state.data, dataItem).inEdit = true;
        this.setState({
            data: this.state.data.slice()
        });
    }

    private save(dataItem: EmailAlias) {

        if (!dataItem.EmailAlias) {
            alert("Please enter an email alias!");
            return;
        }

        this.setState({ loading: true });

        const data = {
            CustomerNumber: dataItem.CustomerNumber,
            EmailAlias: dataItem.EmailAlias,
            AutoOffer: dataItem.AutoOffer,
            ShowRate: dataItem.ShowRate,
        }
        fetchApi('/api/Customer/CreateEmailAlias', data, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    dataItem.inEdit = undefined;
                    this.fetchData();
                } else {
                    this.setState({ loading: false });
                    alert(response.ErrorMessage);
                }
            })
            .catch(() => {
                alert("Unable to create/update agent note");
                this.setState({ loading: false });
            });
    }

    private cancel(dataItem: EmailAlias) {
        if (dataItem.CustomerID) {
            let originalItem = this.state.originalData.find((p) => p.CustomerID === dataItem.CustomerID);
            originalItem.inEdit = undefined;
            this.update(this.state.data, originalItem);
        } else {
            this.update(this.state.data, dataItem, !dataItem.CustomerID);
        }
        this.setState({
            data: this.state.data.slice()
        });
    }

    private remove(dataItem: EmailAlias) {
        if (dataItem.CustomerID) {
            const data = {
                CustomerID: dataItem.CustomerID
            }
            fetchApi('/api/Customer/DeleteEmailAlias', data, 'DELETE')
                .then(() => {

                    // Remove from Grid
                    dataItem.inEdit = undefined;
                    this.update(this.state.data, dataItem, true);
                    this.update(this.state.originalData, dataItem, true);
                    this.setState({
                        data: this.state.data.slice()
                    });
                });
        } else {

            // Remove from Grid
            dataItem.inEdit = undefined;
            this.update(this.state.data, dataItem, true);
            this.update(this.state.originalData, dataItem, true);
            this.setState({
                data: this.state.data.slice()
            });
        }
    }

    private itemChange(event: GridItemChangeEvent) {
        const value = event.value;
        const name = event.field;
        if (!name) {
            return;
        }
        const updatedData = this.state.data.slice();
        const item = this.update(updatedData, event.dataItem);
        (item as any)[name] = value;
        this.setState({
            data: updatedData
        });
    }

    private update(data: EmailAlias[], item: EmailAlias, remove: boolean = false): EmailAlias {
        let updated;
        let index = data.findIndex(p => p === item || item.CustomerID && p.CustomerID === item.CustomerID);
        if (index === -1) {
            index = data.findIndex(p => p.CustomerID === 0);
        }
        if (index >= 0) {
            updated = Object.assign({}, item);
            data[index] = updated;
        }

        if (remove) {
            data = data.splice(index, 1);
        }

        return data[index];
    }

    private reset() {
        this.setState({ dataSort: [{ field: "CustomerNumber", dir: "asc" }] });
    }

    private onSortChange(changeEvent: GridSortChangeEvent) {
        this.setState({ data: orderBy(this.state.data, this.state.dataSort), dataSort: changeEvent.sort });
    }

    private fetchData() {
        this.setState({ loading: true });

        fetchApi('/api/Customer/GetEmailAliases')
            .then((data: EmailAlias[]) => {
                data = orderBy(data, this.state.dataSort);
                this.setState({ data, originalData: data });
            }).finally(() => {
                this.setState({ loading: false });
            })
    }
}
