import * as React from 'react';
import LoadingPanel from '../../components/LoadingPanel';
import { filterBy, orderBy, SortDescriptor, FilterDescriptor, CompositeFilterDescriptor, FilterOperator } from '@progress/kendo-data-query';
import assetActionCell from './AssetActionPopup';
import {
  Grid,
  GridColumn as Column,
  GridSortChangeEvent,
  GridToolbar,
  GridPageChangeEvent,
  GridSelectionChangeEvent,
  GridPagerSettings,
  GridColumnProps,
  GridColumnMenuFilter,
  GridCellProps
} from '@progress/kendo-react-grid';
import VehicleDriverNumber from './Vehicle/DriverNumber';
import DriverNumber from './Driver/DriverNumber';
import DriverVehicleNumber from './Driver/VehicleNumber';
import VehicleNumber from './Vehicle/VehicleNumber';
import TrailerNumber from './TrailerNumber';
import ServiceStatus from './ServiceStatus';
import { MakeRoboCall } from './MakeRoboCall';
import { DriverRequestSignature } from './Driver/DriverRequestSignature';
import { VehicleRequestSignature } from './Vehicle/VehicleRequestSignature';
import { HealthFormsDialog } from './HealthForms';
import CarrierGrid, { SelectedCarrierValue } from './CarrierGrid';
import Documents, { DocumentEntityType } from '../Documents/Documents';
import CenterDivPanel from '../../components/CenterDivPanel';
import { Link } from 'react-router-dom';
import { ILink } from '../../types/link';
import TrailerGrid from './TrailerGrid';
import DriverName from './DriverName';
import DriverContactInfo from './DriverContactInfo';
import { Input } from '@progress/kendo-react-inputs';
import { debounce } from 'ts-debounce';
import DriverCardMaintenance from './Driver/DriverCardMaintenance';
import driverCardNumberCell from './DriverCardNumber';
import DriverAccidentReportDialog from './Driver/DriverAccidentReportDialog';
import ColumnMenu from '../LoadBoard/BidHistory/ColumnMenu';
import IssueMoneyCodeDialog from './Driver/IssueMoneyCodeDialog';
import { fetchApi } from '../../services/api';
import OwnerGrid from './OwnerGrid';
import CopyDriverDialog from './Driver/CopyDriverDialog';
import { openWindow } from '../../services/openWindow';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import AddVehicleDialog from '../AssetVehicles/AddVehicleDialog';
import { AssetCarrierViewModel } from 'TypeGen/Assets/Carriers/List/asset-carrier-view-model';
import SetVehiclePayRateMaster from '../Settlements/SetVehiclePayRateMaster';
import { JsonResponse } from 'TypeGen/json-response';
import LastAvailablePopup from 'views/AssetVehicles/LastAvailablePopup';
import PositionCell from './Vehicle/PositionCell';
import PayloadCell from './Vehicle/PayloadCell';
import AvailableCell from './AvailableCell';
import { AssetVehicleViewModel } from 'TypeGen/Assets/Vehicles/List/asset-vehicle-view-model';
import vehicleReserveCell from 'views/Quote/Vehicles/ReserveCell';
import ReservePopup from 'views/AssetVehicles/ReservePopup';
import SetDriverPayRateMaster from '../Settlements/SetDriverPayRateMaster';
import { ExcelExport, ExcelExportColumn as ExportColumn } from '@progress/kendo-react-excel-export';
import HideFromDispatchDialog from 'views/AssetVehicles/HideFromDispatchDialog';
import { arrowRotateCwIcon, downloadIcon, filterClearIcon, plusIcon } from '@progress/kendo-svg-icons';

export enum MenuOption {
  RoboCall,
  HealthForms,
  DriverDocuments,
  VehicleDocuments,
  DriverRequestSignature,
  VehicleRequestSignature,
  CardMaintenance,
  AccidentReport,
  MoneyCode,
  HideFromDispatch,
  ShowToDispatch,
  VehiclePayRateMaster,
  VehicleLastAvailable,
  DriverPayRateMaster
}

enum MissingOrField {
  PW = 1
}

export enum AssetStatus {
  Active = 0,
  Retired = 1,
  Any = 2
}

type State = {
  skip: number;
  take: number;
  pageSize: number;
  tabIndex: number;
  pageable: boolean | GridPagerSettings;
  loading: boolean;
  driverData: DriverValue[] | null;
  driverSort: SortDescriptor[];
  driverFilter: CompositeFilterDescriptor;
  driverButtonGroupCompositeFilter: CompositeFilterDescriptor;
  driverGridFilterDescriptorFilter: CompositeFilterDescriptor;
  vehicleData: AssetVehicleViewModel[] | null;
  vehicleSort: SortDescriptor[];
  vehicleFilter: CompositeFilterDescriptor;
  vehicleButtonGroupCompositeFilter: CompositeFilterDescriptor;
  vehicleGridFilterDescriptorFilter: CompositeFilterDescriptor;
  inputCompositeFilter: CompositeFilterDescriptor;
  selectedDrivers: DriverValue[];
  selectedVehicles: AssetVehicleViewModel[];
  selectedCarriers: AssetCarrierViewModel[];
  selectedRowValues: SelectedRowValues | null;
  selectedCarrier: SelectedCarrierValue | null;
  viewingActiveRecords: AssetStatus;
  missingPaperwork: boolean;
  dimensions: Dimensions;
  showRoboCallDialog: boolean;
  showDriverDocumentDialog: boolean;
  showCopyDriverDialog: boolean;
  showVehicleDocumentDialog: boolean;
  showDriverRequestSignature: boolean;
  showVehicleRequestSignature: boolean;
  showHealthFormsDialog: boolean;
  showCardMaintenanceDialog: boolean;
  showMoneyCodeDialog: boolean;
  showAccidentReportDialog: boolean;
  searchInput: string;
  showAddVehicleDialog: boolean;
  showPayRateMasterDialog: boolean;
  showLastAvailableDialog: boolean;
  showHideFromDispatchDialog: boolean;
  reserveVehicleId: number;
}

type Props = {
  tabIndex: number;
  searchTerm: string;
};

export type Dimensions = {
  Width: number;
  Height: number;
}

export type SelectedRowValues = {
  VehicleID: number;
  DriverID: number;
  OwnerID: number;
  DriverNumber: string;
  DriverName: string;
  VehicleNumber: string;
  OwnerName: string;
  IsDriverRow: boolean;
  IsVehicleRow: boolean;
  IsCarrierRow: boolean;
}

export type DriverValue = {
  VehicleID: number;
  DriverID: number;
  DriverNumber: string;
  DriverFullName: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Mobile: string;
  VehicleNumber: string;
  VehicleType: string;
  PayLoad: number;
  BoxLength: number;
  BoxWidth: number;
  BoxHeight: number;
  Selected: boolean;
  Active: boolean;
  OwnerID: number;
  OwnerNumber: string;
  OwnerName: string;
  HazMatExpiring: boolean;
  PhysicalExpiring: boolean;
  LicenseExpiring: boolean;
  FastCSAExpiring: boolean;
  MissingPW: boolean;
  CardNumber: string;
  CardID: number;
  DOB: string;
  SecurityNumber: string;
  DriversLicenseState: string;
  DriversLicense: string;
  FullName: string
  Hash: string;

  Links: ILink[];
}

export enum AssetHardwareType {
  Netradyne = 0
}

export enum AssetEntityType {
  Vehicle = 0,
  Trailer = 1,
  Driver = 2,
  Owner = 3
}

const centerDiv = {
  margin: "auto",
  width: "50%",
  textAlign: "center",
  opacity: 0.4,
  fontSize: "14px",
  fontStyle: "italic"
} as React.CSSProperties;

export class VehicleTypeCell extends React.Component<GridCellProps> {
  render() {
    if (!this.props.field) return null;
    return (
      <td>
        <a
          href="#"
          style={{ color: '#007bff' }}
          onClick={(e) => {
            openWindow(this.props.dataItem.Links.find((x: ILink) => x.Name === 'WorkloadHistory').Link);
            e.preventDefault();
          }}
        >{this.props.dataItem.VehicleType}
        </a>
        <div style={{ fontSize: '11px' }}>{this.props.dataItem.RevenueClass}</div>
      </td>
    );
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return nextProps.dataItem.Hash !== this.props.dataItem.Hash;
  }
}

export default class AssetPanel extends React.Component<Props, State> {
  private _exportTemplate = React.createRef<ExcelExport | null>();

  static _FilterDescriptor_Input = 0;
  static _DefaultFilter = {
    logic: 'and', filters: []
  } as CompositeFilterDescriptor;
  static _DefaultButtonGroupCompositeFilter = { logic: 'or', filters: [] as FilterDescriptor[] } as CompositeFilterDescriptor;
  static _DefaultDriverSort = [{ field: "DriverNumber", dir: "asc" }] as SortDescriptor[];
  static _DefaultVehicleSort = [{ field: "VehicleNumber", dir: "asc" }] as SortDescriptor[];

  _DriverSort = sessionStorage.getItem('AssetDrivers-sort');
  _DriverFilter = sessionStorage.getItem('AssetDrivers-filter');
  _DriverButtonGroupCompositeFilter = sessionStorage.getItem('AssetDrivers-buttonGroupCompositeFilter');
  _DriverGridFilterDescriptorFilter = sessionStorage.getItem('AssetDrivers-gridFilterDescriptorFilter');

  _VehicleSort = sessionStorage.getItem('AssetVehicles-sort');
  _VehicleFilter = sessionStorage.getItem('AssetVehicles-filter');
  _VehicleButtonGroupCompositeFilter = sessionStorage.getItem('AssetVehicles-buttonGroupCompositeFilter');
  _VehicleGridFilterDescriptorFilter = sessionStorage.getItem('AssetVehicles-gridFilterDescriptorFilter');

  _SearchInput = sessionStorage.getItem(this.props.tabIndex === 1 ? 'AssetDrivers-inputFilter' : 'AssetVehicles-inputFilter');

  constructor(props: Props) {
    super(props);

    this.state = {
      tabIndex: 0,
      loading: false,
      driverData: null,
      driverSort: this._DriverSort ? JSON.parse(this._DriverSort) : AssetPanel._DefaultDriverSort,
      driverFilter: this._DriverFilter ? JSON.parse(this._DriverFilter) : AssetPanel._DefaultFilter,
      driverButtonGroupCompositeFilter: this._DriverButtonGroupCompositeFilter ? JSON.parse(this._DriverButtonGroupCompositeFilter) : AssetPanel._DefaultButtonGroupCompositeFilter,
      driverGridFilterDescriptorFilter: this._DriverGridFilterDescriptorFilter ? JSON.parse(this._DriverGridFilterDescriptorFilter) : AssetPanel._DefaultFilter,
      vehicleData: null,
      vehicleSort: this._VehicleSort ? JSON.parse(this._VehicleSort) : AssetPanel._DefaultVehicleSort,
      vehicleFilter: this._VehicleFilter ? JSON.parse(this._VehicleFilter) : AssetPanel._DefaultFilter,
      vehicleButtonGroupCompositeFilter: this._VehicleButtonGroupCompositeFilter ? JSON.parse(this._VehicleButtonGroupCompositeFilter) : AssetPanel._DefaultButtonGroupCompositeFilter,
      vehicleGridFilterDescriptorFilter: this._VehicleGridFilterDescriptorFilter ? JSON.parse(this._VehicleGridFilterDescriptorFilter) : AssetPanel._DefaultFilter,
      inputCompositeFilter: { logic: 'or', filters: [] as FilterDescriptor[] } as CompositeFilterDescriptor,
      skip: 0,
      take: 50,
      pageSize: 50,
      pageable: this.state ? this.state.pageable : {
        buttonCount: 10,
        info: true,
        type: 'numeric',
        pageSizes: [50, 100, 150],
        previousNext: true
      },
      selectedDrivers: [],
      selectedVehicles: [],
      selectedCarriers: [],
      selectedCarrier: null,
      selectedRowValues: null,
      viewingActiveRecords: AssetStatus.Active,
      missingPaperwork: false,
      dimensions: null,
      showRoboCallDialog: false,
      showDriverDocumentDialog: false,
      showCopyDriverDialog: false,
      showVehicleDocumentDialog: false,
      showDriverRequestSignature: false,
      showVehicleRequestSignature: false,
      showHealthFormsDialog: false,
      showCardMaintenanceDialog: false,
      showMoneyCodeDialog: false,
      showAccidentReportDialog: false,
      searchInput: this._SearchInput ? this._SearchInput : '',
      showAddVehicleDialog: false,
      showPayRateMasterDialog: false,
      showLastAvailableDialog: false,
      showHideFromDispatchDialog: false,
      reserveVehicleId: 0
    }
    this.driverSortChange = this.driverSortChange.bind(this);
    this.vehicleSortChange = this.vehicleSortChange.bind(this);
    this.loadAssetDrivers = this.loadAssetDrivers.bind(this);
    this.loadAssetVehicles = this.loadAssetVehicles.bind(this);

    this.setTab = this.setTab.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.loadSupportedVehiclesDialogScreen = this.loadSupportedVehiclesDialogScreen.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.checkQueryString = this.checkQueryString.bind(this);
  }

  private columnProps(field: string): Partial<GridColumnProps> {
    return {
      field: field,
      columnMenu: ColumnMenu,
      headerClassName: this.isColumnActive(
        field,
        this.state.tabIndex === 1 ? this.state.driverGridFilterDescriptorFilter : this.state.vehicleGridFilterDescriptorFilter
      ) ? 'active' : ''
    };
  }

  private isColumnActive(field: string, dataState: CompositeFilterDescriptor) {
    return GridColumnMenuFilter.active(field, dataState);
  }

  public render() {
    return (
      <div className="card">
        {(this.state.loading) && <LoadingPanel />}
        <div className="card-header">
          <ul className="nav nav-tabs card-header-tabs">
            <li className="nav-item">
              <Link className={`nav-link ${this.state.tabIndex === 1 && 'active'}`} to="/Assets/Drivers">Drivers</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${this.state.tabIndex === 2 && 'active'}`} to="/Assets/Vehicles">Vehicles</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${this.state.tabIndex === 3 && 'active'}`} to="/Assets/Trailers">Trailers</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${this.state.tabIndex === 4 && 'active'}`} to="/Assets/Owners">Owners</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${this.state.tabIndex === 5 && 'active'}`} to="/Assets/Carriers">Carriers</Link>
            </li>
          </ul>
        </div>
        <div>
          {this.state.tabIndex === 1 && this.loadAssetDrivers(this.state.driverData)}
          {this.state.tabIndex === 2 && this.loadAssetVehicles(this.state.vehicleData)}
          {this.state.tabIndex === 3 && <TrailerGrid />}
          {this.state.tabIndex === 4 && <OwnerGrid />}
          {this.state.tabIndex === 5 && <CarrierGrid />}
        </div>
        {this.state.showRoboCallDialog &&
          <CenterDivPanel>
            <MakeRoboCall
              CloseDialog={() => { this.setState({ showRoboCallDialog: false, selectedRowValues: null }) }}
              SelectedDrivers={this.state.selectedDrivers}
              SelectedVehicles={this.state.selectedVehicles}
              SelectedRowValues={this.state.selectedRowValues}
            />
          </CenterDivPanel>}
        {this.state.showDriverRequestSignature &&
          <DriverRequestSignature
            CloseDialog={() => { this.setState({ showDriverRequestSignature: false, selectedRowValues: null }) }}
            SelectedDrivers={this.state.selectedDrivers}
            SelectedRowValues={this.state.selectedRowValues}
          />}
        {this.state.showVehicleRequestSignature &&
          <VehicleRequestSignature
            CloseDialog={() => { this.setState({ showVehicleRequestSignature: false, selectedRowValues: null }) }}
            SelectedVehicles={this.state.selectedVehicles}
            SelectedRowValues={this.state.selectedRowValues}
          />}
        {this.state.showDriverDocumentDialog &&
          <CenterDivPanel>
            <Documents
              IsWindow
              EntityType={DocumentEntityType.Driver}
              EntityId={this.state.selectedRowValues.DriverID}
              EntityNumber={this.state.selectedRowValues.DriverNumber}
              CloseDocumentModal={() => { this.setState({ showDriverDocumentDialog: false }); }}
            />
          </CenterDivPanel>
        }
        {this.state.showCopyDriverDialog &&
          <CopyDriverDialog
            CloseDialog={() => { this.setState({ showCopyDriverDialog: false }); }}
          />
        }
        {this.state.showVehicleDocumentDialog &&
          <CenterDivPanel>
            <Documents
              IsWindow
              EntityType={DocumentEntityType.Vehicle}
              EntityId={this.state.selectedRowValues.VehicleID}
              EntityNumber={this.state.selectedRowValues.VehicleNumber}
              CloseDocumentModal={() => { this.setState({ showVehicleDocumentDialog: false }); }}
            />
          </CenterDivPanel>
        }
        {this.state.showCardMaintenanceDialog &&
          <DriverCardMaintenance
            RefreshParent={() => { this.getAssetDrivers(this.state.viewingActiveRecords, this.state.missingPaperwork); }}
            SelectedDrivers={this.state.selectedDrivers}
            SelectedRowValues={this.state.selectedRowValues}
            CloseDialog={() => { this.setState({ showCardMaintenanceDialog: false }); }}
          />
        }
        {this.state.showMoneyCodeDialog &&
          <IssueMoneyCodeDialog
            DriverID={this.state.selectedRowValues.DriverID}
            DriverName={this.state.selectedRowValues.DriverName}
            DriverNumber={this.state.selectedRowValues.DriverNumber}
            CloseDialog={() => { this.setState({ showMoneyCodeDialog: false }); }}
          />
        }
        {this.state.showAccidentReportDialog &&
          <DriverAccidentReportDialog
            DriverID={this.state.selectedRowValues.DriverID}
            CloseDialog={() => { this.setState({ showAccidentReportDialog: false }); }}
          />
        }
        {this.state.showHealthFormsDialog &&
          <HealthFormsDialog
            SelectedRowValues={this.state.selectedRowValues}
            CloseDialog={() => {
              this.setState({ showHealthFormsDialog: false, selectedRowValues: null });
            }}
          />}
        {this.state.showPayRateMasterDialog && this.state.selectedRowValues.IsVehicleRow &&
          <SetVehiclePayRateMaster
            VehicleID={this.state.selectedRowValues.VehicleID}
            CloseDialog={() => { this.setState({ showPayRateMasterDialog: false }); }}
          />}
        {this.state.showPayRateMasterDialog && this.state.selectedRowValues.IsDriverRow &&
          <SetDriverPayRateMaster
            DriverID={this.state.selectedRowValues.DriverID}
            CloseDialog={() => { this.setState({ showPayRateMasterDialog: false }); }}
          />}
        {this.state.showLastAvailableDialog &&
          <LastAvailablePopup
            vehicleId={this.state.selectedRowValues.VehicleID}
            close={() => { this.setState({ showLastAvailableDialog: false }); }}
          />}
        {this.state.showHideFromDispatchDialog &&
          <HideFromDispatchDialog
            vehicleIds={this.state.selectedVehicles.length > 0 ? this.state.selectedVehicles.map(x => x.VehicleID) : [this.state.selectedRowValues.VehicleID]}
            close={() => { this.setState({ showHideFromDispatchDialog: false }); }}
            refresh={() => { this.getAssetVehicles(this.state.viewingActiveRecords); }}
          />}
      </div>
    )
  }

  public handleResize(e: UIEvent) {
    this.setState({
      dimensions: {
        Width: window.innerWidth,
        Height: window.innerHeight,
      }
    });
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  public componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize(null);

    this.setTab(null, this.props.tabIndex);
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.tabIndex !== prevProps.tabIndex) {
      this.setTab(null, this.props.tabIndex);
    }
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (nextProps && nextProps.searchTerm && nextProps.searchTerm !== this.state.searchInput) {
      this.setState({ searchInput: nextProps.searchTerm }, () => {
        if (this.state.tabIndex === 1) {
          this.filterInputChangeDriver();
          setTimeout(this.checkQueryString, 100);
        } else if (this.state.tabIndex === 2) {
          this.filterInputChangeVehicle();
          setTimeout(this.checkQueryString, 100);
        }
      });
    }
  }

  private loadSupportedVehiclesDialogScreen(SelectedRowValues: SelectedRowValues, SelectedDrivers: DriverValue[], SelectedVehicles: AssetVehicleViewModel[], SelectedMenuOption: MenuOption) {
    switch (SelectedMenuOption) {
      case MenuOption.RoboCall:
        this.setState({
          showRoboCallDialog: true,
          selectedRowValues: SelectedRowValues,
          selectedDrivers: SelectedDrivers,
          selectedVehicles: SelectedVehicles
        });
        break;
      case MenuOption.HealthForms:
        this.setState({ showHealthFormsDialog: true, selectedRowValues: SelectedRowValues });
        break;
      case MenuOption.DriverDocuments:
        this.setState({ showDriverDocumentDialog: true, selectedRowValues: SelectedRowValues });
        break;
      case MenuOption.VehicleDocuments:
        this.setState({ showVehicleDocumentDialog: true, selectedRowValues: SelectedRowValues });
        break;
      case MenuOption.DriverRequestSignature:
        this.setState({
          showDriverRequestSignature: true,
          selectedRowValues: SelectedRowValues,
          selectedDrivers: SelectedDrivers
        });
        break;
      case MenuOption.VehicleRequestSignature:
        this.setState({
          showVehicleRequestSignature: true,
          selectedRowValues: SelectedRowValues,
          selectedVehicles: SelectedVehicles
        });
        break;
      case MenuOption.CardMaintenance:
        this.setState({
          showCardMaintenanceDialog: true,
          selectedRowValues: SelectedRowValues,
          selectedDrivers: SelectedDrivers
        });
        break
      case MenuOption.MoneyCode:
        this.setState({
          showMoneyCodeDialog: true,
          selectedRowValues: SelectedRowValues,
          selectedDrivers: SelectedDrivers
        });
        break;
      case MenuOption.AccidentReport:
        this.setState({
          showAccidentReportDialog: true,
          selectedRowValues: SelectedRowValues
        });
        break;
      case MenuOption.HideFromDispatch:
        this.setState({
          showHideFromDispatchDialog: true,
          selectedRowValues: SelectedRowValues,
          selectedVehicles: SelectedVehicles
        });
        break;
      case MenuOption.ShowToDispatch:
        const vehicleIds = SelectedVehicles.length > 0 ? SelectedVehicles.map(x => x.VehicleID) : [SelectedRowValues.VehicleID]
        if (window.confirm(`Are you sure show vehicle(s) to dispatch?`)) {
          fetchApi('/api/Asset/HideFromDispatch', { VehicleIDs: vehicleIds, HideFromDispatch: false }, 'POST')
            .then((response: JsonResponse) => {
              if (response.Success) {
                this.getAssetVehicles(this.state.viewingActiveRecords);
              } else {
                alert(response.ErrorMessage);
              }
            }).catch((e) => {
              // If not problem details
              if (!e?.status) alert('Unable to show vehicle(s) to dispatch!');
            });
        }
        break;
      case MenuOption.VehiclePayRateMaster:
      case MenuOption.DriverPayRateMaster:
        this.setState({
          showPayRateMasterDialog: true,
          selectedRowValues: SelectedRowValues
        });
        break;
      case MenuOption.VehicleLastAvailable:
        this.setState({
          showLastAvailableDialog: true,
          selectedRowValues: SelectedRowValues
        });
        break;
    }
  }

  private checkQueryString() {
    const urlParams = new URLSearchParams(window.location.search);
    const sendMessageParam = urlParams.get('sendMessage');
    if (sendMessageParam != null) {
      if (this.state.tabIndex === 1) {

        // Data not loaded...retry
        if (this.state.driverData === null) {
          setTimeout(this.checkQueryString, 200);
          return;
        }
        const data = filterBy(this.state.driverData, this.state.driverFilter);
        if (data.length === 1) {
          this.loadSupportedVehiclesDialogScreen({
            DriverID: data[0].DriverID,
            DriverNumber: data[0].DriverNumber,
            VehicleID: data[0].VehicleID,
            VehicleNumber: data[0].VehicleNumber,
            OwnerID: data[0].OwnerID,
            OwnerName: data[0].OwnerName,
            IsDriverRow: true,
            IsVehicleRow: false
          } as SelectedRowValues, [], [], MenuOption.RoboCall);
        }
      }

      if (this.state.tabIndex === 2) {

        // Data not loaded...retry
        if (this.state.vehicleData === null) {
          setTimeout(this.checkQueryString, 200);
          return;
        }
        const data = filterBy(this.state.vehicleData, this.state.vehicleFilter);
        if (data.length === 1) {
          this.loadSupportedVehiclesDialogScreen({
            DriverID: data[0].DriverID,
            DriverNumber: data[0].DriverNumber,
            VehicleID: data[0].VehicleID,
            VehicleNumber: data[0].VehicleNumber,
            OwnerID: data[0].OwnerID,
            OwnerName: data[0].OwnerName,
            IsDriverRow: false,
            IsVehicleRow: true
          } as SelectedRowValues, [], [], MenuOption.RoboCall);
        }
      }
    }
  }

  private setTab(e: any, tabIndex: number) {
    if (e) e.preventDefault();

    let sort: SortDescriptor[] = [];
    if (this.state.tabIndex != 1 && tabIndex == 1) {
      sort = [{ field: "DriverNumber", dir: "asc" }];
      this.getAssetDrivers(AssetStatus.Active, false);
    } else if (this.state.tabIndex != 2 && tabIndex == 2) {
      sort = [{ field: "VehicleNumber", dir: "asc" }];
      this.getAssetVehicles(AssetStatus.Active);
    } else if (this.state.tabIndex != 3 && tabIndex == 3) {
      sort = [{ field: "TrailerNumber", dir: "asc" }];
    } else if (this.state.tabIndex != 4 && tabIndex == 4) {
      sort = [{ field: "CompanyName", dir: "asc" }];
    } else if (this.state.tabIndex != 5 && tabIndex == 5) {
      sort = [{ field: "CarrierName", dir: "asc" }];
    }

    if (sort.length > 0) {
      this.setState({
        tabIndex: tabIndex,
        skip: 0,
        viewingActiveRecords: AssetStatus.Active,
        missingPaperwork: false
      });
    }
  }

  private getAssetDrivers(active: AssetStatus, missingPW: boolean) {
    this.setState({ loading: true });

    const data = {
      Active: active,
      MissingPaperwork: missingPW
    };

    fetchApi('/api/Asset/GetAssetDrivers', data, 'POST')
      .then((response: { AssetDrivers: DriverValue[] }) => {
        this.setState({ driverData: response.AssetDrivers, loading: false, viewingActiveRecords: active, missingPaperwork: missingPW });
        if (this.state.searchInput) this.filterInputChangeDriver();
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  private getAssetDriversWithParams(active: AssetStatus, field: MissingOrField, fieldValue: boolean) {

    let missingPW: boolean;

    switch (field) {
      case MissingOrField.PW:
        missingPW = fieldValue;
        break;
      default:
        missingPW = fieldValue == false ? false : !fieldValue;
        break;
    }

    this.getAssetDrivers(active, missingPW);

    this.setState({
      viewingActiveRecords: active,
      missingPaperwork: missingPW
    });
  }

  private driverSortChange(event: GridSortChangeEvent) {
    sessionStorage.setItem('AssetDrivers-sort', JSON.stringify(event.sort));
    this.setState({
      driverSort: event.sort
    });
  }

  private getAssetVehicles(active: AssetStatus) {
    this.setState({ loading: true });

    const data = {
      Active: active,
    };
    fetchApi('/api/Asset/GetAssetVehicles', data, 'POST')
      .then((response: { AssetVehicles: AssetVehicleViewModel[] }) => {
        this.setState({ vehicleData: response.AssetVehicles, loading: false, viewingActiveRecords: active });
        if (this.state.searchInput) this.filterInputChangeVehicle();
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  private vehicleSortChange(event: GridSortChangeEvent) {
    sessionStorage.setItem('AssetVehicles-sort', JSON.stringify(event.sort));
    this.setState({
      vehicleSort: event.sort
    });
  }

  private pageChange(event: GridPageChangeEvent) {
    this.setState({
      skip: event.page.skip,
      take: event.page.take,
      pageSize: event.page.take
    });
  }

  selectionChange = (event: GridSelectionChangeEvent) => {
    event.dataItem.Selected = !event.dataItem.Selected;
    //let index = this.find(this.props.driverData, event.dataItem);
    this.forceUpdate();
  }

  headerDriverSelectionChange = (event: any) => {
    const checked = event.syntheticEvent.target.checked;
    this.state.driverData.forEach(item => item.Selected = checked);
    this.forceUpdate();
  }

  headerVehicleSelectionChange = (event: any) => {
    const checked = event.syntheticEvent.target.checked;
    this.state.vehicleData.forEach(item => item.Selected = checked);
    this.forceUpdate();
  }

  hasFilterDriverDescriptor = (field: string): boolean => {
    return (this.state.driverFilter.filters as FilterDescriptor[]).filter(x => x.field == field).length > 0;
  }

  hasFilterVehicleDescriptor = (field: string): boolean => {
    return (this.state.vehicleFilter.filters as FilterDescriptor[]).filter(x => x.field == field).length > 0;
  }

  private hasFilter(field: string): boolean {
    if (this.state.tabIndex === 1) {
      return this.state.driverButtonGroupCompositeFilter.filters.length > 0 && (this.state.driverButtonGroupCompositeFilter.filters as FilterDescriptor[]).filter(x => x.field === field).length > 0;
    } else {
      return this.state.vehicleButtonGroupCompositeFilter.filters.length > 0 && (this.state.vehicleButtonGroupCompositeFilter.filters as FilterDescriptor[]).filter(x => x.field === field).length > 0;
    }
  }


  private hasFilterValue(field: string, value: any): boolean {
    if (this.state.tabIndex === 1) {
      return this.state.driverButtonGroupCompositeFilter.filters.length > 0 && (this.state.driverButtonGroupCompositeFilter.filters as FilterDescriptor[]).filter(x => x.field === field && x.value === value).length > 0;
    } else {
      return this.state.vehicleButtonGroupCompositeFilter.filters.length > 0 && (this.state.vehicleButtonGroupCompositeFilter.filters as FilterDescriptor[]).filter(x => x.field === field && x.value === value).length > 0;
    }
  }

  reloadMainCompositeGridFilter = () => {
    let compositeBuilder: CompositeFilterDescriptor[] = [];

    if (this.state.tabIndex === 1) {
      if (this.state.driverGridFilterDescriptorFilter && this.state.driverGridFilterDescriptorFilter.filters.length > 0)
        compositeBuilder.push(this.state.driverGridFilterDescriptorFilter);
      if (this.state.inputCompositeFilter && this.state.inputCompositeFilter.filters.length > 0)
        compositeBuilder.push(this.state.inputCompositeFilter);
      if (this.state.driverButtonGroupCompositeFilter && this.state.driverButtonGroupCompositeFilter.filters.length > 0)
        compositeBuilder.push(this.state.driverButtonGroupCompositeFilter);

      this.setState({
        driverFilter: { ...AssetPanel._DefaultFilter, filters: compositeBuilder },
        inputCompositeFilter: this.state.inputCompositeFilter,
        driverButtonGroupCompositeFilter: this.state.driverButtonGroupCompositeFilter
      });

      sessionStorage.setItem('AssetDrivers-filter', JSON.stringify({ ...AssetPanel._DefaultFilter, filters: compositeBuilder }));
      sessionStorage.setItem('AssetDrivers-buttonGroupCompositeFilter', JSON.stringify(this.state.driverButtonGroupCompositeFilter));
    } else if (this.state.tabIndex === 2) {
      if (this.state.vehicleGridFilterDescriptorFilter && this.state.vehicleGridFilterDescriptorFilter.filters.length > 0)
        compositeBuilder.push(this.state.vehicleGridFilterDescriptorFilter);
      if (this.state.inputCompositeFilter && this.state.inputCompositeFilter.filters.length > 0)
        compositeBuilder.push(this.state.inputCompositeFilter);
      if (this.state.vehicleButtonGroupCompositeFilter && this.state.vehicleButtonGroupCompositeFilter.filters.length > 0)
        compositeBuilder.push(this.state.vehicleButtonGroupCompositeFilter);

      this.setState({
        vehicleFilter: { ...AssetPanel._DefaultFilter, filters: compositeBuilder },
        inputCompositeFilter: this.state.inputCompositeFilter,
        vehicleButtonGroupCompositeFilter: this.state.vehicleButtonGroupCompositeFilter
      });
      sessionStorage.setItem('AssetVehicles-filter', JSON.stringify({ ...AssetPanel._DefaultFilter, filters: compositeBuilder }));
      sessionStorage.setItem('AssetVehicles-buttonGroupCompositeFilter', JSON.stringify(this.state.vehicleButtonGroupCompositeFilter));

    }
  }

  updateFilter = (field: string, value: any, operator: FilterOperator, isInputFilter: boolean) => {
    let cFD: CompositeFilterDescriptor;
    if (isInputFilter)
      cFD = this.state.inputCompositeFilter;
    else
      cFD = this.state.tabIndex === 1 ? this.state.driverButtonGroupCompositeFilter : this.state.vehicleButtonGroupCompositeFilter;

    if (value) {
      if (cFD.filters.length > 0) {
        var filterDescriptors = cFD.filters as FilterDescriptor[];
        if (filterDescriptors.filter(x => x.field === field).length > 0) {
          //update existing filterdescriptor in composite (cFD)
          filterDescriptors.filter(x => x.field === field)[0].value = value;
          this.reloadMainCompositeGridFilter();
        } else {
          //add filterdescriptor to existing array
          filterDescriptors.push({
            field: field,
            value: value,
            ignoreCase: true,
            operator: operator
          } as FilterDescriptor);
          this.reloadMainCompositeGridFilter();
        }
      } else {
        //add first filterdescriptor to empty filterdescriptor array
        cFD.filters.push({
          field: field,
          value: value,
          ignoreCase: true,
          operator: operator
        } as FilterDescriptor);
        this.reloadMainCompositeGridFilter();
      }
    } else {
      if (cFD.filters.length > 0) {
        if (isInputFilter) {
          cFD.filters = []; //empty it
          //if value is empty, remove the composite entirely                      
        } else {
          //remove field
          cFD.filters = (cFD.filters as FilterDescriptor[]).filter(x => x.field !== field);
        }

        this.reloadMainCompositeGridFilter();
      }
    }
  }

  updateVehiclerSort = (field: string, direction: "" | "asc" | "desc") => {
    if (direction) {
      this.setState(prev => ({
        vehicleSort: [{ field: field, dir: direction }, ...prev.vehicleSort]
      }), () => {
        sessionStorage.setItem('AssetVehicles-sort', JSON.stringify(this.state.vehicleSort));
      });
    } else {
      this.setState(prev => ({
        vehicleSort: prev.vehicleSort.filter(x => x.field !== field)
      }), () => {
        sessionStorage.setItem('AssetVehicles-sort', JSON.stringify(this.state.vehicleSort));
      });
    }
  }

  reserve = (vehicleID: number) => {
    const data = {
      VehicleID: vehicleID,
      Comment: 'From Listing'
    }

    fetchApi('/api/Quote/Reserve', data, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          this.getAssetVehicles(this.state.viewingActiveRecords);
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(() => alert("Unable to reserve vehicle!"));
  }

  reservePopup = (vehicleId: number) => {
    this.setState({ reserveVehicleId: vehicleId });
  }

  private filterInputChangeDriverDebounced = debounce(this.filterInputChangeDriver, 750);
  private filterInputChangeDriver(): Promise<boolean> {
    this.updateFilter("VehicleNumber", this.state.searchInput, FilterOperator.Contains, true);
    this.updateFilter("DriverNumber", this.state.searchInput, FilterOperator.Contains, true);
    this.updateFilter("OwnerNumber", this.state.searchInput, FilterOperator.Contains, true);
    this.updateFilter("FirstName", this.state.searchInput, FilterOperator.Contains, true);
    this.updateFilter("LastName", this.state.searchInput, FilterOperator.Contains, true);
    this.updateFilter("Email", this.state.searchInput, FilterOperator.Contains, true);
    this.updateFilter("Mobile", this.state.searchInput, FilterOperator.Contains, true);
    this.updateFilter("CardNumber", this.state.searchInput, FilterOperator.Contains, true);
    return Promise.resolve(false);
  }

  private filterInputChangeVehicleDebounced = debounce(this.filterInputChangeVehicle, 750);
  private filterInputChangeVehicle(): Promise<boolean> {
    this.updateFilter("VehicleNumber", this.state.searchInput, FilterOperator.Contains, true);
    this.updateFilter("OwnerNumber", this.state.searchInput, FilterOperator.Contains, true);
    this.updateFilter("DriverNumber", this.state.searchInput, FilterOperator.Contains, true);
    this.updateFilter("TrailerNumber", this.state.searchInput, FilterOperator.Contains, true);
    this.updateFilter("VehicleType", this.state.searchInput, FilterOperator.Contains, true);
    this.updateFilter("ServiceStatus", this.state.searchInput, FilterOperator.Contains, true);
    this.updateFilter("AvailableCityInfo", this.state.searchInput, FilterOperator.Contains, true);
    this.updateFilter("PositionCityInfo", this.state.searchInput, FilterOperator.Contains, true);
    this.updateFilter("PayLoad", this.state.searchInput, FilterOperator.Contains, true);
    this.updateFilter("Dims", this.state.searchInput, FilterOperator.Contains, true);
    this.updateFilter("NetradyneHardware", this.state.searchInput, FilterOperator.Contains, true);
    this.updateFilter("RevenueClass", this.state.searchInput, FilterOperator.Contains, true);
    this.updateFilter("LicensePlate", this.state.searchInput, FilterOperator.StartsWith, true);
    this.updateFilter("VINNumber", this.state.searchInput, this.state.searchInput?.length > 4 ? FilterOperator.Contains : FilterOperator.StartsWith, true);
    this.updateFilter("OmnitracsHardware", this.state.searchInput, FilterOperator.EqualTo, true);
    return Promise.resolve(false);
  }

  private excelExportTemplate = () => {
    if (this._exportTemplate.current !== null) {
      this._exportTemplate.current.save();
    }
  };

  private loadAssetDrivers(paramData: DriverValue[] | null) {
    if (!paramData) {
      return <React.Fragment>
        <div style={centerDiv}>Loading...</div>
      </React.Fragment>
    }

    let data = paramData;

    data = orderBy(data, this.state.driverSort);
    data = filterBy(data, this.state.driverFilter);

    return <React.Fragment>
      <ExcelExport fileName={`Drivers.xlsx`} data={data} ref={this._exportTemplate}>
        <ExportColumn field="FullName" title="Driver Name" width={200} />
        <ExportColumn field="VehicleNumber" title="Unit #" width={200} />
        <ExportColumn field="VehicleType" title="Vehicle Type" width={200} />
        <ExportColumn field="DOB" title="DOB" width={200} cellOptions={{ format: "mm/dd/yyyy" }} />
        <ExportColumn field="DriversLicense" title="DL#" width={200} />
        <ExportColumn field="DriversLicenseState" title="DL State" />
        <ExportColumn field="Mobile" title="Cell" width={200} />
        <ExportColumn field="SecurityNumber" title="SSN" width={200} />
      </ExcelExport>
      <Grid
        style={{ maxHeight: this.state.dimensions.Height - 176 + "px" }}
        skip={this.state.skip}
        take={this.state.take}
        total={data.length}
        pageable={this.state.pageable}
        pageSize={this.state.pageSize}
        onPageChange={this.pageChange}
        sortable={{ allowUnsort: true, mode: "single" }}
        sort={this.state.driverSort}
        selectedField="Selected"
        onSelectionChange={this.selectionChange}
        onHeaderSelectionChange={this.headerDriverSelectionChange}
        onSortChange={this.driverSortChange}
        filter={this.state.driverGridFilterDescriptorFilter}
        onFilterChange={(e) => {
          this.setState({ driverGridFilterDescriptorFilter: e.filter }, () => { this.reloadMainCompositeGridFilter(); });
          sessionStorage.setItem('AssetDrivers-gridFilterDescriptorFilter', JSON.stringify(e.filter));
        }}
        data={data.slice(this.state.skip, this.state.take + this.state.skip)}>
        <GridToolbar>
          <Input
            value={this.state.searchInput}
            className="ml-1"
            onChange={(e) => {
              this.setState({ searchInput: e.value }, () => { this.filterInputChangeDriverDebounced() });
              sessionStorage.setItem('AssetDrivers-inputFilter', e.value);
            }}
            placeholder="Search..."
          />
          <Button
            title="Clear All Filters and Sort"
            icon="filter-clear"
            svgIcon={filterClearIcon}
            onClick={() => {
              this.setState({
                searchInput: '',
                driverFilter: AssetPanel._DefaultFilter,
                driverButtonGroupCompositeFilter: AssetPanel._DefaultButtonGroupCompositeFilter,
                driverGridFilterDescriptorFilter: AssetPanel._DefaultFilter,
                driverSort: AssetPanel._DefaultDriverSort,
              }, () => { this.filterInputChangeDriverDebounced(); });
              sessionStorage.removeItem('AssetDrivers-inputFilter');
              sessionStorage.removeItem('AssetDrivers-buttonGroupCompositeFilter');
              sessionStorage.removeItem('AssetDrivers-gridFilterDescriptorFilter');
              sessionStorage.removeItem('AssetDrivers-filter');
              sessionStorage.removeItem('AssetDrivers-sort');
            }}
          />
          <Button
            title="Refresh"
            icon="refresh"
            svgIcon={arrowRotateCwIcon}
            onClick={() => this.getAssetDrivers(this.state.viewingActiveRecords, this.state.missingPaperwork)}
          />
          <Button
            themeColor="primary"
            icon="plus"
            svgIcon={plusIcon}
            onClick={() => { this.setState({ showCopyDriverDialog: true }); }}
          >Driver
          </Button>
          <label style={{ fontSize: "12px", marginTop: "6px" }}>Expiring:</label>
          <ButtonGroup>
            <Button
              togglable
              selected={this.hasFilter('LicenseExpiring')}
              onClick={() => { this.updateFilter('LicenseExpiring', this.hasFilter('LicenseExpiring') ? '' : true, FilterOperator.EqualTo, false) }}
            >License
            </Button>
            <Button
              togglable
              selected={this.hasFilter('FastCSAExpiring')}
              onClick={() => { this.updateFilter('FastCSAExpiring', this.hasFilter('FastCSAExpiring') ? '' : true, FilterOperator.EqualTo, false) }}
            >FAST
            </Button>
            <Button
              togglable
              selected={this.hasFilter('PhysicalExpiring')}
              onClick={() => { this.updateFilter('PhysicalExpiring', this.hasFilter('PhysicalExpiring') ? '' : true, FilterOperator.EqualTo, false) }}
            >Physical
            </Button>
            <Button
              togglable
              selected={this.hasFilter('HazMatExpiring')}
              onClick={() => { this.updateFilter('HazMatExpiring', this.hasFilter('HazMatExpiring') ? '' : true, FilterOperator.EqualTo, false) }}
            >Hazmat
            </Button>
          </ButtonGroup>
          &nbsp;&nbsp;
          <label style={{ fontSize: "12px", marginTop: "6px" }}>Missing:</label>
          <Button
            togglable
            selected={this.state.missingPaperwork}
            onClick={() => { this.getAssetDriversWithParams(this.state.viewingActiveRecords, MissingOrField.PW, !this.state.missingPaperwork); }}
            title="Paperwork"
          >Paperwork
          </Button>
          &nbsp;&nbsp;
          <label style={{ fontSize: "12px", marginTop: "6px" }}>Status:</label>
          <ButtonGroup>
            <Button
              togglable
              selected={this.state.viewingActiveRecords === AssetStatus.Active}
              onClick={() => { this.getAssetDrivers(AssetStatus.Active, this.state.missingPaperwork); }}
            >Active</Button>
            <Button
              togglable
              selected={this.state.viewingActiveRecords === AssetStatus.Any}
              onClick={() => { this.getAssetDrivers(AssetStatus.Any, this.state.missingPaperwork); }}
            >Any</Button>
            <Button
              togglable
              selected={this.state.viewingActiveRecords === AssetStatus.Retired}
              onClick={() => { this.getAssetDrivers(AssetStatus.Retired, this.state.missingPaperwork); }}
            >Retired</Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button
              title="Export Excel"
              icon="download"
              svgIcon={downloadIcon}
              onClick={this.excelExportTemplate}
            >Export</Button>
          </ButtonGroup>
        </GridToolbar>
        <Column {...this.columnProps("DriverNumber")} title="Driver ID" cell={DriverNumber} />
        <Column {...this.columnProps("FirstName")} title="Driver Name" cell={DriverName} />
        <Column {...this.columnProps("Email")} title="Contact Info" cell={DriverContactInfo} sortable={false} />
        <Column {...this.columnProps("VehicleNumber")} title="Vehicle ID" cell={DriverVehicleNumber} />
        <Column {...this.columnProps("CardNumber")} title="Card No." cell={driverCardNumberCell(this.loadSupportedVehiclesDialogScreen)} />
        <Column
          field="Selected"
          width="50px"
          headerSelectionValue={
            data.findIndex(dataItem => dataItem.Selected === false) === -1
          } sortable={false} filterable={false} />
        <Column title="Action" cell={assetActionCell(data.filter(x => x.Selected), [], this.loadSupportedVehiclesDialogScreen)} sortable={false} filterable={false} />
      </Grid>
    </React.Fragment>
  }

  private loadAssetVehicles(paramData: AssetVehicleViewModel[] | null) {
    if (!paramData) {
      return <React.Fragment>
        <div style={centerDiv}>Loading...</div>
      </React.Fragment>
    }

    let data = paramData;

    data = orderBy(data, this.state.vehicleSort);
    data = filterBy(data, this.state.vehicleFilter);

    return <React.Fragment>
      {this.state.showAddVehicleDialog && <AddVehicleDialog
        CloseDialog={() => this.setState({ showAddVehicleDialog: false })}
      />}
      {this.state.reserveVehicleId > 0 && <ReservePopup
        vehicleId={this.state.reserveVehicleId}
        close={() => this.setState({ reserveVehicleId: 0 })}
        refresh={() => this.setState({ reserveVehicleId: 0 }, () => this.getAssetVehicles(this.state.viewingActiveRecords))}
      />}
      <Grid
        style={{ maxHeight: this.state.dimensions.Height - 176 + "px" }}
        skip={this.state.skip}
        take={this.state.take}
        total={data.length}
        pageable={this.state.pageable}
        pageSize={this.state.pageSize}
        onPageChange={this.pageChange}
        sortable={{ allowUnsort: true, mode: "single" }}
        sort={this.state.vehicleSort}
        selectedField="Selected"
        onSelectionChange={this.selectionChange}
        onHeaderSelectionChange={this.headerVehicleSelectionChange}
        onSortChange={this.vehicleSortChange}
        filter={this.state.vehicleGridFilterDescriptorFilter}
        onFilterChange={(e) => {
          this.setState({ vehicleGridFilterDescriptorFilter: e.filter }, () => { this.reloadMainCompositeGridFilter(); });
          sessionStorage.setItem('AssetVehicles-gridFilterDescriptorFilter', JSON.stringify(e.filter));
        }}
        data={data.slice(this.state.skip, this.state.take + this.state.skip)}
      >
        <GridToolbar>
          <Input
            value={this.state.searchInput}
            className="ml-1"
            onChange={(e) => {
              this.setState({ searchInput: e.value }, () => { this.filterInputChangeVehicleDebounced() });
              sessionStorage.setItem('AssetVehicles-inputFilter', e.value);
            }}
            placeholder="Search..."
          />
          <Button
            title="Clear All Filters and Sort"
            icon="filter-clear"
            svgIcon={filterClearIcon}
            onClick={() => {
              this.setState({
                searchInput: '',
                vehicleFilter: AssetPanel._DefaultFilter,
                vehicleButtonGroupCompositeFilter: AssetPanel._DefaultButtonGroupCompositeFilter,
                vehicleGridFilterDescriptorFilter: AssetPanel._DefaultFilter,
                vehicleSort: AssetPanel._DefaultVehicleSort
              }, () => { this.filterInputChangeVehicleDebounced(); });
              sessionStorage.removeItem('AssetVehicles-inputFilter');
              sessionStorage.removeItem('AssetVehicles-filter');
              sessionStorage.removeItem('AssetVehicles-buttonGroupCompositeFilter');
              sessionStorage.removeItem('AssetVehicles-gridFilterDescriptorFilter');
              sessionStorage.removeItem('AssetVehicles-sort');
            }}
          />
          <Button
            title="Refresh"
            icon="refresh"
            svgIcon={arrowRotateCwIcon}
            onClick={() => this.getAssetVehicles(this.state.viewingActiveRecords)}
          />
          <Button
            icon="plus"
            svgIcon={plusIcon}
            themeColor="primary"
            onClick={() => this.setState({ showAddVehicleDialog: true })}
          > Vehicle
          </Button>
          &nbsp;&nbsp;
          <label style={{ fontSize: 12, marginTop: 6 }}>Expiring:</label>
          <ButtonGroup>
            <Button
              togglable
              selected={this.hasFilter('PreventiveMaintenanceExpiringDays')}
              onClick={() => {
                if (this.hasFilter('PreventiveMaintenanceExpiringDays')) {
                  this.updateFilter('PreventiveMaintenanceExpiringDays', false, FilterOperator.GreaterThanOrEqual, false);
                  this.updateVehiclerSort('PreventiveMaintenanceExpiringDays', '');
                } else {
                  this.updateFilter('PreventiveMaintenanceExpiringDays', 1, FilterOperator.GreaterThanOrEqual, false);
                  this.updateVehiclerSort('PreventiveMaintenanceExpiringDays', 'desc');
                }
              }}
            >Maintenance
            </Button>
            <Button
              togglable
              selected={this.hasFilter('InspectionExpiringDays')}
              onClick={() => {
                if (this.hasFilter('InspectionExpiringDays')) {
                  this.updateFilter('InspectionExpiringDays', false, FilterOperator.GreaterThanOrEqual, false);
                  this.updateVehiclerSort('InspectionExpiringDays', '');
                } else {
                  this.updateFilter('InspectionExpiringDays', 1, FilterOperator.GreaterThanOrEqual, false);
                  this.updateVehiclerSort('InspectionExpiringDays', 'desc');
                }
              }}
            >Inspection
            </Button>
            <Button
              togglable
              selected={this.hasFilter('LicensePlateExpiringDays')}
              onClick={() => {
                if (this.hasFilter('LicensePlateExpiringDays')) {
                  this.updateFilter('LicensePlateExpiringDays', false, FilterOperator.GreaterThanOrEqual, false);
                  this.updateVehiclerSort('LicensePlateExpiringDays', '');
                } else {
                  this.updateFilter('LicensePlateExpiringDays', 1, FilterOperator.GreaterThanOrEqual, false);
                  this.updateVehiclerSort('LicensePlateExpiringDays', 'desc');
                }
              }}
            >License Plate
            </Button>
          </ButtonGroup>
          &nbsp;
          <label style={{ fontSize: 12, marginTop: 6 }}>Dispatch:</label>
          <Button
            togglable
            selected={this.hasFilter('HideFromDispatch')}
            onClick={() => { this.updateFilter('HideFromDispatch', this.hasFilter('HideFromDispatch') ? '' : true, FilterOperator.EqualTo, false) }}
          >Hidden
          </Button>
          &nbsp;
          <label style={{ fontSize: 12, marginTop: 6 }}>Status:</label>
          <ButtonGroup>
            <Button
              togglable
              selected={this.state.viewingActiveRecords === AssetStatus.Active}
              onClick={() => { this.setState({ viewingActiveRecords: AssetStatus.Active }, () => this.getAssetVehicles(AssetStatus.Active)); }}
            >Active</Button>
            <Button
              togglable
              selected={this.state.viewingActiveRecords === AssetStatus.Any}
              onClick={() => { this.setState({ viewingActiveRecords: AssetStatus.Any }, () => this.getAssetVehicles(AssetStatus.Any)); }}
            >Any</Button>
            <Button
              togglable
              selected={this.state.viewingActiveRecords === AssetStatus.Retired}
              onClick={() => { this.setState({ viewingActiveRecords: AssetStatus.Retired }, () => this.getAssetVehicles(AssetStatus.Retired)); }}
            >Retired</Button>
          </ButtonGroup>
          &nbsp;
          <ButtonGroup>
            <Button
              togglable
              selected={this.hasFilterValue('RevenueClass', 'TRUCKLOAD-01')}
              onClick={() => { this.updateFilter('RevenueClass', this.hasFilterValue('RevenueClass', 'TRUCKLOAD-01') ? '' : 'TRUCKLOAD-01', FilterOperator.EqualTo, false) }}
            >Truckload
            </Button>
            <Button
              togglable
              selected={this.hasFilterValue('RevenueClass', 'DEDICATED-03')}
              onClick={() => { this.updateFilter('RevenueClass', this.hasFilterValue('RevenueClass', 'DEDICATED-03') ? '' : 'DEDICATED-03', FilterOperator.EqualTo, false) }}
            >Dedicated
            </Button>
            <Button
              togglable
              selected={this.hasFilterValue('RevenueClass', 'EXPEDITE-04')}
              onClick={() => { this.updateFilter('RevenueClass', this.hasFilterValue('RevenueClass', 'EXPEDITE-04') ? '' : 'EXPEDITE-04', FilterOperator.EqualTo, false) }}
            >Expedite
            </Button>
            <Button
              togglable
              selected={this.hasFilterValue('RevenueClass', 'SPECIAL/PDQ-05')}
              onClick={() => { this.updateFilter('RevenueClass', this.hasFilterValue('RevenueClass', 'SPECIAL/PDQ-05') ? '' : 'SPECIAL/PDQ-05', FilterOperator.EqualTo, false) }}
            >Special/PDQ
            </Button>
          </ButtonGroup>
        </GridToolbar>
        <Column {...this.columnProps("VehicleNumber")} title="Vehicle ID" cell={VehicleNumber} />
        <Column {...this.columnProps("IsReserved")} title="Reserve" cell={vehicleReserveCell(this.reserve, this.reservePopup)} />
        <Column {...this.columnProps("DriverNumber")} title="Driver ID" cell={VehicleDriverNumber} width={128} />
        <Column {...this.columnProps("TrailerNumber")} title="Trailer ID" cell={TrailerNumber} />
        <Column {...this.columnProps("VehicleType")} title="Vehicle Type" cell={VehicleTypeCell} />
        <Column {...this.columnProps("ServiceStatus")} title="Status" cell={ServiceStatus} />
        <Column {...this.columnProps("AvailableCityInfo")} title="Available" cell={AvailableCell} />
        <Column {...this.columnProps("Comment3")} title="Note" />
        <Column {...this.columnProps("PositionCityInfo")} title="Position" cell={PositionCell} />
        <Column {...this.columnProps("Payload")} filter="numeric" cell={PayloadCell} />
        <Column {...this.columnProps("GrossVehicleWeight")} title="GVW" filter="numeric" format="{0:n0}" />
        <Column {...this.columnProps("LastAvailable")} title="LA" filter="numeric" />
        <Column {...this.columnProps("NetradyneHardware")} title="Netradyne ID" />
        <Column
          field="Selected"
          width="50px"
          headerSelectionValue={
            data.findIndex(dataItem => dataItem.Selected === false) === -1
          } sortable={false} filterable={false} />
        <Column title="Action" cell={assetActionCell([], data.filter(x => x.Selected), this.loadSupportedVehiclesDialogScreen)} sortable={false} filterable={false} />
      </Grid>
    </React.Fragment>
  }
}
