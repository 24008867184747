import { useEffect, useState } from 'react';
import { Checkbox, Input, NumericTextBox, TextArea } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import Moment from 'moment-timezone';
import { useParams } from 'react-router';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import realFormatter from '../../services/formatting/number';
import { fetchApi } from '../../services/api';
import { dimsDisplayValue, dimsUnitOfMeasureAbbreviation, weightDisplayValue, weightUnitOfMeasureAbbreviation } from '../../utils/utils';
import { ILink } from '../../types/link';
import useConfirm from 'components/useConfirm';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';

interface Quote {
  QuoteID: number;
  ExpiryDateTime: Date;
  OfferedBy: string;
  VehicleTypeName: string;
  Distance: number;
  Note: string;
  HazMat: boolean;
  DockHigh: boolean;
  LiftGate: boolean;
  BookItRate: number;
  Confirmed: boolean;
  RequireVehicleDimensions: boolean;
  Stops: QuoteStop[];
  Freight: QuoteFreight[];
  Commands: ILink[];
}

interface QuoteStop {
  QuoteID: number;
  StopType: 0|1;
  StopVerb: string;
  City: string;
  State: string;
  ZipCode: string;
  ScheduledDateTime: Date;
}

interface QuoteFreight {
  QuoteFreightID: number;
  Pieces: number;
  Weight: number;
  WeightUnitOfMeasure: 0|1;
  Length: number;
  Width: number;
  Height: number;
  DimsUnitOfMeasure: 0|1|2;
  Stackable: boolean;
}

interface QuoteResponse {
  AllInRate: number;
  Note: string;
  AlternativePickup: Date | null;
  AlternativeDelivery: Date | null;
  MilesFromPickup: number | null;
  BoxLength: number | null;
  BoxWidth: number | null;
  BoxHeight: number | null;
  IsVehicleEmpty: boolean | null;
  DispatcherName: string;
}

type RouteComponentParams = {
  key: string;
  quoteId: string;
};

const CarrierOffer = () => {
  const { key: keyParam, quoteId: quoteIdParam } = useParams<RouteComponentParams>();

  const { ConfirmationDialog, confirm } = useConfirm({
    labels: {
      confirm: 'Book It Now',
      cancel: 'Cancel',
    },
    themeColor: 'success',
  });
  const [loading, setLoading] = useState(true);
  const [allInRateError, setAllInRateError] = useState('');
  const [showAlternativePickup, setShowAlternativePickup] = useState(false);
  const [alternativePickupError, setAlternativePickupError] = useState('');
  const [showAlternativeDelivery, setShowAlternativeDelivery] = useState(false);
  const [alternativeDeliveryError, setAlternativeDeliveryError] = useState('');
  const [milesFromPickupError, setMilesFromPickupError] = useState('');
  const [vehicleDimsError, setVehicleDimsError] = useState('');
  const [isVehicleEmptyError, setIsVehicleEmptyError] = useState('');
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [quote, setQuote] = useState<Quote | null>(null);
  const [quoteResponse, setQuoteResponse] = useState<QuoteResponse>({
    AllInRate: 0,
    Note: '',
    AlternativePickup: null,
    AlternativeDelivery: null,
    MilesFromPickup: null,
    BoxLength: null,
    BoxWidth: null,
    BoxHeight: null,
    IsVehicleEmpty: null,
    DispatcherName: '',
  });


  useEffect(() => {
    fetchApi('/api/Quote/CarrierOffer/' + quoteIdParam + '/' + keyParam)
      .then((response) => {
        setLoading(false);
        setQuote(response);
        setQuoteResponse({
          AllInRate: response.BookItRate,
          Note: '',
          AlternativePickup: null,
          AlternativeDelivery: null,
          MilesFromPickup: null,
          BoxLength: null,
          BoxWidth: null,
          BoxHeight: null,
          IsVehicleEmpty: null,
          DispatcherName: '' || localStorage.getItem('Bid_DispatcherName') || ''
        });
      })
  }, [quoteIdParam, keyParam]);

  const updateQuoteResponse = (value: Partial<QuoteResponse>) => {
    const quoteResponseValue = Object.assign({}, quoteResponse, value);
    setQuoteResponse(quoteResponseValue);
  }

  const decline = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    setLoading(true)
    const data = {
      Note: quoteResponse.Note
    }
    fetchApi('/api/Quote/CarrierDecline/' + quoteIdParam + '/' + keyParam, data, 'post')
      .then((response: { Success: boolean, ErrorMessage: string }) => {
        setLoading(false)
        if (response.Success) {
          setSuccess(true);
        } else {
          setErrorMessage(response.ErrorMessage);
        }
      })
      .catch(() => {
        setLoading(false);
        setErrorMessage('Our apologies, but the server has failed to process your request.  Please try again later.');
      });
  }

  const accept = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const allInRateError = (quoteResponse.AllInRate === 0) ? 'Please enter an all-in rate.' : '';
    const alternativePickupError = (showAlternativePickup && !quoteResponse.AlternativePickup) ? 'Please enter alternative pickup date.' : '';
    const alternativeDeliveryError = (showAlternativeDelivery && !quoteResponse.AlternativeDelivery) ? 'Please enter alternative delivery date.' : '';
    const milesFromPickupError = (quoteResponse.MilesFromPickup === null) ? 'Please enter miles from pickup.' : '';
    const vehicleDimsError = (quote.RequireVehicleDimensions === true && (quoteResponse.BoxLength === null || quoteResponse.BoxWidth === null || quoteResponse.BoxHeight === null)) ? 'Please enter vehicle dimensions.' : '';
    const isVehicleEmptyError = (quoteResponse.IsVehicleEmpty === null) ? 'Please indicate if vehicle is empty.' : '';

    if (allInRateError || alternativePickupError || alternativeDeliveryError || milesFromPickupError || vehicleDimsError || isVehicleEmptyError) {
      setAllInRateError(allInRateError);
      setAlternativePickupError(alternativePickupError);
      setAlternativeDeliveryError(alternativeDeliveryError);
      setMilesFromPickupError(milesFromPickupError);
      setVehicleDimsError(vehicleDimsError);
      setIsVehicleEmptyError(isVehicleEmptyError);
      return;
    }

    if (quote.Confirmed && !await confirm("Confirm Book It Now?")) {
      return;
    }

    setLoading(true);

    const data = {
      AllInRate: quoteResponse.AllInRate,
      Note: quoteResponse.Note,
      AlternativePickup: showAlternativePickup ? quoteResponse.AlternativePickup : null,
      AlternativeDelivery: showAlternativeDelivery ? quoteResponse.AlternativeDelivery : null,
      MilesFromPickup: quoteResponse.MilesFromPickup,
      BoxLength: quoteResponse.BoxLength,
      BoxWidth: quoteResponse.BoxWidth,
      BoxHeight: quoteResponse.BoxHeight,
      IsVehicleEmpty: quoteResponse.IsVehicleEmpty,
      DispatcherName: quoteResponse.DispatcherName,
    }

    fetchApi('/api/Quote/CarrierAccept/' + quoteIdParam + '/' + keyParam, data, 'post')
      .then((response: { Success: boolean, ErrorMessage: string }) => {
        setLoading(false);
        if (response.Success) {
          setSuccess(true);
        } else {
          setErrorMessage(response.ErrorMessage);
        }
      })
      .catch(() => {
        setLoading(false);
        setErrorMessage('Our apologies, but the server has failed to process your request.  Please try again later.');
      });
  }

  if (success) {
    return (<div className="container text-center">
      <h1>Thank you, your response has been recorded!</h1>
    </div>)
  }

  if (loading || quote === null) {
    return (
      <span
        className="k-i-loading k-icon"
        style={{ fontSize: 64, position: 'absolute', left: '50%', top: '50px' }}
      />
    );
  }

  return (
    <div className="container">
      <ConfirmationDialog />
      <br />
      <img src="/images/L1logo.png" height="90" width="225" />
      <br /><br />
      {errorMessage &&
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      }
      {quote.Note && <>
        <h4>Note</h4>
        <p>{quote.Note}</p>
      </>}
      <h4>Stops ({realFormatter(quote.Distance)} miles)</h4>
      <table className="table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Location</th>
            <th>Scheduled</th>
          </tr>
        </thead>
        <tbody>
          {quote.Stops.map((stop, index) => {
            return (
              <tr key={index}>
                <td>
                  {stop.StopType === 0 ? 'Pickup' : 'Delivery'}
                </td>
                <td>
                  {stop.City}, {stop.State} {stop.ZipCode}
                </td>
                <td>
                  {Moment.utc(stop.ScheduledDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")} EST ({stop.StopVerb})
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <h4>Freight ({quote.VehicleTypeName})</h4>
      <table className="table">
        <thead>
          <tr>
            <th>Pieces</th>
            <th>Weight</th>
            <th>Dims (LxWxH)</th>
            <th>Stackable</th>
          </tr>
        </thead>
        <tbody>
          {quote.Freight.map((freight, index) => {
            return (
              <tr key={index}>
                <td>{freight.Pieces}</td>
                <td>{weightDisplayValue(freight.Weight, freight.WeightUnitOfMeasure)} {weightUnitOfMeasureAbbreviation(freight.WeightUnitOfMeasure)}</td>
                <td>{dimsDisplayValue(freight.Length, freight.DimsUnitOfMeasure)} x {dimsDisplayValue(freight.Width, freight.DimsUnitOfMeasure)} x {dimsDisplayValue(freight.Height, freight.DimsUnitOfMeasure)} {dimsUnitOfMeasureAbbreviation(freight.DimsUnitOfMeasure)}</td>
                <td>{freight.Stackable ? "Yes" : "No"}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="row">
        <div className="col-md-3"><b>Hazardous</b>: {quote.HazMat ? <span className='badge badge-danger'>Yes</span> : "No"}</div>
        <div className="col-md-3"><b>Dock High</b>: {quote.DockHigh ? "Yes" : "No"}</div>
        <div className="col-md-3"><b>Lift Gate</b>: {quote.LiftGate ? "Yes" : "No"}</div>
      </div>
      <br />
      <div className="form-group">
        <label htmlFor="acceptAmount" className="form-label">All-in rate</label>
        <br />
        <NumericTextBox
          disabled={quote.Confirmed}
          format="c2"
          placeholder="$0.00"
          onChange={e => updateQuoteResponse({ AllInRate: e.value || 0 })}
          defaultValue={quote.BookItRate || undefined}
          spinners={false}
          step={0}
          min={0}
        />
        {allInRateError && <Error>{allInRateError}</Error>}
      </div>
      <div className="form-group">
        <label className="form-label">Can you make the pickup time?</label>
        <br />
        <DropDownList
          disabled={quote.Confirmed}
          data={["Yes", "No"]}
          defaultValue="Yes"
          onChange={(e) => setShowAlternativePickup(e.target.value == "No")}
        />
      </div>
      {showAlternativePickup && <div className="form-group">
        <label className="form-label">Enter alternative pickup time: (Eastern Standard Time)</label>
        <br />
        <DateTimePicker
          format="MM/dd/yyyy HH:mm"
          value={quoteResponse.AlternativePickup}
          onChange={(e) => {
            updateQuoteResponse({
              AlternativePickup: e.value
            });
          }}
        />
        {alternativePickupError && <Error>{alternativePickupError}</Error>}
      </div>}
      <div className="form-group">
        <label className="form-label">Can you make the delivery time?</label>
        <br />
        <DropDownList
          disabled={quote.Confirmed}
          data={["Yes", "No"]}
          defaultValue="Yes"
          onChange={(e) => setShowAlternativeDelivery(e.target.value == "No")}
        />
      </div>
      {showAlternativeDelivery && <div className="form-group">
        <label className="form-label">Enter alternative delivery time: (Eastern Standard Time)</label>
        <br />
        <DateTimePicker
          format="MM/dd/yyyy HH:mm"
          value={quoteResponse.AlternativeDelivery}
          onChange={(e) => {
            updateQuoteResponse({
              AlternativeDelivery: e.value
            });
          }}
        />
        {alternativeDeliveryError && <Error>{alternativeDeliveryError}</Error>}
      </div>}
      <div className="form-group">
        <NumericTextBox
          format="##,# 'miles out'"
          placeholder="Miles to Pickup"
          onChange={e => {
            updateQuoteResponse({ MilesFromPickup: e.value || null });
            if (e.value) setMilesFromPickupError('');
          }}
          defaultValue={quoteResponse.MilesFromPickup || undefined}
          spinners={false}
          step={0}
          min={0}
        />
        {milesFromPickupError && <Error>{milesFromPickupError}</Error>}
      </div>
      <div className="form-group">
        <div className='d-flex justify-content-between'>
          <NumericTextBox
            width="33%"
            format="##,# 'in'"
            placeholder="Box Length"
            onChange={e => {
              updateQuoteResponse({ BoxLength: e.value || null });
              setVehicleDimsError('');
            }}
            defaultValue={quoteResponse.BoxLength || undefined}
            spinners={false}
            step={0}
            min={0}
          />
          <NumericTextBox
            width="33%"
            format="##,# 'in'"
            placeholder="Box Width"
            onChange={e => {
              updateQuoteResponse({ BoxWidth: e.value || null });
              setVehicleDimsError('');
            }}
            defaultValue={quoteResponse.BoxWidth || undefined}
            spinners={false}
            step={0}
            min={0}
          />
          <NumericTextBox
            width="33%"
            format="##,# 'in'"
            placeholder="Box Height"
            onChange={e => {
              updateQuoteResponse({ BoxHeight: e.value || null });
              setVehicleDimsError('');
            }}
            defaultValue={quoteResponse.BoxHeight || undefined}
            spinners={false}
            step={0}
            min={0}
          />
        </div>
        {vehicleDimsError && <Error className='k-mb-2'>{vehicleDimsError}</Error>}
      </div>
      <div className="form-group">
        <Checkbox
          checked={quoteResponse.IsVehicleEmpty}
          onChange={(e) => {
            updateQuoteResponse({ IsVehicleEmpty: e.value });
            setIsVehicleEmptyError('');
          }}
        />
        <label className="form-label ml-2">Is the vehicle empty now?</label>
        {isVehicleEmptyError && <Error className='k-mb-2'>{isVehicleEmptyError}</Error>}
      </div>
      <div className="form-group">
        <TextArea placeholder="Notes" value={quoteResponse.Note} onChange={e => updateQuoteResponse({ Note: e.value || '' })} />
      </div>
      <div className='form-group'>
        <Input
          placeholder="Your Name"
          maxLength={60}
          value={quoteResponse.DispatcherName}
          onChange={e => {
            updateQuoteResponse({ DispatcherName: e.value });
            localStorage.setItem('Bid_DispatcherName', e.value);
          }}
        />
      </div>
      <button className="btn btn-success" type="button" onClick={accept}>{quote.Confirmed ? "Book It Now" : "Submit Bid"}</button>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <button className="btn btn-danger" type="button" onClick={decline}>Decline</button>
      <br /><br />
    </div>
  );
}

export default CarrierOffer;