import * as React from 'react';
import { formatNumber } from '@progress/kendo-intl';
import { ComboBox, ComboBoxCloseEvent, ComboBoxChangeEvent, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import realFormatter from '../../services/formatting/number';
import { fetchApi } from '../../services/api';
import './Quote.css';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import LoadingPanel from '../../components/LoadingPanel';
import { Loader } from '@progress/kendo-react-indicators';
import { bankersRounding } from 'utils/bankersRounding';
import { SvgIcon } from "@progress/kendo-react-common";
import { caretAltDownIcon, caretAltUpIcon, xIcon } from '@progress/kendo-svg-icons';
import { Chart, ChartCategoryAxis, ChartCategoryAxisItem, ChartSeries, ChartSeriesItem, ChartTooltip, ChartValueAxis, ChartValueAxisItem } from '@progress/kendo-react-charts';
import { noop } from 'lodash';

type State = {
  chargeRate: number;
  chargePercentage: number;
  chargeAmount: number | null;
  chargeType: number;
  chargeTypes: ChargeType[];
  chargeTypesFiltered: ChargeType[];
  chargeTypeFilter: any;
  winPercentageDataLoading: boolean;
  showModal: boolean;
  allInRate: number;
  selected: number;
}

export type ChargeType = {
  RateDescriptionID: number;
  RateType: number;
  Description: string;
}

type Props = {
  QuoteID: number;
  distance: number;
  data: QuoteValue | null;
  smartbidData: SmartbidValue | null;
  winPercentageData: WinPercentageChartValue | null;
  loading: boolean;
  outdated: boolean;
  smartbidLoading: boolean;
  tolls: number;
  fuelRate: number;
  updateCharge: (index: number, data: { ChargeType: ChargeType, Amount: number }) => void;
  addCharge: (data: { ChargeType: ChargeType, Quantity: number, QuantityUoM: number, Rate: number, RateUoM: number, Amount: number }) => void;
  removeCharge: (index: number) => void;
  replaceCharges: (
    data: Array<{ ChargeType: ChargeType, Quantity: number, QuantityUoM: number, Rate: number, RateUoM: number, Amount: number }>,
    smartbidUsed?: boolean,
    smartbidRateUpDown?: number,
    smartbidWinPercentagePrevious?: number,
    smartbidWinPercentageCurrent?: number
  ) => void;
  fetchWinPercentageChart: () => Promise<void>;
  recalculateRate: () => void;
};

export type QuoteValue = {
  Amount: number;
  LineHaulAmount: number;
  FuelAmount: number;
  AccessorialAmount: number;
  BonusAmount: number;
  RateComment: string;
  LineItems: QuoteLineItemValue[];
}

export type SmartbidValue = {
  Current: WinRatePercentageData;
  Next: WinRatePercentageData;
}

export type WinRatePercentageData = {
  RPM: number;
  LinehaulRPM: number;
  Min: number;
  Max: number;
  Mean: number;
  Increase: boolean;
}

export type WinPercentageChartValue = {
  CurrentBidIndex: number;
  Percentile1: Array<{ RPM: number, LinehaulRPM: number, Min: number, Max: number, Mean: number }>;
}

export type SmartbidRate = {
  Rate: number | null;
  Confidence: number;
  AdjustingUp: boolean | null;
  UsingAllCustomerData: boolean;
}

export type SmartBidHistoricalData = {
  LinehaulRPM: number;
  LinehaulCharge: number;
  LinehaulPay: number;
  Margin: number;
  MarginPercentage: number;
  CustomerNumber: string;
  OrderID: number;
  OrderNumber: string;
  Distance: number;
  Description: string;
}

export type QuoteLineItemValue = {
  RateDescriptionID: number;
  RateType: number;
  Name: string;
  Quantity: number;
  QuantityUoM: number;
  Rate: number;
  RateUoM: number;
  Amount: number;
  IsAutoRated: boolean;
}

export default class QuotePanel extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      chargeRate: 0,
      chargePercentage: props.fuelRate * 100,

      chargeAmount: 0,
      chargeType: 0,
      chargeTypes: [],
      chargeTypesFiltered: [],
      chargeTypeFilter: undefined,
      winPercentageDataLoading: false,
      showModal: false,
      allInRate: 0,
      selected: 0
    }

    this.addCharge = this.addCharge.bind(this);
    this.removeCharge = this.removeCharge.bind(this);
    this.setChargeType = this.setChargeType.bind(this);
    this.filterChargeTypeChange = this.filterChargeTypeChange.bind(this);
    this.resetFilterChangeTypes = this.resetFilterChangeTypes.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.setPerMileRate = this.setPerMileRate.bind(this);
    this.setAllInRate = this.setAllInRate.bind(this);
    this.useHistoricalRate = this.useHistoricalRate.bind(this);
    this.calculateAllInRate = this.calculateAllInRate.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
  }

  render() {
    if (!this.props.data) {
      return null;
    }

    const total67 = this.props.data.Amount - ((this.props.data.LineHaulAmount * 0.67) + this.props.data.FuelAmount + this.props.tolls);
    const total70 = this.props.data.Amount - ((this.props.data.LineHaulAmount * 0.70) + this.props.data.FuelAmount + this.props.tolls);
    const rateType = this.getRateType();
    const allInRate = this.state.allInRate > 0 ? this.state.allInRate : '';

    return (
      <TabStrip
        selected={this.state.selected}
        onSelect={this.handleTabSelect}
        style={{ overflowY: 'auto', height: '100%' }}
        animation={false}
      >
        <TabStripTab title="Rate">
          {this.props.loading && <LoadingPanel />}
          {this.props.outdated && <div className="alert alert-warning" role="alert">
            <a href="#" onClick={(e) => { e.preventDefault(); this.props.recalculateRate(); }}>Recalculate Rate</a> if necessary as the quote has changed.
          </div>}
          <h5 className="card-title">
            <div className="row">
              <div className="col-lg-7">
                {formatNumber(this.props.data.Amount, "c")}
                <span style={{ fontWeight: 'normal', fontSize: '8pt' }}>
                  &nbsp; <span style={{ color: 'orange' }}>67%: {formatNumber(total67, "c")} ({formatNumber(total67 / this.props.data.Amount, "p")})</span>
                  &nbsp; <span style={{ color: 'green' }}>70%: {formatNumber(total70, "c")} ({formatNumber(total70 / this.props.data.Amount, "p")})</span>
                </span>
              </div>
              <div className="col-lg-5">
                <div className="input-group">
                  <input type="number" className="form-control form-control-sm" placeholder="Rate" value={allInRate} onChange={(e) => this.setState({ allInRate: parseFloat(e.target.value) || 0 })} />
                  <div className="input-group-append">
                    <button type="button" className="btn btn-sm btn-outline-secondary" onClick={this.setPerMileRate}>Per Mile</button>
                    <button type="button" className="btn btn-sm btn-outline-secondary" onClick={this.setAllInRate}>All In</button>
                  </div>
                </div>
              </div>
            </div>
          </h5>
          <table className="table">
            <caption>{this.props.data.RateComment}{this.props.fuelRate > 0 && ` (FSC ${formatNumber(this.props.fuelRate, "p2")})`}</caption>
            <thead>
              <tr>
                <th>Line Item</th>
                <th className="text-muted text-right">Qty</th>
                <th className="text-muted text-right">Rate</th>
                <th className="text-right">Amount</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {this.props.data.LineItems.map((lineItem, index) => {
                return <tr key={index}>
                  <td>{lineItem.Name}</td>
                  <td className="text-muted text-right">{formatNumber(lineItem.Quantity, 'n2')}</td>
                  <td className="text-muted text-right">{formatNumber(lineItem.Rate, (lineItem.RateType == 84 || lineItem.RateType == 85) ? 'n5' : lineItem.RateType == 76 ? 'n3' : 'n2')}</td>
                  <td className="text-right">
                    {(lineItem.Quantity === 1 && lineItem.IsAutoRated === false)
                      ? <NumericTextBox spinners={false} value={lineItem.Amount} format="c2" min={0} onChange={(e) => this.updateChargeAmount(e, index)} />
                      : formatNumber(lineItem.Amount, "c")}
                  </td>
                  <td><a href="#" onClick={(e) => this.removeCharge(e, index)}><SvgIcon icon={xIcon} /></a></td>
                </tr>
              })}
              <tr>
                <td colSpan={5}>
                  <button type="button" className="btn btn-link" onClick={this.showModal}>+ Add Charge</button>
                  {this.state.showModal && <Dialog title="Add Charge" onClose={this.hideModal} className='dialog-w800'>
                    <div className="form-group">
                      <ComboBox
                        style={{ width: '100%' }}
                        data={this.state.chargeTypesFiltered}
                        textField="Description"
                        filterable={true}
                        onFilterChange={this.filterChargeTypeChange}
                        placeholder="Select Charge Type..."
                        onClose={this.resetFilterChangeTypes}
                        onChange={this.setChargeType}
                      />
                    </div>
                    {rateType === 'mileage' && <div className="form-group">
                      <label>Rate&nbsp;</label>
                      <NumericTextBox
                        defaultValue={this.state.chargeRate}
                        format="c2"
                        min={0}
                        width={120}
                        spinners={false}
                        value={this.state.chargeRate}
                        onChange={(e) => {
                          if (e.value) {
                            this.setState({ chargeRate: e.value, chargeAmount: e.value * this.props.distance })
                          }
                        }}
                      /> x {realFormatter(this.props.distance)} miles
                      <br /><br />
                      Total: {this.state.chargeAmount !== null && formatNumber(this.state.chargeAmount, 'c')}
                    </div>}
                    {rateType === 'percentlh' && <div className="form-group">
                      <label>Rate&nbsp;</label>
                      <NumericTextBox
                        min={0}
                        max={100}
                        width={120}
                        value={this.state.chargePercentage}
                        onChange={(e) => {
                          if (e.value !== null && this.props.data) {
                            const percentage = e.value;
                            this.setState({ chargePercentage: percentage, chargeAmount: (percentage / 100) * this.props.data.LineHaulAmount })
                          }
                        }}
                      /> x {formatNumber(this.props.data.LineHaulAmount, "c")} line haul
                      <br /><br />
                      Total: {this.state.chargeAmount !== null && formatNumber(this.state.chargeAmount, 'c')}
                    </div>}
                    {rateType === 'percentfuel' && <div className="form-group">
                      <label>Amount:</label>
                      <NumericTextBox
                        defaultValue={this.state.chargeAmount}
                        format="c2"
                        min={0}
                        width={120}
                        spinners={false}
                        value={this.state.chargeAmount}
                        onChange={(e) => this.setState({ chargeAmount: e.value })}
                      />
                      &nbsp;- Fuel:&nbsp;
                      <NumericTextBox
                        min={0}
                        max={100}
                        width={120}
                        value={this.state.chargePercentage}
                        onChange={(e) => {
                          if (e.value !== null && this.props.data) {
                            this.setState({ chargePercentage: e.value })
                          }
                        }}
                      />%
                      <br /><br />
                      Total: {this.state.chargeAmount !== null && formatNumber(this.state.chargeAmount, 'c')}
                    </div>}
                    {rateType === 'flat' && <div className="form-group">
                      <label>Amount: </label>
                      <NumericTextBox
                        defaultValue={this.state.chargeAmount}
                        format="c2"
                        min={0}
                        width={120}
                        spinners={false}
                        value={this.state.chargeAmount}
                        onChange={(e) => this.setState({ chargeAmount: e.value })}
                      />
                    </div>}
                    <DialogActionsBar>
                      <Button themeColor="primary" onClick={this.addCharge}>Add Charge</Button>
                    </DialogActionsBar>
                  </Dialog>}
                </td>
              </tr>
            </tbody>
          </table>
          {(this.props.smartbidLoading && !this.props.smartbidData) && <Loader size="small" />}
          {this.props.smartbidData && <>
            {this.props.smartbidData.Current && <Button
              disabled
              size="small"
              className="mr-1"
              style={{ backgroundImage: `linear-gradient(to right, rgb(108, 117, 125) ${this.props.smartbidData.Current.Mean * 100}%, transparent ${this.props.smartbidData.Current.Mean * 100}%)` }}
            >
              {formatNumber(this.props.smartbidData.Current.RPM, 'c2')}/mi (Win {formatNumber(this.props.smartbidData.Current.Mean, 'p')})
            </Button>}
            {this.props.smartbidData.Next && this.props.smartbidData.Next.Increase === false && <Button
              size="small"
              className="mr-1"
              icon="arrow-s"
              svgIcon={caretAltDownIcon}
              style={{ backgroundImage: `linear-gradient(to right, rgba(108, 117, 125, .5) 0 ${this.props.smartbidData.Current.Mean * 100}%, rgb(25, 135, 84) ${this.props.smartbidData.Current.Mean * 100}% ${this.props.smartbidData.Next.Mean * 100}%, transparent ${this.props.smartbidData.Next.Mean * 100}%)` }}
              onClick={() => this.useHistoricalRate(this.props.smartbidData.Next.LinehaulRPM, this.props.smartbidData.Next.LinehaulRPM - this.props.smartbidData.Current.LinehaulRPM, this.props.smartbidData.Current.Mean, this.props.smartbidData.Next.Mean)}
            >
              {formatNumber(this.props.smartbidData.Next.RPM, 'c2')}/mi (Win +{formatNumber(this.props.smartbidData.Next.Mean - this.props.smartbidData.Current.Mean, 'p2')})
            </Button>}
            {this.props.smartbidData.Next && this.props.smartbidData.Next.Increase && <Button
              size="small"
              className="mr-1"
              icon="arrow-n"
              svgIcon={caretAltUpIcon}
              style={{ backgroundImage: `linear-gradient(to right, rgba(108, 117, 125, .5) 0 ${this.props.smartbidData.Next.Mean * 100}%, rgb(220, 53, 69) ${this.props.smartbidData.Current.Mean * 100}%, transparent ${this.props.smartbidData.Current.Mean * 100}%)` }}
              onClick={() => this.useHistoricalRate(this.props.smartbidData.Next.LinehaulRPM, this.props.smartbidData.Next.LinehaulRPM - this.props.smartbidData.Current.LinehaulRPM, this.props.smartbidData.Current.Mean, this.props.smartbidData.Next.Mean)}
            >
              {formatNumber(this.props.smartbidData.Next.RPM, 'c2')}/mi ({this.props.smartbidData.Next.Mean - this.props.smartbidData.Current.Mean === 0 ? "Same Win %" : "Win " + formatNumber(this.props.smartbidData.Next.Mean - this.props.smartbidData.Current.Mean, 'p2')})
            </Button>}
          </>}
        </TabStripTab>
        <TabStripTab title="Win %" disabled={this.state.winPercentageDataLoading}>
          {this.props.winPercentageData && <Chart
            transitions={false}
            style={{ height: 341 }}
            onSeriesClick={(e) => this.useHistoricalRate(Math.round(e.dataItem.LinehaulRPM * 100) / 100, (Math.round(e.dataItem.LinehaulRPM * 100) / 100) - this.props.winPercentageData.Percentile1[this.props.winPercentageData.CurrentBidIndex].LinehaulRPM, this.props.winPercentageData.Percentile1[this.props.winPercentageData.CurrentBidIndex].Mean, e.stackValue)}
          >
            <ChartSeries>
              <ChartSeriesItem
                color="rgb(25,135,84)"
                type="rangeArea"
                data={this.props.winPercentageData.Percentile1}
                fromField="Min"
                toField="Max"
                meanField="Mean"
                categoryField="RPM"
                opacity={.95}
              />
              <ChartSeriesItem
                color="#000000"
                type="line"
                data={this.props.winPercentageData.Percentile1}
                field="Mean"
                markers={{ visible: false }}
                opacity={1}
              >
                <ChartTooltip format='{0:p}' />
              </ChartSeriesItem>
            </ChartSeries>
            <ChartValueAxis>
              <ChartValueAxisItem labels={{ format: "{0:p}" }} />
            </ChartValueAxis>
            <ChartCategoryAxis>
              <ChartCategoryAxisItem labels={{ rotation: "auto", format: "{0:c}", step: 3 }} plotBands={[{ from: this.props.winPercentageData.CurrentBidIndex, to: this.props.winPercentageData.CurrentBidIndex, color: "#dc3545", label: { rotation: 90, text: "Current Bid", color: 'white', background: '#dc3545', padding: { left: 5, right: 5 } } }]} />
            </ChartCategoryAxis>
            <ChartTooltip format='{0:p} - {1:p}' />
          </Chart>}
        </TabStripTab>
         <TabStripTab title="Win % Statement">
          <p>The win rate % indicated is provided by a data science model which takes the set of attributes on this bid, compares them to historical LoadOne bid information, and determines our probability of winning based upon the per mile line haul fee. The model is being updated nightly based upon the prior 30 days of data.</p>
          <p>Dispatchers should leverage information provided by this model as another tool in their toolbox when determining a bid. The model does not know everything about the real world, so dispatchers have autonomy to trust their gut.</p>
         </TabStripTab>
      </TabStrip>)
  }

  private handleTabSelect(e: TabStripSelectEventArguments) {
    if (e.selected === 1 && !this.props.winPercentageData)
    {
      this.setState({ winPercentageDataLoading: true });
      this.props.fetchWinPercentageChart()
        .then(() => this.setState({ selected: e.selected }))
        .catch(noop)
        .finally(() => this.setState({ winPercentageDataLoading: false }));
    }
    else
    {
      this.setState({ selected: e.selected });
    }
  }

  private getRateType(): string {
    if (this.state.chargeType === 100 || this.state.chargeType === 5000) return 'mileage';
    if (this.state.chargeType === 5001) return 'percentlh';
    if (this.state.chargeType === 50) return 'percentfuel';
    return 'flat';
  }

  private filterChargeTypeChange(event: ComboBoxFilterChangeEvent) {
    this.setState({
      chargeTypesFiltered: this.filterChargeTypeData(event.filter),
      chargeTypeFilter: event.filter.value
    });
  }

  private filterChargeTypeData(filter: FilterDescriptor) {
    const data = this.state.chargeTypes.slice();
    return filterBy(data, filter);
  }

  private setChargeType(e: ComboBoxChangeEvent) {
    if (e.target.value) {
      this.setState({ chargeType: e.target.value.RateDescriptionID }, () => {
        const rateType = this.getRateType();
        if (this.props.data && rateType === 'percentlh') {
          this.setState({ chargeAmount: (this.state.chargePercentage / 100) * this.props.data.LineHaulAmount });
        }
      });
    }
  }

  private resetFilterChangeTypes(e: ComboBoxCloseEvent) {
    this.setState({ chargeTypesFiltered: this.state.chargeTypes });
  }

  public componentDidMount() {
    fetchApi('/api/Quote/GetChargeTypes')
      .then((chargeTypes: ChargeType[]) => {
        this.setState({ chargeTypes, chargeTypesFiltered: chargeTypes });
      });
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (nextProps.fuelRate != this.props.fuelRate) {
      this.setState({ chargePercentage: nextProps.fuelRate * 100 });
    }
    if (!nextProps.winPercentageData && this.state.selected === 1) {
      this.setState({ selected: 0 });
    }
    if (nextProps.data && nextProps.QuoteID != this.props.QuoteID) {
      let allInRate = 0;
      if (nextProps.data.RateComment == 'User Override') {
        if (nextProps.data.LineItems.length == 1) {
          allInRate = nextProps.data.LineItems[0].Rate;
        } else {
          allInRate = nextProps.data.Amount;
        }
      }
      this.setState({ allInRate });
    }
  }

  private showModal() {
    this.setState({ showModal: true });
  }

  private hideModal() {
    this.setState({ chargeType: 0, chargeAmount: 0, chargeRate: 0, chargePercentage: this.props.fuelRate * 100, showModal: false });
  }

  private setPerMileRate() {
    if (this.state.allInRate < 1) {
      alert("Please enter a per mile rate!");
      return;
    }

    if (this.props.distance < 1) {
      alert("Please fix quote mileage!");
      return;
    }

    this.props.replaceCharges([
      {
        ChargeType: this.state.chargeTypes.filter(x => x.RateDescriptionID === 50)[0], // Linehaul
        Quantity: this.props.distance,
        QuantityUoM: 2,
        Rate: this.state.allInRate,
        RateUoM: 8,
        Amount: this.state.allInRate * this.props.distance,
      }]);
  }

  private setAllInRate() {
    if (this.props.data) {

      if (this.state.allInRate < 1) {
        alert("Please enter an all in rate!");
        return;
      }

      this.calculateAllInRate(this.state.allInRate);
    }
  }

  private formatFuelRateLineItem(existingRate: QuoteLineItemValue, fuel: number, fuelRate: number): {
    ChargeType: ChargeType,
    Quantity: number,
    QuantityUoM: number,
    Rate: number,
    RateUoM: number,
    Amount: number
  } {
    var rate = {
      ChargeType: this.state.chargeTypes.filter(x => x.RateDescriptionID === existingRate.RateDescriptionID)[0],
      Quantity: 1,
      QuantityUoM: existingRate.QuantityUoM,
      Rate: fuel,
      RateUoM: existingRate.RateUoM,
      Amount: fuel,
    }

    switch (existingRate.RateDescriptionID) {
      case 5000:
        rate.Quantity = existingRate.Quantity;
        rate.Rate = existingRate.Rate;
        break;
      case 5001:
        rate.Quantity = fuelRate > 0 ? (fuel / fuelRate) : 0;
        rate.Rate = fuelRate;
        break;
      case 5003:
        break;
    }

    return rate;
  }

  private addCharge() {

    if (!this.state.chargeAmount) {
      alert("Please enter a charge amount");
      return;
    }
    if (!this.state.chargeType) {
      alert("Please select a charge type");
      return;
    }

    const chargeType = this.state.chargeTypes.filter(x => x.RateDescriptionID === this.state.chargeType)[0];

    const rateType = this.getRateType();
    if (rateType === 'mileage') {
      this.props.addCharge({
        ChargeType: chargeType,
        Quantity: this.props.distance,
        QuantityUoM: 2,
        Rate: this.state.chargeRate,
        RateUoM: 8,
        Amount: this.state.chargeAmount,
      });
    } else if (rateType === 'percentlh') {
      this.props.addCharge({
        ChargeType: chargeType,
        Quantity: this.props.data.LineHaulAmount,
        QuantityUoM: 3,
        Rate: this.state.chargePercentage / 100,
        RateUoM: 3,
        Amount: this.state.chargeAmount,
      });
    } else if (rateType === "percentfuel") {
      var linehaul = this.state.chargeAmount / (1 + (this.state.chargePercentage / 100));
      this.props.addCharge({
        ChargeType: chargeType,
        Quantity: 1,
        QuantityUoM: 1,
        Rate: linehaul,
        RateUoM: 8,
        Amount: linehaul
      });
      if (this.state.chargePercentage > 0) {
        const fuelChargeType = this.state.chargeTypes.filter(x => x.RateDescriptionID === 5001)[0];
        this.props.addCharge({
          ChargeType: fuelChargeType,
          Quantity: linehaul,
          QuantityUoM: 3,
          Rate: this.state.chargePercentage / 100,
          RateUoM: 3,
          Amount: this.state.chargeAmount - linehaul
        });
      }
    } else {
      this.props.addCharge({
        ChargeType: chargeType,
        Quantity: 1,
        QuantityUoM: 1,
        Rate: this.state.chargeAmount,
        RateUoM: 8,
        Amount: this.state.chargeAmount,
      });
    }
    this.hideModal();
  }

  private updateChargeAmount(e: NumericTextBoxChangeEvent, index: number) {
    const chargeType = this.state.chargeTypes.filter(x => x.RateDescriptionID === this.state.chargeType)[0];

    this.props.updateCharge(index, { Amount: e.value, ChargeType: chargeType });
  }

  private removeCharge(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) {
    e.preventDefault();

    this.props.removeCharge(index);
  }

  private useHistoricalRate(rate: number, rateUpDown: number, previousWinPercentage: number | null = null, currentWinPercentage: number | null = null) {

    if (this.props.distance < 1) {
      alert("Please fix quote mileage!");
      return;
    }

    var linehaul = bankersRounding(rate * this.props.distance);
    var fuel = linehaul * this.props.fuelRate;

    // Reuse Fuel Surcharge Per Mile if it exists
    const existingFuels = this.props.data.LineItems.filter(x => x.RateDescriptionID === 5000 && x.Rate > 0);
    if (existingFuels.length !== 0) {
      fuel = existingFuels[0].Amount;
    } else if (this.props.fuelRate < 0.01) {
      alert("Please fix fuel rate!");
      return;
    }

    const accessorials = this.props.data.LineItems
      .filter(x => x.RateType === 65 && this.state.chargeTypes.find(c => c.RateDescriptionID === x.RateDescriptionID))
      .reduce((a, b) => a + b.Amount, 0);

    this.calculateAllInRate(linehaul + fuel + accessorials, true, rateUpDown, previousWinPercentage, currentWinPercentage);
  }

  public calculateAllInRate(total: number, smartbid: boolean = false, rateUpDown: number | null = null, previousWinPercentage: number | null = null, currentWinPercentage: number | null = null) {
    const existingAccessorials = this.props.data.LineItems.filter(x => x.RateType === 65 && this.state.chargeTypes.find(c => c.RateDescriptionID === x.RateDescriptionID));
    const allInRate = total - existingAccessorials.reduce((a, b) => a + b.Amount, 0);
    if (allInRate < 1) {
      alert("Please enter an all in rate that is greater than the existing accessorials!");
      return;
    }

    // Ensure input matches total if called from quote main
    if (!smartbid) {
      this.setState({ allInRate });
    }

    var linehaul = allInRate / (1 + this.props.fuelRate);
    var fuel = allInRate - linehaul;

    // Read current fuel rates if any and just update with the same style
    const existingFuels = this.props.data.LineItems.filter(x => [5000, 5001, 5003].includes(x.RateDescriptionID) && x.Rate > 0);

    // Existing fuel not found
    if (existingFuels.length !== 1) {
      this.props.replaceCharges([
        {
          ChargeType: this.state.chargeTypes.find(x => x.RateDescriptionID === 50), // Linehaul
          Quantity: 1,
          QuantityUoM: 1,
          Rate: linehaul,
          RateUoM: 8,
          Amount: linehaul
        },
        {
          ChargeType: this.state.chargeTypes.find(x => x.RateDescriptionID === 5003), // Fuel
          Quantity: 1,
          QuantityUoM: 1,
          Rate: fuel,
          RateUoM: 8,
          Amount: fuel
        },
        ...existingAccessorials.map(existingAccessorial => ({
          ChargeType: this.state.chargeTypes.find(x => x.RateDescriptionID === existingAccessorial.RateDescriptionID),
          Quantity: existingAccessorial.Quantity,
          QuantityUoM: existingAccessorial.QuantityUoM,
          Rate: existingAccessorial.Rate,
          RateUoM: existingAccessorial.RateUoM,
          Amount: existingAccessorial.Amount
        }))
      ], smartbid, rateUpDown, previousWinPercentage, currentWinPercentage);
    } else {
      // Special case for existing Fuel Surcharge Per Mile
      if (existingFuels[0].RateDescriptionID === 5000) {
        fuel = existingFuels[0].Amount;
        linehaul = allInRate - fuel;
      }

      this.props.replaceCharges([
        {
          ChargeType: this.state.chargeTypes.filter(x => x.RateDescriptionID === 50)[0], // Linehaul
          Quantity: 1,
          QuantityUoM: 1,
          Rate: linehaul,
          RateUoM: 8,
          Amount: linehaul
        },
        this.formatFuelRateLineItem(existingFuels[0], fuel, this.props.fuelRate),
        ...existingAccessorials.map(existingAccessorial => ({
          ChargeType: this.state.chargeTypes.find(x => x.RateDescriptionID === existingAccessorial.RateDescriptionID),
          Quantity: existingAccessorial.Quantity,
          QuantityUoM: existingAccessorial.QuantityUoM,
          Rate: existingAccessorial.Rate,
          RateUoM: existingAccessorial.RateUoM,
          Amount: existingAccessorial.Amount
        }))
      ], smartbid, rateUpDown, previousWinPercentage, currentWinPercentage);
    }
  }
}
