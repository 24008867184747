import * as React from 'react';
import Moment from 'moment-timezone';
import { openWindow } from '../../services/openWindow';
import { OfferValue } from './Vehicles';
import { formatNumber } from '@progress/kendo-intl';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button, SplitButton } from '@progress/kendo-react-buttons';
import { bankersRounding } from 'utils/bankersRounding';
import { checkIcon, starIcon } from '@progress/kendo-svg-icons';

type Props = {
    quoteID: number;
    offer: OfferValue;

    email: (offer: OfferValue) => void;
    reply: (offer: OfferValue) => void;
    copyTemplate: (offer: OfferValue) => void;
    award: (offer: OfferValue) => void;
    reOffer: () => void;
    offerSelected: (total: number, offerId: number) => void;
};

type State = {
    visible: boolean;
}

export default class CarrierOfferButton extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            visible: false,
        }

        this.toggleDialog = this.toggleDialog.bind(this);
        this.openCarrierProfile = this.openCarrierProfile.bind(this);
    }

    public render() {
        if (this.props.offer.Status === 0) {
            return <Button size="small" themeColor='info' onClick={(e) => {
                e.preventDefault();
                this.openCarrierProfile();
            }}>
                Waiting
            </Button>
        }

        if (this.props.offer.Status == 1 || this.props.offer.Status == 3 || this.props.offer.Status == 6) {
            return <SplitButton
                size='small'
                themeColor={this.props.offer.Status == 3 ? 'warning' : 'error'}
                title={this.props.offer.Note}
                onButtonClick={(e) => this.openCarrierProfile()}
                onItemClick={this.props.reOffer}
                text={this.props.offer.Status == 1 ? 'Declined' : this.props.offer.Status == 3 ? 'Expired' : 'Retracted'}
                items={[{ text: 'Re-Offer' }]}
            />
        }

        if (this.props.offer.Status == 2) {
            return <React.Fragment>
                <SplitButton
                    size="small"
                    themeColor='success'
                    disabled={this.props.offer.CarrierOnboarded === false || this.props.offer.Withdrawn}
                    buttonClass='pe-auto'
                    onButtonClick={this.toggleDialog}
                    onItemClick={(e) => {
                        switch (e.itemIndex) {
                            case 0:
                                this.props.offerSelected(bankersRounding(this.props.offer.AllInRate * 1.12), this.props.offer.OfferID);
                                break;
                            case 1:
                                this.props.offerSelected(bankersRounding(this.props.offer.AllInRate * 1.15), this.props.offer.OfferID);
                                break;
                            case 2:
                                this.props.offerSelected(bankersRounding(this.props.offer.AllInRate * 1.20), this.props.offer.OfferID);
                                break;
                        }
                    }}
                    title={this.props.offer.Note}
                    items={[
                        `${formatNumber(this.props.offer.AllInRate * 1.12, "c")} (12%)`,
                        `${formatNumber(this.props.offer.AllInRate * 1.15, "c")} (15%)`,
                        `${formatNumber(this.props.offer.AllInRate * 1.20, "c")} (20%)`
                    ]}
                    icon={this.props.offer.Awarded ? 'star' : this.props.offer.Confirmed ? 'check' : undefined}
                    svgIcon={this.props.offer.Awarded ? starIcon : this.props.offer.Confirmed ? checkIcon : undefined}
                    text={`${formatNumber(this.props.offer.AllInRate, "c")} ${(this.props.offer.AlternativePickup || this.props.offer.AlternativeDelivery) ? " (Alt)" : ""}`}
                />
                {this.state.visible && <Dialog
                    title={this.props.offer.Confirmed ? "Book It Now" : "Bid Response"}
                    onClose={this.toggleDialog}
                    className='dialog-w800'
                >
                    <h3 className="text-center">{formatNumber(this.props.offer.AllInRate, "c")}</h3>
                    <p className="text-center">
                        by <Button fillMode='link' themeColor='primary' className='p-0' onClick={() => this.props.email(this.props.offer)}>{this.props.offer.ContactName}</Button> at <Button fillMode='link' themeColor='primary' className='p-0' onClick={this.openCarrierProfile}>{this.props.offer.AssetName}</Button></p>
                    <p>Notes: {this.props.offer.Note}</p>
                    {this.props.offer.AlternativePickup &&
                        <p>Alt Pickup: {Moment.utc(this.props.offer.AlternativePickup).tz("America/New_York").format("MM/DD/YYYY HH:mm")}</p>}
                    {this.props.offer.AlternativeDelivery &&
                        <p>Alt Delivery: {Moment.utc(this.props.offer.AlternativeDelivery).tz("America/New_York").format("MM/DD/YYYY HH:mm")}</p>}
                    {!this.props.offer.AlternativePickup && !this.props.offer.AlternativeDelivery &&
                        <p>Can make pickup and delivery times.</p>}
                    <DialogActionsBar>
                        <SplitButton
                            text="Reply"
                            themeColor="primary"
                            buttonClass='flex-grow-1'
                            onButtonClick={() => this.props.reply(this.props.offer)}
                            onItemClick={() => this.props.copyTemplate(this.props.offer)}
                            items={[ 'Copy Email' ]}
                        />
                        {this.props.offer.Links.find(x => x.Name == 'Award') && <Button icon="star" svgIcon={starIcon} themeColor="success" onClick={() => this.props.award(this.props.offer)}>Award</Button>}
                        {this.props.offer.Awarded && <Button icon="star" svgIcon={starIcon} themeColor="success" disabled>Awarded</Button>}
                        <Button themeColor='info' onClick={this.props.reOffer}>Re-Offer</Button>
                    </DialogActionsBar>
                </Dialog>}
            </React.Fragment>
        }
    }

    private toggleDialog() {
        this.setState({
            visible: !this.state.visible
        });
    }

    private openCarrierProfile() {
        if (this.props.offer.ID > 0) openWindow(`/Assets/Carrier/${this.props.offer.ID}`);
    }
}
