import * as React from 'react';
import moment from "moment-timezone";
import { Title } from '../utils/title';
import { fetchApi } from '../services/api';
import { ViewModel as HomeViewModel } from 'TypeGen/Home/Get/view-model';
import { Button, FloatingActionButton } from '@progress/kendo-react-buttons';
import Feedback from 'components/Feedback';
import { useBoolean } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom-v5-compat';
import { DashboardResponse, UserPerformance } from './Reports/user';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { formatNumber } from '@progress/kendo-intl';
import { ApiLink } from 'TypeGen/api-link';
import UserPerformanceGoalDialog from './Reports/UserPerformanceGoalDialog';
import { commentIcon, graphIcon } from '@progress/kendo-svg-icons';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [dashboardLoading, setDashboardLoading] = React.useState(false);
  const [salespersonID, setSalespersonID] = React.useState<number | null>(null);
  const [hasUserGoal, setHasUserGoal] = React.useState<boolean>(false);
  const { value: feedback, setTrue: showFeedback, setFalse: hideFeedback } = useBoolean(false);
  const [users, setUsers] = React.useState<UserPerformance[]>([]);
  const [editUserId, setEditUserId] = React.useState<number | null>(null);

  React.useEffect(() => {
    fetchApi("/api/User/Home")
      .then((response: HomeViewModel) => {
        if (response.FeedbackPrompt) showFeedback();
        setSalespersonID(response.SalespersonID);
        setHasUserGoal(response.HasUserGoal);
      })
      .finally(() => setLoading(false));
  }, [showFeedback]);

  const fetchDashboard = React.useCallback(() => {
    if (!hasUserGoal) return;
    setDashboardLoading(true);
    const data = {
      UserID: 0,
      VehicleTypeID: 0,
      BillingCustomerID: 0,
      FromDate: moment.tz("America/New_York").startOf('week').toDate(),
      ToDate: moment.tz("America/New_York").endOf('week').startOf('day').toDate()
    }
    fetchApi('/api/User/Dashboard', data, 'POST')
      .then((response: DashboardResponse) => {
        setUsers(response.Users);
      })
      .finally(() => setDashboardLoading(false));
  }, [hasUserGoal]);

  React.useEffect(() => {
    fetchDashboard();
  }, [fetchDashboard]);

  return <React.Fragment>
    <Title string="Home" />
    <br />
    <div className="container">
      <div className="jumbotron">
        <h1 className="display-4">Welcome to Transcend One!</h1>
        <p className="lead">Choose an item from the top menu to get started.</p>
        {salespersonID && <Button themeColor="primary" className='mr-2' onClick={() => navigate(`/SalesReports`)} icon="graph" svgIcon={graphIcon}>View your personal sales dashboard</Button>}
        {hasUserGoal && <Button themeColor="primary" onClick={() => navigate("/UserReports")} icon="graph" svgIcon={graphIcon}>View performance dashboard</Button>}
      </div>
    </div>
    {editUserId && <UserPerformanceGoalDialog userId={editUserId} close={() => setEditUserId(null)} refresh={fetchDashboard} />}
    {users.length > 0 && <Grid
      data={users.filter(x => x.GoalPercentage !== null)}
      className="mt-2"
      selectedField="Me"
    >
      <Column field="UserName" title="User" cell={(props) => {
        const link = props.dataItem.Links.find((x: ApiLink) => x.Name === 'UpdateGoal');
        return <td>
          {link ? <a href="#" style={{ color: "#007bff" }} onClick={(e) => { e.preventDefault(); setEditUserId(props.dataItem.UserID); }}>{props.dataItem.UserName}</a> : props.dataItem.UserName}
        </td>}} />
      <Column field="QuoteCount" title="Quoted" format="{0:n0}" />
      <Column field="OrderCount" title="Booked" format="{0:n0}" />
      <Column field="DispatchCount" title="Dispatched" format="{0:n0}" />
      <Column field="CustomerCount" title="Customers" format="{0:n0}" />
      <Column field="AverageOrderRPM" title="Order RPM" format="{0:c2}" />
      <Column field="AverageQuoteRPM" title="Quote RPM" format="{0:c2}" />
      <Column field="TotalOrderCharges" title="Order Charges" format="{0:c2}" />
      <Column field="TotalPay" title="Trip Pay" format="{0:c2}" />
      <Column field="TotalQuoteCharges" title="Quote Charges" format="{0:c2}" />
      <Column field="BrokerPercentage" title="Broker %" format="{0:p2}" />
      <Column field="BlindBidPercentage" title="Blind Bid %" format="{0:p2}" />
      <Column field="MarginPercentage" title="Avg. Margin" format="{0:p2}" />
      <Column field="ConversionPercentage" title="Conversion %" format="{0:p2}" />
      <Column field="GoalPercentage" title="Week Goal" cell={(props) => <td>
        {props.dataItem.GoalPercentage !== null ? formatNumber(props.dataItem.GoalPercentage, 'p') : <span className="text-danger">No Goal</span>}
      </td>} />
    </Grid>}
    {feedback && <Feedback onClose={hideFeedback} />}
    <FloatingActionButton
      icon="comment"
      svgIcon={commentIcon}
      text="Feedback"
      themeColor="primary"
      onClick={showFeedback}
    />
  </React.Fragment>;
}

export default Home;