import { Button } from '@progress/kendo-react-buttons';
import { MultiSelect, MultiSelectChangeEvent } from '@progress/kendo-react-dropdowns';
import { Grid, GridCellProps, GridColumn as Column, GridItemChangeEvent, GridRowDoubleClickEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { TextBox, TextBoxChangeEvent } from '@progress/kendo-react-inputs';
import Moment from 'moment-timezone';
import { useCallback, useEffect, useRef, useState } from 'react';
import CenterDivPanel from '../../components/CenterDivPanel';
import useAlert from '../../components/useAlert';
import useConfirm from '../../components/useConfirm';
import { fetchApi } from '../../services/api';
import { JsonResponse } from 'TypeGen/json-response';

type Props = {
    RateMasterID: number;
    disabled?: boolean;
    className?: string;
    //refresh: (tierUpdated: boolean) => void;
    //tiersInEditMode: () => void;
};

type TierSummary = {
    TierGroups: TierGroup[];
    SupportedStates: string[];
};

type TierGroup = {
    TierID: number;
    ModifiedByFullName: string;
    ModifiedDateTime: Date;

    From: string[];
    To: string[];
    Comments: string;

    inEdit?: boolean | string;
    isNew?: boolean;
    Delete?: boolean;
};

interface EditCommandCellProps extends GridCellProps {
    edit: (item: TierGroup) => void;
    remove: (item: TierGroup) => void;
    add: (item: TierGroup) => void;
    discard: () => void;
    update: (item: TierGroup) => void;
    cancel: (item: TierGroup) => void;
    editField: string;
}

const CommentCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    //let dataItem: TierGroup = props.dataItem;
    var value = props.dataItem[props.field] as string;

    const handleChange = (e: TextBoxChangeEvent) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            });
        }
    };

    return (
        <td colSpan={props.colSpan} style={{ textAlign: "left" }}>
            {props.dataItem.inEdit ? <TextBox
                value={value}
                onChange={handleChange}
            /> :
                <span>{value}</span>
            }
        </td>
    );
}

const MultiSelectCell = (props: GridCellProps, supportedStates: string[], editFieldName: string) => {
    let dataItem: TierGroup = props.dataItem;
    var values = props.dataItem[props.field] as string[];

    const multiSelectRef = useRef<MultiSelect>();

    const handleChange = (e: MultiSelectChangeEvent) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.value,
            });
        }
    };

    useEffect(() => { //used for multiline edits
        if (props.field === editFieldName) {
            multiSelectRef?.current?.focus();
        }
    })

    return (
        <td colSpan={props.colSpan} style={{ textAlign: "left" }}>
            {dataItem.inEdit ? <MultiSelect
                ref={multiSelectRef}
                data={supportedStates}
                value={values}
                onChange={handleChange}
            /> :
                <div style={{ maxWidth: '35px', display: 'flex', gap: '4px' }}> {/*too all for doubleclick*/}
                    {values.map((x, key) => (
                        <span key={key} className="badge badge-dark p-2 font-weight-normal" style={{ fontSize: '12px' }}>{x}</span>
                    ))}
                </div>
            }
        </td>
    );
};

const LastModifiedByCell = (props: GridCellProps) => {
    if (!props.field)
      return null;

    let dataItem: TierGroup = props.dataItem;
    return (
      <td colSpan={props.colSpan} style={props.style}>
        <span>{Moment.utc(dataItem.ModifiedDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")} - {dataItem.ModifiedByFullName}</span>
      </td>
    );
};

const Tier = (props: Props) => {
    const { alert } = useAlert();
    const { ConfirmationDialog, confirm } = useConfirm({});
    const [loading, setLoading] = useState(true);
    const editField: string = "inEdit";
    const [field, setField] = useState<string>("");
    const [tierSummary, setTierSummary] = useState<TierSummary>();
    const [tierGroups, setTierGroups] = useState<TierGroup[]>([]);
    const [CSIDataCopy, setCSIDataCopy] = useState<TierGroup[]>([]);

    const EditCommandCell = (_props: EditCommandCellProps) => {
        const inEdit = _props.dataItem[_props.editField];
        let dataItem: TierGroup = _props.dataItem;
        const isNewItem = dataItem.isNew;

        const disabled = dataItem.From.length === 0 || dataItem.To.length === 0;

        return inEdit ? (
            <td className="k-command-cell">
                <Button themeColor={"primary"}
                    disabled={disabled}
                    onClick={() =>
                        isNewItem ? _props.add(dataItem) : _props.update(dataItem)
                    }
                >
                    {isNewItem ? "Save" : "Update"}
                </Button>
                <Button themeColor={"secondary"}
                    onClick={() =>
                        isNewItem ? _props.discard() : _props.cancel(dataItem)
                    }
                >
                    {isNewItem ? "Discard" : "Cancel"}
                </Button>
            </td>
        ) : (
            <td className="k-command-cell">
                <Button themeColor={"primary"}
                    disabled={tierGroups.some(x => x.inEdit === true) || props.disabled === true}
                    onClick={() => _props.edit(dataItem)}>Edit</Button>
                <Button themeColor={"secondary"}
                    disabled={tierGroups.some(x => x.inEdit === true) || props.disabled === true}
                    onClick={async () => {
                        if (!await confirm(`Remove ?`))
                            return;
                        _props.remove(dataItem);
                    }}>Delete</Button>
            </td>
        );
    };

    const MyEditCommandCell = (props: GridCellProps) => (
        <EditCommandCell {...props}
            edit={enterEdit}
            remove={remove}
            add={add}
            discard={discard}
            update={update}
            cancel={cancel}
            editField={editField}
        />
    );

    const enterEdit = (dataItem: TierGroup) => {
        if (tierGroups.some(x => x.inEdit === true || props.disabled === true))
            return;

        //props.tiersInEditMode();

        setTierGroups(
            tierGroups.map((item) =>
                item.TierID === dataItem.TierID ? { ...item, inEdit: true } : item
            )
        );
    };

    const remove = (dataItem: TierGroup) => {
        updateTiers({ ...dataItem, Delete: true });
    };

    const add = (dataItem: TierGroup) => {
        updateTiers(dataItem);
    };

    const update = (dataItem: TierGroup) => {
        updateTiers(dataItem);
    };

    const cancel = (dataItem: TierGroup) => {
        const originalItem = CSIDataCopy.find(
            (p) => p.TierID === dataItem.TierID
        );
        const newData = tierGroups.map((item) =>
            item.TierID === originalItem.TierID ? cloneObject(originalItem) : item
        );

        setTierGroups(newData);
        setField('');
        //props.refresh(false);
    };

    const generateId = () =>
        tierGroups.reduce((acc, current) => Math.max(acc, current.TierID), 0) + 1;

    const addNew = () => {
        //props.tiersInEditMode();
        const newDataItem = {
            inEdit: true, TierID: generateId(), isNew: true,
            From: [], To: []
        } as TierGroup;
        setTierGroups([newDataItem, ...tierGroups]);
    };

    const discard = () => {
        const newData = [...tierGroups];
        newData.splice(0, 1);
        setTierGroups(newData);
    };

    const refresh = useCallback(() => {
        setLoading(true);

        fetchApi(`/api/Quote/GetTiers/${props.RateMasterID}`)
            .then((response: TierSummary) => {
                setTierSummary(response);
                setTierGroups(response.TierGroups);
                setCSIDataCopy(cloneObject(response.TierGroups)) //deep copy/clone   
                setLoading(false);
            })
            .catch(async e => {
                if (!e?.status)
                    await alert('Error: Please see admin');
                else if (e.status !== 404) {
                    await alert(e?.detail);
                }
                setLoading(false);
            });
    }, [props.RateMasterID, alert]);


    const cloneObject = (obj: any): any => {
        return JSON.parse(JSON.stringify(obj));
    }

    const updateTiers = (dataItem: TierGroup) => {
        setLoading(true);

        const data = { ...dataItem }
        fetchApi(`api/Quote/Tier/${props.RateMasterID}`, data, 'POST')
            .then(async (response: JsonResponse) => {
                if (response.Success === false) {
                    setLoading(false);
                    await alert(`Error: ${response.ErrorMessage}`);
                    //props.refresh(false);
                }
                else {
                    setField('');
                    refresh();
                    //props.refresh(true);
                }
            })
            .catch(async e => {
                setLoading(false);
                // If not problem details
                if (!e?.status) await alert('An error occurred while saving.');
            });
    }

    useEffect(() => {
        refresh();
    }, [props.RateMasterID, refresh]);

    const itemChange = (event: GridItemChangeEvent) => {
        const field = event.field || "";
        const newData = tierGroups.map((item) =>
            item.TierID === event.dataItem.TierID
                ? { ...item, [field]: event.value }
                : item
        );

        setField(field);

        setTierGroups(newData);
    }

    const dataView = () => {
        return (<div className={`${props.className ?? ''}`} style={{ position: "relative" }}>
            {loading && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}
            <h4 className="text-left">Tiers</h4>
            <Grid
                style={{
                    maxHeight: `${window.innerHeight * .35}px`,
                }}
                onRowDoubleClick={(e: GridRowDoubleClickEvent) => enterEdit(e.dataItem)}
                data={tierGroups}
                onItemChange={itemChange}
                editField={editField}
            >
                <GridToolbar>
                    <Button themeColor={'primary'}
                        disabled={tierGroups.some(x => x.inEdit === true) || props.disabled === true}
                        title="Add new tier"
                        onClick={addNew}
                    >
                        Add new tier
                    </Button>
                </GridToolbar>
                <Column field="TierID" title="Tier" editable={false} />
                <Column field="From" title="From" cell={(e) => MultiSelectCell(e, tierSummary.SupportedStates, field)} />
                <Column field="To" title="To" cell={(e) => MultiSelectCell(e, tierSummary.SupportedStates, field)} />
                <Column field="Comments" title="Comment" cell={CommentCell} />
                <Column field="ModifiedByFullName" title="Modified By" cell={LastModifiedByCell} width={250} />
                <Column cell={MyEditCommandCell} width={160} />
            </Grid>

            <ConfirmationDialog />
        </div>);
    };

    return dataView();
}


export default Tier;
