
import { useParams } from 'react-router';
import AssetPanel from '../Assets/AssetPanel';

type Params = {
  searchTerm: string;
};

const AssetVehicles = () => {

  const { searchTerm } = useParams<Params>();
  return <div className="form-row">
    <div className="col-lg-12 p-1">
      <AssetPanel
        searchTerm={searchTerm}
        tabIndex={2}
      />
    </div>
  </div>
}

export default AssetVehicles;
