import * as React from 'react';
import { Title } from '../../utils/title';
import { Button } from '@progress/kendo-react-buttons';
import { fetchApi } from '../../services/api';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Checkbox, NumericTextBox } from '@progress/kendo-react-inputs';
import { ViewModel as Settings } from 'TypeGen/AccountSettings/view-model';
import { FilterDescriptor, filterBy } from '@progress/kendo-data-query';

interface Props {}

interface State {
  loading: boolean;
  userFilter: FilterDescriptor;
  settings: null|Settings;
}

const dispatchTeams = [
  { ID: "DTW", Name: "DTW" },
  { ID: "DTW1", Name: 'DTW1' },
  { ID: "DTW2", Name: 'DTW2' },
  { ID: "DTW3", Name: 'DTW3' },
  { ID: "DTW4", Name: 'DTW4' },
  { ID: "DTW5", Name: 'DTW5' },
  { ID: "DTW6", Name: 'DTW6' },
  { ID: "DTW7", Name: 'DTW7' },
  { ID: "DTW8", Name: 'DTW8' },
  { ID: "DTW9", Name: 'DTW9' },
  { ID: "DTW10", Name: 'DTW10' },
  { ID: "DTW11", Name: 'DTW11' },
  { ID: "CLT", Name: 'Charlotte' },
  { ID: "DAY", Name: 'Dayton' },
  { ID: "FWA", Name: 'Fort Wayne' },
  { ID: "GR", Name: 'Grand Rapids' },
  { ID: "TN", Name: 'Tennessee' },
  { ID: "MX", Name: 'Mexico' },
];

const revenueAllocation = [
  { value: 0, text: 'Default Rev. Allocation' },
  { value: 1, text: 'TRUCKLOAD-01' },
  { value: 2, text: 'DEDICATED-03' },
  { value: 3, text: 'EXPEDITE-04' },
  { value: 4, text: 'BROKERAGE-02' },
  { value: 5, text: 'SPECIALIZED-05' },
  //{ value: 6, text: 'TL BROKERAGE-06' },
  { value: 7, text: 'CROSSDOCK/WH-08' },
];

const serviceTeams = [
  { value: 0, text: 'Default Service Team' },
  { value: 1, text: '1-TRUCKLOAD' },
  { value: 2, text: '2-BROKERAGE' },
  { value: 3, text: '3-DEDICATED' },
  { value: 4, text: '4-EXPEDITE' },
  //{ value: 5, text: '5-TL BROKERAGE' },
  { value: 6, text: '6-SPECIALIZED' },
  { value: 7, text: '7-NOT COMMITTED' },
  { value: 8, text: '8-CROSSDOCK/WH' },
];

const orderStatus = [
  { value: 100, text: 'Save As: Available' },
]

export default class AccountSettings extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.saveSettings = this.saveSettings.bind(this);
    this.state = {
      loading: true,
      userFilter: null,
      settings: null,
    };
  }

  public componentDidMount() {
    fetchApi('/api/Account/Settings')
    .then((response) => {
      this.setState({
          loading: false,
          settings: response,
      });
    });
}

  public render() {
    if (this.state.loading || this.state.settings === null) {
      return (
        <span
          className="k-i-loading k-icon"
          style={{ fontSize: 64, position: 'absolute', left: '50%' }}
        />
      );
    }

    return (
      <div className="container card-block k-form k-form-md">
        <Title string="User Settings" />
        {this.state.settings.Users.length > 0 && <DropDownList
          className='my-2'
          filterable
          onFilterChange={(e) => this.setState({ userFilter: e.filter })}
          data={filterBy(this.state.settings.Users, this.state.userFilter)}
          value={this.state.settings.Users.find(x => x.UserID === this.state.settings.UserID)}
          onChange={(e) => {
            this.setState({ loading: true });
            fetchApi(`/api/Account/Settings/${e.target.value.UserID}`)
            .then((response) => {
              this.setState({
                loading: false,
                userFilter: null,
                settings: response,
              });
            });
          }}
          textField='Name'
        />}
        <div className="row">
          <div className="col">
            <legend>User Interface</legend>

            <Checkbox
              label="Dark Mode"
              value={localStorage.getItem('darkmode') !== 'false'}
              onChange={() => {
                if (localStorage.getItem('darkmode') === 'false') {
                  localStorage.setItem('darkmode', 'true');
                  window.location.reload();
                } else {
                  localStorage.setItem('darkmode', 'false');
                  window.location.reload();
                }
              }}
            />

            <legend>Load Board Notifications</legend>

            <Checkbox
              label="Award Loads"
              value={this.state.settings.ReceiveAwardNotifications}
              onChange={(e) => this.setSettings({ ReceiveAwardNotifications: e.value })}
            />

            <br /><br />

            <Checkbox
              label="Open Shipments: Only For Load Boards I Am Watching"
              value={localStorage.getItem('LoadBoard-NotifyWatchingOnly') !== null}
              onChange={() => {
                if (localStorage.getItem('LoadBoard-NotifyWatchingOnly')) {
                  localStorage.removeItem('LoadBoard-NotifyWatchingOnly');
                } else {
                  localStorage.setItem('LoadBoard-NotifyWatchingOnly', 'true');
                }
                this.forceUpdate();
              }}
            />

            <legend>Assets</legend>

            <Checkbox
              label="Defect Notifications"
              value={this.state.settings.ReceiveDefectNotifications}
              onChange={(e) => this.setSettings({ ReceiveDefectNotifications: e.value })}
            />

            <br /><br />

            <Checkbox
              label="Vehicle Compliance Emails"
              value={this.state.settings.ReceiveVehicleChangeEmails}
              onChange={(e) => this.setSettings({ ReceiveVehicleChangeEmails: e.value })}
            />

            <br /><br />

            <Checkbox
              label="Driver Compliance Emails"
              value={this.state.settings.ReceiveDriverChangeEmails}
              onChange={(e) => this.setSettings({ ReceiveDriverChangeEmails: e.value })}
            />

            <br /><br />

            <Checkbox
              label="Owner Compliance Emails"
              value={this.state.settings.ReceiveOwnerChangeEmails}
              onChange={(e) => this.setSettings({ ReceiveOwnerChangeEmails: e.value })}
            />

            <br /><br />

            <Checkbox
              label="Trailer Compliance Emails"
              value={this.state.settings.ReceiveTrailerChangeEmails}
              onChange={(e) => this.setSettings({ ReceiveTrailerChangeEmails: e.value })}
            />

            <legend>Sound Alerts</legend>

            <Checkbox
              label="Chat Incoming Messages"
              value={this.state.settings.SoundChatMessages}
              onChange={(e) => this.setSettings({ SoundChatMessages: e.value })}
            />

            <legend>Order Creation Defaults</legend>
            <DropDownList
              className="mb-2"
              value={revenueAllocation.find(x => x.value == this.state.settings.DefaultRevenueType1)}
              data={revenueAllocation}
              onChange={(e) => this.setSettings({ DefaultRevenueType1: e.target.value.value })}
              textField="text"
            />
            <br />
            <DropDownList
              className="mb-2"
              value={serviceTeams.find(x => x.value == this.state.settings.DefaultServiceTeamID)}
              data={serviceTeams}
              onChange={(e) => this.setSettings({ DefaultServiceTeamID: e.target.value.value })}
              textField="text"
            />
            <br />
            <DropDownList
              className="mb-2"
              value={orderStatus.find(x => x.value == this.state.settings.DefaultOrderStatus)}
              data={orderStatus}
              onChange={(e) => this.setSettings({ DefaultOrderStatus: e.target.value.value })}
              textField="text"
            />
            <br />
            <legend>Quoting</legend>
            <NumericTextBox
              label="Default Search Radius"
              min={25}
              max={500}
              className="mb-2"
              value={this.state.settings.QuoteRadius}
              onChange={(e) => this.setSettings({ QuoteRadius: e.value })}
            />
            {this.state.settings.Locations && <>
              <legend>Location</legend>
              <DropDownList
                className="mb-2"
                value={this.state.settings.Locations.find(x => x.ID == this.state.settings.LocationID)}
                data={this.state.settings.Locations}
                onChange={(e) => this.setSettings({ LocationID: e.target.value.ID })}
                textField="Name"
              />
            </>}
            {this.state.settings.ShiftCategories && <>
              <legend>Shift</legend>
              <DropDownList
                className="mb-2"
                value={this.state.settings.ShiftCategories.find(x => x.ID == this.state.settings.ShiftCategoryID)}
                data={this.state.settings.ShiftCategories}
                onChange={(e) => this.setSettings({ ShiftCategoryID: e.target.value.ID })}
                textField="Name"
              />
            </>}
            <br /><br />
          </div>
          <div className="col">
            <legend>Dispatch Teams</legend>
            {dispatchTeams.map((dispatchTeam, index) => {
              const checked = this.state.settings.DispatchTeams.findIndex(x => x === dispatchTeam.ID) !== -1;
              return <React.Fragment key={index}>
                <Checkbox
                  label={dispatchTeam.Name}
                  value={checked}
                  onChange={(value) => {
                    this.setSettings({
                      DispatchTeams: checked ? this.state.settings.DispatchTeams.filter(x => x !== dispatchTeam.ID) : [...this.state.settings.DispatchTeams, dispatchTeam.ID]
                    });
                  }}
                />
                <br /><br />
              </React.Fragment>
              })}
            <legend>Service Teams</legend>
            <Checkbox
              label="Truckload"
              value={this.state.settings.ServiceTeam1Notifications}
              onChange={(e) => this.setSettings({ ServiceTeam1Notifications: e.value })}
            />
            <br /><br />
            <Checkbox
              label="Brokerage"
              value={this.state.settings.ServiceTeam2Notifications}
              onChange={(e) => this.setSettings({ ServiceTeam2Notifications: e.value })}
            />
            <br /><br />
            <Checkbox
              label="Dedicated"
              value={this.state.settings.ServiceTeam3Notifications}
              onChange={(e) => this.setSettings({ ServiceTeam3Notifications: e.value })}
            />
            <br /><br />
            <Checkbox
              label="Expedite"
              value={this.state.settings.ServiceTeam4Notifications}
              onChange={(e) => this.setSettings({ ServiceTeam4Notifications: e.value })}
            />
            <br /><br />
            <Checkbox
              label="Truckload Brokerage"
              value={this.state.settings.ServiceTeam5Notifications}
              onChange={(e) => this.setSettings({ ServiceTeam5Notifications: e.value })}
            />
            <br /><br />
            <Checkbox
              label="Specialized"
              value={this.state.settings.ServiceTeam6Notifications}
              onChange={(e) => this.setSettings({ ServiceTeam6Notifications: e.value })}
            />
            <br /><br />
            <Checkbox
              label="Not Committed"
              value={this.state.settings.ServiceTeam7Notifications}
              onChange={(e) => this.setSettings({ ServiceTeam7Notifications: e.value })}
            />
          </div>
        </div>

        <Button themeColor="primary" onClick={this.saveSettings}>Save User Settings</Button>
      </div>
    );
  }

  private setSettings(value: Partial<Settings>) {
    const settings = Object.assign({}, this.state.settings, value);
    this.setState({ settings });
  }

  private saveSettings() {
    this.setState({ loading: true });
    fetchApi('/api/Account/Settings', this.state.settings as Settings, 'post')
    .then(() => {
      this.setState({ loading: false });
    });
  }
}
