import './index.css';
import * as React from 'react';
import Timeago from 'react-timeago';
import DailyRevenueAssets from './DailyRevenueAssets';
import DailyRevenueBrokerage from './DailyRevenueBrokerage';
import TripCountByServiceTeam from './TripCountByServiceTeam';
import TripCountToday from './TripCountToday';
import TripCountYesterday from './TripCountYesterday';
import TripCountTomorrow from './TripCountTomorrow';
import VehicleAverageLA from './VehicleAverageLA';
import TripCountNextMonday from './TripCountNextMonday';
import ConversionPercentage from './ConversionPercentage';
import { Title } from '../../utils/title';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { useInterval, useUpdateEffect } from 'usehooks-ts';
import { Button } from '@progress/kendo-react-buttons';
import { arrowRotateCwIcon } from '@progress/kendo-svg-icons';

export const filters: { Name: string, Filter: string }[] = [
  { Name: 'All', Filter: '' },
  { Name: 'DTW', Filter: 'DTW*' },
  { Name: 'MEX', Filter: '*MX' },
  { Name: 'Agents', Filter: 'CLT,CLE,CLTMX,CLEMX,TN,TNMX' },
  { Name: 'DAY', Filter: 'DAY' },
]

 const CLevelReports = () => {

  const { status } = useThemeSwitcher();
  const [lastRefresh, setLastRefresh] = React.useState(new Date());
  const tripCountToday = React.useRef<TripCountToday>();
  const tripCountByServiceTeam = React.useRef<TripCountByServiceTeam>();
  const tripCountTomorrow = React.useRef<TripCountTomorrow>();
  const vehicleAverageLA = React.useRef<VehicleAverageLA>();
  const dailyRevenueAssets = React.useRef<DailyRevenueAssets>();
  const dailyRevenueBrokerage = React.useRef<DailyRevenueBrokerage>();
  const tripCountYesterday = React.useRef<TripCountYesterday>();
  const tripCountNextMonday = React.useRef<TripCountNextMonday>();
  const conversionPercentage = React.useRef<ConversionPercentage>();
  const conversionPercentageDtw = React.useRef<ConversionPercentage>();
  const [filter, setFilter] = React.useState(filters[0]);

  const refresh = () => {
    tripCountToday.current?.refreshData();
    tripCountByServiceTeam.current?.refreshData();
    tripCountTomorrow.current?.refreshData();
    vehicleAverageLA.current?.refreshData();
    dailyRevenueAssets.current?.refreshData();
    dailyRevenueBrokerage.current?.refreshData();
    tripCountYesterday.current?.refreshData();
    tripCountNextMonday.current?.refreshData();
    conversionPercentage.current?.refreshData();
    conversionPercentageDtw.current?.refreshData();
    setLastRefresh(new Date());
  }

  useUpdateEffect(() => {
    refresh();
  }, [filter]);

  useInterval(() => {
    refresh();
  }, 5 * 60 * 1000);

  const formatter = (value: number, unit: string, suffix: string, epochMiliseconds: number) => {
    return unit === 'second' ? 'now' : `${value} ${unit}${value > 1 ? 's' : ''} ${suffix}`;
  }

  if (status !== 'loaded') {
    return <div>Loading...</div>;
  }

  return <React.Fragment>
    <Title string="C-Level Reports" />
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <a className="navbar-brand" href="/CLevelReports">C-Level Reports</a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
              {filter.Name}
            </a>
            <div className="dropdown-menu">
              {filters.map((x, index) => <a key={index} className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); setFilter(x); }}>{x.Name}</a>)}
            </div>
          </li>
        </ul>
      </div>
      <ul className="nav navbar-nav navbar-right">
        <Button
          title="Refresh"
          icon="refresh"
          svgIcon={arrowRotateCwIcon}
          onClick={refresh}
        />
      </ul>
    </nav>
    <div className="container-fluid">
      <div className="row">
        <TripCountToday ref={tripCountToday} filter={filter} />
        <TripCountTomorrow ref={tripCountTomorrow} filter={filter} />
        <TripCountYesterday ref={tripCountYesterday} filter={filter} />
        <TripCountNextMonday ref={tripCountNextMonday} filter={filter} />
        <VehicleAverageLA ref={vehicleAverageLA} />
        <TripCountByServiceTeam ref={tripCountByServiceTeam} filter={filter} />
        <DailyRevenueAssets ref={dailyRevenueAssets} filter={filter} />
        <DailyRevenueBrokerage margin ref={dailyRevenueBrokerage} filter={filter} />
        <ConversionPercentage ref={conversionPercentage} filter={filter} />
        <ConversionPercentage ref={conversionPercentageDtw} filter={filters[1]} />
      </div>
    </div>
    <div className="text-muted text-center my-2">Last Refresh: <Timeago formatter={formatter} date={lastRefresh} /></div>
  </React.Fragment>
}

export default CLevelReports;
