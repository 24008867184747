import { Button } from '@progress/kendo-react-buttons';
import { Loader } from '@progress/kendo-react-indicators';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { useCallback, useState } from 'react';
import CenterDivPanel from '../../components/CenterDivPanel';
import KendoWindow from '../../components/KendoWindow';
import useAlert from '../../components/useAlert';
import useConfirm from '../../components/useConfirm';
import { downloadBlobAsFile, fetchApi, fetchByteData } from '../../services/api';
import { Title } from "../../utils/title";
import OwnerAutoComplete from '../AssetOwners/OwnerAutoComplete';
import { OwnerViewModel } from '../Assets/OwnerGrid';
import PayPeriodDropDownList from './PayPeriodDropDownList';
import { downloadIcon, envelopIcon, fileIcon } from '@progress/kendo-svg-icons';

type Props = {
  //OwnerID?: number;
  PayPeriodID: number;
  onDialogClose?: () => void;
};

const PaySheetViewer = (props: Props) => {
  const { alert } = useAlert();
  const { ConfirmationDialog, confirm } = useConfirm({});
  const [loading, setLoading] = useState(false);
  const [html, setHtml] = useState('');
  const [filterByOwner, setFilterByOwner] = useState<OwnerViewModel>();
  const [filterByPayPeriod, setFilterByPayPeriod] = useState(props.PayPeriodID);
  const [showUnpaidTrips, setShowUnpaidTrips] = useState(true);

  const emailOwnerPaySheet = () => {
    setLoading(true);

    const data = {
      ShowUnpaidTrips: showUnpaidTrips
    };

    fetchApi(`api/Settlements/EmailPaySheet/${filterByPayPeriod}/${filterByOwner.OwnerID}`, data, 'POST')
      .then(() => {
        setLoading(false);
      })
      .catch(async e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) await alert('An error occurred sending the email.');
      });
  }

  const refresh = useCallback(() => {
    setLoading(true);

    const data = {
      ShowUnpaidTrips: showUnpaidTrips
    };

    const fetchData = async () => {
      try {
        const response = await fetch(`/api/Settlements/ViewPaySheet/${filterByPayPeriod}/${filterByOwner.OwnerID}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          });

        if (!response.ok) {
          return;
          //throw new Error('Network response was not ok');
        }
        const responseData = await response.text();
        setHtml(responseData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, [filterByPayPeriod, filterByOwner, showUnpaidTrips]);

  const downloadPaySheetPDF = () => {
    setLoading(true);

    const data = {
      ShowUnpaidTrips: showUnpaidTrips
    };

    fetchByteData(`/api/Settlements/DownloadPaySheet/${filterByPayPeriod}/${filterByOwner.OwnerID}`, data, 'POST')
      .then((resp) => {
        setLoading(false);
        downloadBlobAsFile(resp.blob, resp.filename);
      })
      .catch(async e => {
        setLoading(false);
        // If not problem details                
        if (!e?.status) await alert('An error occurred.');
      });
  };

  const dataView = () => {
    return (<div className="mt-4">
      <Title string={`Owner Pay Sheet`} />
      {loading && <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>}
      {/*         <h4 className="text-left">Owner Pay Sheet</h4>*/}
      <div className={props.onDialogClose ? "container-fluid" : "container-xl"}>
        <div>
          <div className="row">
            <div className="col-md-auto px-1">
              <Label className="mr-2">Owner:</Label>
              <OwnerAutoComplete
                setFocus
                includeRecentlyRetiredOwners
                selectedOwnerID={filterByOwner != null ? filterByOwner.OwnerID : null}
                style={{ width: '250px' }}
                onSelectedOwner={(e) => {
                  setHtml('');
                  setFilterByOwner(e);
                }}
              />
            </div>
            <div className="col-md-auto px-1">
              <Label className="mr-2">Pay Period:</Label>
              <PayPeriodDropDownList
                style={{ width: 250 }}
                onSelected={(e) => {
                  setFilterByPayPeriod(e.PayPeriodID);
                }}
                selectedPayPeriodID={filterByPayPeriod}
                includeClosedPayPeriods={true}
                includeOpenPayPeriods={true}
              />
            </div>
            <div className="col-md-auto px-1 d-flex align-items-center">
              <div>
                <Checkbox
                  value={showUnpaidTrips}
                  onChange={(e) => setShowUnpaidTrips(e.value)}
                  label="Show Unpaid Trips?"
                />
              </div>
            </div>
            <div className="col-md-auto px-1">
              <Button className="mr-4" themeColor={'primary'} icon="file" svgIcon={fileIcon}
                disabled={filterByOwner == null}
                onClick={() => { refresh(); }}
              >
                View Pay Sheet
              </Button>
            </div>
            <div className="col-md-auto px-1">
              {html.length > 0 && filterByOwner && <Button themeColor={'secondary'} icon="download" svgIcon={downloadIcon}
                onClick={() => downloadPaySheetPDF()}
              >
                Download Pay Sheet
              </Button>}
            </div>
            <div className="col-md-auto px-1">
              {html.length > 0 && filterByOwner &&
                <Button themeColor={'secondary'} icon="email" svgIcon={envelopIcon}
                  onClick={async () => {
                    if (!await confirm(`E-Mail Owner Pay Sheet?`, 'Confirm'))
                      return;
                    emailOwnerPaySheet()
                  }}
                >
                  E-Mail Pay Sheet
                </Button>}
            </div>
          </div>
        </div>

        <hr></hr>
        <div className="px-2" dangerouslySetInnerHTML={{ __html: html }} />
      </div>

      <ConfirmationDialog />
    </div>);
  };

  if (props.onDialogClose) {
    return <KendoWindow
      autoSizePercentage={95}
      title="Owner Pay Sheet"
      resizable
      onClose={props.onDialogClose}
    >
      {dataView()}
    </KendoWindow >
  }

  return dataView();
}

export default PaySheetViewer;
