import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useEffect, useState } from 'react';
import { fetchApi } from '../../../services/api';
import { Button } from '@progress/kendo-react-buttons';
import { ApiLink } from 'TypeGen/api-link';
import { GridLoadingPanel } from 'components/GridLoadingPanel';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';

type Props = {
    Link: ApiLink;
    CloseDialog: () => void;
}

type AccessorialRate = {
    Description: string;
    RateDescriptionID: number;
    Break01: number;
    Break02: number;
    Break03: number;
    Break04: number;
    Break15: number;
}

type Response = {
    CustomerNumber: string;
    Rates: AccessorialRate[];
}

const CustomerAccessorialRatesPopup = ({ Link, CloseDialog }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [customerNumber, setCustomerNumber] = useState<string>("Customer");
    const [accessorialRates, setAccessorialRates] = useState<AccessorialRate[]>([]);

    useEffect(() => {
        fetchApi(Link.Link, {}, Link.Method)
            .then((response: Response) => {
                setLoading(false);
                setCustomerNumber(response.CustomerNumber);
                setAccessorialRates(response.Rates);
            })
            .catch(() => {
                setLoading(false);
                alert("Unable to load");
            });
    }, [Link]);

    return <Dialog title={`${customerNumber} Accessorial Rates`} onClose={CloseDialog} className='dialog-w800'>
        {loading && <GridLoadingPanel />}
        <Grid data={accessorialRates} scrollable='none'>
            <GridNoRecords>No Accessorials Rates By Vehicle Type Found</GridNoRecords>
            <Column field="Description" title="Charge Type" />
            <Column field="Break01" title="CARGO VAN" />
            <Column field="Break15" title="SPRINTER" />
            <Column field="Break02" title="SMALL STRAIGHT" />
            <Column field="Break03" title="LARGE STRAIGHT" />
            <Column field="Break04" title="TRACTOR" />
        </Grid>
    </Dialog>
}

export default CustomerAccessorialRatesPopup;
