import { CompositeFilterDescriptor, DataSourceRequestState, FilterDescriptor, State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { Loader } from "@progress/kendo-react-indicators";
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { debounce } from 'ts-debounce';
import EmailCell from '../../components/cells/EmailCell';
import PhoneCellAndExtension from '../../components/cells/PhoneCellAndExtension';
import CenterDivPanel from '../../components/CenterDivPanel';
import { fetchApi } from '../../services/api';
import { Title } from '../../utils/title';
import AddCustomerContactDialog from './AddCustomerContactDialog';
import { CustomerContactCell, CustomerContactViewModel } from './CustomerContactGrid';
import { CustomerNavBar } from './NavBar';
import { plusIcon } from '@progress/kendo-svg-icons';

type RouteComponentParams = {
  customerId: string;
};

type CustomerContactResult = {
  Data: CustomerContactViewModel[],
  Total: number,
}

//const defaultAndFilter = {
//    logic: 'and', filters: [
//        { field: 'Active', operator: 'eq', value: true },
//    ]
//} as CompositeFilterDescriptor;

const resetDataState = {
  skip: 0,
  take: 50,
  sort: [{
    field: "FullName",
    dir: "asc"
  }],
} as GridState;

const defaultAndFilter = {
  logic: 'and', filters: [
    { field: 'BusinessUnitID', operator: 'eq', value: 2610 },
    { field: 'Active', operator: 'eq', value: true }
  ]
} as CompositeFilterDescriptor;

const CustomerContacts = () => {
  const { customerId: customerIdParam } = useParams<RouteComponentParams>();
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState(true);
  const [dataState, setDataState] = useState<DataSourceRequestState>({ ...resetDataState, filter: { ...defaultAndFilter } });

  const [customerContacts, setCustomerContacts] = useState<CustomerContactResult>();
  const [showAddContactDialog, setShowAddContactDialog] = useState(false);

  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFilter = useCallback(
    debounce((func: () => void) => {
      func();
    }, 750), []);

  const refresh = useCallback(() => {
    if (query) {
      setLoading(true);
      const data = {
        //Active: true,
        CustomerID: customerIdParam
      };

      const queryStr = `${toDataSourceRequestString(dataState)}`;
      fetchApi(`/api/Customer/CustomerContacts?${queryStr}`, data, 'POST')
        .then(data => {
          setLoading(false);
          setCustomerContacts(data);
        })
        .catch(err => {
          alert(err);
          setLoading(false);
        });
    }
  }, [customerIdParam, dataState, query]);

  useEffect(() => {
    refresh();
  }, [customerIdParam, refresh]);

  return (
    <div className="container-fluid" style={{ position: "relative" }}>
      <CustomerNavBar id={customerIdParam} currentTab="Contacts" />
      <Title string="Contacts" />
      {showAddContactDialog && <AddCustomerContactDialog
        CustomerId={Number(customerIdParam)}
        CloseDialog={(newCustomerContactId) => {
          if (newCustomerContactId > 0) history.push(`/Customers/Customer/Contact/${newCustomerContactId}`);
          setShowAddContactDialog(false);
        }}
      />}
      {loading && <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>}
      <Grid
        {...dataState}
        data={customerContacts?.Data}
        total={customerContacts?.Total}
        sortable
        pageable
        filterable
        onDataStateChange={(e) => { setQuery(false); setDataState(e.dataState); debounceFilter(() => { setQuery(true) }); }}
      >
        <GridToolbar>
          <Button
            icon="plus"
            svgIcon={plusIcon}
            themeColor="primary"
            onClick={() => setShowAddContactDialog(true)}
          > Contact
          </Button>
          <Button
            className="ml-1"
            themeColor={((dataState.filter as CompositeFilterDescriptor).filters as FilterDescriptor[]).find(x => x.field === 'Active').value === true ? 'primary' : 'warning'}
            onClick={() => {
              let activeFilter = ((dataState.filter as CompositeFilterDescriptor).filters as FilterDescriptor[]).find(x => x.field === 'Active');
              activeFilter.value = !(activeFilter.value as boolean);
              setDataState({ ...dataState });
            }}
          >{((dataState.filter as CompositeFilterDescriptor).filters as FilterDescriptor[]).find(x => x.field === 'Active').value === true ? 'Active' : 'Retired'}</Button>
        </GridToolbar>
        <Column field="FullName" title="Name" cell={CustomerContactCell} />
        <Column field="EMailAddress" title="E-Mail" cell={EmailCell} />
        <Column field="PhoneNumber" title="Phone" cell={PhoneCellAndExtension} />
        <Column field="Title" title="Title" />
        {/*<Column field="CustomerNumber" title="Customer" cell={CustomerCell} filterable={false} />*/}
      </Grid>
    </div>
  )
}

export default CustomerContacts;