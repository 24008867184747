import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Grid, GridColumn as Column, GridNoRecords, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { Upload as KendoUpload, UploadOnAddEvent, UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';
import * as React from 'react';
import LoadingPanel from '../../components/LoadingPanel';
import { fetchApi } from '../../services/api';
import { kendoUploadOnStatusChangeEventHandler } from '../../utils/kendo';
import documentActionCell from './DocumentActionPopup';
import DocumentEdit from './DocumentEdit';
import { DocumentCreatedCell } from './DocumentTitleCell';
import { DocumentType } from 'TypeGen/Documents/document-type';

export enum DocumentEntityType {
    Customer = 0,
    Driver = 1,
    Carrier = 2,
    PurchaseOrder = 3,
    Vehicle = 4,
    DriverAccident = 5,
}

interface Props {
    IsWindow?: boolean;
    CloseDocumentModal?: (numberOfDocuments: number) => void;
    EntityType: DocumentEntityType;
    EntityId: number;
    EntityNumber?: string;
    DocumentType?: DocumentType;
}

type State = {
    documents: Document[];
    isLoading: boolean;
    sort: SortDescriptor[];
    selectedDocument: Document | null;
    openEditDialog: boolean;
}

export type Document = {
    DocumentID: number;
    Title: string;
    Description: string;
    DocumentType: number;
    DocumentName: string;
    CannotDisplay: boolean;
}

export default class Documents extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            documents: [],
            isLoading: false,
            sort: [],
            selectedDocument: null,
            openEditDialog: false
        }

        this.sortChange = this.sortChange.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
    }

    public componentWillMount() {
        this.requestDocuments();
    }

    private onAdd(e: UploadOnAddEvent) {
        this.setState({
            selectedDocument: { ...this.state.selectedDocument, Title: e.affectedFiles[0].name, CannotDisplay: e.affectedFiles[0].extension === '.msg' || e.affectedFiles[0].extension === '.eml' },
        });
    }

    private onStatusChange(e: UploadOnStatusChangeEvent) {
        this.setState({ isLoading: false });
        kendoUploadOnStatusChangeEventHandler(e, () => {
            this.setState({
                selectedDocument: { ...this.state.selectedDocument, DocumentID: e.response.response.DocumentID },
                openEditDialog: this.props.DocumentType === undefined
            });

            if (this.props.DocumentType !== undefined) {
                this.requestDocuments();
            }
        });
    }

    private sortChange(event: GridSortChangeEvent) {
        this.setState({
            sort: event.sort,
            documents: orderBy(this.state.documents, event.sort)
        });
    }

    public render() {
        return (
            <>
                {this.props.IsWindow ? <>
                    <Dialog
                        className='dialog-w800'
                        title={`Documents${(this.props.EntityNumber) ? " (" + this.props.EntityNumber + ")" : ""}`}
                        onClose={() => this.props.CloseDocumentModal(this.state.documents.length)}
                    >
                        {this.state.isLoading && <LoadingPanel />}
                        {this.InnerRender()}
                    </Dialog>
                </> : this.InnerRender()}
                {this.state.openEditDialog &&
                    <DocumentEdit
                        Document={this.state.selectedDocument}
                        EntityType={this.props.EntityType} //0 = customer
                        EntityId={this.props.EntityId} //customerId
                        CloseDocumentModal={(requestDoc: boolean) => { this.setState({ selectedDocument: null, openEditDialog: false, isLoading: false }, () => { this.requestDocuments(); }); }}
                    />
                }
            </>
        );
    }

    private InnerRender() {
        const allowedExtensions = ['.jpeg', '.jpg', '.png', '.gif', '.pdf', '.doc', '.docx', '.csv', '.xls', '.xlsx', '.msg', '.eml', '.mp4'];
        return <>
            <Grid
                data={this.state.documents}
                sortable={{ allowUnsort: true }}
                sort={this.state.sort}
                onSortChange={this.sortChange}
            >
                <GridNoRecords>
                    No Documents Uploaded
                </GridNoRecords>
                {/*<Column field="Title" cell={DocumentTitleCell} />*/}
                <Column field="Title" title="Title" />
                <Column field="DocumentName" title="Type" />
                <Column field="CreatedDate" title="Created Date" cell={DocumentCreatedCell} />
                <Column title="Action" cell={documentActionCell((document: Document) => {
                    this.setState({
                        selectedDocument: document,
                        openEditDialog: true
                    })
                })} width="150px" />
            </Grid>
            <br />
            <KendoUpload
                batch={false}
                multiple={false}
                defaultFiles={[]}
                restrictions={{
                    allowedExtensions: allowedExtensions
                }}
                saveUrl={this.props.DocumentType
                    ? `/api/Document/Upload/${this.props.EntityType}/${this.props.EntityId}/${this.props.DocumentType}`
                    : `/api/Document/Upload/${this.props.EntityType}/${this.props.EntityId}`} //send selectedDocument.title in link, then work on save, teh test with pdf files
                saveField="file"
                saveMethod="POST"
                onBeforeUpload={() => this.setState({ isLoading: true })}
                onAdd={this.onAdd}
                onStatusChange={this.onStatusChange}
            />
            <p className="p-1">File types supported: {allowedExtensions.join(', ')}</p>
        </>
    }

    private requestDocuments() {
        this.setState({ isLoading: true });
        fetchApi(this.props.DocumentType ? `/api/Document/List/${this.props.EntityType}/${this.props.EntityId}/${this.props.DocumentType}` : `/api/Document/List/${this.props.EntityType}/${this.props.EntityId}`)
            .then((documents: Document[]) => {
                this.setState({ documents });
            }).finally(() => this.setState({ isLoading: false }));
    }
}
