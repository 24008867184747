import * as React from 'react';
import Moment from 'moment';
import TimeAgo from 'react-timeago';
import { fetchApi } from '../../services/api';
import { ILink } from '../../types/link';
import { Dialog } from '@progress/kendo-react-dialogs';

type Props = {
  Name: string;
  PhoneNumber: string;
  CloseDialog: (newOverallScore: CarrierRatingOverallScore) => void;
}

type State = {
  newScore: number;
  newReview: string;

  isLoading: boolean;

  // Reviews
  overallScore: number;
  reviews: Review[];
}

type Response = {
  OverallScore: number;
  Reviews: Review[];
}

type CarrierRatingOverallScore = {
  Score: number;
  ReviewCount: number;
}

type Review = {
  Author: string;
  Score: number;
  Review: string;
  Date: Date;
  Links: ILink[];
}

export default class CarrierReviews extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      newScore: 0,
      newReview: '',

      isLoading: true,

      overallScore: 0,
      reviews: [],
    };

    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
  }

  public componentWillMount() {
    this.fetch();
  }

  public render() {
    return (
      <Dialog
        title={`${this.props.Name} Reviews`}
        minWidth="90%"
        height="90%"
        onClose={() => this.props.CloseDialog({ Score: this.state.overallScore * 20, ReviewCount: this.state.reviews.length } as CarrierRatingOverallScore)}
      >
        
        <h3>Write a review</h3>
        <div className="form-group row">
          <label className="col-md-3 col-form-label">Rating</label>
          <div className="col-md-6">
            <img
              height={17}
              onClick={() => this.setState({ newScore: 1 })}
              style={{ opacity: this.state.newScore >= 1 ? 1 : .3 }}
              src="/images/CarrierRatingOne.png" alt=""
            />
            <img
              height={17}
              onClick={() => this.setState({ newScore: 2 })}
              style={{ opacity: this.state.newScore >= 2 ? 1 : .3 }}
              src="/images/CarrierRatingOne.png" alt=""
            />
            <img
              height={17}
              onClick={() => this.setState({ newScore: 3 })}
              style={{ opacity: this.state.newScore >= 3 ? 1 : .3 }}
              src="/images/CarrierRatingOne.png" alt=""
            />
            <img
              height={17}
              onClick={() => this.setState({ newScore: 4 })}
              style={{ opacity: this.state.newScore >= 4 ? 1 : .3 }}
              src="/images/CarrierRatingOne.png" alt=""
            />
            <img
              height={17}
              onClick={() => this.setState({ newScore: 5 })}
              style={{ opacity: this.state.newScore >= 5 ? 1 : .3 }}
              src="/images/CarrierRatingOne.png" alt=""
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-md-3 col-form-label">Carrier Review</label>
          <div className="col-md-6">
            <textarea
              className="form-control"
              placeholder="Carrier Review..."
              value={this.state.newReview}
              onChange={(event) => {
                this.setState({
                  newReview: event.target.value
                });
              }}
            />
          </div>
        </div>
        <button className="btn btn-primary" onClick={this.save}>Add Review</button>
        <br /><br />
        {this.state.reviews.map((review, index) => {
          return <div className="card mb-2" key={index}>
            <div className="card-body">
              <h5 className="card-title">
                <img style={{ clipPath: `inset(0% ${Math.abs(review.Score - 100)}% 0% 0%)` }} height={24} src="/images/CarrierRating.png" alt="" />
              </h5>
              <h6 className="card-subtitle mb-2 text-muted"><TimeAgo date={Moment.utc(review.Date).toDate()} /> - {review.Author}</h6>
              <p className="card-text">{review.Review}</p>
              {review.Links.find(x => x.Name === 'Delete') && <button type="button" className="btn btn-danger" onClick={() => this.delete(review)}>Delete</button>}
            </div>
          </div>
        })}
        {this.state.reviews.length === 0 && <h3>No Reviews Yet.</h3>}
      </Dialog>
    );
  }

  private fetch() {
    const phoneNumber = this.props.PhoneNumber.replace(/\D/g, '').slice(-10);
    fetchApi(`/api/CarrierRating/Get/${phoneNumber}`)
      .then((response: Response) => {
        this.setState({
          isLoading: false,
          overallScore: response.OverallScore,
          reviews: response.Reviews
        });
      });
  }

  private save() {
    if (!this.state.newScore) {
      alert("Please select a 1-5 rating");
      return;
    }

    if (!this.state.newReview) {
      alert("Please write a review");
      return;
    }

    const phoneNumber = this.props.PhoneNumber.replace(/\D/g, '').slice(-10);
    const data = {
      Name: this.props.Name,
      PhoneNumber: phoneNumber,
      Score: this.state.newScore,
      Review: this.state.newReview,
    }
    fetchApi("/api/CarrierRating/Add/", data, 'POST')
      .then(() => {
        this.setState({ newScore: 0, newReview: '' });
        this.fetch();
      });
  }

  private delete(review: Review) {
    if (window.confirm("Are you sure?")) {
      const deleteLink = review.Links.find(x => x.Name == "Delete");
      fetchApi(deleteLink.Link, {}, deleteLink.Method)
        .then(() => {
          this.fetch();
        })
    }
  }
}
