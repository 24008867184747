import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { fetchApi } from '../../services/api';
import { CompositeFilterDescriptor, DataSourceRequestState, State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn as Column, GridDataStateChangeEvent, GridEvent, GridFilterChangeEvent, GridSortChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { ILink } from '../../types/link';
import { BooleanFilter, Filter, FilterChangeEvent, NumericFilter, Operators, TextFilter } from '@progress/kendo-react-data-tools';
import { Title } from '../../utils/title';
import { useDebouncedCallback } from 'use-debounce';
import { Button } from '@progress/kendo-react-buttons';
import AddSalespersonDialog from './AddSalespersonDialog';
import { Link } from 'react-router-dom';
import { GridLoadingPanel } from 'components/GridLoadingPanel';
import { arrowRotateCwIcon, plusIcon } from '@progress/kendo-svg-icons';

export type SalesPeopleViewModel = {
    SalespersonID: number;
    TerritoryID: number;
    Territory: string;
    Number: string;
    FullName: string;
    FirstName: string;
    LastName: string;
    Address: string;
    City: string;
    State: string;
    ZipCode: string;
    Active: boolean;
    EmailAddress: string;
    Hash: string;
    Links: ILink[];
}

const SalespeopleGrid = () => {
    const resetDataState = {
        skip: 0,
        take: 50,
        sort: [{
            field: "FirstName",
            dir: "asc"
        }]
    } as GridState;

    const defaultAndFilter = {
        logic: 'and', filters: [
            { field: 'active', operator: 'eq', value: true },
        ]
    } as CompositeFilterDescriptor;

    const sessionStorageAndFilter = sessionStorage.getItem("SalespeopleGrid-andFilter");
    let andFilter: CompositeFilterDescriptor = null;
    if (sessionStorageAndFilter) {
        andFilter = JSON.parse(sessionStorageAndFilter);
    } else {
        andFilter = { ...defaultAndFilter };
    }
    const [dataState, setDataState] = useState<DataSourceRequestState>({ ...resetDataState, filter: andFilter });
    const [loading, setLoading] = useState(true);
    const [records, setRecords] = useState<SalesPeopleViewModel[]>([]);
    const [showAddSalespersonDialog, setShowAddSalespersonDialog] = useState(false);
    const totalRecords = useRef(0);
    const DataState_Take = 50;

    useLayoutEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    const fetchRecords = useCallback((append: boolean) => {
        sessionStorage.setItem("SalespeopleGrid-andFilter", JSON.stringify(dataState.filter));
        setLoading(true);
        const dataGridState = { ...dataState };
        if (!append) {
            dataGridState.take = DataState_Take;
            dataGridState.skip = 0;
            window.scrollTo(0, 0);
            document.getElementsByClassName("k-grid-content")[0].scrollTop = 0;
        }
        const queryStr = `${toDataSourceRequestString(dataGridState)}`;
        fetchApi(`/api/Customer/GetSalespeople?${queryStr}`, {}, 'POST')
            .then(({ Data, Total }) => {
                totalRecords.current = Total;
                if (!append) {
                    setRecords(Data);
                } else {
                    setRecords(prevData => prevData.concat(Data));
                }
                setLoading(false);
                handleResize(null);
            }).catch(() => {
                setLoading(false);
            });
    }, [dataState]);

    const search = useDebouncedCallback(useCallback(() => {
        fetchRecords(false);
    }, [fetchRecords]), 750);

    useEffect(() => {
        search();
    }, [dataState.filter, dataState.sort, search]);

    const handleResize = (e: UIEvent) => {
        if ((document.getElementsByClassName("k-grid")[0]) as HTMLDivElement != null) {
            ((document.getElementsByClassName("k-grid")[0]) as HTMLDivElement).style.height = (document.documentElement.clientHeight - 250) + "px";
        }
    }

    const scrollHandler = (event: GridEvent) => {
        const e = event.nativeEvent;
        if (e.target.scrollTop >= (e.target.scrollHeight - (e.target.clientHeight)) - 1) {
            if (records.length === totalRecords.current)  //When auto-scrolling, ignore when we've hit max records
                return;

            dataState.skip = dataState.skip + dataState.take;
            fetchRecords(true);
        }
    }

    const dataStateChange = (changeEvent: GridDataStateChangeEvent) => {
        setDataState(changeEvent.dataState);
    }

    const onFilterChange = (event: FilterChangeEvent) => {
        setDataState({ ...dataState, filter: event.filter });
    };

    const onGridFilterChange = (event: GridFilterChangeEvent) => {
        setDataState({ ...dataState, filter: event.filter ?? { ...defaultAndFilter } });
    };

    const onGridSortChange = (event: GridSortChangeEvent) => {
        setDataState({ ...dataState, sort: event.sort });
    };

    const TerritoryIDCell = (props: GridCellProps) => {
        if (!props.field)
            return null;

        let dataItem: SalesPeopleViewModel = props.dataItem;
        var profile = dataItem.Links.find((x: ILink) => x.Name === 'SalespersonProfile');

        return (
            <td colSpan={props.colSpan} style={props.style}>
                {profile &&
                    <Link
                        to={profile.Link}
                        style={{ color: '#007bff' }}
                    >
                        {dataItem.TerritoryID}
                    </Link>}
            </td>
        );
    };

    return (
        <div className="container-fluid" style={{ position: "relative" }}>
            <Title string="Sales Listing" />
            {showAddSalespersonDialog && <AddSalespersonDialog
                CloseDialog={() => setShowAddSalespersonDialog(false)}
            />}
            <div>
                <Filter
                    className="kendoFilterControl"
                    value={dataState.filter}
                    onChange={onFilterChange}
                    fields={[

                        {
                            name: "FullName",
                            label: "Name",
                            filter: TextFilter,
                            operators: Operators.text,
                        },
                        {
                            name: "TerritoryID",
                            label: "Territory ID",
                            filter: NumericFilter,
                            operators: Operators.numeric,
                        },
                        {
                            name: "active",
                            label: "Active",
                            filter: BooleanFilter,
                            operators: Operators.boolean
                        },
                        {
                            name: "City",
                            label: "City",
                            filter: TextFilter,
                            operators: Operators.text,
                        },
                        {
                            name: "State",
                            label: "State",
                            filter: TextFilter,
                            operators: Operators.text,
                        },
                        {
                            name: "ZipCode",
                            label: "Zip",
                            filter: TextFilter,
                            operators: Operators.text,
                        }
                    ]}
                />
            </div>
            {loading && <GridLoadingPanel />}
            <Grid
                {...dataState}
                onDataStateChange={dataStateChange}
                data={records}
                resizable={true}
                reorderable={true}
                sortable={true}
                total={totalRecords.current}
                onScroll={scrollHandler}
                filterable={true}
                onFilterChange={(e) => onGridFilterChange(e)}
                onSortChange={(e) => onGridSortChange(e)}
            >
                <GridToolbar>
                    <Button
                        title="Refresh"
                        icon="refresh"
                        svgIcon={arrowRotateCwIcon}
                        onClick={() => fetchRecords(false)}
                    />
                    <Button
                        icon="plus"
                        svgIcon={plusIcon}
                        themeColor="primary"
                        onClick={() => setShowAddSalespersonDialog(true)}
                    > Salesperson
                    </Button>
                </GridToolbar>
                {/* <Column field="SalesNumber" title="Sales ID" cell={SalesNumberCell} />*/}
                <Column field="TerritoryID" title="Territory ID" filter="numeric" cell={TerritoryIDCell} />
                <Column field="FullName" title="Name" />
                <Column field="EmailAddress" title="Email" />
                <Column field="City" />
                <Column field="State" />
                <Column field="ZipCode" title="Zip" />
            </Grid>
            {
                totalRecords.current > 0 &&
                <div className="k-pager k-pager-md k-grid-pagert">
                    <div style={{ marginLeft: "auto", marginRight: 0 }}>
                        {dataState.skip + dataState.take > totalRecords.current ? totalRecords.current : dataState.skip + dataState.take} of {totalRecords.current} items
                    </div>
                </div>


            }
        </div>
    )
}

export default SalespeopleGrid;