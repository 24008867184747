import * as React from 'react';
import { Grid, GridColumn as Column, GridToolbar, GridCellProps } from "@progress/kendo-react-grid";
import { fetchApi } from '../../services/api';
import PhoneCell from '../../components/cells/PhoneCell';
import { Button } from '@progress/kendo-react-buttons';
import { AssetCarrierViewModel } from 'TypeGen/Assets/Carriers/List/asset-carrier-view-model';
import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { CarrierTierValue } from 'TypeGen/carrier-tier-value';
import { CarrierStatus } from 'TypeGen/carrier-status';
import { JsonResponse } from 'TypeGen/json-response';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { AutoComplete, AutoCompleteChangeEvent, ListItemProps } from '@progress/kendo-react-dropdowns';
import { useDebouncedCallback } from 'use-debounce';
import { SvgIcon } from "@progress/kendo-react-common";
import { plusIcon, starIcon, xIcon } from '@progress/kendo-svg-icons';

type Props = {
  dataItem: { GroupID: number, Name: string };
}

type CarrierType = {
  GroupID: number;
  CarrierID: number;
  Name: string;
  Location: string;
  Phone: string;
}

const CarrierList = (props: Props) => {

  const [carriers, setCarriers] = React.useState<CarrierType[]>([]);
  const [searchOptions, setSearchOptions] = React.useState<AssetCarrierViewModel[]>([]);
  const [searchLoading, setSearchLoading] = React.useState(false);
  const [selectedCarrier, setSelectedCarrier] = React.useState<AssetCarrierViewModel | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const carrierAutoComplete = React.useRef<AutoComplete>(null);

  const CommandCell = (props: GridCellProps) => {
    return <td>
        <Button
          themeColor="error"
          svgIcon={xIcon}
          onClick={() => window.confirm(`Remove carrier '${props.dataItem.Name}'?`) && remove(props.dataItem)}
        >
          Carrier
        </Button>
      </td>
  };

  const openDialog = () => {
    setDialogOpen(true);
    setSearchOptions([]);

    setTimeout(() => {
      carrierAutoComplete.current?.focus();
    });
  }

  const fetchCarriers = (groupID: number) => {
    fetchApi(`/api/CarrierGroups/GetCarriers/${groupID}`)
      .then((carriers: CarrierType[]) => {
        setCarriers(carriers);
        setLoading(false);
      })
      .catch(() => {
        alert("Unable to load carriers!");
      });
  }

  const remove = (dataItem: CarrierType) => {
    const data = {
      GroupID: props.dataItem.GroupID,
      CarrierID: dataItem.CarrierID
    };
    fetchApi('/api/CarrierGroups/RemoveCarrier', data, 'post')
      .then(() => {
        fetchCarriers(props.dataItem.GroupID);
      })
      .catch(() => {
        alert("Unable to remove carrier!");
      });
  }

  const add = React.useCallback(() => {

    const data = {
      GroupID: props.dataItem.GroupID,
      CarrierID: selectedCarrier.CarrierID
    }

    if (!data.CarrierID) {
      alert("Please select a carrier!");
      return;
    }

    fetchApi('/api/CarrierGroups/AddCarrier', data, 'post')
      .then((response: JsonResponse) => {
        if (response.Success) {
          setSelectedCarrier(null);
          setDialogOpen(false);
          if (carrierAutoComplete.current) {
            // this.CarrierAutoComplete.current.clear();
          }
          fetchCarriers(props.dataItem.GroupID);
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(() => {
        alert("Unable to add carrier to group!");
      });
  }, [selectedCarrier, props.dataItem.GroupID]);

  const updateCarriersDebounced =  useDebouncedCallback((searchTerm: string) => {
    setSearchLoading(true);
    const dataGridState = {
      skip: 0,
      take: 20,
      sort: [{
        field: "CarrierName",
        dir: "asc"
      }],
      filter: {
        logic: 'and', filters: [
          { field: 'CarrierName', operator: 'contains', value: searchTerm },
          { field: 'CarrierStatus', operator: 'eq', value: CarrierStatus.Active },
          { field: "Active", operator: "eq", value: true }
        ]
      }
    } as DataSourceRequestState;
    const queryStr = toDataSourceRequestString(dataGridState);
    fetchApi(`/api/Asset/GetAssetCarriers?${queryStr}`, {}, 'POST')
      .then(({ Data }: { Data: AssetCarrierViewModel[] }) => {
        setSearchLoading(false);
        setSearchOptions(Data);
      })
  }, 350);

  React.useEffect(() => {
    fetchCarriers(props.dataItem.GroupID);
  }, [props.dataItem.GroupID]);

  const carrierItemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = <>
      {itemProps.dataItem.CarrierName}
      {itemProps.dataItem.CarrierTier === CarrierTierValue.Platinum ? <SvgIcon icon={starIcon} svgStyle={{ fill: "url(#platinum-carrier-gradient)" }} />
      : itemProps.dataItem.CarrierTier === CarrierTierValue.Gold ? <SvgIcon icon={starIcon} color="#ecb73d" />
      : itemProps.dataItem.CarrierTier === CarrierTierValue.Silver ? <SvgIcon icon={starIcon} color="#9B9B9B" /> : null}
    </>;
    return React.cloneElement(li, li.props, itemChildren);
  }

  if (loading) return (
    <div style={{ height: "50px", width: '100%' }}>
      <div style={{ position: 'absolute', width: '100%' }}>
        <div className="k-loading-image" />
      </div>
    </div>
  );

  return <React.Fragment>
    {dialogOpen && <Dialog
      title="Add Carrier to Group"
      className='dialog-w800'
      onClose={() => setDialogOpen(false)}
    >
      <div className="form-group">
        <label htmlFor="add-carrier-name">Carrier Name</label>
        <AutoComplete
          ref={carrierAutoComplete}
          loading={searchLoading}
          data={searchOptions}
          value={selectedCarrier?.CarrierName}
          placeholder='Search for carrier...'
          itemRender={carrierItemRender}
          onChange={(event: AutoCompleteChangeEvent) => {
            // Picked from auto complete
            if (typeof event.target.value === 'object') {
              const carrierObj = event.target.value as AssetCarrierViewModel;
              setSelectedCarrier(carrierObj);
            } else {
              setSelectedCarrier(null);

              // Update Autocomplete Suggestions
              const searchTerm = event.target.value;
              if (searchTerm.length > 2) {
                updateCarriersDebounced(searchTerm);
              }
            }
          }}
        />
      </div>
      <DialogActionsBar layout={'end'}>
        <Button themeColor={'primary'} onClick={add}>Add Carrier</Button>
      </DialogActionsBar>
    </Dialog>}
    <Grid data={carriers}>
      <GridToolbar>
        <Button
          icon="plus"
          svgIcon={plusIcon}
          themeColor="primary"
          onClick={openDialog}
        >
          Carrier
        </Button>
      </GridToolbar>
      <Column field="Name" />
      <Column field="Location" />
      <Column field="Phone" cell={PhoneCell} />
      <Column cell={CommandCell} width="120px" />
    </Grid>
  </React.Fragment>;
}

export default CarrierList;
