import { filterBy, FilterDescriptor, getter } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Grid, GridColumn as Column, GridExpandChangeEvent, GridToolbar, GridHeaderSelectionChangeEvent, GridSelectionChangeEvent, getSelectedState, GridSelectionCell } from "@progress/kendo-react-grid";
import { Checkbox, Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBoolean, useDebounce, useInterval, useLocalStorage, useUpdateEffect } from 'usehooks-ts';
import { fetchApi } from "../../services/api";
import { IDName } from "../../types/idname";
import { ILink } from '../../types/link';
import { Title } from '../../utils/title';
import CreatedCell from './CreatedCell';
import DriverNumberCell from './DriverNumberCell';
import MessageNameCell from './MessageNameCell';
import ReadSentCell from './ReadSentCell';
import SendSatMessageDialog from './SendSatMessageDialog';
import StatusCell from './StatusCell';
import TripNumberCell from './TripNumberCell';
import VehicleNumberCell from './VehicleNumberCell';
import { arrowRotateCwIcon } from '@progress/kendo-svg-icons';

export type FleetMessage = {
  FleetMessageID: number;
  VehicleID: number | null;
  VehicleNumber: string;
  DriverNumber: string;
  HOSFormatted: string;
  TripID: number | null;
  TripNumber: number | null;
  Type: FleetMessageType;
  Status: FleetMessageStatus;
  MessageName: string;
  CreatedDateTime: Date;
  CreatedByUserNumber: string;
  ReadSentDateTime: Date | null;
  ReadByUserNumber: string;
  Priority: string;
  Message: string;
  FormattedMessage: string[];
  Error: string;
  Expanded: boolean;
  Hash: string;

  Links: ILink[];
}

export enum FleetMessageType {
  Unknown,
  Forward,
  Return,
}

export enum FleetMessageStatus {
  Unknown,
  Unread,
  Read,
  Unsent,
  Sent,
  Error,
}

type GetFleetMessagesResponse = {
  Vehicles: IDName[];
  MacroTypes: Array<{ ID: string, Name: string }>;
}

type FleetMessagesResponse = {
  Messages: FleetMessage[];
}

const messagesTypes = [
  { ID: 0, Name: "Unread Return/Unsent Forward" },
  { ID: 1, Name: "All Return" },
  { ID: 2, Name: "All Forward" },
  { ID: 3, Name: "All Return/Forward" },
  { ID: 4, Name: "Unread Forward" },
] as IDName[];

const DATA_ITEM_KEY: string = "FleetMessageID";
const SELECTED_FIELD: string = "selected";
const SELECTED_COL_INDEX: number = 12;
const idGetter = getter(DATA_ITEM_KEY);

const FleetMessages = () => {
  // Sound
  const [skipSound, setSkipSound] = useState(true);
  const [playSound, setPlaySound] = useState('');
  const [muteSound, setMuteSound] = useLocalStorage('fleet-messages-mute-sound', false);
  const [maxFleetMessageId, setMaxFleetMessageId] = useState(0);

  // Filters
  const [tripNumber, setTripNumber] = useState(null);
  const debouncedTripNumber = useDebounce(tripNumber, 500);
  const [messageType, setMessageType] = useState(0);
  const [macroType, setMacroType] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [term, setTerm] = useState('');
  const debouncedTerm = useDebounce(term, 500);
  const [macroTypes, setMacroTypes] = useState<Array<{ ID: string, Name: string }>>([]);
  const [vehicleId, setVehicleId] = useState(0);
  const [vehiclesFilter, setVehiclesFilter] = useState<FilterDescriptor>();

  // Data
  const [vehicles, setVehicles] = useState<IDName[]>([]);
  const [messages, setMessages] = useState<FleetMessage[]>([]);

  // Selection
  const [selectedState, setSelectedState] = useState<{
    [id: string]: boolean | number[];
  }>({});
  const selectedIds = Object.entries(selectedState).filter(([, value]) => value === true).map(([key]) => parseInt(key));

  // New Message
  const { value: sendMessage, toggle: toggleSendMessage } = useBoolean(false);
  const [toVehicleId, setToVehicleId] = useState<number>();
  const [quoteReply, setQuoteReply] = useState('');

  useEffect(() => {
    fetchApi("/api/Asset/FleetMessages")
      .then((response: GetFleetMessagesResponse) => {
        setMacroTypes(response.MacroTypes);
        setVehicles(response.Vehicles);
      });
  }, []);

  const refresh = useCallback(() => {
    const data = {
      VehicleID: vehicleId,
      TripNumber: debouncedTripNumber,
      MessageType: messageType,
      MacroType: macroType,
      StartDate: startDate,
      EndDate: endDate,
      Term: debouncedTerm,
    }
    fetchApi("/api/Asset/FleetMessages", data, 'POST')
      .then((response: FleetMessagesResponse) => {
        setMessages((prevMessages) => response.Messages.map(message => {
          const oldMessage = prevMessages.find(o => o.FleetMessageID === message.FleetMessageID);
          return { ...message, Expanded: oldMessage?.Expanded ?? message.Expanded };
        }));
      });
  }, [vehicleId, debouncedTripNumber, messageType, macroType, startDate, endDate, debouncedTerm]);

  const markReadSent = async () => {
    try {
      for (let i = 0; i < selectedIds.length; i++) {
        await fetchApi(`/api/Asset/MarkReadSent/${selectedIds[i]}`, {}, 'POST');
      }
      setSelectedState({});
    } catch (e: any) {
      // If not problem details
      if (!e?.status) alert(e);
      return;
    }

    refresh();
  }

  useEffect(() => {
    setSkipSound(true);
    refresh();
  }, [refresh]);

  useInterval(() => {
    setSkipSound(false);
    refresh();
  }, 60 * 1000);

  // Determind if there is a new message
  const newMaxFleetMessageId = useMemo(() => Math.max(maxFleetMessageId, ...messages.map(x => x.FleetMessageID)), [messages, maxFleetMessageId]);
  useUpdateEffect(() => {
    setMaxFleetMessageId(newMaxFleetMessageId);
    setPlaySound('/sounds/newChatMessage.mp3');
  }, [newMaxFleetMessageId]);

  const expandChange = (e: GridExpandChangeEvent) => {
    e.dataItem.Expanded = !e.dataItem.Expanded;
    setMessages([...messages]);
  }

  const onSelectionChange = useCallback(
    (event: GridSelectionChangeEvent) => {
      if (event.startColIndex === SELECTED_COL_INDEX)
      {
        console.log(event);
        const newSelectedState = getSelectedState({
          event,
          selectedState: selectedState,
          dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedState(newSelectedState);
      }
    },
    [selectedState]
  );

  const onHeaderSelectionChange = useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: any = event.syntheticEvent.target;
      const checked = checkboxElement.checked;
      const newSelectedState = {} as any;

      event.dataItems.forEach((item) => {
        if (item.Links.find((x: ILink) => x.Name === 'MarkReadSent')) {
          newSelectedState[idGetter(item)] = checked;
        }
      });
      setSelectedState(newSelectedState);
    },
    []
  );

  return <>
    <Title string="Fleet Messages" />
    {playSound && <audio muted={skipSound || muteSound} src={playSound} onEnded={() => setPlaySound('')} autoPlay />}
    <div className="row py-2">
      <div className="col-md-3">
        <DropDownList
          label='Vehicle'
          data={filterBy(vehicles, vehiclesFilter)}
          style={{ width: "100%" }}
          textField="Name"
          filterable
          onFilterChange={(e) => setVehiclesFilter(e.filter)}
          value={vehicles.find(x => x.ID === vehicleId)}
          onChange={(e) => setVehicleId(e.target.value.ID)}
        />
      </div>
      <div className="col-md-3">
        <NumericTextBox
          label='Trip #'
          value={tripNumber}
          width="100%"
          format="0"
          spinners={false}
          step={0}
          onChange={(e) => setTripNumber(e.target.value)}
        />
      </div>
      <div className="col-md-3">
        <DropDownList
          label='Message Type'
          data={messagesTypes}
          style={{ width: "100%" }}
          textField="Name"
          value={messagesTypes.find(x => x.ID === messageType)}
          onChange={(e) => setMessageType(e.target.value.ID)}
        />
      </div>
      <div className="col-md-3">
        <DropDownList
          label='Macro Type'
          data={macroTypes}
          style={{ width: "100%" }}
          textField="Name"
          value={macroTypes.find(x => x.ID === macroType)}
          onChange={(e) => {
            const id = e.target.value.ID as string;
            if (id.startsWith("F")) {
              setMessageType(2);
            } else if (id.startsWith("R")) {
              setMessageType(1);
            } else {
              setMessageType(0);
            }
            setMacroType(id)
          }}
        />
      </div>
      <div className="col-md-3">
        <DatePicker
          label='Start Date'
          format="MM/dd/yyyy"
          value={startDate}
          width="100%"
          onChange={(e) => setStartDate(e.target.value)}
        />
      </div>
      <div className="col-md-3">
        <DatePicker
          label='End Date'
          format="MM/dd/yyyy"
          value={endDate}
          width="100%"
          onChange={(e) => setEndDate(e.target.value)}
        />
      </div>
      <div className="col-md-3">
        <Input
          label='Text Search'
          value={term}
          style={{ width: '100%' }}
          onChange={(e) => setTerm(e.value)}
        />
      </div>
      <div className="col-md-3 mt-3">
        <Checkbox label="Mute New Messages Sound" checked={muteSound} onChange={(e) => setMuteSound(e.value)} />
      </div>
    </div>
    {sendMessage && <SendSatMessageDialog
      VehicleID={toVehicleId}
      QuoteReply={quoteReply}
      CloseDialog={(newMessageCreated) => {
        if (newMessageCreated)  refresh();
        toggleSendMessage();
      }}
    />}
    <Grid
      dataItemKey="FleetMessageID"
      expandField="Expanded"
      onExpandChange={expandChange}
      scrollable="none"
      detail={(e) => {
        return <code style={{ whiteSpace: 'pre-wrap' }}>{e.dataItem.Message}</code>
      }}
      data={messages.map((item) => ({
        ...item,
        [SELECTED_FIELD]: selectedState[idGetter(item)],
      }))}
      selectedField={SELECTED_FIELD}
      selectable={{
        enabled: true,
        drag: false,
        cell: false,
        mode: "multiple",
      }}
      onSelectionChange={onSelectionChange}
      onHeaderSelectionChange={onHeaderSelectionChange}
    >
      <GridToolbar>
        <Button
          icon="refresh"
          svgIcon={arrowRotateCwIcon}
          onClick={refresh}
        />
        <Button themeColor="primary" onClick={() => {
          setToVehicleId(null);
          setQuoteReply('');
          toggleSendMessage();
        }}>
          Send Message
        </Button>
        {selectedIds.length > 0 && <>
          <Button onClick={() => setSelectedState({})}>Deselect</Button>
          <Button themeColor='primary' onClick={markReadSent}>Mark Read/Sent</Button>
        </>}
      </GridToolbar>
      <Column
        field="MessageName"
        title="Message"
        cell={(e) => <MessageNameCell {...e} onClick={(dataItem) => {
          setToVehicleId(dataItem.VehicleID);
          setQuoteReply(dataItem.FormattedMessage.join('\n'));
          toggleSendMessage();
        }} />}
      />
      <Column field="VehicleNumber" title="Vehicle" cell={VehicleNumberCell} />
      <Column field="DriverNumber" title="Driver" cell={DriverNumberCell} />
      <Column field="TripNumber" title="Trip #" cell={TripNumberCell} />
      <Column field="Type" title="Type" cell={(e) => <td>{e.dataItem.Type == 1 ? 'Forward' : 'Return'}</td>} />
      <Column field="Status" title="Status" cell={StatusCell} />
      <Column field="Priority" />
      <Column field="CreatedDateTime" title="Created" cell={CreatedCell} />
      <Column field="CreatedByUserNumber" title="Created By" />
      <Column field="ReadSentDateTime" title="Sent/Read" cell={ReadSentCell} />
      <Column field="ReadByUserNumber" title="Read By" />
      <Column
        field={SELECTED_FIELD}
        width="50px"
        headerSelectionValue={
          messages.findIndex((item) => !selectedState[idGetter(item)]) === -1
        }
        cell={(props) => {
          const readLink = props.dataItem.Links.find((x: ILink) => x.Name === 'MarkReadSent');
          return readLink ? <GridSelectionCell {...props} /> : <td />;
        }}
      />
    </Grid>
    {selectedIds.length} {selectedIds.length === 1 ? 'message' : 'messages'} selected
  </>
}

export default FleetMessages;