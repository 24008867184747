import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { ILink } from '../../../types/link';
import { Link } from 'react-router-dom';
import { AssetVehicleViewModel } from 'TypeGen/Assets/Vehicles/List/asset-vehicle-view-model';

const DriverNumber = (props: GridCellProps) => {
  const dataItem = props.dataItem as AssetVehicleViewModel;

  var driver1Profile = dataItem.Links.find((x: ILink) => x.Name === 'DriverProfile');
  var driver2Profile = dataItem.Links.find((x: ILink) => x.Name === 'Driver2Profile');

  if (!driver1Profile && !driver2Profile) {
    return <td>No Driver</td>
  }

  const driver1ProfileLink = driver1Profile && <>
    <Link
      to={driver1Profile.Link}
      style={{ color: '#007bff' }}
    >
      {dataItem.DriverNumber}
    </Link>
    {dataItem.Driver1HosStatus && <>
      <br />
      <Link
        to={`/Assets/Driver/${dataItem.DriverID}/HoursOfService`}
        style={{ color: '#007bff' }}
        title={dataItem.Driver1HosStatus}
      >
        {dataItem.Driver1HosHours}
      </Link>
    </>}
  </>;

  const driver2ProfileLink = driver2Profile && <>
    <br />
    <Link
      to={driver2Profile.Link}
      style={{ color: '#007bff' }}
    >
      {dataItem.DriverNumber2}
    </Link>
    {dataItem.Driver2HosStatus && <>
      <br />
      <Link
        to={`/Assets/Driver/${dataItem.DriverID2}/HoursOfService`}
        style={{ color: '#007bff' }}
        title={dataItem.Driver2HosStatus}
      >
        {dataItem.Driver2HosHours}
      </Link>
    </>}
  </>;

  return <td>
    {driver1ProfileLink}
    {driver2ProfileLink}
  </td>;
}
export default React.memo(DriverNumber, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);
