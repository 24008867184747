import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { formatNumber } from '@progress/kendo-intl';

export default function vehiclePayCell(rate: number, fuel: number, bonus: number) {
  return class extends React.Component<GridCellProps> {
    render() {
      const color = this.props.dataItem.PayPercentage == 0.70 
        ? 'green' : this.props.dataItem.PayPercentage == 0.67 
        ? 'orange' : undefined;
      const payAmount = this.props.dataItem.QuoteOfferAllInRate 
        ? this.props.dataItem.QuoteOfferAllInRate : (rate * this.props.dataItem.PayPercentage) + (this.props.dataItem.VehicleNumber.charAt(0) === '9' ? 0 : fuel) + bonus;
      return (
        <td style={{ color }}>
          {formatNumber(payAmount, "c")}
        </td>
      );
    }

    public shouldComponentUpdate(nextProps: GridCellProps) {
      return nextProps.dataItem.Hash !== this.props.dataItem.Hash;
    }
  };
}
