import { CompositeFilterDescriptor, filterBy, State as GridState, toDataSourceRequestString, } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import DateCell from "../../../components/cells/DateCell";
import LoadingPanel from "../../../components/LoadingPanel";
import { fetchApi } from "../../../services/api";
import VehicleNumberCell from "./Cells/VehicleNumber";
import { PlanningContext } from ".";
import { ILink } from "../../../types/link";
import { openWindow } from "../../../services/openWindow";
import PhoneCell from "../../../components/cells/PhoneCell";
import carrierTierCell from "../../Assets/CarrierTierCell";
import { useDebouncedCallback } from "use-debounce";
import { AssetCarrierViewModel } from "TypeGen/Assets/Carriers/List/asset-carrier-view-model";
import { CarrierStatus } from "TypeGen/carrier-status";

type RouteComponentParams = {
  orderId: string;
};

export type VehicleSuggestion = {
  VehicleID: number;
  VehicleNumber: string;
  DriverNumber1: string;
  TrailerNumber1: string;
  VehicleTypeID: number;
  VehicleTypeName: string;
  ComplianceDays: number;
  PayLoad: number;
  Dims: string;
  LastAvailable: number;
  ServiceStatus: string;
  StatusColor: string;
  AvailableLocation: string;
  AvailableDateTime: Date;
  HasAcceptedOffer: boolean;

  Ranking: number;

  Links: ILink[];
}

enum VehiclesCarriers {
  Vehicles = 0,
  Carriers = 1
}

const OrderPlanningSuggestions = () => {
  const history = useHistory();
  const { vehiclesCarriers, setVehiclesCarriers, toggleMap } = useContext(PlanningContext);
  const { orderId: orderIdParam } = useParams<RouteComponentParams>();
  const [loadingVehicleSuggestions, setLoadingVehicleSuggestions] = useState(false);

  // Vehicle suggestions
  const [vehicles, setVehicles] = useState<VehicleSuggestion[]>([]);
  const [vehiclesFilter, setVehiclesFilter] = useState<CompositeFilterDescriptor>({ logic: "and", filters: [] });

  // Carrier suggestions
  const [carriers, setCarriers] = useState<AssetCarrierViewModel[]>([]);
  const [carriersFilter, setCarriersFilter] = useState<CompositeFilterDescriptor>({ logic: "or", filters: [
      {
        field: "CarrierName",
        operator: "startswith",
        value: ''
      },
      {
        field: "MCNumber1",
        operator: "startswith",
        value: ''
      }
  ]});

  const refreshVehicleSuggestions = useCallback(() => {
    setLoadingVehicleSuggestions(true);
    fetchApi(`/api/Asset/VehicleSuggestions/${orderIdParam}`)
      .then((response: { Vehicles: VehicleSuggestion[] }) => {
        setLoadingVehicleSuggestions(false);
        setVehicles(response.Vehicles);
      })
      .catch(err => {
        alert(err);
        setLoadingVehicleSuggestions(false);
      });
  }, [orderIdParam]);

  const setCarrierFilterDebounced = useDebouncedCallback((value: string) => {
    setCarriersFilter({
      logic: "or",
      filters: [
        {
          field: "CarrierName",
          operator: "startswith",
          value: value
        },
        {
          field: "MCNumber1",
          operator: "startswith",
          value: value
        }
      ]
    });
  }, 500);

  const refreshCarrierSuggestions = useCallback(() => {
    setLoadingVehicleSuggestions(true);

    const dataGridState = {
      take: 50,
      skip: 0,
      sort: [{ field: "CarrierName", dir: "asc" }], // TODO: By Tier?
      filter: {
        logic: 'and',
        filters: [
          {
            logic: 'and',
            filters: [
              { field: "Active", operator: "eq", value: true },
            ]
          },
          { field: "CarrierStatus", operator: "eq", value: CarrierStatus.Active },
          carriersFilter
        ]
      }
    } as GridState;

    const queryStr = toDataSourceRequestString(dataGridState);
    fetchApi(`/api/Asset/GetAssetCarriers?${queryStr}`, {}, 'POST')
      .then(({ Data, Total }) => {
        setLoadingVehicleSuggestions(false);
        setCarriers(Data);
      })
      .catch(err => {
        alert(err);
        setLoadingVehicleSuggestions(false);
      });
  }, [carriersFilter]);

  const selectVehicle = (vehicle: VehicleSuggestion) => {
    if (vehicle.Links.find(l => l.Name === "AddProTO")) {
      openWindow(vehicle.Links.find(l => l.Name === "AddProTO").Link);
    } else {
      history.push(`/Order/Planning/${orderIdParam}/Vehicle/${vehicle.VehicleID}`);
    }

    // TODO: AddProTO in TMS Mode
  }

  const selectCarrier = (carrier: AssetCarrierViewModel) => {
    history.push(`/Order/Planning/${orderIdParam}/Carrier/${carrier.CarrierID}`)
  }

  useEffect(() => {
    if (vehiclesCarriers === VehiclesCarriers.Vehicles) {
      refreshVehicleSuggestions();
    } else if (vehiclesCarriers === VehiclesCarriers.Carriers) {
      refreshCarrierSuggestions();
    }
  }, [vehiclesCarriers, refreshVehicleSuggestions, refreshCarrierSuggestions]);

  const vehiclesFiltered = filterBy(vehicles, vehiclesFilter);

  return <>
      <div className="input-group flex-nowrap">
        <ButtonGroup className="mb-2">
          <Button selected={vehiclesCarriers === VehiclesCarriers.Vehicles} onClick={() => setVehiclesCarriers(0)} togglable>Vehicles</Button>
          <Button selected={vehiclesCarriers === VehiclesCarriers.Carriers} onClick={() => setVehiclesCarriers(1)} togglable>Carriers</Button>
        </ButtonGroup>
        <Input
          autoFocus
          placeholder="Search..."
          className="mb-2 ml-2"
          onChange={e => vehiclesCarriers === VehiclesCarriers.Vehicles
            ? setVehiclesFilter({
                logic: "or",
                filters: [
                  {
                    field: "VehicleNumber",
                    operator: "startswith",
                    value: e.value
                  },
                  {
                    field: "VehicleTypeName",
                    operator: "startswith",
                    value: e.value
                  }
                ]
              })
            : setCarrierFilterDebounced(e.value)
          }
        />
        <Button className="mb-2 ml-2" onClick={toggleMap}>Map</Button>
      </div>
      {loadingVehicleSuggestions && <LoadingPanel />}
      {vehiclesCarriers === VehiclesCarriers.Vehicles && <Grid
        data={vehiclesFiltered}
        onRowClick={e => selectVehicle(e.dataItem)}
      >
        <Column field="VehicleNumber" title="Unit #" cell={VehicleNumberCell} />
        <Column field="DriverNumber1" title="Driver #" />
        <Column field="TrailerNumber1" title="Trailer #" />
        <Column field="VehicleTypeName" title="Size" />
        <Column field="PayLoad" title="Payload" format="{0:n0}" />
        <Column field="Dims" title="LxWxH" />
        <Column field="AvailableLocation" title="Available" />
        <Column field="AvailableDateTime" title="Date Time" cell={DateCell} />
        <Column field="LastAvailable" title="LA" width={40} />
      </Grid>}
      {vehiclesCarriers === VehiclesCarriers.Carriers && <Grid
        data={carriers}
        onRowClick={e => selectCarrier(e.dataItem)}
      >
        <Column field="CarrierName" title="Carrier" cell={(props) => <td role="button">{props.dataItem.CarrierName}</td>} />
        <Column field="MCNumber1" title="MC Number" />
        <Column field="City" />
        <Column field="State" />
        <Column field="Phone1" title="Phone" sortable={false} cell={PhoneCell} />
        <Column field="CarrierTier" title="Tier" cell={carrierTierCell(() => {})} sortable={false} filterable={false} />
      </Grid>}
    </>;
}

export default OrderPlanningSuggestions;