import AssetPanel from '../Assets/AssetPanel';

const AssetTrailers = () => {
  return <div className="form-row">
    <div className="col-lg-12 p-1">
      <AssetPanel
        searchTerm=""
        tabIndex={3}
      />
    </div>
  </div>
}

export default AssetTrailers;

