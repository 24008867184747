import * as React from 'react';
import { Title } from '../../utils/title';
import moment from 'moment-timezone';
import { Wrapper as MapWrapper } from '@googlemaps/react-wrapper';
import { Loader } from "@progress/kendo-react-indicators";
import { Window } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { FilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { fetchApi } from '../../services/api';
import * as MapStyle from './MapStyle.json';
import { Switch, Checkbox } from '@progress/kendo-react-inputs';
import { IDName } from '../../types/idname';
import Map from './Map';
import Marker from './Marker';
import InfoWindow from './InfoWindow';
import { ViewModel } from 'TypeGen/LoadMap/UnitsInArea/view-model';
import realFormatter from 'services/formatting/number';
import { Coordinate } from 'TypeGen/coordinate';
import SingleLocationAutoComplete from 'views/PCMiler/SingleLocationAutoComplete';

type State = {
    loading: boolean;
    vehicles: IDName[];
    vehicleTypes: IDName[];
    loadingFilter: boolean;
    vehicleFilter: FilterDescriptor;
    todayFilter: IDName[];
    filter: HeatMapFilter;
    response: HeatMapResponse | null;
    center: google.maps.LatLngLiteral | google.maps.LatLng | null;

    activeMarker: google.maps.LatLng | null;
    activeMarkerTitle: string;
    activeMarkerDescription: string;
    showingInfoWindow: boolean;

    showingVehicleInfoWindow: boolean;

    searchPin: google.maps.LatLngLiteral | null;

    loadingUnitsInArea: boolean;
    unitsInArea: ViewModel | null;
}

type HeatMapFilter = {
    DriverID: number;
    VehicleTypeID: number;
    DaysAgo: number;
    Days: number;
    Today: boolean;
    MissedOpportunities: boolean;
    Trucks: boolean;
    AllianceTrucks: boolean;
    USDomesticLoads: boolean;
    InternationalLoads: boolean;
}

type HeatMapResponse = {
    VehiclePosition: VehiclePosition | null;
    Vehicles: VehicleMarker[];
    AllianceVehicles: ClusterMarker[];
    BookedOpportunities: ClusterMarker[];
    MissedOpportunities: ClusterMarker[];
}

type VehiclePosition = {
    DateTime: Date;
    Location: string;
    Coordinates: Coordinate;
    LastAvailableHours?: number;
    CallingCard: string | null;
    TruckingType: TruckingType;
}

type VehicleMarker = {
    Location: string;
    Coordinates: Coordinate;
    Team: boolean;
    LastAvailableHours: number;
    CallingCard: string | null;
    TruckingType: TruckingType;
}

enum TruckingType {
    NA,
    LongHaul,
    Regional,
    Local,
    LocalLongHaul,
}

type ClusterMarker = {
    Count: number;
    Location: string;
    Coordinates: Coordinate;
}

type Props = {}

export class LoadMap extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        const todayFilter: IDName[] = [];
        todayFilter.push({ ID: 0, Name: `Today`});
        todayFilter.push({ ID: 1, Name: `Last 2 Days`});
        todayFilter.push({ ID: 2, Name: `Last 7 Days`});
        todayFilter.push({ ID: 3, Name: `Last 14 Days`});
        todayFilter.push({ ID: 4, Name: `Last 30 Days`});
        for (let i = 1; i < 6; i++) {
            const date = moment().subtract(Math.abs(i - 8), 'days');
            const dateFormat = `${date.format('dddd')} - ${date.format('MM/DD/YYYY')}`;
            todayFilter.push({ ID: todayFilter.length, Name: `Last ${dateFormat}`});
        }

        this.state = {
            loading: false,
            vehicles: [],
            vehicleTypes: [],
            loadingFilter: true,
            vehicleFilter: null,
            todayFilter,
            filter: {
                DriverID: -1,
                VehicleTypeID: 0,
                DaysAgo: 0,
                Days: 2,
                Today: false,
                MissedOpportunities: false,
                Trucks: true,
                AllianceTrucks: false,
                USDomesticLoads: true,
                InternationalLoads: true
            },
            response: null,
            center: null,

            activeMarker: null,
            showingInfoWindow: false,
            activeMarkerTitle: '',
            activeMarkerDescription: '',

            showingVehicleInfoWindow: true,

            searchPin: null,

            loadingUnitsInArea: false,
            unitsInArea: null
        }

        this.exit = this.exit.bind(this);
        this.fetchUnitsInArea = this.fetchUnitsInArea.bind(this);
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.onVehicleMarkerClick = this.onVehicleMarkerClick.bind(this);
    }

    public componentDidMount() {
        this.get();
    }

    public render() {
        const vehicles = filterBy(this.state.vehicles, this.state.vehicleFilter);
        const mapOptions = localStorage["darkmode"] === 'false' ? {} : { styles: MapStyle, backgroundColor: '#242f3e' } as { styles: any, backgroundColor? : string };
        return <div className="w-100 vh-100 d-flex">
            <Title string="Load Map" />
            <section className="col-3 p-2">
                <button type="button" className="btn btn-danger btn-lg btn-block" onClick={this.exit}>BACK</button>
                <br />
                <DropDownList
                    style={{ width: '100%' }}
                    data={vehicles}
                    value={vehicles.find((value) => value.ID === this.state.filter.DriverID)}
                    textField="Name"
                    filterable={true}
                    onFilterChange={(e) => this.setState({ vehicleFilter: e.filter })}
                    onChange={(e) => this.setFilter({
                        DriverID: e.target.value.ID,
                        VehicleTypeID: 0,
                        AllianceTrucks: e.target.value.ID === 0 ? false : this.state.filter.AllianceTrucks,
                        MissedOpportunities: e.target.value.ID === 0 ? false : this.state.filter.MissedOpportunities,
                    })}
                    defaultValue={{ Name: 'Select Vehicle' }}
                    loading={this.state.loadingFilter}
                />
                <DropDownList
                    style={{ width: '100%' }}
                    className='mt-2'
                    data={this.state.vehicleTypes}
                    value={this.state.vehicleTypes.find((value) => value.ID === this.state.filter.VehicleTypeID)}
                    textField="Name"
                    onChange={(e) => this.setFilter({
                        DriverID: e.target.value.ID > 0 ? 0 : -1,
                        VehicleTypeID: e.target.value.ID,
                        AllianceTrucks: false,
                        MissedOpportunities: e.target.value.ID === 0 ? false : this.state.filter.MissedOpportunities,
                    })}
                />
                <SingleLocationAutoComplete
                    autoCompleteProps={{ clearButton: true, placeholder: 'Find City or Zip', disabled: this.state.filter.DriverID !== 0, className: 'mt-2' }}
                    onSelected={(e) => {
                        const searchPin = e ? { lat: parseFloat(e.Coords.Lat), lng: parseFloat(e.Coords.Lon) } : null;
                        if (e) {
                            this.setState({
                                showingInfoWindow: false,
                                center: searchPin,
                                searchPin
                            });
                        } else {
                            this.setState({ searchPin });
                        }
                    }}
                />
                <br /><br />
                {this.state.filter.MissedOpportunities ? 'Missed' : 'Booked'} <Switch
                    disabled={this.state.filter.DriverID === 0 && this.state.filter.VehicleTypeID === 0}
                    checked={this.state.filter.MissedOpportunities}
                    onChange={(e) => this.setFilter({ MissedOpportunities: e.value })}
                />
                <br /><br />
                <DropDownList 
                    style={{ width: '100%' }}
                    data={this.state.todayFilter}
                    textField="Name"
                    onChange={(e) => {
                        const buttonIndex = e.target.value.ID;
                        const days = (buttonIndex === 0) ? 0 :
                            (buttonIndex === 1) ? 2 :
                            (buttonIndex === 2) ? 7 :
                            (buttonIndex === 3) ? 14 :
                            (buttonIndex === 4) ? 30 : 1;
                        const daysAgo = (buttonIndex <= 1) ? 0 :
                            (buttonIndex === 2) ? 0 :
                            (buttonIndex === 3) ? 0 :
                            (buttonIndex === 4) ? 0 : buttonIndex - 12;
                        const todayFilter = buttonIndex === 0;
                        this.setFilter({  Today: todayFilter, Days: days, DaysAgo: daysAgo });
                    }}
                    defaultValue={{ ID: 1, Name: 'Last 2 Days' }}
                />
                <br /><br />
                <Checkbox 
                    value={this.state.filter.Trucks}
                    onChange={(e) => this.setFilter({ Trucks: e.value })}
                    label={'Trucks'}
                />
                <br /><br />
                <Checkbox 
                    disabled={this.state.filter.DriverID === 0}
                    value={this.state.filter.AllianceTrucks}
                    onChange={(e) => this.setFilter({ AllianceTrucks: e.value })}
                    label={'Alliance Trucks'}
                />
                <br /><br />
                <Checkbox 
                    value={this.state.filter.USDomesticLoads}
                    onChange={(e) => this.setFilter({ USDomesticLoads: e.value })}
                    label={'U.S. Domestic Loads'}
                />
                <br /><br />
                <Checkbox 
                    value={this.state.filter.InternationalLoads}
                    onChange={(e) => this.setFilter({ InternationalLoads: e.value })}
                    label={'International Loads'}
                />
                <br /><br />
                {this.state.filter.DriverID !== 0 && <p className="text-muted">*Tip: Right click map to see units in area</p>}
            </section>
            <section className="col-9 p-0">
                {this.state.loading && <span
                    className="k-i-loading k-icon"
                    style={{ fontSize: 64, position: 'absolute', left: '50%', top: '5%', zIndex: 999 }}
                />}
                <MapWrapper apiKey='AIzaSyCDy2Rv9ng43qFzqsnzJ_R0t3WC209lvyA'>
                {this.state.response != null ? <Map
                    {...mapOptions}
                    clickableIcons={true}
                    center={this.state.center}
                    zoom={11}
                    style={{ height: "100%" }}
                    onRightClick={(e) => {
                        this.fetchUnitsInArea(e.latLng.lat(), e.latLng.lng());
                    }}
                >
                    {this.state.response.MissedOpportunities.map((missed, index) => {
                        const scaledSize = 16 + (Math.min(100, missed.Count) / 2);
                        const title = missed.Location;
                        return missed.Count == 1 ? <Marker
                            key={'m1' + index}
                            onClick={(marker) => this.onMarkerClick(marker, title)}
                            title={title}
                            position={{lat: missed.Coordinates.Latitude, lng: missed.Coordinates.Longitude}}
                            icon={{
                                url: "/images/DriverApp/MapIconMissed@2x.png",
                                scaledSize: new google.maps.Size(27, 27)
                            }}
                        /> : <Marker
                            key={'m2' + index}
                            onClick={(marker) => this.onMarkerClick(marker, title)}
                            label={missed.Count.toString()}
                            title={title}
                            position={{lat: missed.Coordinates.Latitude, lng: missed.Coordinates.Longitude}}
                            icon={{
                                url: "/images/DriverApp/RedPin.png",
                                scaledSize: new google.maps.Size(scaledSize, scaledSize)
                            }} />
                    })}
                    {this.state.response.BookedOpportunities.map((booked, index) => {
                        const scaledSize = 16 + (Math.min(100, booked.Count) / 2);
                        const title = booked.Location;
                        return booked.Count == 1 ? <Marker
                            key={'b1' + index}
                            onClick={(marker) => this.onMarkerClick(marker, title)}
                            title={title}
                            position={{lat: booked.Coordinates.Latitude, lng: booked.Coordinates.Longitude}}
                            icon={{
                                url: "/images/DriverApp/MapIconBooked@2x.png",
                                scaledSize: new google.maps.Size(27, 27)
                            }}
                        /> : <Marker
                            key={'b2' + index}
                            onClick={(marker) => this.onMarkerClick(marker, title)}
                            label={booked.Count.toString()}
                            title={title}
                            position={{lat: booked.Coordinates.Latitude, lng: booked.Coordinates.Longitude}}
                            icon={{
                                url: "/images/DriverApp/GreenPin.png",
                                scaledSize: new google.maps.Size(scaledSize, scaledSize)
                            }} />
                    })}
                    {this.state.response.AllianceVehicles.map((allianceVehicle, index) => {
                        const scaledSize = 16 + (Math.min(100, allianceVehicle.Count) / 2);
                        const title = allianceVehicle.Count == 1 
                            ? `Carrier Unit at ${allianceVehicle.Location.toUpperCase()}` : `Carrier Units at ${allianceVehicle.Location.toUpperCase()}`;
                        return allianceVehicle.Count == 1 ? <Marker
                            key={'av1' + index}
                            onClick={(marker) => this.onMarkerClick(marker, title)}
                            title={allianceVehicle.Location.toUpperCase()}
                            position={{lat: allianceVehicle.Coordinates.Latitude, lng: allianceVehicle.Coordinates.Longitude}}
                            icon={{
                                url: "/images/DriverApp/MapIconAllianceTruck@2x.png",
                                scaledSize: new google.maps.Size(27, 27)
                            }}
                        /> : <Marker
                            key={'av2' + index}
                            onClick={(marker) => this.onMarkerClick(marker, title)}
                            label={allianceVehicle.Count.toString()}
                            title={allianceVehicle.Location.toUpperCase()}
                            position={{lat: allianceVehicle.Coordinates.Latitude, lng: allianceVehicle.Coordinates.Longitude}}
                            icon={{
                                url: "/images/DriverApp/OrangePin.png",
                                scaledSize: new google.maps.Size(scaledSize, scaledSize)
                            }} />
                    })}
                    {this.state.response.Vehicles.map((vehicle, index) => {
                        const title = `${this.getTruckerTypeName(vehicle.TruckingType)} Unit at ${vehicle.Location.toUpperCase()}`;
                        const description = vehicle.CallingCard ? `INS for ${vehicle.LastAvailableHours} hr(s). ${vehicle.CallingCard}` : `INS for ${vehicle.LastAvailableHours} hr(s).`;
                        return <Marker
                            key={'v' + index}
                            onClick={(marker) => this.onMarkerClick(marker, title, description)}
                            title={vehicle.Location.toUpperCase()}
                            position={{lat: vehicle.Coordinates.Latitude, lng: vehicle.Coordinates.Longitude}}
                            icon={{
                                url: `/images/DriverApp/MapIconTruck${vehicle.Team ? 'Team' : ''}@2x.png`,
                                scaledSize: new google.maps.Size(27, 27)
                            }}
                        />
                    })}
                    {this.state.filter.DriverID > 0 && <Marker
                        onClick={this.onVehicleMarkerClick}
                        position={this.state.center}
                        zIndex={10000}
                        icon={{
                            url: "/images/DriverApp/TruckRight@2x.png",
                            anchor: new google.maps.Point(30, 0),
                            scaledSize: new google.maps.Size(60, 60)
                        }}
                    />}
                    {this.state.searchPin && <Marker
                        position={this.state.searchPin}
                    />}
                    {this.state.activeMarker != null && this.state.showingInfoWindow && <InfoWindow
                        position={this.state.activeMarker}
                        pixelOffset={new google.maps.Size(0, -20)}
                        content={`<p class="text-dark font-weight-bold m-0">
                                ${this.state.activeMarkerTitle}
                            </p>
                            ${this.state.activeMarkerDescription ? `<p class="text-dark m-0">
                                ${this.state.activeMarkerDescription}
                            </p>` : ''}
                        `}
                    />}
                    {this.state.showingVehicleInfoWindow && this.state.response.VehiclePosition && <InfoWindow
                        position={this.state.center}
                        content={`
                            <p class="text-dark m-0">
                                As Of: ${moment.utc(this.state.response.VehiclePosition.DateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}
                            </p>
                            <p class="text-dark m-0">
                                At: ${this.state.response.VehiclePosition.Location}
                            </p>
                            ${this.state.response.VehiclePosition.LastAvailableHours !== null ?
                                `<p class="text-dark m-0">INS for ${this.state.response.VehiclePosition.LastAvailableHours} hrs.</p>` : ''}
                            ${this.state.response.VehiclePosition.CallingCard !== null ?
                                `<p class="text-dark m-0">${this.state.response.VehiclePosition.CallingCard}</p>` : ''}
                        `}
                    />}
                </Map> : <h1 className="text-center p-2">Select Vehicle or Vehicle Type</h1>}
                </MapWrapper>
                {(this.state.unitsInArea !== null || this.state.loadingUnitsInArea === true) && <Window
                    title={this.state.unitsInArea !== null ? `Units In Area - ${this.state.unitsInArea.Location}` : 'Units In Area'}
                    onClose={() => this.setState({ unitsInArea: null })}
                    initialHeight={400}
                    initialWidth={500}
                >
                    {this.state.loadingUnitsInArea === true && <div className='text-center'>
                        <Loader size="large" />
                    </div>}
                    {this.state.unitsInArea != null && <div className="p-2 text-center">
                        <p>Your Position: {this.state.unitsInArea.Position}</p>
                        <p>{realFormatter(this.state.unitsInArea.Distance)} miles from current location</p>
                        {this.state.unitsInArea.Vehicles.map((value, index) => {
                            return <p className='mb-0' key={index}>{value.Name}: {value.Count}</p>
                        })}
                        <p className='mt-1'>75 miles radius</p>
                    </div>}
                </Window>}
            </section>
        </div>;
    }

    private setFilter(value: Partial<HeatMapFilter>) {
        const filter = Object.assign({}, this.state.filter, value);
        this.setState({ filter }, this.fetch);
    }

    private exit() {
        window.history.go(-1);
    }

    private get() {
        fetchApi("/api/LoadBoard/HeatMap")
            .then((response: { Vehicles: IDName[], VehicleTypes: IDName[] }) => {
                this.setState({ vehicles: response.Vehicles, vehicleTypes: response.VehicleTypes, loadingFilter: false });
            });
    }

    private fetch() {
        // Hasn't selected a vehicle yet
        if (this.state.filter.DriverID == -1) {
            return;
        }
        this.setState({ loading: true });
        fetchApi("/api/LoadBoard/HeatMap", this.state.filter, 'POST')
            .then((response: HeatMapResponse) => {
                // Initial or new vehicle
                if (response.VehiclePosition && (this.state.response == null || this.state.response.VehiclePosition == null || this.state.response.VehiclePosition.Coordinates.Latitude != response.VehiclePosition.Coordinates.Latitude || this.state.response.VehiclePosition.Coordinates.Longitude != response.VehiclePosition.Coordinates.Longitude)) {
                    this.setState({
                        center: {
                            lat: response.VehiclePosition.Coordinates.Latitude,
                            lng: response.VehiclePosition.Coordinates.Longitude
                        }
                    });
                } else if (this.state.center == null) {
                    this.setState({ center: { lat: 42.225707, lng: -83.26349 } });
                }
                this.setState({ response });
            })
            .catch((e) => {
                // If not problem details
                if (!e?.status) alert('Unable to load map');
            })
            .finally(() => this.setState({ loading: false }));
    }

    private fetchUnitsInArea(lat: number, lng: number, radius: number = 75) {
        if (this.state.filter.DriverID < 1) return;

        this.setState({ loadingUnitsInArea: true });
        const payload = {
            DriverID: this.state.filter.DriverID,
            Latitude: lat,
            Longitude: lng,
            Radius: radius
        }
        fetchApi("/api/LoadBoard/UnitsInArea", payload, 'POST')
            .then((response: ViewModel) => {
                this.setState({ unitsInArea: response, loadingUnitsInArea: false });
            })
            .catch(() => this.setState({ loadingUnitsInArea: false }));
    }

    private onMarkerClick(marker: google.maps.Marker, title: string, description: string = '') {
        this.setState({
            activeMarker: marker.getPosition(),
            activeMarkerTitle: title,
            activeMarkerDescription: description,
            showingInfoWindow: true,
            showingVehicleInfoWindow: false,
        });
    }

    private onVehicleMarkerClick() {
        this.setState({ showingVehicleInfoWindow: true, showingInfoWindow: false });
    }

    private getTruckerTypeName(truckingType: TruckingType) {
        switch(truckingType)
        {
            case TruckingType.LongHaul: return "Long Haul";
            case TruckingType.Regional: return "Regional";
            case TruckingType.Local: return "Local";
            case TruckingType.LocalLongHaul: return "Local/Long";
            default: return "Load1";
        }
    }
}

export default LoadMap;