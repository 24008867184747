import { Button } from '@progress/kendo-react-buttons';
import { Label } from '@progress/kendo-react-labels';
import BrokerInput from "components/BrokerInput";
import { CreatingField } from "components/CreatingField";
import CustomerContactInput from "components/CustomerContactInput";
import CustomerInput from "components/CustomerInput";
import OrderStatusNoteInput from 'components/OrderStatusNoteInput';
import LoadingPanel from "components/LoadingPanel";
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { fetchApi } from "services/api";
import { IDNameViewModel } from "TypeGen/id-name-view-model";
import { JsonResponse } from 'TypeGen/json-response';
import { Command } from "TypeGen/Order/Attributes/command";
import { ViewModel } from "TypeGen/Order/Attributes/view-model";
import { OrderContext } from '.';

type RouteComponentParams = {
  orderId: string;
};

type Props = {
  children?: React.ReactNode
};

const OrderAttributes: React.FC<Props> = () => {

  const { orderId: orderIdParam } = useParams<RouteComponentParams>();
  const { setActiveStopIds, setDeleteStopIds } = useContext(OrderContext);
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState<ViewModel>();
  const [saved, setSaved] = useState(false);

  const refreshOrderAttributes = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Order/Attributes/${orderIdParam}`)
      .then((response: ViewModel) => {
        setDetails(response);
        setLoading(false);
      })
      .catch(err => {
        alert(err);
        setLoading(false);
      });
  }, [orderIdParam]);

  const populateAuthorizationCustomerContacts = useCallback((customerId: number) => {
    if (!customerId) return;
    fetchApi('/api/Quote/CustomerContacts', { CustomerID: customerId }, 'POST')
      .then((response: { PerferredContactID: number, Contacts: IDNameViewModel[] }) => {
        setDetails(prevState => ({
          ...prevState,
          AuthorizationCustomerContacts: response.Contacts,
          AuthorizationCustomerContactID: prevState.AuthorizationCustomerContactID || response.PerferredContactID || null
        }));
      });
  }, [setDetails]);

  const save = () => {
    setLoading(true);
    const command = details.Links.find(l => l.Name === 'Save');
    const data: Command = {
      OriginalBillToCustomerID: details.OriginalBillToCustomerID,
      LogisticsCustomerID: details.LogisticsCustomerID,
      AuthorizationCustomerID: details.AuthorizationCustomerID,
      AuthorizationCustomerContactID: details.AuthorizationCustomerContactID,
      CSACustomerID: details.CSACustomerID,
      ReferenceNumber1: details.ReferenceNumber1,
      ReferenceNumber2: details.ReferenceNumber2,
      ReferenceNumber3: details.ReferenceNumber3,
      ReferenceNumber4: details.ReferenceNumber4,
      LaneName: details.LaneName,
      StatusNote: details.StatusNote,
      BookingSource: details.BookingSource,
      RevenueType1: details.RevenueType1,
      RevenueType2: details.RevenueType2,
      ServiceTeamID: details.ServiceTeamID,
      VehicleSpeed: details.VehicleSpeed,
      BrokerID: details.BrokerID,
      TotalOverrideDistance: details.TotalOverrideDistance,
      DockLevel: details.DockLevel,
      BordersOpen: details.BordersOpen,
      IsCritical: details.IsCritical,
      BilledVehicleTypeID: details.BilledVehicleTypeID,
      CustomerReqestedVehicle: details.CustomerReqestedVehicle,
      SelectedVehicleReasonID: details.SelectedVehicleReasonID,
    }
    fetchApi(command.Link, data, command.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          setSaved(true);
          setTimeout(() => setSaved(false), 2000);
          refreshOrderAttributes();
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(e => {
        // If not problem details
        if (!e?.status) alert('Unable to save order');
      })
      .finally(() => setLoading(false));
  }

  const openFront = () => {
    const win = window.open(`https://app.frontapp.com/open/${details.ReferenceNumber2}`, '_blank');
    win.focus();
  }

  useEffect(() => {
    refreshOrderAttributes();
    setActiveStopIds([]);
    setDeleteStopIds([]);
  }, [orderIdParam, refreshOrderAttributes, setActiveStopIds, setDeleteStopIds]);

  if (loading && !details) {
    return <LoadingPanel />;
  }

  return <div>
    <div className="row">
      <div className="form-group offset-md-4 col-md-4">
        <Label>Total Override Miles</Label>
        <CreatingField
          dataType="number"
          data={details.TotalOverrideDistance}
          inputProps={{ min: 0, format: '0 mi', placeholder: "Total Override Miles", spinners: false }}
          onChange={(e) => {
            setDetails({
              ...details,
              TotalOverrideDistance: e || 0
            });
          }}
        />
      </div>
      <div className="form-group col-md-4">
        <Label>Borders Open</Label>
        <CreatingField
          dataType="boolean"
          data={details.BordersOpen}
          onChange={(e) => {
            setDetails({
              ...details,
              BordersOpen: e
            });
          }}
        />
      </div>
    </div>
    <div className="row">
      <div className="form-group col-md-4">
        <Label>Billing Terms</Label>
        <div className="input-group">
          <select className="custom-select" >
            <option value="1" disabled>Pre-Paid (shipper)</option>
            <option value="2" disabled>Collect (consignee)</option>
            <option value="3" selected>3rd Party (select)</option>
          </select>
        </div>
      </div>
      <div className="form-group col-md-4">
        <Label>Billing Company</Label>
        <div className="input-group">
          <CustomerInput
            disabled={details.IsBillingOrderCompleted}
            onCreditHold={false}
            placeholder='Billing Company'
            CustomerID={details.OriginalBillToCustomerID}
            CustomerNumber={details.OriginalBillToCustomerNumber}
            onChange={(e) => {
              setDetails({
                ...details,
                OriginalBillToCustomerID: e.CustomerID || null,
                OriginalBillToCustomerNumber: e.CustomerNumber
              });
            }}
          />
        </div>
      </div>
      <div className="form-group col-md-4">
        <Label>Logistics Company</Label>
        <div className="input-group">
          <CustomerInput
            placeholder='Logistics Company'
            CustomerID={details.LogisticsCustomerID}
            CustomerNumber={details.LogisticsCustomerNumber}
            onChange={(e) => {
              setDetails({
                ...details,
                LogisticsCustomerID: e.CustomerID || null,
                LogisticsCustomerNumber: e.CustomerNumber
              });
            }}
          />
        </div>
      </div>
    </div>
    <div className="row">
      <div className="form-group col-md-4">
        <Label>Authorization</Label>
        <div className="input-group">
          <select className="custom-select" >
            <option value="0" selected>Other</option>
          </select>
        </div>
      </div>
      <div className="form-group col-md-4">
        <Label>Authorized Company</Label>
        <div className="input-group">
          <CustomerInput
            placeholder='Authorization Company'
            CustomerID={details.AuthorizationCustomerID}
            CustomerNumber={details.AuthorizationCustomerNumber}
            onChange={(e) => {
              setDetails({
                ...details,
                AuthorizationCustomerID: e.CustomerID || null,
                AuthorizationCustomerNumber: e.CustomerNumber,
                AuthorizationCustomerContactID: null,
                AuthorizationCustomerContacts: []
              });
              populateAuthorizationCustomerContacts(e.CustomerID);
            }}
          />
        </div>
      </div>
      <div className="form-group col-md-4">
        <Label>Authorized Contact</Label>
        <div className="input-group">
          <CustomerContactInput
            CustomerID={details.AuthorizationCustomerID}
            CustomerContactID={details.AuthorizationCustomerContactID}
            CustomerContacts={details.AuthorizationCustomerContacts}
            onChange={(e) => {
              if (e.CustomerID) {
                setDetails({
                  ...details,
                  AuthorizationCustomerID: e.CustomerID || null,
                  AuthorizationCustomerNumber: e.CustomerNumber,
                  AuthorizationCustomerContactID: e.CustomerContactID,
                  AuthorizationCustomerContacts: []
                });
                populateAuthorizationCustomerContacts(e.CustomerID);
              } else {
                setDetails({
                  ...details,
                  AuthorizationCustomerContactID: e.CustomerContactID
                });
              }
            }}
          />
        </div>
      </div>
    </div>
    <div className="row">
      <div className="form-group offset-md-4 col-md-4">
        <Label>Calculate ETA @ MPH</Label>
        <CreatingField
          dataType="number"
          data={details.VehicleSpeed}
          inputProps={{ min: 0, format: '0 mph', placeholder: "MPH", spinners: false }}
          onChange={(e) => {
            setDetails({
              ...details,
              VehicleSpeed: e || 0
            });
          }}
        />
      </div>
      <div className="form-group col-md-4">
        <Label>Dock Level</Label>
        <CreatingField
          dataType="boolean"
          data={details.DockLevel}
          onChange={(e) => {
            setDetails({
              ...details,
              DockLevel: e
            });
          }}
        />
      </div>
    </div>
    <div className="row">
      <div className="form-group col-md-4">
        <Label>Selected Vehicle</Label>
        <CreatingField
          data={details.BilledVehicleTypeID}
          renderEditable={(data, onChange) => <select className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
            {details.BilledVehicleTypeOptions.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
          </select>}
          onChange={(data) => {
            setDetails({
              ...details,
              BilledVehicleTypeID: data
            });
          }}
        />
      </div>
      <div className="form-group col-md-4">
        <Label>Selected Vehicle Reason</Label>
        <CreatingField
          data={details.SelectedVehicleReasonID}
          renderEditable={(data, onChange) => <select className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
            {details.SelectedVehicleReasonOptions.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
          </select>}
          onChange={(data) => {
            setDetails({
              ...details,
              SelectedVehicleReasonID: data
            });
          }}
        />
      </div>
      <div className="form-group col-md-4">
        <Label>Customer Requested Vehicle</Label>
        <CreatingField
          dataType="boolean"
          data={details.CustomerReqestedVehicle}
          onChange={(data) => {
            setDetails({
              ...details,
              CustomerReqestedVehicle: data
            });
          }}
        />
      </div>
    </div>
    <div className="row">
      <div className="form-group col-md-4">
        <Label>Ref 1 / Customer</Label>
        <CreatingField
          dataType="string"
          inputProps={{
            placeholder: "Ref 1 / Customer",
            maxLength: 17
          }}
          data={details.ReferenceNumber1}
          onChange={(e) => {
            setDetails({
              ...details,
              ReferenceNumber1: e
            });
          }}
        />
      </div>
      <div className="form-group col-md-4">
        <Label>Ref 2 / Front ID</Label>
        <CreatingField
          dataType="string"
          inputProps={{
            placeholder: "Ref 2 / Front ID",
            maxLength: 17
          }}
          data={details.ReferenceNumber2}
          onChange={(e) => {
            setDetails({
              ...details,
              ReferenceNumber2: e
            });
          }}
          suffix={() => (details.ReferenceNumber2.startsWith("cnv_") || details.ReferenceNumber2.startsWith("msg_")) && <div className="input-group-append">
            <button className="btn btn-outline-secondary" type="button" onClick={openFront}>Open</button>
          </div>}
        />
      </div>
      <div className="form-group col-md-4">
        <Label>Ref 3 / Location</Label>
        <CreatingField
          dataType="string"
          inputProps={{
            placeholder: "Ref 3 / Location",
            maxLength: 17
          }}
          data={details.ReferenceNumber3}
          onChange={(e) => {
            setDetails({
              ...details,
              ReferenceNumber3: e
            });
          }}
        />
      </div>
    </div>

    <div className="row">
      <div className="form-group col-md-4">
        <Label>Ref 4 / Other</Label>
        <CreatingField
          dataType="string"
          inputProps={{
            placeholder: "Ref 4 / Other",
            maxLength: 30
          }}
          data={details.ReferenceNumber4}
          onChange={(e) => {
            setDetails({
              ...details,
              ReferenceNumber4: e
            });
          }}
        />
      </div>
    </div>

    <div className="row">
      <div className="form-group col-md-4">
        <Label>Broker</Label>
        <div className="input-group">
          <BrokerInput
            BrokerID={details.BrokerID}
            BrokerName={details.BrokerName}
            onChange={(e) => {
              setDetails({
                ...details,
                BrokerID: e.BrokerID,
                BrokerName: e.BrokerName
              });
            }}
          />
        </div>
      </div>
      <div className="form-group col-md-4">
        <Label>CSA/FAST Importer</Label>
        <div className="input-group">
          <CustomerInput
            placeholder='CSA/FAST Importer'
            CustomerID={details.CSACustomerID}
            CustomerNumber={details.CSACustomerNumber}
            onChange={(e) => {
              setDetails({
                ...details,
                CSACustomerID: e.CustomerID || null,
                CSACustomerNumber: e.CustomerNumber
              });
            }}
          />
        </div>
      </div>
      <div className="form-group col-md-4">
        <Label>Booking Source</Label>
        <CreatingField
          data={details.BookingSource}
          renderEditable={(data, onChange) => <select className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
            {details.BookingSourceOptions.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
          </select>}
          onChange={(data) => {
            setDetails({
              ...details,
              BookingSource: data
            });
          }}
        />
      </div>
    </div>
    <div className="row">
      <div className="form-group col-md-4">
        <Label>Revenue Allocation</Label>
        <CreatingField
          data={details.RevenueType1}
          renderEditable={(data, onChange) => <select className="custom-select" value={data}
            disabled={details.IsBillingOrderCompleted} onChange={(e) => onChange(parseInt(e.target.value))}>
            {details.RevenueType1Options.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
          </select>}
          onChange={(data) => {
            setDetails({
              ...details,
              RevenueType1: data
            });
          }}
        />
      </div>
      <div className="form-group col-md-4">
        <Label>Cross Dock</Label>
        <CreatingField
          data={details.RevenueType2}
          renderEditable={(data, onChange) => <select className="custom-select" value={data}
            onChange={(e) => onChange(parseInt(e.target.value))}>
            {details.RevenueType2Options.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
          </select>}
          onChange={(data) => {
            setDetails({
              ...details,
              RevenueType2: data
            });
          }}
        />
      </div>
      <div className="form-group col-md-4">
        <Label>Status Note</Label>
        <CreatingField
          dataType="string"
          renderEditable={(data, onChange) => <OrderStatusNoteInput value={data} onChange={onChange} />}
          data={details.StatusNote}
          onChange={(e) => {
            setDetails({
              ...details,
              StatusNote: e
            });
          }}
        />
      </div>
    </div>
    <div className="row">
      <div className="form-group col-md-4">
        <Label>Service Team</Label>
        <CreatingField
          data={details.ServiceTeamID}
          renderEditable={(data, onChange) => <select className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
            {details.ServiceTeamOptions.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
          </select>}
          onChange={(data) => {
            setDetails({
              ...details,
              ServiceTeamID: data
            });
          }}
        />
      </div>
      <div className="form-group col-md-4">
        <Label>Route Name</Label>
        <CreatingField
          dataType="string"
          inputProps={{
            placeholder: "Route Name",
            maxLength: 51
          }}
          data={details.LaneName}
          onChange={(e) => {
            setDetails({
              ...details,
              LaneName: e
            });
          }}
        />
      </div>
      <div className="form-group col-md-4">
        <Label>Critical</Label>
        <CreatingField
          dataType="boolean"
          data={details.IsCritical}
          onChange={(e) => {
            setDetails({
              ...details,
              IsCritical: e
            });
          }}
        />
      </div>
    </div>
    <div className="text-center mt-3">
      <Button themeColor={saved ? "success" : "primary"} onClick={save} disabled={loading}>Save Order</Button>
    </div>
  </div>
}

export default OrderAttributes;