import { Button } from '@progress/kendo-react-buttons';
import { SvgIcon } from "@progress/kendo-react-common";
import { Grid, GridCellProps, GridColumn as Column, GridEvent, GridToolbar } from '@progress/kendo-react-grid';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { arrowRotateCwIcon, fileIcon, filterClearIcon, inheritedIcon, trackChangesAcceptIcon } from '@progress/kendo-svg-icons';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import CenterDivPanel from '../../../components/CenterDivPanel';
import { fetchApi } from '../../../services/api';
import { openWindowPerc } from '../../../services/openWindow';
import { InvoiceEmailReviewDialog } from '../EmailInvoices/DetailRow/InvoiceEmailReviewDialog';
import { TotalChargesCell, TotalChargesHeaderCell } from '../TotalChargesCell';
import { InvoiceCustomerSummary, InvoiceDetail, InvoiceGatewayContext, InvoiceGatewayOrderPoint, PostDataFilter, PostDataState } from './InvoiceGateway';

type props = {
  Filter: PostDataFilter,
  InvoiceCustomerSummary: InvoiceCustomerSummary
};

interface InvoiceDetailCommandCellProps extends GridCellProps {
  sendInvoice: (item: InvoiceDetail) => void;
  showIdentificationRecords: (item: InvoiceDetail) => void;
}

const InvoiceDetailCommandCell = (props: InvoiceDetailCommandCellProps) => {
  let dataItem: InvoiceDetail = props.dataItem;

  return (
    <td className="k-command-cell">
      <Button themeColor={"primary"} onClick={() => props.sendInvoice(dataItem)}>Send Invoice</Button>
      {/*<SplitButton text="Send Invoice" themeColor={"primary"} items={[{ text: 'Set Identification Records' }]}*/}
      {/*  onButtonClick={() => props.sendInvoice(dataItem)}*/}
      {/*  onItemClick={(e) => {*/}
      {/*    e.item.text === 'Set Identification Records' && props.showIdentificationRecords(dataItem)*/}
      {/*  }} />*/}
    </td>
  );
};

const InvoiceGatewayInvoiceDetailComponent = (props: props) => {
  const [loading, setLoading] = useState(false);
  const render = useRef(true);

  const DataState_Take = 175;

  const initialPropsPostDataState = {
    skip: 0,
    take: DataState_Take,
    filters: { ...props.Filter, PostBatchID: props.InvoiceCustomerSummary.InvoicePostBatchID, CustomerID: props.InvoiceCustomerSummary.CustomerID, UsesInvoiceEmailModule: props.InvoiceCustomerSummary.InvoiceEmailRecord }
  } as PostDataState

  const defaultPostDataState = {
    skip: 0,
    take: DataState_Take,
    filters: {
      ...props.Filter, PostBatchID: props.InvoiceCustomerSummary.InvoicePostBatchID, CustomerID: props.InvoiceCustomerSummary.CustomerID, UsesInvoiceEmailModule: props.InvoiceCustomerSummary.InvoiceEmailRecord,
      OrderNumber: null
    }
  } as PostDataState

  const [postDataState, setPostDataState] = useState<PostDataState>({ ...initialPropsPostDataState });

  const totalRecords = useRef(0);

  const [invoiceDetail, setInvoiceDetail] = useState<InvoiceDetail[]>([]);

  const fetchMain = useCallback((append: boolean, _postDataState: PostDataState = null) => {
    //if (props.InvoiceCustomerSummary.Expanded === false)
    //  return;
    setLoading(true);

    const dataGridState = _postDataState == null ? { ...postDataState } : { ..._postDataState };
    if (!append) {
      dataGridState.take = DataState_Take;
      dataGridState.skip = 0;

      //document.querySelector('.InvoiceGatway-andFilter .k-grid-content').scrollTop = 0;
      setPostDataState(dataGridState);
    }

    fetchApi(`/api/Billing/GetInvoiceGatewayInvoices`, dataGridState, 'POST')
      .then(({ Data, Total }) => {
        totalRecords.current = Total;
        if (!append) {
          setInvoiceDetail(Data);
        } else {
          setInvoiceDetail(prevData => prevData.concat(Data));
        }
        setLoading(false);
      }).catch(async e => {
        setLoading(false);
        if (!e?.status)
          alert('Error: Please see admin');
        else if (e.status !== 404) {
          alert(e?.detail);
        }
      });
  }, [postDataState]);

  useEffect(() => {
    if (props.InvoiceCustomerSummary.Expanded === false)
      return;

    if (render.current) {
      render.current = false;
    } else {
      return;
    }
    fetchMain(false);
  }, [fetchMain, postDataState, props.InvoiceCustomerSummary.Expanded]);

  const search = useDebouncedCallback(useCallback((o: PostDataState) => {
    fetchMain(false, o);
  }, [fetchMain]), 750);

  const SaveAndFetchPostDataState = (o: PostDataState) => {
    setPostDataState(o);
    search(o);
  }

  const scrollHandler = (event: GridEvent) => {
    const e = event.nativeEvent;
    if (e.target.scrollTop >= (e.target.scrollHeight - (e.target.clientHeight)) - 1) {
      if (invoiceDetail.length === totalRecords.current)  //When auto-scrolling, ignore when we've hit max records
        return;

      postDataState.skip = postDataState.skip + postDataState.take;

      fetchMain(true);
    }
  }
  const markAsAccepted = (dataItem: InvoiceDetail) => {

    setLoading(true);

    const data = {
      PostBatchNumber: dataItem.InvoicePostBatchID,
      InvoiceID: dataItem.InvoiceID,
      CustomerID: dataItem.CustomerID,
      LocationID: dataItem.LocationID,
      APICredID: dataItem.APICredID,
      Status: 100, //accepted //todo add enum
      InvoiceStatusRecordType: postDataState.filters?.InvoiceStatusRecordType,
      InvoiceEmailRecord: dataItem.InvoiceEmailRecord
    };

    fetchApi(`/api/billing/UpdateInvoiceGatewayInvoiceStatus`, data, 'POST')
      .then((resp: any) => {
        dataItem.InvoiceStatusName = resp.StatusName;
        dataItem.InvoiceStatus = resp.Status;
      }).catch(async e => {
        if (!e?.status)
          alert('Error: Please see admin');
        else if (e.status !== 404) {
          alert(e?.detail);
        }
      }).finally(() => setLoading(false));
  }

  const fetchInvoiceGatewayOrderPoints = (props: InvoiceDetail) => {
    setLoading(true);
    fetchApi(`/api/Billing/InvoiceGatewayOrderPoints/${props.OrderID}`)
      .then((response: any) => {
        props.InvoiceGatewayOrderPoints = response.InvoiceGatewayOrderPoints;
        // setInvoiceBatchSummary([...invoiceBatchSummary]);

        setLoading(false);
      })
      .catch(e => {
        if (!e?.status)
          alert('Error: Please see admin');
        else if (e.status !== 404) {
          alert(e?.detail);
        }
        setLoading(false);
      });
  }

  const setAsInProgress = (dataItem: InvoiceDetail) => {

    setLoading(true);

    const data = {
      InvoiceID: dataItem.InvoiceID,
      PostBatchNumber: dataItem.InvoicePostBatchID,
      CustomerID: dataItem.CustomerID,
      LocationID: dataItem.LocationID,
      APICredID: dataItem.APICredID,
      Status: 19, //processing (generic), server will perform the switch  //todo add enum
      InvoiceStatusRecordType: postDataState.filters?.InvoiceStatusRecordType,
      InvoiceEmailRecord: dataItem.InvoiceEmailRecord
    };

    fetchApi(`/api/billing/UpdateInvoiceGatewayInvoiceStatus`, data, 'POST')
      .then((resp: any) => {
        dataItem.InvoiceStatusName = resp.StatusName;
        dataItem.InvoiceStatus = resp.Status;
        dataItem.InvoiceSentStatusComment = '';
      }).catch(async e => {
        if (!e?.status)
          alert('Error: Please see admin');
        //else if (e.status !== 404) {
        //  alert(e?.detail);
        //}
      }).finally(() => setLoading(false));
  }

  const InvoiceNumberCell = (props: GridCellProps) => {
    if (!props.field)
      return null;

    let dataItem: InvoiceDetail = props.dataItem;

    return (
      <td colSpan={props.colSpan} style={props.style}>
        <a href="#" style={{ color: "#338aff" }} tabIndex={-1} onClick={(e) => {
          e.preventDefault();
          openWindowPerc(`/Order/${dataItem.OrderID}/Rate/${dataItem.InvoiceSequence}/${dataItem.InvoiceLetter}`, .9, .85);
        }}>{dataItem.InvoiceNumber}</a>

        <a href="#" style={{ color: "#338aff" }} tabIndex={-1} className="ml-2" title='Invoice View' onClick={(e) => {
          e.preventDefault();
          openWindowPerc(`/Invoice/View/${dataItem.OrderID}/${dataItem.InvoiceLetter}`, .9, .85);
        }}><SvgIcon icon={fileIcon} size="xsmall" /></a>
      </td>
    );
  };

  const SentStatusCell = (props: GridCellProps) => {
    if (!props.field)
      return null;

    let dataItem: InvoiceDetail = props.dataItem;

    return (
      <td colSpan={props.colSpan} style={props.style}>
        <div className="container">
          <div className="row" style={{ alignItems: 'center' }}>
            <div className="col-3 pl-0" style={{ whiteSpace: 'nowrap' }}>
              <Tooltip position={'bottom'}>
                <div title={dataItem.InvoiceSentStatusComment}>
                  {dataItem.InvoiceStatusName}
                </div>
              </Tooltip>
            </div>
            {dataItem.InvoiceStatus !== 100 && <div className="col-auto pl-2">
              <Button size={'small'} themeColor={"primary"} fillMode="outline" style={{ fontSize: 12 }} onClick={() => markAsAccepted(dataItem)}>
                Mark as Accepted
              </Button>
            </div>}
          </div>
        </div>

      </td>
    );
  };

  const ShowAllReferenceNumbers = (dataItem: InvoiceDetail) => {
    dataItem.ShowAllReferenceNumbers = !dataItem.ShowAllReferenceNumbers;
    setInvoiceDetail([...invoiceDetail]);
  }

  const ReferenceNumberCell = (props: GridCellProps) => {
    if (!props.field)
      return null;

    let dataItem: InvoiceDetail = props.dataItem;
    return (
      <td colSpan={props.colSpan} style={props.style}>
        <div><a href="#" style={{ color: "#338aff" }} tabIndex={-1} onClick={(e) => { e.preventDefault(); ShowAllReferenceNumbers(dataItem); }}>{dataItem.ReferenceNumber1}</a></div>
        {
          dataItem.ShowAllReferenceNumbers && <div>
            <div>{dataItem.ReferenceNumber2}</div>
            <div>{dataItem.ReferenceNumber3}</div>
            {dataItem.ReferenceNumber4 && <div>{dataItem.ReferenceNumber4}</div>}
          </div>
        }
      </td >
    );
  };

  const EntityIdentificationCell = (props: GridCellProps) => {
    const [showMenu, setShowMenu] = useState<boolean>(false);

    if (!props.field)
      return null;

    let dataItem: InvoiceDetail = props.dataItem;

    if (dataItem.LocationID > 0) {
      return (
        <td colSpan={props.colSpan} style={props.style}>
          {!dataItem.InvoiceGatewayOrderPoints
            ?
            <div>
              <a href="#" style={{ color: "#338aff" }} tabIndex={-1} onClick={(e) => { e.preventDefault(); fetchInvoiceGatewayOrderPoints(dataItem); }}><SvgIcon icon={inheritedIcon} /></a>
            </div>
            :
            <div>
              <div><a href="#" style={{ color: "#338aff" }} tabIndex={-1} onClick={(e) => { e.preventDefault(); openWindowPerc(`/edi/updateedientityidentification/${dataItem.LocationID}/${dataItem.InvoiceGatewayOrderPoints.ShipperCustomerID}`, .5, .70); }}>PU</a>: {dataItem.InvoiceGatewayOrderPoints.ShipperNumber}-{dataItem.InvoiceGatewayOrderPoints.ShipperName}</div>
              <div><a href="#" style={{ color: "#338aff" }} tabIndex={-1} onClick={(e) => { e.preventDefault(); openWindowPerc(`/edi/updateedientityidentification/${dataItem.LocationID}/${dataItem.InvoiceGatewayOrderPoints.ConsigneeCustomerID}`, .5, .70); }}>DR</a>: {dataItem.InvoiceGatewayOrderPoints.ConsigneeNumber}-{dataItem.InvoiceGatewayOrderPoints.ConsigneeName}</div>
              <div><a href="#" style={{ color: "#338aff" }} tabIndex={-1} onClick={(e) => { e.preventDefault(); openWindowPerc(`/edi/updateedientityidentification/${dataItem.LocationID}/${dataItem.InvoiceGatewayOrderPoints.BillToCustomerID}/${dataItem.InvoiceID}`, .5, .70); }}>BT</a>: {dataItem.InvoiceGatewayOrderPoints.BillToNumber}-{dataItem.InvoiceGatewayOrderPoints.BillToName}</div>
            </div>
          }
        </td>
      );
    }

    if (dataItem.InvoiceEmailRecord) {
      return (
        <td colSpan={props.colSpan} style={props.style}>
          {showMenu &&
            <InvoiceEmailReviewDialog
              InvoiceID={dataItem.InvoiceID}
              CustomerID={dataItem.CustomerID}
              InvoiceNumber={dataItem.InvoiceNumber}
              CustomerName={dataItem.CustomerName}
              CloseDialog={() => setShowMenu(false)}
            />
          }
          <a
            href="#" style={{ color: "#338aff" }} tabIndex={-1}
            onClick={(e) => {
              e.preventDefault();
              setShowMenu(true);
            }}
          >
            <SvgIcon icon={trackChangesAcceptIcon} />
          </a>
        </td>
      );
    }

    return (
      <td colSpan={props.colSpan} style={props.style}>
        <div>-</div>
      </td>
    );
  };

  return (<div className="" style={{ position: "relative" }}>
    {loading && <CenterDivPanel>
      <div className="k-loading-image" />
    </CenterDivPanel>}
    <Grid
      //className='InvoiceGatway-andFilter'
      style={{
        maxHeight: `490px`,
      }}
      data={invoiceDetail}
      total={totalRecords.current}
      onScroll={scrollHandler}
      resizable={true}
    >
      <GridToolbar size="small">
        <Button
          icon="refresh"
          svgIcon={arrowRotateCwIcon}
          title="Refresh"
          size="small"
          onClick={() => fetchMain(false)}
        />

        <NumericTextBox
          value={postDataState.filters?.OrderNumber}
          placeholder='Order Number'
          min={0}
          spinners={false}
          width={130}
          format="0"
          onChange={(e) => {
            SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, OrderNumber: e?.value } });
          }}
        />

        <Button
          title="Clear All Filters and Sort"
          icon="filter-clear"
          svgIcon={filterClearIcon}
          onClick={() => {
            SaveAndFetchPostDataState({ ...defaultPostDataState });
          }}
        />
      </GridToolbar>
      <Column field="InvoiceNumber" title="Invoice Number" width="120px" cell={InvoiceNumberCell} />
      <Column field="InvoiceStatus" title="Sent Status" cell={SentStatusCell} />
      <Column field="ReferenceNumber1" title="Reference Number" cell={ReferenceNumberCell} />
      <Column field="ReferenceNumber1" title="Entity Identification/Settings" cell={EntityIdentificationCell} />
      <Column field="TotalCharges" title="Total Charges" headerCell={TotalChargesHeaderCell} cell={TotalChargesCell} />
      <Column width={110} cell={(props: GridCellProps) => <InvoiceDetailCommandCell {...props} sendInvoice={(e) => { setAsInProgress(e); }} showIdentificationRecords={(e) => { alert(e.CustomerName); }} />} />
    </Grid>
    {/*</InvoiceGatewayContext.Provider>*/}
    {
      totalRecords.current > 0 &&
      <div className="k-pager k-pager-md k-grid-pager">
        <div style={{ marginLeft: "auto", marginRight: 0 }}>
          {postDataState.skip + postDataState.take > totalRecords.current ? totalRecords.current : postDataState.skip + postDataState.take} of {totalRecords.current} items
        </div>
      </div>
    }
  </div>);
}

export default InvoiceGatewayInvoiceDetailComponent;
