import * as React from 'react';
import Moment from 'moment';
import { fetchApi } from '../../services/api';
import JsonPretty from '../../components/JsonPretty';
import { Dialog } from '@progress/kendo-react-dialogs';

interface Props {
  CloseDialog: () => void;
  DriverAccidentID: number,
}

type AuditLogViewModel = {
  DateTime: string;
  UserName: string;
  OldValues: string;
  NewValues: string;
}

type Response = {
  Logs: AuditLogViewModel[];
}

const DriverAccidentAuditLogs = (props: Props) => {

  const [logs, setLogs] = React.useState<AuditLogViewModel[]>([]);

  React.useEffect(() => {
    fetchApi(`/api/Asset/DriverAccidentAuditLogs/${props.DriverAccidentID}`)
      .then((response: Response) => {
        setLogs(response.Logs);
      })
  }, [props.DriverAccidentID]);

  return (
    <Dialog title="Audit Logs" onClose={props.CloseDialog} className='dialog-w1140'>
      <table className="table">
        <thead>
          <tr>
            <th>User</th>
            <th>Timestamp</th>
            <th>Old Values</th>
            <th>New Values</th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log, index) => {
            return <tr key={index}>
                  <td>{log.UserName}</td>
                  <td>{Moment.utc(log.DateTime).local().format("YYYY-MM-DD HH:mm:ss")}</td>
                  <td><JsonPretty json={log.OldValues} /></td>
                  <td><JsonPretty json={log.NewValues} /></td>
                </tr>
          })}
        </tbody>
      </table>
    </Dialog>
  );
}

export default DriverAccidentAuditLogs;
