import { filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { ComboBoxFilterChangeEvent, MultiColumnComboBox } from '@progress/kendo-react-dropdowns';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { fetchApi } from '../../../services/api';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  invoiceGatewayPartners?: InvoiceGatewayPartner[];
  preSelectedInvoiceGatewayPartner?: { CustomerId?: number, UsesInvoiceEmailModule?: boolean };

  selectedInvoiceGatewayPartner: (CustomerId?: number, UsesInvoiceEmailModule?: boolean) => void;
  returnedInvoiceGatewayPartners?: (invoiceGatewayPartners: InvoiceGatewayPartner[]) => void;
}

export enum TransferMethod {
  EDIFTP,
  EDIAS2,
  BS,
  FTP,
  API
}

export interface InvoiceGatewayPartner {
  LocationID: number;
  CustomerID: number;
  CustomerNumber: string;
  Name: string;
  CommunicationPartner: string;
  TransferMethod: TransferMethod;
  TransferMethodValue: string;
  DataFilterByContents: string;
  APICredID: number;
  UniqueIdentifier: string;
  UsesInvoiceEmailModule: boolean;
}

const InvoiceGatewayCustomerMultiColumnComboBox = (props: Props) => {
  const [invoiceGatewayPartners, setInvoiceGatewayPartners] = useState<InvoiceGatewayPartner[] | undefined>(props.invoiceGatewayPartners);
  //const [invoiceGatewayPartnersSubset, setInvoiceGatewayPartnersSubset] = useState<InvoiceGatewayPartner[] | undefined>(props.invoiceGatewayPartners);
  const [invoiceGatewayPartner, setInvoiceGatewayPartner] = useState<InvoiceGatewayPartner | null>(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  //useEffect(() => {
  //  if (!props.invoiceGatewayPartners) {
  //    fetchApi(`/api/Billing/InvoiceGatewayPartners`)
  //      .then((response: any) => {
  //        setInvoiceGatewayPartners(response.InvoiceGatewayPartners);
  //        setInvoiceGatewayPartnersSubset(response.InvoiceGatewayPartners);
  //      })
  //      .catch(e => {
  //        if (!e?.status) {
  //          alert('Error: Please see admin');
  //        } else if (e.status !== 404) {
  //          alert(e?.detail);
  //        }
  //      });
  //  }
  //}, [props.invoiceGatewayPartners]);

  useEffect(() => {
    if (invoiceGatewayPartners && props.preSelectedInvoiceGatewayPartner) {
      setInvoiceGatewayPartner(invoiceGatewayPartners.find(x => x.CustomerID === props.preSelectedInvoiceGatewayPartner.CustomerId && x.UsesInvoiceEmailModule === props.preSelectedInvoiceGatewayPartner.UsesInvoiceEmailModule) ?? null);
    } else {
      setInvoiceGatewayPartner(null);
    }
    if (invoiceGatewayPartners && props.returnedInvoiceGatewayPartners)
      props.returnedInvoiceGatewayPartners(invoiceGatewayPartners);
  }, [props, invoiceGatewayPartners, props.preSelectedInvoiceGatewayPartner]);

  const search = useDebouncedCallback(async (filter: FilterDescriptor) => {
    if (filter.value.length > 2) {

      setLoading(true);
      const response = await fetchApi(`/api/Billing/InvoiceGatewayPartners?filter=${filter.value}`);
      if (response.InvoiceGatewayPartners.length > 0) {
        setOpen(true);
      }
      setInvoiceGatewayPartners(response.InvoiceGatewayPartners);
      setLoading(false);
    } else {
      //setInvoiceGatewayPartners(invoiceGatewayPartners?.slice() || []);
      setInvoiceGatewayPartners([]);
    }
  }, 750);

  const filterChange = async (event: ComboBoxFilterChangeEvent) => {
    setOpen(false);
    search(event.filter);

  };

  const multiColumnComboBoxColumns = [
    { field: "CustomerNumber", header: "Code", width: "125px" },
    { field: "Name", header: "Customer", width: "300px" },
    { field: "TransferMethodValue", header: "Method", width: "75px" },
    { field: "CommunicationPartner", header: "Communication Partner", width: "300px" }
  ];

  return (
    <MultiColumnComboBox
      style={{ width: "175px" }}
      popupSettings={{ height: 300 }}
      placeholder="Customer"
      data={invoiceGatewayPartners || []}
      columns={multiColumnComboBoxColumns}
      value={invoiceGatewayPartner}
      dataItemKey="UniqueIdentifier"
      textField="CustomerNumber"
      filterable={true}
      loading={loading}
      opened={open}
      onClose={() => setOpen(false)}
      onOpen={() => { if (invoiceGatewayPartners?.length > 0) { setOpen(true); } }}
      onFilterChange={filterChange}
      onChange={(e) => {
        setOpen(false);
        setInvoiceGatewayPartner(e.value);
        props.selectedInvoiceGatewayPartner(e.value?.CustomerID, e.value?.UsesInvoiceEmailModule);
        console.log(e.value);
      }}
    />
  );
};

export default InvoiceGatewayCustomerMultiColumnComboBox;