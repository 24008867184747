import SimpleDatePicker from "./SimpleDatePicker";
import { DropDownList, DropDownListProps } from "@progress/kendo-react-dropdowns";
import { Loader } from "@progress/kendo-react-indicators";
import { MaskedTextBox, MaskedTextBoxProps, NumericTextBox, NumericTextBoxHandle, NumericTextBoxProps, TextBoxProps } from "@progress/kendo-react-inputs";
import { formatNumber } from '@progress/kendo-intl';
import Moment from 'moment-timezone';
import { useRef, useEffect, useState } from "react";
import { DefaultInputComponentProps, parsePhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/input";
import { SvgIcon } from "@progress/kendo-react-common";
import { checkIcon, hyperlinkOpenIcon, xIcon } from '@progress/kendo-svg-icons';

interface BaseProps<T> {
    data: T;
    readOnly?: boolean;
    required?: boolean;
    open?: () => void;
    save: (data: T) => Promise<void>;
    renderDisplay?: (data: T) => JSX.Element | string;
    renderEditable?: (data: T, saving: boolean, onChange: (data: T) => void) => JSX.Element;
}

interface PropsGeneric<T> extends BaseProps<T> {
    dataType?: never;
    inputProps?: never;
}

interface PropsString<T> extends BaseProps<T> {
    dataType: "string";
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

interface PropsNumber<T> extends BaseProps<T> {
    dataType: "number";
    inputProps?: NumericTextBoxProps;
}

interface PropsDate<T> extends BaseProps<T> {
    dataType: "date";
    inputProps?: TextBoxProps;
}

interface PropsBoolean<T> extends BaseProps<T> {
    dataType: "boolean";
    inputProps?: DropDownListProps;
}

interface PropsPhone<T> extends BaseProps<T> {
    dataType: "phone";
    inputProps?: MaskedTextBoxProps;
}

interface PropsIntlPhone<T> extends BaseProps<T> {
    dataType: "intlphone";
    inputProps?: DefaultInputComponentProps;
}

type Props<T> = PropsGeneric<T> | PropsString<T> | PropsNumber<T> | PropsDate<T> | PropsBoolean<T> | PropsPhone<T> | PropsIntlPhone<T>;

const yesNoOptions = [{ text: 'Yes', value: 'true' }, { text: 'No', value: 'false' }];

export const EditableField = <T extends any>(props: Props<T>) => {
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [data, setData] = useState(props.data);

    const phoneInput = useRef<MaskedTextBox>();
    const numberInput = useRef<NumericTextBoxHandle>();

    // Update internal data
    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    const stopEditing = () => {
        setData(props.data);
        setEditing(false);
    }

    const startEditing = () => {
        if (props.readOnly) return;
        setEditing(true);
    }

    useEffect(() => {
        if (editing) {
            if (props.dataType === "phone") {
                phoneInput.current.focus();
            }
            if (props.dataType === "number") {
                numberInput.current.focus();
            }
        }
    }, [editing, props.dataType]);

    const save = async () => {
        setSaving(true);
        try {
            await props.save(data);
            setEditing(false);
        } catch { }
        setSaving(false);
    }

    const saveForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        save();
    }

    const captureEscape = (e: any) => {
        if (e.key === "Escape") {
            stopEditing();
        }
    }

    const formattedData = () => {
        switch (props.dataType) {
            case "string":
            case "phone":
                return props.data as string;
            case "intlphone":
                const value = parsePhoneNumber(props.data as string, "US");
                return value ? value.countryCallingCode == '1' ? value.formatNational() : value.formatInternational() : props.data as string;
            case "number":
                return formatNumber(props.data as number, props.inputProps?.format || "n0");
            case "boolean":
                return props.data ? 'Yes' : 'No'
            case "date":
                return Moment.utc(props.data).tz("America/New_York").format("MM/DD/YYYY");
        }
    }

    const renderDisplay = () => {
        return <span onClick={(props.readOnly && props.open)? props.open : startEditing} className={`${props.readOnly ? 'disabled' : 'editable-field'} text-left btn btn-block`}>
            {props.renderDisplay ? props.renderDisplay(props.data) : formattedData()}
        </span>
    }

    const renderInputField = () => {
        switch (props.dataType) {
            case "string":
                return <input autoFocus required={props.required} disabled={saving} className="form-control" type="text" value={data as string} onChange={(e) => {
                    setData(e.target.value as T);
                }} {...props.inputProps} />;
            case "number":
                return <NumericTextBox
                    ref={numberInput}
                    disabled={saving}
                    required={props.required}
                    className="k-numeric-wrap-append flex-fill"
                    value={data as number}
                    onChange={(e) => setData(e.value as T)}
                    {...props.inputProps}
                />;
            case "boolean":
                return <DropDownList
                    disabled={saving}
                    required={props.required}
                    className="k-dropdown-wrap-append flex-fill"
                    value={yesNoOptions.find(x => x.value === data.toString())}
                    textField="text"
                    dataItemKey="value"
                    data={yesNoOptions}
                    onChange={(e) => setData((e.target.value.value === 'true') as T)}
                    {...props.inputProps}
                />;
            case "date":
                return <SimpleDatePicker
                    required={props.required}
                    value={data as Date}
                    min={new Date(1753, 1, 1)}
                    max={new Date(2999, 11, 31)}
                    onChange={(e) => setData(e as T)}
                    inputProps={{
                        disabled: saving,
                        className: "k-textbox-wrap-append",
                        ...props.inputProps
                    }}
                />;
            case "phone":
                return <MaskedTextBox
                    ref={phoneInput}
                    disabled={saving}
                    required={props.required}
                    validationMessage="Please enter a valid phone number!"
                    mask="(000) 000-0000"
                    className="k-maskedtextbox-wrap-append flex-fill"
                    value={data as string}
                    onChange={(e) => e.value === '(___) ___-____' ? setData('' as T) : setData(e.value as T)}
                    {...props.inputProps}
                />
            case "intlphone":
                return <PhoneInput
                    autoFocus
                    disabled={saving}
                    required={props.required}
                    className="form-control"
                    placeholder="Use + for international numbers"
                    value={data as any}
                    defaultCountry="US"
                    onChange={(value) => setData(value as T)}
                    {...props.inputProps}
                />
        }
    }

    const renderEditable = () => {
        return <form className="input-group flex-nowrap" onKeyDown={captureEscape} onSubmit={saveForm}>
            {props.renderEditable ? props.renderEditable(data, saving, setData) : renderInputField()}
            <div className="input-group-append">
                {props.open && <button className="btn btn-outline-secondary" disabled={saving} type="button" onClick={props.open}>
                    <SvgIcon icon={hyperlinkOpenIcon} />
                </button>}
                <button className="btn btn-outline-primary" disabled={saving} type="submit">
                    {saving ? <Loader size="small" /> : <SvgIcon icon={checkIcon} />}
                </button>
                <button className="btn btn-outline-warning" disabled={saving} type="button" onClick={stopEditing}>
                    <SvgIcon icon={xIcon} />
                </button>
            </div>
        </form>;
    };

    return <>
        {editing ? renderEditable() : renderDisplay()}
    </>;
}