import { DropDownButton } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { plusIcon } from "@progress/kendo-svg-icons";
import Moment from 'moment-timezone';
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { AssetComplianceTypeID } from "TypeGen/Assets/asset-compliance-type-id";
import { DocumentType } from "TypeGen/Documents/document-type";
import { JsonResponse } from "TypeGen/json-response";
import Documents, { DocumentEntityType } from "views/Documents/Documents";
import AuditInfo, { AuditInfoViewModel } from "../../components/AuditInfo";
import LocalDateOnlyCell from "../../components/cells/LocalDateOnlyCell";
import CenterDivPanel from "../../components/CenterDivPanel";
import { EditableAddress } from "../../components/EditableAddress";
import { EditableField } from "../../components/EditableField";
import { EditableIDName } from "../../components/EditableIDName";
import { fetchApi } from "../../services/api";
import { AddressViewModel } from '../../types/address';
import { IDName } from '../../types/idname';
import { Title } from '../../utils/title';
import { PayDivisions } from "../../utils/utils";
import OwnerAutoComplete, { GetOwnerAutoCompleteDisplayValue } from "../AssetOwners/OwnerAutoComplete";
import { AssetEntityType } from "../Assets/AssetPanel";
import CompliancePopup, { ComplianceViewModel } from "../Assets/CompliancePopup";
import { OwnerViewModel } from "../Assets/OwnerGrid";
import { DriverNavBar } from "./NavBar";

type Props = {
  isDialog?: boolean
  driverId?: number;

  onClose?: () => void;
}

type RouteComponentParams = {
  driverId: string;
};

export type DriverProfileType = {
  Driver: DriverType,
  Users: IDName[];
}

type DriverType = {
  ModNum: number;
  DriverNumber: string;
  FirstName: string;
  LastName: string;
  Active: boolean;
  PayrollActive: boolean;
  CanCrossBorder: boolean;
  ComplianceDays: number;
  BirthDate: Date;
  HiredDate: Date;
  HiredComment: string;
  HiredByUserID: number;
  EMailAddress: string;
  CellPhone1: string;
  CellPhone2: string;
  HomePhone1: string;
  HomePhone2: string;
  Pager1: string;
  FaxNumber: string;
  EmergencyContact: string;
  EmergencyContactPhoneNumber: string;
  PayDivision: number;
  Domicile: string;
  Print1099: boolean;
  MailPayCheck: boolean;
  DDCShuttle: boolean;
  RandomDrugTest: boolean;
  RegulatedDrugTest: boolean;
  TankerEndorsement: boolean;
  Citizenship1: string;
  Citizenship2: string;
  DriversLicense: string;
  DriversLicenseState: string;
  DriversLicenseClass: string;
  SecurityNumber: string;
  DriversLicenseExpiryDate: Date;
  MVRTestDueDate: Date;
  LastReviewDate: Date;
  NextReviewDate: Date;
  LastPhysicalDate: Date;
  NextPhysicalDate: Date;
  WorkVisaRequired: boolean;
  WorkVisaExpiryDate: Date;
  WorkersCompensationExpiryDate: Date;
  FacilityAccessStartDate: Date;
  FacilityAccessEndDate: Date;
  PassportNumber: string;
  PassportExpiryDate: Date;
  PassportCountry: string;
  Address: AddressViewModel;
  AuditInfo: AuditInfoViewModel;
  Compliance: ComplianceViewModel[];
  OwnerInfo: OwnerViewModel;
}
const Domiciles = [{ ID: 'U', Name: 'US' }, { ID: 'C', Name: 'CAN' }, { ID: 'M', Name: 'MEX' }];
//ISO-3166 Alpha-3
const CountryCodes = [{ ID: 'USA', Name: 'USA' }, { ID: 'CAN', Name: 'CANADA' }, { ID: 'MEX', Name: 'MEXICO' }, { ID: 'IND', Name: 'INDIA' }, { ID: 'PAN', Name: 'PANAMA' }, { ID: 'ROU', Name: 'ROMANIA' }];

const DriverProfile = (props: Props) => {

  const { driverId: driverIdParam } = useParams<RouteComponentParams>();
  const [driverId, setDriverId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<DriverProfileType>();
  const [compliancePopup, setCompliancePopup] = useState<ComplianceViewModel>(null);
  const [showDriverPhotoPopup, setShowDriverPhotoPopup] = useState(false);
  const history = useHistory();

  const fetchProfile = useCallback(() => {
    async function fetchData() {
      setLoading(true);
      await fetchApi(`/api/Asset/Driver/${driverId}`)
        .then((data: DriverProfileType) => {
          setLoading(false);
          setProfile(data);
        })
        .catch(err => {
          alert(err);
          setLoading(false);
        });
    }
    fetchData();
  }, [driverId]);

  useEffect(() => {
    if (props.driverId > 0)
      setDriverId(props.driverId);
    else
      setDriverId(Number(driverIdParam));
  }, [driverIdParam, props.driverId]);

  useEffect(() => {
    if (driverId > 0) fetchProfile();
  }, [driverId, fetchProfile]);

  const saveAsyncProfile = async (value: Partial<DriverType>): Promise<void> => {
    let errorMsg = '';
    const newProfile: DriverType = Object.assign({}, profile.Driver, value);
    await fetchApi(`/api/Asset/Driver/${driverId}`, newProfile, 'PUT')
      .then((response: JsonResponse) => {
        if (response.Success === false) {
          errorMsg = response.ErrorMessage;
        } else {
          const newProfile: DriverType = Object.assign({}, profile.Driver, value);
          setProfile({ ...profile, Driver: { ...newProfile, ModNum: newProfile.ModNum + 1 } });
        }
      }).catch((e) => {
        if (e?.status) {
          return Promise.reject(e);
        } else {
          errorMsg = "Unknown Error Occurred!";
        }
      });
    if (errorMsg.length) {
      alert(errorMsg);
      return Promise.reject(errorMsg);
    }
    return Promise.resolve();
  }

  const removeCompliance = (compliance: ComplianceViewModel) => {
    return fetchApi(`/api/Asset/RemoveDriverCompliance/${driverId}/${compliance.TypeID}`, {}, 'DELETE')
      .then((response: JsonResponse) => {
        if (response.Success) {
          return fetchProfile();
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(err => {
        alert(`Error: ${err}`);
      });
  }

  const updateCompliance = (compliance: ComplianceViewModel) => {
    return fetchApi(`/api/Asset/UpdateDriverCompliance/${driverId}`, compliance, 'PUT')
      .then((response: JsonResponse) => {
        if (response.Success) {
          return fetchProfile();
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(err => {
        alert(`Error: ${err}`);
      });
  }

  const dataView = () => {
    if (loading || !profile) {
      return <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>;
    }

    return (<>
      {props.isDialog !== true && driverId > 0 && <DriverNavBar id={driverId} currentTab="Profile" />}
      <Title string={profile?.Driver?.DriverNumber} />
      <div className="row m-2">
        <div className="col-md-5">
          <div className="form-group row">
            <label htmlFor="DriverNumber" className="col-sm-2 col-form-label">Driver ID</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 10 }}
                data={profile.Driver.DriverNumber}
                save={async (data) => await saveAsyncProfile({ DriverNumber: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="DriverName" className="col-sm-2 col-form-label">Name</label>
            <div className="col-sm-10">
              <EditableField
                data={{ FirstName: profile.Driver.FirstName, LastName: profile.Driver.LastName }}
                renderDisplay={(data) => `${data.FirstName} ${data.LastName}`}
                renderEditable={(data, saving, onChange) => <>
                  <input autoFocus required disabled={saving} className="form-control" type="text" value={data.FirstName} maxLength={20} onChange={(e) => onChange({ FirstName: e.target.value, LastName: data.LastName })} />
                  <input required disabled={saving} className="form-control" type="text" value={data.LastName} maxLength={20} onChange={(e) => onChange({ FirstName: data.FirstName, LastName: e.target.value })} />
                </>}
                save={async (data) => await saveAsyncProfile({ FirstName: data.FirstName, LastName: data.LastName })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="Address" className="col-sm-2 col-form-label">Home Address</label>
            <div className="col-sm-10">
              <EditableAddress
                title="Home Address"
                data={profile.Driver.Address}
                save={async (data) => await saveAsyncProfile({ Address: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="PayrollActive" className="col-sm-2 col-form-label">Payroll Active</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Driver.PayrollActive}
                save={async (data) => await saveAsyncProfile({ PayrollActive: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="CanCrossBorder" className="col-sm-2 col-form-label">Can Cross Border</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Driver.CanCrossBorder}
                save={async (data) => await saveAsyncProfile({ CanCrossBorder: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="EMailAddress" className="col-sm-2 col-form-label">Email Address</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ type: 'email', maxLength: 50 }}
                data={profile.Driver.EMailAddress}
                save={async (data) => await saveAsyncProfile({ EMailAddress: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="CellPhone1" className="col-sm-2 col-form-label">Cell Phone 1</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="phone"
                data={profile.Driver.CellPhone1}
                save={async (data) => await saveAsyncProfile({ CellPhone1: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="CellPhone2" className="col-sm-2 col-form-label">Cell Phone 2</label>
            <div className="col-sm-10">
              <EditableField
                dataType="phone"
                data={profile.Driver.CellPhone2}
                save={async (data) => await saveAsyncProfile({ CellPhone2: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="HomePhone1" className="col-sm-2 col-form-label">Home Phone 1</label>
            <div className="col-sm-10">
              <EditableField
                dataType="phone"
                data={profile.Driver.HomePhone1}
                save={async (data) => await saveAsyncProfile({ HomePhone1: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="HomePhone2" className="col-sm-2 col-form-label">Home Phone 2</label>
            <div className="col-sm-10">
              <EditableField
                dataType="phone"
                data={profile.Driver.HomePhone2}
                save={async (data) => await saveAsyncProfile({ HomePhone2: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="Pager1" className="col-sm-2 col-form-label">Open Path No.</label>
            <div className="col-sm-10">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 51 }}
                data={profile.Driver.Pager1}
                save={async (data) => await saveAsyncProfile({ Pager1: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="FaxNumber" className="col-sm-2 col-form-label">Fuel Card No.</label>
            <div className="col-sm-10">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 51 }}
                data={profile.Driver.FaxNumber}
                save={async (data) => await saveAsyncProfile({ FaxNumber: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="Domicile" className="col-sm-2 col-form-label">Domicile</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Driver.Domicile}
                renderDisplay={(data) => Domiciles.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(e.target.value)}>
                  {Domiciles.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ Domicile: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="EmergencyContact" className="col-sm-2 col-form-label">Emergency Contact</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 20 }}
                data={profile.Driver.EmergencyContact}
                save={async (data) => await saveAsyncProfile({ EmergencyContact: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="EmergencyContactPhoneNumber" className="col-sm-2 col-form-label">Emergency Contact Phone</label>
            <div className="col-sm-10">
              <EditableField
                dataType="phone"
                data={profile.Driver.EmergencyContactPhoneNumber}
                save={async (data) => await saveAsyncProfile({ EmergencyContactPhoneNumber: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="BirthDate" className="col-sm-2 col-form-label">Birthday</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="date"
                data={Moment.utc(profile.Driver.BirthDate).tz("America/New_York").toDate()}
                save={async (data) => await saveAsyncProfile({ BirthDate: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="HiredDate" className="col-sm-2 col-form-label">Hired Date</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="date"
                data={Moment.utc(profile.Driver.HiredDate).tz("America/New_York").toDate()}
                save={async (data) => await saveAsyncProfile({ HiredDate: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="HiredComment" className="col-sm-2 col-form-label">Hired Comment</label>
            <div className="col-sm-10">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 60 }}
                data={profile.Driver.HiredComment}
                save={async (data) => await saveAsyncProfile({ HiredComment: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="HiredDate" className="col-sm-2 col-form-label">Hired By</label>
            <div className="col-sm-10">
              <EditableIDName
                data={profile.Users.find(x => x.ID === profile.Driver.HiredByUserID)}
                options={profile.Users}
                save={async (data) => await saveAsyncProfile({ HiredByUserID: data.ID })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="PayDivision" className="col-sm-2 col-form-label">Payroll</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Driver.PayDivision}
                renderDisplay={(data) => PayDivisions.find(x => x.ID == data)?.Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {PayDivisions.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ PayDivision: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="Print1099" className="col-sm-2 col-form-label">Print 1099</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Driver.Print1099}
                save={async (data) => await saveAsyncProfile({ Print1099: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="MailPayCheck" className="col-sm-2 col-form-label">Mail Paycheck</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Driver.MailPayCheck}
                save={async (data) => await saveAsyncProfile({ MailPayCheck: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="DDCShuttle" className="col-sm-2 col-form-label">DDC Shuttle</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Driver.DDCShuttle}
                save={async (data) => await saveAsyncProfile({ DDCShuttle: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="RandomDrugTest" className="col-sm-2 col-form-label">Random Drug Test</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Driver.RandomDrugTest}
                save={async (data) => await saveAsyncProfile({ RandomDrugTest: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="RegulatedDrugTest" className="col-sm-2 col-form-label">Regulated Drug Test</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Driver.RegulatedDrugTest}
                save={async (data) => await saveAsyncProfile({ RegulatedDrugTest: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="SecurityNumber" className="col-sm-2 col-form-label">Security Number</label>
            <div className="col-sm-10">
              <EditableField
                inputProps={{ maxLength: 9 }}
                dataType="string"
                data={profile.Driver.SecurityNumber}
                renderDisplay={(data) => (data != null ? 'Hidden' : "None")}
                save={async (data) => await saveAsyncProfile({ SecurityNumber: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="TankerEndorsement" className="col-sm-2 col-form-label">Tanker Endorsement</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Driver.TankerEndorsement}
                save={async (data) => await saveAsyncProfile({ TankerEndorsement: data })}
              />
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <div className="form-group row">
            <label htmlFor="AssignedOwner" className="col-sm-2 col-form-label">Assigned Owner</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Driver.OwnerInfo}
                renderDisplay={(data) => (data != null ? GetOwnerAutoCompleteDisplayValue(data) : "None")}
                renderEditable={(data, saving, onChange) =>
                  <OwnerAutoComplete
                    disabled={saving}
                    placeholder='type an owner id, name or leave blank...'
                    style={{ flex: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    selectedOwnerID={profile.Driver.OwnerInfo?.OwnerID}
                    setFocus={true}
                    onSelectedOwner={(e) => onChange(e)}
                  />}
                open={profile.Driver.OwnerInfo ? () => history.push(profile.Driver.OwnerInfo.Links.find(x => x.Name == "OwnerProfile").Link) : null}
                save={async (data) => {
                  if (data == null && window.confirm("Remove Owner?") == false) {
                    return Promise.resolve();
                  }
                  await saveAsyncProfile({ OwnerInfo: data });
                }}
              />
            </div>
          </div>
          <h2>Compliance <span title="Minimum Days Of Compliance Left">({profile.Driver.ComplianceDays})</span></h2>
          <Grid
            data={profile.Driver.Compliance.filter(x => x.Active)}
            onRowDoubleClick={(row) => {
              setCompliancePopup(row.dataItem)
            }}
          >
            <GridToolbar>
              <DropDownButton
                themeColor="primary" icon="plus" svgIcon={plusIcon} text="Add"
                items={[
                  { TypeID: AssetComplianceTypeID.HazMat, Name: 'HAZMAT CERTIFIED' },
                  { TypeID: AssetComplianceTypeID.FAST, Name: 'FAST APPROVED' },
                  { TypeID: AssetComplianceTypeID.CSA, Name: 'CSA APPROVED' },
                  { TypeID: AssetComplianceTypeID.TWIC, Name: 'TWIC CERTIFIED' },
                  { TypeID: AssetComplianceTypeID.TSA, Name: 'TSA CERTIFIED' },
                  { TypeID: AssetComplianceTypeID.Pet, Name: 'PET ONBOARD' },
                ]}
                textField="Name"
                onItemClick={(e) => {
                  setCompliancePopup(profile.Driver.Compliance.find(x => x.TypeID == e.item.TypeID) ?? {
                    ...e.item,
                    Start: "1900-01-01T05:00:00",
                    Expiry: "2999-12-31T00:00:00",
                  });
                }}
              />
            </GridToolbar>
            <Column field="Name" />
            <Column field="Start" cell={LocalDateOnlyCell} />
            <Column field="Expiry" cell={LocalDateOnlyCell} />
          </Grid>
          {compliancePopup && <CompliancePopup
            assetEntityType={AssetEntityType.Driver}
            data={compliancePopup}
            close={() => setCompliancePopup(null)}
            remove={removeCompliance}
            save={updateCompliance}
          />}
          <br />
          <div className="form-group row">
            <label htmlFor="Citizenship1" className="col-sm-2 col-form-label">Citizenship 1</label>
            <div className="col-sm-4">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 20 }}
                data={profile.Driver.Citizenship1}
                save={async (data) => await saveAsyncProfile({ Citizenship1: data })}
              />
            </div>
            <label htmlFor="OccupationalAccidentInsurance" className="col-sm-2 col-form-label">OCC. ACC.</label>
            <div className="col-sm-4">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 20 }}
                data={profile.Driver.Citizenship2}
                save={async (data) => await saveAsyncProfile({ Citizenship2: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="DriversLicense" className="col-sm-2 col-form-label">Driver's License</label>
            <div className="col-sm-4">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 26 }}
                data={profile.Driver.DriversLicense}
                save={async (data) => await saveAsyncProfile({ DriversLicense: data })}
              />
            </div>
            <label htmlFor="DriversLicenseState" className="col-sm-2 col-form-label">State</label>
            <div className="col-sm-4">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 2, placeholder: 'Two-Digit State Code' }}
                data={profile.Driver.DriversLicenseState}
                save={async (data) => await saveAsyncProfile({ DriversLicenseState: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="DriversLicenseClass" className="col-sm-2 col-form-label">License Class</label>
            <div className="col-sm-4">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 5 }}
                data={profile.Driver.DriversLicenseClass}
                save={async (data) => await saveAsyncProfile({ DriversLicenseClass: data })}
              />
            </div>
            <label htmlFor="DriversLicenseExpiryDate" className="col-sm-2 col-form-label">License Expiry</label>
            <div className="col-sm-4">
              <EditableField
                required
                dataType="date"
                data={Moment.utc(profile.Driver.DriversLicenseExpiryDate).tz("America/New_York").toDate()}
                save={async (data) => await saveAsyncProfile({ DriversLicenseExpiryDate: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="MVRTestDueDate" className="col-sm-2 col-form-label">MVR Test Due</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="date"
                data={Moment.utc(profile.Driver.MVRTestDueDate).tz("America/New_York").toDate()}
                save={async (data) => await saveAsyncProfile({ MVRTestDueDate: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Openpath Access Start</label>
            <div className="col-sm-4">
              <EditableField
                required
                dataType="date"
                data={Moment.utc(profile.Driver.FacilityAccessStartDate).tz("America/New_York").toDate()}
                save={async (data) => await saveAsyncProfile({ FacilityAccessStartDate: data })}
              />
            </div>
            <label className="col-sm-2 col-form-label">Openpath Access End</label>
            <div className="col-sm-4">
              <EditableField
                required
                dataType="date"
                data={Moment.utc(profile.Driver.FacilityAccessEndDate).tz("America/New_York").toDate()}
                save={async (data) => await saveAsyncProfile({ FacilityAccessEndDate: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Last Review</label>
            <div className="col-sm-4">
              <EditableField
                required
                dataType="date"
                data={Moment.utc(profile.Driver.LastReviewDate).tz("America/New_York").toDate()}
                save={async (data) => await saveAsyncProfile({ LastReviewDate: data })}
              />
            </div>
            <label className="col-sm-2 col-form-label">Next Review</label>
            <div className="col-sm-4">
              <EditableField
                dataType="date"
                data={Moment.utc(profile.Driver.NextReviewDate).tz("America/New_York").toDate()}
                save={async (data) => await saveAsyncProfile({ NextReviewDate: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Last Physical</label>
            <div className="col-sm-4">
              <EditableField
                required
                dataType="date"
                data={Moment.utc(profile.Driver.LastPhysicalDate).tz("America/New_York").toDate()}
                save={async (data) => await saveAsyncProfile({ LastPhysicalDate: data })}
              />
            </div>
            <label className="col-sm-2 col-form-label">Next Physical</label>
            <div className="col-sm-4">
              <EditableField
                required
                dataType="date"
                data={Moment.utc(profile.Driver.NextPhysicalDate).tz("America/New_York").toDate()}
                save={async (data) => await saveAsyncProfile({ NextPhysicalDate: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="WorkVisaRequired" className="col-sm-2 col-form-label">Work Visa</label>
            <div className="col-sm-4">
              <EditableField
                dataType="boolean"
                data={profile.Driver.WorkVisaRequired}
                save={async (data) => await saveAsyncProfile({ WorkVisaRequired: data })}
              />
            </div>
            {profile.Driver.WorkVisaRequired && <>
              <label htmlFor="WorkVisaExpiryDate" className="col-sm-2 col-form-label">Visa Exipry</label>
              <div className="col-sm-4">
                <EditableField
                  required
                  dataType="date"
                  data={Moment.utc(profile.Driver.WorkVisaExpiryDate).tz("America/New_York").toDate()}
                  save={async (data) => await saveAsyncProfile({ WorkVisaExpiryDate: data })}
                />
              </div>
            </>}
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Worker's Comp. Expires</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="date"
                data={Moment.utc(profile.Driver.WorkersCompensationExpiryDate).tz("America/New_York").toDate()}
                save={async (data) => await saveAsyncProfile({ WorkersCompensationExpiryDate: data })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Passport Number</label>
            <div className="col-sm-4">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 20 }}
                data={profile.Driver.PassportNumber}
                save={async (data) => await saveAsyncProfile({ PassportNumber: data })}
              />
            </div>
            <label className="col-sm-2 col-form-label">Passport Expires</label>
            <div className="col-sm-4">
              <EditableField
                required
                dataType="date"
                data={Moment.utc(profile.Driver.PassportExpiryDate).tz("America/New_York").toDate()}
                save={async (data) => await saveAsyncProfile({ PassportExpiryDate: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="PassportCountry" className="col-sm-2 col-form-label">Passport Country</label>
            <div className="col-sm-4">
              <EditableField
                data={profile.Driver.PassportCountry}
                renderDisplay={(data) => CountryCodes.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(e.target.value)}>
                  {CountryCodes.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ PassportCountry: data })}
              />
            </div>
          </div>

          <AuditInfo audit={profile.Driver.AuditInfo} />
          <img
            alt=""
            src={`/api/Document/DriverPhoto/${driverId}`}
            className="img-fluid h-25 rounded"
            style={{ cursor: 'pointer' }}
            onClick={() => setShowDriverPhotoPopup(true)}
          />
        </div>
      </div>
      {showDriverPhotoPopup &&
        <Documents
          IsWindow
          EntityType={DocumentEntityType.Driver}
          EntityId={driverId}
          EntityNumber={`Driver ${profile.Driver.DriverNumber}`}
          DocumentType={DocumentType.DriverPhoto}
          CloseDocumentModal={() => setShowDriverPhotoPopup(false)}
        />
      }
    </>);
  };

  if (props.isDialog) {
    return <Dialog
      className="dialog-w11/12"
      title={<Link to={`/Assets/Driver/${props.driverId}`}>Driver Profile {profile?.Driver.DriverNumber ?? ''}</Link>}
      onClose={() => {
        props.onClose();
      }}
    >
      {dataView()}
    </Dialog>
  }

  return dataView();
}

export default DriverProfile;