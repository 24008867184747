import AssetPanel from '../Assets/AssetPanel';

const AssetOwners = () => {
return <div className="form-row">
    <div className="col-lg-12 p-1">
    <AssetPanel
        searchTerm=""
        tabIndex={4}
    />
    </div>
</div>
}

export default AssetOwners;
